import React, {useState, useEffect, createRef} from "react";
import {Form, Button, Row, Col} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {useTranslation} from "react-i18next";
import {getHttpMessage, showPopup} from "../generics/alerts";

import * as billingAPI from "../../services/billingApi";

const MergeTagForm = ({tagToMerge, setShow, user, setReload}) => {
  const {t} = useTranslation(["tags", "shared"]);
  const ref = createRef();
  const [tags, setTags] = useState([]);
  const [params, setParams] = useState({});
  const [mergeOnTag, setMergeOnTag] = useState();

  const handleOnSubmit = (e) => {
    e.preventDefault();
    mergeTag(user.selected, {
      merge_tag_id: tagToMerge.id,
      merge_on_tag_id: mergeOnTag[0].id,
    });
  };

  useEffect(() => {
    async function fetchTags() {
      try {
        const resp = await billingAPI.getTags(user.selected, params);
        setTags(resp.data.data);
      } catch (err) {
        showPopup(getHttpMessage(err), "error");
      }
    }
    fetchTags();
  }, [params, user.selected, t]);

  const mergeTag = async (portfolio, payload) => {
    try {
      await billingAPI.mergeTag(portfolio, payload);
      showPopup(t("shared:success"), "success");
      setReload((prev) => !prev);
      setShow(false);
    } catch (err) {
      showPopup(getHttpMessage(err), "error");
      setShow(false);
    }
  };

  return (
    <Form onSubmit={handleOnSubmit} className="mt-2">
      <Form.Group as={Row} controlId="mergeTagForm">
        <Form.Label column sm={2} md={2} className="ml-5 p-1">
          {t("mergeTag")}
        </Form.Label>
        <Col sm={7} md={7} className="pr-3">
          <Form.Control
            disabled={true}
            type="text"
            placeholder="Enter Tag"
            value={tagToMerge.name}
            size="sm"
            className="p-2"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="mergeTagForm">
        <Form.Label column sm={2} md={2} className="ml-5 p-2">
          {t("mergeOn")}
        </Form.Label>
        <Col sm={7} md={7} className="p-1 ml-1">
          <Typeahead
            onInputChange={(e) => setParams({name: e})}
            onChange={(tag) => setMergeOnTag(tag)}
            id="tag-input"
            labelKey="name"
            filterBy={(option, props) => option.name !== tagToMerge.name}
            options={tags}
            placeholder={t("typeMergeOnTag")}
            size={"small"}
            newSelectionPrefix={t("newSelectionPrefix")}
            ref={ref}
            className="ml-0 pl-2 pr-3"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="p-0">
        <Col sm={10} md={10} className="pr-2">
          <Button
            type="submit"
            variant="danger"
            disabled={mergeOnTag ? false : true}
            className="mt-2 ml-2 ml-sm-2 pull-right"
          >
            {t("shared:merge")}
          </Button>
          <Button
            variant="light"
            className="mt-2  pull-right"
            onClick={() => setShow(false)}
          >
            {t("shared:cancel")}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default MergeTagForm;
