import {toast} from "react-toastify";
import i18next from "i18next";
import React, {useEffect} from "react";
import * as authApi from "../../services/authApi";
import {canShowUpdate, cancelUpdate} from "../../services/aminiUpdates";
import {useTranslation} from "react-i18next";
import {Row, Col, Button} from "react-bootstrap";
import {Link} from "react-router-dom";

//showPopup shows a react-toastify popup
export function showPopup(message, type, position = "top-right") {
  let toaster;
  switch (type) {
    case "success":
      toaster = toast.success;
      break;
    case "info":
      toaster = toast.info;
      break;
    case "warning":
      toaster = toast.warn;
      break;
    case "dark":
      toaster = toast.dark;
      break;
    default:
      toaster = toast.error;
      break;
  }
  const notify = () =>
    toaster(<div>{message}</div>, {
      position: position,
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      bodyClassName: "text-center",
    });
  notify();
}

export function getHttpMessage(err) {
  const httpResponse = err.response;
  if (!httpResponse) {
    return i18next.t("shared:internalError");
  }
  switch (httpResponse.status) {
    case 401:
      return i18next.t("shared:unauthorized");
    case 400:
      const data = httpResponse.data;
      const errors = data.Errors || data.Error || data;
      return decodeErrors(errors);
    case 500:
      return i18next.t("shared:internalError") + ": " + JSON.stringify(httpResponse.data);
    default:
      console.error(err);
      return i18next.t("shared:unknownError");
  }
}

export async function handleHttp(fn) {
  try {
    const resp = await fn();
    showPopup(i18next.t("shared:success"), "success");
    return resp;
  } catch (err) {
    showPopup(getHttpMessage(err));
    return err.response;
  }
}

//get error data and translate to appropriate wording
function decodeErrors(errData) {
  let errorBuffer = []; //holds the errors in the jsx
  errorBuffer.push(i18next.t("shared:requestFailedwithErrors"));

  //check if the object is iteratable (array)
  if (isIterable(errData)) {
    for (let err of errData) {
      if (err.error && err.error.length > 0) {
        addErrors(errorBuffer, i18next.t("genericError:" + err.error, {data: err.data}));
      } else {
        //if error is unknown, return a string which can be displayed
        addErrors(errorBuffer, typeof err === "string" ? err : JSON.stringify(err));
      }
    }
  } else {
    addErrors(
      errorBuffer,
      i18next.t("genericError:FAILED_REQUEST_WITH_ERRORS") + JSON.stringify(errData)
    );
  }
  return errorBuffer;
}
//add a bulleted error to an array of erros
function addErrors(errorBuffer, error) {
  errorBuffer.push(<li key={error}>{error}</li>);
}

//check if an object is iteratable
function isIterable(value) {
  return Symbol.iterator in Object(value);
}

//
export function showErrors(err, setErrors) {
  let errorBuffer = getHttpMessage(err);
  if (Array.isArray(errorBuffer)) {
    setErrors(errorBuffer);
  } else {
    showPopup(errorBuffer, "error");
  }
}

export function AminiUpdates() {
  const {t} = useTranslation(["fileupload"]);

  useEffect(() => {
    async function getUpdates() {
      let params = new URLSearchParams();
      params.set("limit", 1);
      params.set("offset", 0);
      try {
        let resp = await authApi.getAminiUpdates(params);
        if (resp.data.data.length === 1) {
          //get update data
          let update = resp.data.data[0];
          //check and show update
          if (canShowUpdate(update)) {
            createAndShowAminiUpdate(
              update,
              t("updatesHeading"),
              t("shared:cancel"),
              t("seeMore")
            );
          }
        }
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
    }

    getUpdates();
  }, [t]);
  return null;
}

/**
 * show update to user
 * @param {*} update
 */
function createAndShowAminiUpdate(update, heading, cancelText, visitText) {
  let message = update.heading;
  let toaster = toast.info;
  let body = (
    <div>
      <Col ms={12} className="text-primary px-2 text-left">
        {heading}
      </Col>
      <Row className="my-2 px-2">
        <Col md={7} xs={7} className="text-secondary small">
          {message.length > 50 ? message.substr(0, 50) + "..." : message}
        </Col>
        <Col md={5} xs={5}>
          <Button
            as={Link}
            size="sm"
            className="small"
            to="/aminiupdates"
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => cancelUpdate(update.id)}
          >
            {"See More"}
          </Button>
        </Col>
      </Row>
    </div>
  );
  const notify = () =>
    toaster(body, {
      position: "top-right",
      autoClose: 86400,
      hideProgressBar: true,
      onClose: () => cancelUpdate(update.id),
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      bodyClassName: "text-left",
    });
  notify();
}
