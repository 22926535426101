import React from "react";
import {Form} from "react-bootstrap";

const add = (arrList, obj) => {
  if (!arrList.includes(obj)) {
    arrList.push(obj);
  }
  return [...arrList];
};

const remove = (arrList, itemObj) => {
  const index = arrList.indexOf(itemObj);
  if (index >= 0) arrList.splice(index, 1);
  return [...arrList];
};

export function CheckBox({itemObj, selectedData, setSelectedData, selectAllInSearch}) {
  const isSelected = selectedData.includes(itemObj);

  const onClick = (e) => {
    if (e.target.checked) {
      setSelectedData(add(selectedData, itemObj));
    } else {
      setSelectedData(remove(selectedData, itemObj));
    }
  };
  return (
    <Form.Check
      type="checkbox"
      checked={isSelected}
      onChange={onClick}
      disabled={selectAllInSearch}
    />
  );
}

export function CheckBoxAll({allData, selectedData, setSelectedData, selectAllInSearch}) {
  const isChecked = selectedData.length > 0 && selectedData.length === allData.length;
  let onClick = (e) => {
    if (e.target.checked) {
      setSelectedData([...allData]);
    } else {
      setSelectedData([]);
    }
  };
  return (
    <Form.Check
      type="checkbox"
      id="check_and_uncheck_all"
      onChange={onClick}
      disabled={selectAllInSearch}
      checked={isChecked}
    />
  );
}
