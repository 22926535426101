import React, {useState, useContext, useEffect, useCallback} from "react";
import {Form, Row, Col, Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {usePermissions} from "../permissions";
import {useTranslation} from "react-i18next";
import {SaveButton} from "../generics/buttons";
import {UserContext} from "../login/userContext";
import {showPopup, showErrors} from "../generics/alerts";
import moment from "moment";
import * as meteringAPI from "../../services/meteringApi";
import {RegisterButton, CancelButton} from "../generics/buttons";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import {ListHeader} from "../generics/headers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  getFormProps,
  FormField,
  FormSelect,
  FormErrors,
  FormCheck,
} from "../generics/formsFields";
import {
  faMinusCircle,
  faPlusCircle,
  faMoneyBillAlt,
} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import {formatFormDateToRFC3339} from "../../services/date";

export default function CreateTariff() {
  const {t} = useTranslation(["tariffs"]);
  const [disable, setDisable] = useState(false);
  const [errors, setErrors] = useState([]);
  const user = useContext(UserContext);
  const [vendors, setVendors] = useState([]);
  const history = useHistory();
  var tariff = {
    name: "",
    block_rates: [],
    tou_rates: [],
  };
  async function sendToAPI(portfolio, tariff) {
    //if sms is supported, the keyword and details should be added
    if (tariff.supports_sms && (!tariff.details || !tariff.sms_keyword)) {
      setErrors([t("detailsRequired")]);
      return;
    }
    var tariffData = [tariff];
    setErrors([]);
    setDisable(true);
    try {
      await meteringAPI.createTariff(portfolio, tariffData);
      showPopup(t("shared:success"), "success");
      history.push("/tariffs");
    } catch (err) {
      showErrors(err, setErrors);
    }
    setDisable(false);
  }

  const fetchVendors = useCallback(
    async function () {
      try {
        const resp = await meteringAPI.getVendors(user.selected, {});
        let vendorJson = [];
        resp.data.forEach((vendor) => {
          vendorJson.push({name: vendor.Name, value: vendor.VendorId});
        });
        setVendors(vendorJson);
      } catch (err) {
        showPopup(t("vendorError") + ": " + err);
      }
    },
    [t, user.selected]
  );

  useEffect(() => {
    fetchVendors();
  }, [fetchVendors]);

  return (
    <div>
      <ListHeader title={t("registerNewTariff")} icon={faMoneyBillAlt} />
      <div className="mt-2 shadow bg-white border rounded">
        <CreateTariffForm
          tariff={tariff}
          sendToAPI={sendToAPI}
          disable={disable}
          vendors={vendors}
          errors={errors}
        />
      </div>
    </div>
  );
}

export function CreateTariffForm({tariff, sendToAPI, disable, vendors, errors}) {
  const {t} = useTranslation(["tariffs"]);
  const [blockRates, setBlockRates] = useState(tariff.block_rates);
  const [touRates, setTouRates] = useState(tariff.tou_rates);
  const user = useContext(UserContext);
  const {register, handleSubmit} = useForm();
  const [vendorRules, setVendorRules] = useState(tariff.vendor_rules);

  // prep props for form fields
  const name = getFormProps("name", t("name"), "text", "true", tariff.name);
  const vendorTariffID = getFormProps(
    "vendor_tariff_id",
    t("vendorTariffId"),
    "text",
    "false",
    tariff.vendor_tariff_id
  );
  const baseRate = getFormProps(
    "base_rate",
    t("baseRate"),
    "number",
    "true",
    tariff.base_rate,
    "",
    [],
    "false",
    "",
    "0.01",
    "0"
  );
  const planCharge = getFormProps(
    "monthly_charge",
    t("planCharge"),
    "number",
    "false",
    tariff.monthly_charge,
    "",
    [],
    "false",
    "",
    "0.01",
    "0"
  );
  const monthlyChargeDay = getFormProps(
    "monthly_charge_day",
    t("monthlyChargeDay"),
    "number",
    "false",
    tariff.monthly_charge_day,
    "",
    [],
    "false",
    t("placeHolder"),
    "1",
    "1",
    "31"
  );
  const lowBalance = getFormProps(
    "low_balance_warning",
    t("lowBalanceWarning"),
    "number",
    "true",
    tariff.low_bal_warning,
    "",
    [],
    "false",
    "",
    "0.01",
    "0"
  );
  const minimumTopUp = getFormProps(
    "min_top_up",
    t("minimumTopUp"),
    "number",
    "true",
    tariff.minimum_topup,
    "",
    [],
    "false",
    "",
    "0.01",
    "0"
  );
  const vendor = getFormProps(
    "vendor_type",
    t("vendorType"),
    "select",
    "false",
    tariff.vendor_id,
    t("vendorType"),
    vendors
  );

  const smsKeyword = getFormProps(
    "sms_keyword",
    t("smsKeyword"),
    "text",
    "false",
    tariff.sms_keyword
  );
  const details = getFormProps(
    "details",
    t("tariffDetails"),
    "text",
    "false",
    tariff.details
  );
  const enableSMSSupport = getFormProps(
    "supports_sms",
    t("smsSupport"),
    "switch",
    "false",
    tariff.supports_sms
  );

  let country_id = user.permissions[user.selected].countryID;
  const onSubmit = (data) => {
    var tariffData = {
      id: tariff.id,
      name: data.name,
      base_rate: +data.base_rate,
      monthly_charge: +data.monthly_charge,
      monthly_charge_day:
        +data.monthly_charge_day === 0 ? null : +data.monthly_charge_day,
      low_bal_warning: +data.low_balance_warning,
      block_rates: blockRates,
      tou_rates: touRates,
      minimum_topup: +data.min_top_up,
      vendor_id: data.vendor_type,
      vendor_rules: vendorRules == null ? null : vendorRules,
      vendor_tariff_id: data.vendor_tariff_id,
      sms_keyword: data.sms_keyword,
      details: data.details,
      supports_sms: data.supports_sms,
      country_id,
    };

    sendToAPI(user.selected, tariffData);
  };

  const canView = usePermissions(meteringAPI.TARIFFS_PATH, "POST");
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {!canView ? t("registerPermission") : t("sendNote")}
    </Tooltip>
  );

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4">
        <Row>
          <FormErrors errors={errors}></FormErrors>
          <FormField register={register} {...name}></FormField>
          <FormField register={register} {...baseRate}></FormField>
          <FormField register={register} {...planCharge}></FormField>
          <FormField register={register} {...monthlyChargeDay}></FormField>
          <FormSelect register={register} {...vendor}></FormSelect>
          <FormField register={register} {...lowBalance}></FormField>
          <FormField register={register} {...minimumTopUp}></FormField>
          <FormField register={register} {...vendorTariffID}></FormField>
          <FormField register={register} {...smsKeyword}></FormField>
          <FormField register={register} {...details} maxLength={50}></FormField>
          <FormCheck register={register} {...enableSMSSupport}></FormCheck>
        </Row>
        <div>
          <Row>
            <Col xs={12} lg={6}>
              {t("blockRates")}
              <BlockRates
                blockRates={blockRates}
                setBlockRates={setBlockRates}
                tariff={tariff}
                renderTooltip={renderTooltip}
              />
            </Col>
            <Col xs={12} lg={6}>
              {t("touRates")}
              <TouRates
                touRates={touRates}
                setTouRates={setTouRates}
                renderTooltip={renderTooltip}
              />
            </Col>
          </Row>
        </div>
        <TariffButtons
          name={tariff.name}
          disable={disable}
          tariff={tariff}
          setVendorRules={setVendorRules}
        />
      </Form>
    </div>
  );
}

function TariffButtons({name, disable, tariff, setVendorRules}) {
  const {t} = useTranslation(["tariffs"]);
  if (name) {
    return (
      <Row>
        <Col>
          <ShowAdvanced setVendorRules={setVendorRules} tariff={tariff} />
        </Col>
        <SaveButton label={t("saveChanges")} disabled={disable}></SaveButton>
      </Row>
    );
  }
  return (
    <>
      <ShowAdvanced setVendorRules={setVendorRules} tariff={tariff} />
      <Row>
        <CancelButton label={t("shared:cancel")} to="/tariffs"></CancelButton>
        <RegisterButton label={t("shared:register")} disabled={disable}></RegisterButton>
      </Row>
    </>
  );
}
export function ShowAdvanced({setVendorRules, tariff}) {
  const [showJson, setShowJson] = useState(!!tariff.vendor_rules);

  const onClick = () => {
    if (showJson) {
      setVendorRules(null);
      setShowJson(false);
    } else {
      setShowJson(true);
    }
  };

  return (
    <>
      <Form.Check
        className="mb-3"
        type="switch"
        id="custom-switch"
        label="Show advanced"
        defaultChecked={showJson}
        onChange={onClick}
      />
      {showJson ? <Json setVendorRules={setVendorRules} tariff={tariff} /> : null}
    </>
  );
}

function Json({setVendorRules, tariff}) {
  const {t} = useTranslation(["tariffs"]);
  var label = t("vendorRulesInstructions");
  var placeholder = tariff.vendor_rules;
  return (
    <Form.Group controlId="formGridCity">
      <Form.Label>{label}</Form.Label>
      <JSONInput
        locale={locale}
        placeholder={placeholder}
        colors={{
          background: "#FCFDFD",
          string: "#000000", // overrides theme colors with whatever color value you want
        }}
        height="200px"
        onChange={(e) => setVendorRules(e.jsObject)}
      ></JSONInput>
    </Form.Group>
  );
}

export function BlockRates({blockRates, setBlockRates, renderTooltip}) {
  const {t} = useTranslation(["tariffs"]);
  const [minUsage, setMinUsage] = useState("");
  const [maxUsage, setMaxUsage] = useState("");
  const [multiplier, setMultiplier] = useState("");
  const type = "number";
  const energyStep = "1";
  const energymin = "0";
  var label = (
    <pre>
      {t("energyUse")} {"           "}
      {t("multiplier")}
    </pre>
  );
  function addBlockRates(e) {
    e.preventDefault();
    blockRates.push({
      min_usage: +minUsage,
      max_usage: +maxUsage,
      multiplier: +multiplier,
    });
    setBlockRates([...blockRates]);
    setMinUsage("");
    setMaxUsage("");
    setMultiplier("");
  }
  return (
    <>
      {label}
      {blockRates.map((blockrate, i) => (
        <NewRateForm
          Rate={blockrate}
          Rates={blockRates}
          step={energyStep}
          setRates={setBlockRates}
          renderTooltip={renderTooltip}
          min={blockrate.min_usage}
          max={blockrate.max_usage}
          multiplier={blockrate.multiplier}
          key={`${blockrate.min_usage}-${blockrate.max_usage}`}
        />
      ))}
      <RateForm
        type={type}
        min={minUsage}
        max={maxUsage}
        step={energyStep}
        multiplier={multiplier}
        renderTooltip={renderTooltip}
        energymin={energymin}
        setMin={setMinUsage}
        setMax={setMaxUsage}
        setMultiplier={setMultiplier}
        addRates={addBlockRates}
      />
    </>
  );
}

export function TouRates({touRates, setTouRates, renderTooltip}) {
  const {t} = useTranslation(["tariffs"]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [multiplier, setMultiplier] = useState("");
  const type = "time";
  const step = "any";
  const energymin = "any";

  function addTouRates(e) {
    e.preventDefault();
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    var newdate = year + "/" + month + "/" + day;
    var StartDate = newdate + " " + startTime;
    var EndDate = newdate + " " + endTime;
    touRates.push({
      start_time: formatFormDateToRFC3339(StartDate),
      end_time: formatFormDateToRFC3339(EndDate),
      multiplier: +multiplier,
    });
    setTouRates([...touRates]);
    setStartTime("");
    setEndTime("");
    setMultiplier("");
  }
  var label = (
    <pre>
      {t("startTime")}
      {"        "} {t("endTime")} {"         "}
      {t("multiplier")}{" "}
    </pre>
  );
  return (
    <>
      {label}
      {touRates.map((tou_rate, i) => (
        <NewRateForm
          key={`${tou_rate.start_time}-${tou_rate.end_time}`}
          Rate={tou_rate}
          Rates={touRates}
          setRates={setTouRates}
          renderTooltip={renderTooltip}
          min={moment.utc(tou_rate.start_time).format("hh:mm A")}
          max={moment.utc(tou_rate.end_time).format("hh:mm A")}
          multiplier={tou_rate.multiplier}
        />
      ))}
      <RateForm
        type={type}
        min={startTime}
        max={endTime}
        step={step}
        multiplier={multiplier}
        energymin={energymin}
        renderTooltip={renderTooltip}
        setMin={setStartTime}
        setMax={setEndTime}
        setMultiplier={setMultiplier}
        addRates={addTouRates}
      />
    </>
  );
}

export function RateForm({
  type,
  min,
  max,
  multiplier,
  setMin,
  setMax,
  step,
  energymin,
  setMultiplier,
  renderTooltip,
  label,
  addRates,
}) {
  var size = 3;
  const multiplierStep = "0.01";
  const multiplierMin = "0";
  return (
    <Form.Label>
      {label}
      <Form.Row>
        <Form.Group as={Col} controlId="formGridCity" sm={size}>
          <Form.Control
            type={type}
            step={step}
            min={energymin}
            value={min}
            onChange={(e) => setMin(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridCity" sm={size}>
          <Form.Control
            type={type}
            value={max}
            step={step}
            min={energymin}
            onChange={(e) => setMax(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridCity" sm={size}>
          <Form.Control
            type="number"
            step={multiplierStep}
            min={multiplierMin}
            value={multiplier}
            onChange={(e) => setMultiplier(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridCity" sm={size}>
          <Button variant="primary" disabled>
            <FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon>
          </Button>{" "}
          <OverlayTrigger
            placement="bottom"
            delay={{show: 250, hide: 400}}
            overlay={renderTooltip}
          >
            <Button variant="primary" onClick={addRates}>
              <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
            </Button>
          </OverlayTrigger>
        </Form.Group>
      </Form.Row>
    </Form.Label>
  );
}

export function NewRateForm({
  Rate,
  Rates,
  setRates,
  min,
  max,
  multiplier,
  label,
  renderTooltip,
}) {
  function deleteRate(e) {
    e.preventDefault();
    var index = Rates.indexOf(Rate);
    Rates.splice(index, 1);
    setRates([...Rates]);
  }

  var size = 3;
  return (
    <Form.Label>
      {label}
      <Form.Row>
        <Form.Group as={Col} controlId="formGridCity" sm={size}>
          <Form.Control defaultValue={min} disabled></Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridCity" sm={size}>
          <Form.Control defaultValue={max} disabled></Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridCity" sm={size}>
          <Form.Control defaultValue={multiplier} disabled></Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridCity" sm={size}>
          <Button variant="primary" onClick={deleteRate}>
            <FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon>
          </Button>{" "}
          <Button variant="primary" disabled>
            <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
          </Button>
        </Form.Group>
      </Form.Row>
    </Form.Label>
  );
}
