import React from "react";
import {Container} from "react-bootstrap";
export default function Elewa() {
  return (
    <Container fluid>
      <iframe
        title="elewa"
        width="100%"
        height="1100"
        src="https://datastudio.google.com/embed/reporting/82feb534-f6ca-4728-8987-1b03739a9e86/page/6zqLC"
        frameborder="0"
      ></iframe>
    </Container>
  );
}
