export const en = {
  NEW_METER_USED: "The new meter has been assigned to another account",
  ACCOUNT_HAS_NO_METER: "Account number has no meter assigned to it",
  NEW_ACCOUNT_NOTFOUND: "The new account number is not found",
  OLD_ACCOUNT_NOTFOUND: "The old account number not found",
  CUSTOMER_NOTFOUND: "Customer not found",
  BAD_REQUEST_BILLING: "Bad request in the Billing service",
  INVALID_VENDOR: "Invalid vendor",
  SITE_CODE_NOT_FOUND: "Site code not found",
  FAILED_REQUEST_WITH_ERRORS: "Failed request with the following error(s): ",
  ACCOUNT_NOT_FOUND: "Account number {{data}} not found",
  ACCOUNT_NUMBER_DOES_NOT_EXIST: "Account number {{data}} not found",

  //bonus disbursement
  FAILED_TO_CREATE_PAYMENT_IN_METERING:
    "Failed to create bonus payment of this account number {{data}} to meter vendor",
  NEGATIVE_BONUS_AMOUNT: "Failed to create the negative bonus amount of {{data}}",
  ACCOUNT_NUMBER_NOT_REGISTERED_IN_DB: "Account number {{data}} not found",
  CREATE_BONUS_LESS_THAN_50_ACCOUNT_NUMBERS:
    "Failed to create the bonus payment, the request exceed the 50 items",

  //site registration
  SITE_CODE_IN_USE: "Site code is already used",
  INVALID_TARIFF: "The tariff supplied is not found in our system",
  INVALID_DATE_COMMISSIONED: "Invalid commissioning date, please use format '2006-01-02'",

  //server registration
  DUPLICATE_SERVER_ID: "A server with the vendor server ID already exists",
  PORTFOLIO_REQUIRED: "Portfolio ID is required for Nova Sites.",
  COMMS_ENDPOINT_REQUIRED: "Server Endpoint is required",

  //copy tariff
  INVALID_SITE: "The site was not found in our system.",
  INVALID_DATA: "Invalid Data",
  TAG_ID_NOT_EXIST: "Tag was not found",
  MISSING_DATA: "Missing data",

  //customer update
  PHONENUMBER_MUST_ONLY_CONTAIN_NUMBERS: "Phone number contains only numbers",
  PHONENUMBER_MUST_START_WITH_COUNTRY_CODE: "Phone number must start with country code",
  INVALID_LANGUAGE_ID: "Invalid language ID",
  INVALID_GENDER_ID: "Gender ID can be M or F only",
  DATE_FORMAT_MUST_BE_IN_YYYY_MM_DD: "Accepted date format YYYY-MM-DD",
  INVALID_UUID: "Invalid UUID",

  //meterswap
  NO_DATA_PROVIDED: "No data provided in the request",
  ACCOUNT_NO_REQUIRED: "Account number required",
  OLD_METER_INACTIVE: "Old meter is inactive",

  //meter assignment
  FAILED_TO_GET_CUSTOMER_INFO: "Failed  to get the the customer details",
  ACCOUNT_NUMBER_NOT_FOUND: "Failed  to get the the customer details",
  METER_SERIAL_REQUIRED: "New meter serial number not provided",
  ACCOUNT_ALREADY_ASSIGNED: "Account already has an active meter allocation",
  NEW_METER_ALREADY_ACTIVE: "New meter already in use",

  //bulk account energy update
  EM_MISSING_ACCOUNT_NO_AND_ACCOUNT_ID: "Missing account number and account ID",
  EM_INVALID_ACCOUNT_COORDINATES: "Invalid account coordinates",
  DUPLICATE_PHONE_NUMBER: "The Phone Number is already used by another customer",
  DUPLICATE_CUSTOMER_ID_NUMBER: "The ID Number is already used by another customer",
};
