export const en = {
  portfolios: "Portfolios",
  portfoliosError: "Error fetching Portfolios",
  name: "Name",
  shortcode: "Short Code",
  countryid: "AMINI Country ID",
  searchBar: "Search by portfolio name or short code",
  addNew: "Add Portfolio",
  registerPermission: "You don't have permission to add new portfolio",
  addNewPortfolio: "Add New Portfolio",
  updatePortfolio: "Update Portfolio",
};

export const fr = {};

export const sw = {};
