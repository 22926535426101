export const en = {
  username: "Username",
  portfolio: "Portfolio",
  roles: "Roles",
  roleUsers: "Role Users",
  view: "View",
  role: "role",
  confirmDelete: "Are you sure you want to delete",
  noPermission: "You don't have permissions to add role",
  noDeletePermission: "You don't have permissions to delete a role",
  rolesError: "Error fetching roles",
  roleDetailsError: "Error fetching role details",
  viewer: "Viewer",
  confirmDel: "Do you want to delete this permission?",
  confirmAssign: "Do you want to create these permission?",
  confirmAssigns: "Do you want to create these permissions?",
  defaultValue: "permissions",
  assignPermissions: "Assign Permissions",
  verb: "Verb",
  endpoint: "Endpoint",
  delete: "Delete",
};

export const fr = {};

export const swa = {};
