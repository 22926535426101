import React, {useState, useContext, useEffect, useCallback} from "react";
import {UserContext} from "../login/userContext";
import {Button, Modal, Form, Row, Col} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as billingAPI from "../../services/billingApi";
import * as authAPI from "../../services/authApi";

import {getFormProps, FormField, FormErrors, FormSelect} from "../generics/formsFields";
import {RegisterButton} from "../generics/buttons";
import {showPopup, showErrors, getHttpMessage} from "../generics/alerts";

export function PortfolioModal({show, setShow, title, portfolio, refresh, setRefresh}) {
  const handleClose = () => setShow(false);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className="mb-4 text-primary">
            <FontAwesomeIcon icon="earth-africa" /> {title}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PortfolioForm
          setShow={setShow}
          portfolio={portfolio}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Modal.Body>
    </Modal>
  );
}

function PortfolioForm({setShow, portfolio = {}, refresh, setRefresh}) {
  const user = useContext(UserContext);
  const {t} = useTranslation(["portfolios", "shared"]);
  const {register, handleSubmit} = useForm();
  const [loading, setLoading] = useState();
  const [errors, setErrors] = useState([]);
  const [countries, setCountries] = useState([]);

  const fetchCountries = useCallback(
    async function () {
      try {
        const resp = await billingAPI.getCountries(user.selected, {});
        let country_list = [];
        resp.data.data.map((ci) => {
          return country_list.push({name: ci.name, value: ci.id});
        });
        setCountries(country_list);
      } catch (err) {
        showPopup(getHttpMessage(err), "error");
      }
    },
    [user.selected]
  );

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const countryIDs = getFormProps(
    "country_id",
    t("countryid"),
    "select",
    "true",
    portfolio.country_id ? portfolio.country_id : "",
    "",
    countries
  );

  const name = getFormProps(
    "name",
    t("name"),
    "text",
    "true",
    portfolio.name ? portfolio.name : ""
  );

  const shortCode = getFormProps(
    "short_code",
    t("shortcode"),
    "text",
    "true",
    portfolio.short_code ? portfolio.short_code : ""
  );

  const onSubmit = (data) => {
    data.country_id = parseInt(data.country_id);
    if (Object.keys(portfolio).length === 0) {
      registerPortfolio(data);
    } else {
      data.id = portfolio.id;
      updatePortfolio(data);
    }
  };

  async function registerPortfolio(data) {
    try {
      setLoading(true);
      await authAPI.addPortfolio(data);
      showPopup(t("shared:success"), "success");
      setShow(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setLoading(false);
  }

  async function updatePortfolio(data) {
    try {
      setLoading(true);
      await authAPI.updatePortfolio(data);
      showPopup(t("shared:success"), "success");
      setRefresh(!refresh);
      setShow(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setLoading(false);
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <FormErrors errors={errors}></FormErrors>
          <FormField md={12} lg={12} register={register} {...name} />
          <FormField md={12} lg={12} register={register} {...shortCode} />
          <FormSelect md={12} lg={12} register={register} {...countryIDs} />
          <Col xs={6}>
            <Button
              variant="light"
              onClick={() => setShow(false)}
              className="mt-4 p-3 pull-right"
            >
              {t("shared:cancel")}
            </Button>
          </Col>
          <RegisterButton
            disabled={loading}
            xs={6}
            md={6}
            lg={6}
            label={loading ? t("shared:loading") : t("shared:save")}
          ></RegisterButton>
        </Row>
      </Form>
    </div>
  );
}
