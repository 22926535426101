export const en = {
  //send SMS
  pageTitle: "User File Upload Page",
  pageSubTitle: "Send SMS",
  sendSMS: "Send SMS",
  sending: "Sending SMS...",
  sendNote: "Send SMS to selected list.",
  sendSMSPermission: "You don't have permission to send SMS.",
  addSMS: "Write SMS message here...",
  smsBody: "You must add the SMS to send.",
  phoneNumberStructure: "Phone number must start with Country code e.g 255 for TZ",

  //Tariff Change/assign
  tariffChangeTitle: "Tariff Change",
  switchTariff: "Switch Tariff",
  existAccount: "Account number must be already registered in AMINI and meter vendors",
  activeAccount: "Account number must be active both in AMINI and meter vendors",

  selectTariffError: "You have not select the tariff to assign/switch",
  emptyList: "Empty list of account numbers",
  selectTariff: "Please select a tariff to assign",
  bulkAssign: "Bulk Assign",
  registerCustomers: "Register Customers",
  assignMeters: "Assign Meters",

  //deactivate accounts
  deactivateAccountTitle: "Deactivate Accounts",
  deactivate: "Deactivate",

  //activate accounts
  activateAccounts: "Activate Accounts",

  //bonus payments
  bonusTitle: "Disburse Bonus",
  bonusCardTitle: "Bonus Disbursement",
  bonusAmount: "Add Bonus Amount :",
  bonusButton: "Disburse Bonus",
  setBonusError: "You have not input the bonus Amount",
  // assign meter
  accountNumber: "Account #",
  meterSerial: "Meter Serial #",
  server: "Server",
  assign: "Assign",
  assignSuccess: "Meter successfully assigned",

  // bulk assign
  existingAccountsOnly: "Account numbers should already be registered in AMIni",
  validSerialNumbers:
    "Serial numbers should be the hardware serial number of the meter to assign to the account",
  vendorServerIDOptional:
    "Vendor Server ID is only required if there are multiple servers serving a single site",
  missingHeaders: "Missing file headers: ",
  duplicates: "Duplicates: ",

  // bulk upload
  upload: "Upload",
  uploadCustomers: "Upload Customers",
  load: "Load ",
  bulkRegister: "Bulk Registration",
  downloadSample: "Download Sample",
  siteInstruction: "All sites must already be registered in AMIni",
  languageInstruction: "Language must be ENGLISH or KISWAHILI",
  uploadErrorMissingHeaders: "Upload Error: Missing data",
  reUpload: "Re-upload",
  rows: "row(s)",
  pleaseUpload: "Upload a CSV or Excel file with the following headers:",
  selectFile: "Select File to Upload",
  sampleFile: "sample_file_to_upload.csv",
  fileUpload: "File Upload",
  dataPreview: "Data Preview",
  errors: "Errors",
  uploading: "Uploading...",
  loadRow: "Load {{count}} row(s)",
  downloadErrors: "Download Errors",

  //change meter state
  stateChangeTitle: "Change State",
  meterStateChangeTitle: "Change Meter State",
  selectState: "Select state: ",
  switchState: "Switch State",
  selectStateError: "Please select the meter state to switch",
  select: "Select",
  limitWarning:
    "For the request to be processed, the list should have equal or less than 50 account numbers.",
  emptyAccountWarning: "Empty list can not be processed.",

  //Assign Tag
  assignTagCardTitle: "Assign Tag",
  assignTagTitle: "Assign Tag",
  newSelectionPrefix: "Add new tag: ",
  tag: "Tag",
  typeTag: "Type a tag...",
  assignButton: "Assign",
  exportFailure: "Failed to export data",
  noDataToExport: "No data to export",
  exportCurPage: "Export Current Page",
  exportAllData: "Export All Data",
  selectAllFields: "Select All Fields",
  downloadData: "Download Data",
  customDownload: "Customize Download",
  downloading: "Downloading...",

  //Update energy account
  updateEnergyAccountTitle: "Bulk Update Energy Accounts Info",
  update: "Update",
  updateAccTitle: "Update Account",
  seeMore: " See More",
  updatesHeading: "We added new features to Amini!",
};

export const fr = {};
