import React from "react";
import {Form, Row, Col, Dropdown, DropdownButton} from "react-bootstrap";
import Pagination from "react-js-pagination";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  CSVDownloads,
  CSVDownloadAllData,
  SelectFieldsModal,
  UploadProgressBar,
} from "../generics/csvDownloads";

/**
 * Hook to paginate and download a csv file
 * @param {Number} page Active page
 * @param {Number} limit Count of items per page
 * @param {Number} count Total count of items which you are going to display
 * @param {Function} setPage Page change handler. Receive pageNumber as arg
 * @param {Function} setLimit Set count of items per page
 * @param {string} filename the csv filename
 * @param {Array} data list of data to be exported
 * @param {Function} getAllData function to call and return all data that is to be exported *
 * @param {Array} headers optional array of headers to use for csv file
 * @param {Function} setFieldsToExport required function to set the fields which will be export from the downloaded data
 * @param {number} progress contains the progress for data exports
 * @returns
 */

export function ListPagination({
  page,
  limit,
  count,
  setPage,
  setLimit,
  filename = "",
  data = [],
  getAllData,
  headers,
  setFieldsToExport,
  progress,
}) {
  const {t} = useTranslation(["shared"]);
  return (
    <Row>
      <Col xs={5} md={2}>
        <Form.Control
          as="select"
          onChange={(e) => {
            setLimit(Number(e.target.value));
          }}
        >
          <option value="10">{t("itemsPerPage")}</option>
          <option value="10">10</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
        </Form.Control>
      </Col>
      <Col xs={1} md={2}>
        <Form inline>
          <Form.Group>
            <Form.Label>Total Items: </Form.Label>
            <Form.Control
              style={{width: "70px"}}
              className={"mx-1"}
              type="text"
              value={count}
              disabled
            />
          </Form.Group>
        </Form>
      </Col>
      <Col xs={1} md={3} />
      <Col xs={4} md={3}>
        <Pagination
          innerClass="pagination float-right"
          activePage={page}
          itemsCountPerPage={limit}
          totalItemsCount={count}
          pageRangeDisplayed={5}
          onChange={(pageNumber) => setPage(pageNumber)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </Col>
      <Col xs={1} md={2}>
        {progress === 0 ? (
          <DropdownButton
            className="float-right"
            variant="primary"
            title={
              <span className="mx-1">
                <FontAwesomeIcon icon="file-export"></FontAwesomeIcon> Download
              </span>
            }
          >
            <Dropdown.Item
              onClick={() => {
                CSVDownloads(filename, data, headers);
              }}
            >
              Download Current Page
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                CSVDownloadAllData(filename, getAllData, headers);
              }}
            >
              Download All data
            </Dropdown.Item>
            <SelectFieldsModal
              data={data}
              filename={filename}
              getAllData={getAllData}
              headers={headers}
              progress={progress}
            />
          </DropdownButton>
        ) : (
          <UploadProgressBar progress={progress} />
        )}
      </Col>
    </Row>
  );
}
