export const en = {
  smsTemplates: "SMS Templates",
  templateType: "Template Type",
  message: "Message",
  Language: "Template Language",
  MessageID: "Msg ID",
  phoneNumber: "Phone No.",
  smsList: "SMS Logs",
  smsType: "Type",
  unauthorized: "User not authorized to access this data",
  badRequest: "Bad Request parameters, please check the search parameters and resubmit",
  from: "From",
  to: "To",
  phone: "Phone Number",
  incoming: "Incoming SMS",
  outgoing: "Outgoing SMS",
  date: "Date",
  createTemplate: "Create Template",
  languageType: "Language",
  language1: "English",
  language2: "Kiswahili",
  countryInstance: "Country",
  textArea: "Message",
  cancel: "Cancel",
  edit: "Edit",
  editTemplate: "Edit Template",
  placeholder: "Enter template message",
  all: "All",
  actions: "Actions",
  resend: "Resend",
  sendSuccess: "Message succefully sent",
  noRecordsFound: "None",
  resendConfirm: "Are you sure you want to resend this SMS?",
  sending: "Sending...",
  status: "Status",
  notes: "Notes",
  smsID: "SMS ID {{ref}}",
  referenceID: "Reference ID",
  searchbycontent: "Search by SMS contents...",
  allTypes: "All types",
  searchbycountry: "All Countries",
  switchProvider: "Switch SMS Gateway",
  currentProvider: "SMS provider set to ",

  //SMS status
  allStatus: "All Status",
  new: "New",
  forwared: "Forwarded",
  sent: "Sent",
  failed: "Failed",
  received: "Received",
  resolved: "Resolved",
  delivered: "Delivered",
};

export const fr = {};
