import React, {useState, useContext, useEffect} from "react";
import {Card} from "react-bootstrap";
import {Form, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import {Typeahead} from "react-bootstrap-typeahead";
import {UserContext} from "../login/userContext";
import {usePermissions, Unauthorized} from "../permissions";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {FileUpload, Instructions, ErrorList} from "../generics/fileUploads";
import * as billingAPI from "../../services/billingApi";

export default function BulkAssignTag() {
  const {t} = useTranslation(["fileupload", "shared"]);
  const ref = React.createRef();
  const user = useContext(UserContext);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState([]);
  const [params, setParams] = useState({});
  const [selectedTag, setSelectedTag] = useState([]);
  const [tags, setTags] = useState([]);

  const canView = usePermissions(billingAPI.ASSIGN_TAG_PATH, "POST");

  //Fetch tags from db
  useEffect(() => {
    async function fetchTags() {
      try {
        const resp = await billingAPI.getTags(user.selected, params);
        if (resp.data.data != null) {
          setTags(resp.data.data);
        }
      } catch (err) {
        setErrors(getHttpMessage(err));
      }
    }
    fetchTags();
  }, [params, setErrors, user.selected]);

  if (!canView) {
    return <Unauthorized />;
  }

  //sample file
  const filename = "sample_assign_tag.csv";
  const headers = "account_number";
  const sampleData = [["account_number"], ["00400001"], ["232-2-1"]];

  //instructions
  const pageTitle = t("assignTagTitle");
  const reminders = [t("existAccount"), t("activeAccount")];

  async function bulkAssignTab(data, config) {
    const payload = {};
    let accounts = [];
    payload.search_params = "";
    payload.tag = selectedTag[0].name;
    if (data.length > 0) {
      data.forEach((acc) => {
        accounts.push(acc.account_number);
      });
      payload.account_nos = accounts;
    } else {
      setErrors([t("emptyList")]);
      setProgress(0);
      return;
    }

    try {
      await billingAPI.assignTag(user.selected, payload, config);
      showPopup(t("shared:success"), "success");
      resetStates();
    } catch (err) {
      setErrors(getHttpMessage(err));
      setProgress(0);
    }
  }

  const resetStates = () => {
    setProgress(0);
    setData([]);
    setLoaded(false);
    setErrors([]);
    setSelectedTag([]);
  };

  return (
    <div>
      <Card className="shadow">
        <Card.Body className="p-4">
          <Instructions
            title={pageTitle}
            isLoaded={isLoaded}
            headers={headers}
            filename={filename}
            sampleData={sampleData}
            reminders={reminders}
          />
          <Col>
            <Form.Group as={Row}>
              <Col sm={1}>
                <Form.Label>{t("tag")}:</Form.Label>
              </Col>
              <Col xm={12} md={4}>
                <Typeahead
                  clearButton
                  onInputChange={(e) => setParams({name: e})}
                  onChange={(tag) => setSelectedTag(tag)}
                  allowNew={true}
                  id="tag-input"
                  labelKey="name"
                  options={tags}
                  placeholder={t("typeTag")}
                  size={"small"}
                  newSelectionPrefix={t("newSelectionPrefix")}
                  ref={ref}
                  className="mb-3"
                />
              </Col>
            </Form.Group>
          </Col>
          <FileUpload
            sampleData={sampleData}
            data={data}
            errors={errors}
            progress={progress}
            setData={setData}
            setErrors={setErrors}
            setProgress={setProgress}
            setLoaded={setLoaded}
            setColumns={setColumns}
            sendToAPI={bulkAssignTab}
            processButtonName={t("assignButton")}
            handleCancel={resetStates}
          />

          <ErrorList errors={errors} handleReUpload={resetStates} />

          <DataTable
            pagination
            highlightOnHover
            columns={columns}
            data={data}
            noDataComponent=""
          />
        </Card.Body>
      </Card>
    </div>
  );
}
