export const en = {
  pageTitle: "Tags",
  index: "#",
  tag: "Tag",
  editTag: "Edit Tag",
  mergeTag: "Merge Tag",
  assignedAccts: "Assigned Accounts",
  deleteTagMessage: "You want to delete {{tag}} tag assigned to {{count}} customer(s)?",
  deleteTitle: "Are you sure",
  searchFormPlaceholder: "Search Tag By Name",
  mergeOn: "On",
  typeMergeOnTag: "Type Tag...",
};

export const fr = {};

export const swa = {};
