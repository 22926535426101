export function validateData(fileData, sampleData) {
  const targetHeaders = sampleData[0];

  // make sure data isn't empty
  if (!fileData[0]) {
    return targetHeaders.join(", ");
  }

  // ensure headers match the expected headers
  const fileHeaders = Object.keys(fileData[0]);
  const rightLength = fileHeaders.length >= targetHeaders.length;
  const validHeaders = fileHeaders.every((fh) => targetHeaders.includes(fh));
  if (!rightLength || !validHeaders) {
    const missingHeaders = findMissingArrayElements(fileHeaders, targetHeaders);
    return missingHeaders.join(", ");
  }

  // else return empty string
  return "";
}

export function checkDuplicates(data) {
  let duplicates = [];

  const tempArray = [...data].sort();
  //start from 1 and check if previous equals to current data
  for (let i = 1; i < tempArray.length; i++) {
    if (tempArray[i - 1]["meter"] === tempArray[i]["meter"]) {
      duplicates.push(" meter# " + tempArray[i]["meter"]);
    }
    if (tempArray[i - 1]["phone"] === tempArray[i]["phone"]) {
      duplicates.push(" phone# " + tempArray[i]["phone"]);
    }
    //House number is only duplicate if site code is the same
    if (
      tempArray[i - 1]["house"] === tempArray[i]["house"] &&
      tempArray[i - 1]["site_code"] === tempArray[i]["site_code"]
    ) {
      duplicates.push(" house# " + tempArray[i]["house"]);
    }
  } //end for

  return duplicates;
}

function findMissingArrayElements(currentArray, expectedArray) {
  var currentSet = new Set(currentArray);
  return expectedArray.filter((x) => !currentSet.has(x));
}
