import {union, keys} from "lodash";
import {axiosAuthInstance} from "./axios";

export const PERMISSION_PATH = "/permissions";
export const ROLES_PATH = "/roles";
export const USERS_PATH = "/users";
export const TOKEN_PATH = "/users/createToken";
export const PORTFOLIO_PATH = "/portfolios";
export const ROLEPERMISSION_PATH = "/rolepermissions";
export const DELETE_ROLEPERMISSION_PATH = "/rolepermission";
export const AMINI_UPDATES_PATH = "/amini_updates";

export async function getCurrentUser() {
  const resp = await axiosAuthInstance().get("/currentUser");
  const permissions = {};
  resp.data.roles.forEach((r) => {
    r.permissions["countryID"] = r.country_id;
    if (permissions[r.portfolio]) {
      permissions[r.portfolio] = union(permissions[r.portfolio], r.permissions);
    } else {
      permissions[r.portfolio] = r.permissions;
    }
    permissions[r.portfolio]["countryID"] = r.country_id;
  });

  //choose portfolio returned  or default to the first portfolio which isn't AUTH
  let selectedPortfolio = resp.data.selectedPortfolio
    ? resp.data.selectedPortfolio
    : keys(permissions).find((p) => p !== "AUTH");
  return {
    username: resp.data.username,
    selected: selectedPortfolio,
    permissions,
  };
}

export function loginWithGoogle(token) {
  let resp = axiosAuthInstance().post("/googlelogin", {token});
  return resp;
}

export function logout(token) {
  // login without a token causes a logout
  let params = {};
  if (token) {
    params.token = token;
  }
  return axiosAuthInstance().post("/logout", params);
}

export function mockLogin() {
  return axiosAuthInstance().post("/googlelogin_test", {token: "fake-token"});
}

export function createPermission(data) {
  return axiosAuthInstance().post(PERMISSION_PATH, data);
}

export function getPermissions(id) {
  return axiosAuthInstance().get(PERMISSION_PATH, id);
}

export function deletePermission(data) {
  return axiosAuthInstance().delete(PERMISSION_PATH, {data});
}

export function deleteUpdate(id) {
  return axiosAuthInstance().delete(AMINI_UPDATES_PATH);
}

export function getUsers() {
  return axiosAuthInstance().get(USERS_PATH);
}

export function getSingleUser(id) {
  return axiosAuthInstance().get(USERS_PATH, {params: {id}});
}

//Create or refresh userToken
export function createUserToken(data) {
  return axiosAuthInstance().post(TOKEN_PATH, data);
}

export function getAllRoles() {
  return axiosAuthInstance().get(ROLES_PATH);
}

export function getSingleRole(id) {
  return axiosAuthInstance().get(ROLES_PATH, {params: {id}});
}
export function getPortfolios(query) {
  return axiosAuthInstance().get(PORTFOLIO_PATH, {params: query});
}

export function getAminiUpdates(query) {
  return axiosAuthInstance().get(AMINI_UPDATES_PATH, {params: query});
}
export function updateUser(data) {
  return axiosAuthInstance().put(USERS_PATH, data);
}

export function getRolePermissions(id) {
  return axiosAuthInstance().get(ROLEPERMISSION_PATH, {params: {id}});
}

export function deleteRolePermission(data) {
  return axiosAuthInstance().delete(DELETE_ROLEPERMISSION_PATH, {data});
}
export function createUser(data) {
  return axiosAuthInstance().post(USERS_PATH, data);
}

export function assignRolePermission(data) {
  return axiosAuthInstance().post(ROLEPERMISSION_PATH, data);
}

export function reformatRoleAssignments(roleAssignments) {
  var roleMap = {};

  roleAssignments.forEach((roleAssignment) => {
    if (!roleAssignment.roleId) {
      return;
    } else if (!roleMap[roleAssignment.roleId]) {
      roleMap[roleAssignment.roleId] = [roleAssignment.portfolioId];
    } else {
      roleMap[roleAssignment.roleId].push(roleAssignment.portfolioId);
    }
  });
  return Object.keys(roleMap).map((roleId) => {
    return {role_id: roleId, portfolios: roleMap[roleId]};
  });
}

// adds roleId and portfolioId to inital roles -- these come from the API without IDs
export function populateIds(initialRoles, roles, portfolios) {
  if (initialRoles != null) {
    initialRoles.forEach((r) => {
      if (r.name !== "") {
        const role = roles.find((role) => role.name === r.name);
        const portfolio = portfolios.find(
          (portfolio) => portfolio.short_code === r.portfolio
        );

        if (role) {
          r.roleId = role.id;
        }
        if (portfolio) {
          r.portfolioId = portfolio.id;
        }
      }
    });
    return initialRoles;
  }
}

export function addRole(data) {
  return axiosAuthInstance().post(ROLES_PATH, data);
}
export function addAminiUpdates(data) {
  return axiosAuthInstance().post(AMINI_UPDATES_PATH, data);
}
export function updateAminiUpdate(id, data) {
  return axiosAuthInstance().patch(AMINI_UPDATES_PATH, data);
}

export function deleteRole(data) {
  return axiosAuthInstance().delete(ROLES_PATH, {data});
}

export function deleteUser(data) {
  return axiosAuthInstance().delete(USERS_PATH, {data});
}

//a function to filter the existing permissions and permissions available to a role using permission id
export function getUnassignedPermissions(permissions, userPermissions) {
  var permissions_ids = [];
  var userPermissions_ids = [];
  var filteredPermissions = [];
  permissions.forEach((permission) => {
    permissions_ids.push(permission.id);
  });
  userPermissions.forEach((userPermissions) => {
    userPermissions_ids.push(userPermissions.id);
  });
  var filteredPermissionIds = permissions_ids.filter(
    (perm) => !userPermissions_ids.includes(perm)
  );
  filteredPermissionIds.forEach((filteredPermissionId) => {
    for (var i = 0; i < permissions.length + 1; i++) {
      if (permissions[i].id === filteredPermissionId) {
        filteredPermissions.push(permissions[i]);
        break;
      }
    }
  });
  var options = filteredPermissions.map((permission) => ({
    value: permission.id,
    label: [permission.verb, "   ", permission.endpoint],
  }));
  return options;
}

//a function to get selected permission ids that are used to assign permissions
export function getPermissionIds(selectedOptions) {
  var permissions_id = [];
  selectedOptions.forEach((permission) => {
    permissions_id.push(permission.value);
  });
  return permissions_id;
}

export function addPortfolio(data) {
  return axiosAuthInstance().post(PORTFOLIO_PATH, data);
}

export function updatePortfolio(data) {
  return axiosAuthInstance().patch(PORTFOLIO_PATH, data);
}
