import * as types from "./types";
import {axiosInstance} from "./axios";

// paths to the metering api for various pages
export const SITE_PATH = "/metering/api/sites";
export const SERVER_LIST_PATH = "/metering/api/servers";
export const METER_LIST_PATH = "/metering/api/meters";
export const BONUS_DETAILS_PATH = "/metering/api/bonus";
export const SCHEDULE_BONUS_PATH = "/metering/api/addsitebonus";
export const SITE_OWNER_PATH = "/metering/api/site_owner";
export const METER_ALLOCATION_PATH = "/metering/api/meterallocation";
export const VENDOR_LIST_PATH = "/metering/api/vendors";
export const BUNDLE_SUBSCRIPTION_PATH = "/metering/api/bundlesubscription";
export const METER_SWAP_PATH = "/metering/api/meterswap";
export const MOVE_CUSTOMER_PATH = "/metering/api/moveCustomer";
export const RESEND_TRANSACTION_PATH = "/metering/api/resendTransaction";
export const ASSIGN_METERS_PATH = "/metering/api/assignmeters";
export const DEACTIVATE_METER_PATH = "/metering/api/meter_deactivate";
export const COPY_TARIFF_PATH = "/metering/api/tariffs/copy";
export const TARIFFS_PATH = "/metering/api/tariffs";
export const ASSIGN_TARIFF_PATH = "/metering/api/tariffs/assign";
export const CHANGE_METER_STATE = "/metering/api/switch_mode";
export const UPDATE_CUSTOMER_ID = "/metering/api/meters/syncfromvendor";
export const REVERSE_TRANS = "/metering/api/reverse_transaction";

// getSites takes in a portfolio, and query to send to the metering API
export function getSites(portfolio, query) {
  const params = {};
  if (query.page) {
    params.offset = query.page > 0 ? (query.page - 1) * query.limit : 0;
  }
  if (query.limit) {
    params.limit = query.limit;
  }
  if (query.status) {
    params.status = query.status;
  }
  if (query.search) {
    if (types.isPrimitive(query)) {
      if (types.isGuid(query)) {
        params.id = query;
      }
    } else {
      params.name = query.search;
    }
  } else {
    params.name = query.name;
  }
  if (query.code) {
    params.code = query.code;
  }
  if (query.country_id) {
    params.country_id = query.country_id;
  }
  return axiosInstance(portfolio).get(SITE_PATH, {
    params: params,
  });
}

// registerSite takes in a payload, and sends to the metering API to save to db
export function registerSite(portfolio, sites) {
  return axiosInstance(portfolio).post(SITE_PATH, sites);
}

// updateSite takes in a payload, and sends to the metering API to update to db
export function updateSite(portfolio, sites) {
  return axiosInstance(portfolio).put(SITE_PATH, sites);
}
// getOwners takes in a portfolio, and query to send to the metering API
export function getSiteOwners(portfolio, query) {
  const params = {};

  if (query.search) {
    params.name = query.search;
  }
  return axiosInstance(portfolio).get(SITE_OWNER_PATH, {
    params: params,
  });
}

export function getBonusDetails(portfolio, query) {
  const params = {};

  if (query.search) {
    params.bonus_name = query.search;
  }
  return axiosInstance(portfolio).get(BONUS_DETAILS_PATH, {
    params: params,
  });
}

export function scheduleBonus(portfolio, payload) {
  return axiosInstance(portfolio).post(SCHEDULE_BONUS_PATH, payload);
}

export function getServers(portfolio, query) {
  let params = {};

  if (query.country_id) {
    params.country_id = query.country_id;
  }

  if (query.name) {
    params.name = query.name;
  }

  if (query.id) {
    params.id = query.id;
  }

  return axiosInstance(portfolio).get(SERVER_LIST_PATH, {
    params: params,
  });
}

export function getEnergyAccountDetails(portfolio, account_number) {
  const params = {};

  if (account_number) {
    params.account_number = account_number;
  }
  return axiosInstance(portfolio).get(METER_ALLOCATION_PATH, {
    params: params,
  });
}

export function registerServer(portfolio, server) {
  return axiosInstance(portfolio).post(SERVER_LIST_PATH, server);
}

export function meterSwap(portfolio, data) {
  return axiosInstance(portfolio).post(METER_SWAP_PATH, data);
}

export function moveCustomerAccount(portfolio, data) {
  return axiosInstance(portfolio).post(MOVE_CUSTOMER_PATH, null, {
    params: data,
  });
}

export function updateServer(portfolio, server_id, updateData) {
  let url = `${SERVER_LIST_PATH}?id=${server_id}`;
  return axiosInstance(portfolio).put(url, updateData);
}

export function getVendors(portfolio, query) {
  return axiosInstance(portfolio).get(VENDOR_LIST_PATH, {
    params: query,
  });
}

export function getBundleSubscriptions(portfolio, account_number) {
  const params = {};

  if (account_number) {
    params.accountno = account_number;
  }
  return axiosInstance(portfolio).get(BUNDLE_SUBSCRIPTION_PATH, {
    params: params,
  });
}

export function reprocessTransaction(portfolio, data) {
  const bodyData = [
    {
      account_number: data.account_no,
      amount: parseFloat(data.amount),
      reference: data.reference,
      payment_id: data.payment_id,
    },
  ];
  return axiosInstance(portfolio).post(RESEND_TRANSACTION_PATH, bodyData);
}

export function assignMeters(portfolio, data, config) {
  return axiosInstance(portfolio).post(ASSIGN_METERS_PATH, data, config);
}

export function deactivateMeter(portfolio, account_no) {
  return axiosInstance(portfolio).post(DEACTIVATE_METER_PATH, null, {
    params: {account_no: account_no},
  });
}

export function getTariffs(portfolio, query) {
  let params = new URLSearchParams();
  if (!query) {
    query = {};
  }

  const limit = query.limit;
  const page = query.page > 0 ? query.page - 1 : 0;

  if (limit) {
    params.append("limit", limit);
    params.append("offset", page * limit);
  }

  Object.assign(params, query);

  if (query.country_id) {
    params.append("country_id", query.country_id);
  }

  if (query.vendor_id) {
    params.append("vendor_id", query.vendor_id);
  }

  return axiosInstance(portfolio)
    .get(TARIFFS_PATH, {
      params: params,
    })
    .then((resp) => {
      return resp;
    });
}

export function createTariff(portfolio, tariffs) {
  return axiosInstance(portfolio).post(TARIFFS_PATH, tariffs);
}

export function reverseTransaction(portfolio, transaction) {
  let payload = {};
  payload.account_number = transaction.account_no;
  payload.amount = transaction.amount;
  payload.reference = transaction.reference;
  payload.payment_id = transaction.payment_id;
  let mno_name = transaction.mno;
  payload.source = mno_name.includes("CASH") ? "cash" : "bonus";
  return axiosInstance(portfolio).post(REVERSE_TRANS, payload);
}

// copyTariff copy tariff from one site to another/other site(s)
export function copyTariff(portfolio, data) {
  return axiosInstance(portfolio).post(COPY_TARIFF_PATH, data);
}

export function deleteTariff(portfolio, data) {
  return axiosInstance(portfolio).delete(TARIFFS_PATH, {
    params: {id: data},
  });
}

export function updateTariff(portfolio, tariffData) {
  return axiosInstance(portfolio).patch(TARIFFS_PATH, tariffData);
}

export function deleteRate(e, Rate, Rates, setRates) {
  e.preventDefault();
  var index = Rates.indexOf(Rate);
  Rates.splice(index, 1);
  setRates([...Rates]);
  Rates = [...Rates];
}

export function getSingleTariff(portfolio, id) {
  return axiosInstance(portfolio).get(TARIFFS_PATH, {
    params: {id: id},
  });
}
// assigns a given tariff by id to an array of accounts
export function assignTariff(portfolio, data, config) {
  return axiosInstance(portfolio).post(ASSIGN_TARIFF_PATH, data, config);
}

export function changeMeterState(portfolio, data, config) {
  return axiosInstance(portfolio).post(CHANGE_METER_STATE, data, config);
}
export function updateCustomerID(portfolio, data) {
  return axiosInstance(portfolio).post(UPDATE_CUSTOMER_ID, data);
}

//The function accept Sparkmeter or Steamaco API endpoint and clean to a format which accessible via web browser
export function cleanEndpoint(endpoint) {
  if (endpoint.includes("sparkmeter")) {
    return endpoint.replace("/api/v0/", "");
  }
  if (endpoint.includes("steama")) {
    return endpoint.replace("api.", "ui.");
  } else {
    return endpoint;
  }
}

//Check if site is a steamaco site
export function isSteamacoSite(site) {
  return site.vendor_name === "STEAMACO";
}
