export const en = {
  users: "Users",
  refreshApiToken: "Refresh API Token",
  addUser: "Add User",
  usersError: "Error fetching users",
  text1: "Are you sure you want to refresh",
  text2: "'s token? This will invalidate any active tokens.",
  tokentext: "Please save this now, as you will be unable to access this token again",
  refreshFailed: "Token refresh failed.",
  token: "Token",
  copyToClip: "Copy to clipboard",
  confirmDelete: "Are you sure you want to delete",
  deleteSuccess: "User succesfully deleted",
  deleteFailure: "Error deleting user",
};

export const fr = {};

export const swa = {};
