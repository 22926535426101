export const en = {
  customerTrans: "Customer Transactions",
  new: "New",
  received: "Received",
  sentToVendor: "Sent to Vendor",
  processed: "Processed",
  queued: "Queued",
  processing: "Processing",
  processingFailed: "Processing Failed",
  reversed: "Reversed",
  energy: "Energy",
  product: "Product",
  other: "Other",

  from: "From",
  to: "To",
  status: "Status",
  allTransactions: "All Transactions",
  allAccounts: "All Accounts",
  accountRef: "Search by Account or Ref No",
  unauthorized: "User not authorized to access this data",
  badRequest: "Bad Request parameters, please check the search parameters and resubmit",
  datetime: "Date Created",
  accountNo: "Account #",
  NewAccountNo: "New Account #",
  amount: "Amount",
  referenceNo: "Reference No",
  accountType: "Account Type",
  actions: "Actions ",
  datePaid: "Date Paid",

  redirect: "Redirect",
  reverse: "Reverse",
  reverseConfirm: "Are you sure you want to reverse this transaction?",
  reprocess: "Reprocess",
  reprocessConfirm: "Are you sure you want to reprocess this transaction?",
  action: "Action",
  unacceptedAccount: "The functionality works only for energy accounts..",
  unacceptedStatus: "The functionality not works for transaction with new status..",
  processedTransaction: "The transaction has been processed..",
  unprocessedTransaction: "The transaction is not processed..",
  saving: "Saving...",
  noActionPermission: "User is not authorized to perform any action",
  moreDetails: "More Details",
  mno: "MNO",
  organization: "Organization",
  notes: "Notes",
  transaction: "Transaction {{ref}}",
  allMNO: "All MNOs",
  reference: "Reference",
  phoneNumber: "Phone Number",
  payDate: "Payment Date",
  create: "Create",
  createCashTrans: "Create transaction",
  addPayment: "Add Payment",
  resendToken: "Resend Payment Token",
  confirmResendToken: "Are you sure you want to resend payment token?",
  paymentID: "Payment ID",
  token: "Token",
  copyToken: "Copy Token",
  copySuccess: "Token Successfully Copied",
};

export const fr = {};
