import React, {useState, useContext} from "react";
import {UserContext} from "../login/userContext";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as meteringAPI from "../../services/meteringApi";
import * as billingApi from "../../services/billingApi";
import {usePermissions} from "../permissions";
import SweetAlert from "react-bootstrap-sweetalert";
import {showPopup, getHttpMessage} from "../generics/alerts";

export default function DeactivateAccount({accountNo, setAccountsAreLoaded}) {
  const canView = usePermissions(meteringAPI.DEACTIVATE_METER_PATH, "POST");
  const [show, setShow] = useState(false); //dialog show/hide controller
  const [loading, setLoading] = useState(false);
  const user = useContext(UserContext);
  const {t} = useTranslation(["customer", "shared"]);
  const payload = {
    search_params: "",
    account_nos: [],
  };

  async function deactivateAccounts() {
    payload.account_nos.push(accountNo);
    if (!loading) {
      setLoading(true);
    }
    try {
      await billingApi.deactivateAccounts(user.selected, payload);
      showPopup(t("shared:success"), "success");
    } catch (error) {
      showPopup(getHttpMessage(error));
    }
    setLoading(false);
    setShow(false);
    setAccountsAreLoaded(false);
    return;
  }
  return (
    <>
      <Button
        className="mb-2 p-1 pull-right"
        variant="light"
        test-id="move-button"
        disabled={!canView}
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon="minus-circle"></FontAwesomeIcon> {t("deactivate")}
      </Button>
      {show ? (
        <SweetAlert
          warning={true}
          showCancel={true}
          confirmBtnText={loading ? t("saving") : t("deactivate")}
          confirmBtnBsStyle={"danger"}
          title={t("deactivateTitle")}
          onConfirm={deactivateAccounts}
          showConfirmButton={false}
          openAnim={false}
          onCancel={() => setShow(false)}
          disabled={loading}
        >
          {t("deactivateMessage", {accountNo: accountNo})}
        </SweetAlert>
      ) : null}
    </>
  );
}
