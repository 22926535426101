export const en = {
  welcome: "amini.io",
  powered: "powered by PowerGen",
  login: "Login with Google",
  mockLogin: "Mock Login",

  googleAuthError: "Google Authentication Failed",
  unregisteredError:
    "{{username}} is not an Amini user. Please contact software@powergen-re.com to be registered.",
  mockLoginError: "Mock Login Error",
};

export const fr = {};
