import React, {useState, useContext} from "react";
import {UserContext} from "../login/userContext";
import {Button, Modal, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as meteringAPI from "../../services/meteringApi";
import {usePermissions} from "../permissions";
import {getFormProps, InlineFormField, FormErrors} from "../generics/formsFields";
import {showPopup, showErrors} from "../generics/alerts";

export default function MoveCustomerAccount({accountNo, customer, setAccountsAreLoaded}) {
  const canView = usePermissions(meteringAPI.MOVE_CUSTOMER_PATH, "POST");
  const [show, setShow] = useState(false); //dialog show/hide controller
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {t} = useTranslation(["customer"]);

  return (
    <>
      <Button
        className="mb-2 p-1 pull-left"
        variant="success"
        test-id="move-button"
        disabled={!canView}
        onClick={handleShow}
      >
        <FontAwesomeIcon icon="home"></FontAwesomeIcon> {t("moveCustomer")}
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton className="text-secondary">
          <Modal.Title className="text-primary ml-2">{t("moveCustomer")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mr-4">
          <MoveCustomerForm
            setShow={setShow}
            accountNo={accountNo}
            customer={customer}
            setAccountsAreLoaded={setAccountsAreLoaded}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export function MoveCustomerForm({setShow, accountNo, customer, setAccountsAreLoaded}) {
  // set up contexts and variables for the form
  const user = useContext(UserContext);
  const {t} = useTranslation(["customer", "shared"]);
  const {register, handleSubmit} = useForm();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const custName = getFormProps(
    "",
    t("customer"),
    "text",
    "true",
    customer.FirstName + " " + customer.LastName
  );
  const oldAccount = getFormProps(
    "oldAccountNumber",
    t("currentAccount"),
    "text",
    "true",
    accountNo
  );
  const newAccount = getFormProps("newAccountNumber", t("newAccount"), "text", "true");

  // function to call when submitting form
  const onSubmit = (data) => {
    data.customerId = customer.Id;
    data.oldAccountNumber = accountNo;
    const moveData = {...data};
    moveAccounts(user.selected, moveData);
  };

  async function moveAccounts(portfolio, moveData) {
    try {
      setErrors([]);
      setLoading(true);
      await meteringAPI.moveCustomerAccount(portfolio, moveData);
      showPopup(t("movesuccess"), "success");
      setShow(false);
      setAccountsAreLoaded(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setLoading(false);
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormErrors errors={errors}></FormErrors>
        <InlineFormField {...custName} disabled className="my-4" />
        <InlineFormField {...oldAccount} disabled className="my-4" />
        <InlineFormField register={register} {...newAccount} className="my-4" />
        <Button
          md={4}
          lg={4}
          xs={6}
          type="submit"
          variant="success"
          className="mt-2 mb-1 p-3 pull-right"
          disabled={loading}
        >
          <FontAwesomeIcon className=" mx-2" icon="home"></FontAwesomeIcon>
          {loading ? t("saving") : t("moveCustomer")}
        </Button>
      </Form>
    </div>
  );
}
