import React, {useState, useContext} from "react";
import {useLocation} from "react-router-dom";
import {Form, Button, Modal, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {UserContext} from "../login/userContext";
import {usePermissions} from "../permissions";
import {showPopup, getHttpMessage} from "../generics/alerts";
import * as meteringApi from "../../services/meteringApi";
import {UploadProgressBar, handleDataUpload} from "../generics/fileUploads";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CardFieldSelect} from "../generics/formsFields";
import {useForm} from "react-hook-form";

export default function MeterState({
  customerList,
  modalShow,
  setModalShow,
  selectAllInSearch,
  count,
}) {
  const {t} = useTranslation(["bulkActions", "shared"]);
  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton style={{borderBottomWidth: 0}}>
          <Modal.Title className="text-success ml-2">
            <FontAwesomeIcon icon={"toggle-on"} />
            {"   " + t("meterStateHeader")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MeterStateForm
            customerList={customerList}
            setModalShow={setModalShow}
            selectAllInSearch={selectAllInSearch}
            count={count}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

function MeterStateForm({customerList, setModalShow, selectAllInSearch, count}) {
  const {register, handleSubmit} = useForm();
  const {t} = useTranslation(["bulkActions", "shared"]);
  const [errors, setErrors] = useState([]);
  const [progress, setProgress] = useState(0);
  const user = useContext(UserContext);
  const location = useLocation();
  const canChangeMeterState = usePermissions(meteringApi.CHANGE_METER_STATE, "POST");

  const states = ["ON", "OFF", "AUTO"];
  const value = states.map((state) => {
    return {name: state, value: state};
  });

  const onSubmit = (data) => {
    let accounts = [];
    let searchParams = "";
    if (selectAllInSearch) {
      searchParams = location.search.replace("?", "").replace("search_value", "search");
      if (searchParams.length === 0) {
        setErrors([t("warningUsingSearchParams")]);
        return;
      }
    } else {
      customerList.forEach((c) => {
        accounts.push(c.AccountNumber);
      });
    }
    const payload = {
      mode: data.meter_state,
      account_nos: accounts,
      search_params: searchParams,
    };
    handleDataUpload(setProgress, changeMeterState, payload);
  };

  async function changeMeterState(data, config) {
    try {
      await meteringApi.changeMeterState(user.selected, data, config);
      showPopup(t("shared:success"), "success");
      setErrors([]);
      setModalShow(false);
    } catch (err) {
      setErrors(getHttpMessage(err));
      setProgress(0);
    }
  }

  return (
    <>
      <UploadProgressBar
        progress={progress}
        progressVariant="success"
        title={t("changeMeterStatus")}
      />
      <div className="ml-3" style={{color: "red"}}>
        {errors}
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4">
        <Row className="mb-3">
          <Col>
            {t(
              "warningChangeMeterState",
              selectAllInSearch ? {count: count} : {count: customerList.length}
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <CardFieldSelect
              register={register}
              name="meter_state"
              label={t("selectState")}
              defaultValue={""}
              defaultName={""}
              options={value}
              readOnly={!canChangeMeterState}
            ></CardFieldSelect>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit" variant="danger" className="ml-3 p-3 pull-right">
              {t("okButton")}
            </Button>
            <Button
              variant="light"
              onClick={() => setModalShow(false)}
              className="ml-3 p-3 pull-right"
            >
              {t("cancel")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
