import React, {useState, useContext} from "react";
import {useLocation} from "react-router-dom";
import {Form, Button, Modal, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {UserContext} from "../login/userContext";
import {showPopup, getHttpMessage} from "../generics/alerts";
import * as billingAPI from "../../services/billingApi";
import {UploadProgressBar, handleDataUpload} from "../generics/fileUploads";
import {getFormProps, InlineFormField} from "../generics/formsFields";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from "react-hook-form";

export default function BonusDisbursement({
  customerList,
  modalShow,
  setModalShow,
  selectAllInSearch,
  count,
}) {
  const {t} = useTranslation(["bulkActions", "shared"]);
  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-success ml-2">
            <FontAwesomeIcon icon="dollar-sign" />
            {"   " + t("bonusDisbursement")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BonusDisbursementForm
            customerList={customerList}
            setModalShow={setModalShow}
            selectAllInSearch={selectAllInSearch}
            count={count}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export function BonusDisbursementForm({
  customerList,
  setModalShow,
  selectAllInSearch,
  count,
}) {
  const {register, handleSubmit} = useForm();
  const {t} = useTranslation(["bulkActions", "shared"]);
  const [errors, setErrors] = useState([]);
  const [progress, setProgress] = useState(0); // %
  const user = useContext(UserContext);
  const location = useLocation();

  const onSubmit = (data) => {
    let accounts = [];
    let searchParams = "";
    if (selectAllInSearch) {
      searchParams = location.search.replace("?", "").replace("search_value", "search");
      if (searchParams.length === 0) {
        setErrors([t("warningUsingSearchParams")]);
        return;
      }
    } else {
      customerList.forEach((c) => {
        accounts.push(c.AccountNumber);
      });
    }
    const payload = {
      amount: parseFloat(data.amount),
      account_nos: accounts,
      search_params: searchParams,
    };
    handleDataUpload(setProgress, addBonus, payload);
  };

  async function addBonus(data, config) {
    try {
      await billingAPI.disburseBonusPayment(user.selected, data, config);
      showPopup(t("shared:success"), "success");
      setErrors([]);
      setModalShow(false);
    } catch (err) {
      setErrors(getHttpMessage(err));
      setProgress(0);
    }
  }

  const amount = getFormProps("amount", t("addBonusAmount"), "number");

  return (
    <>
      <UploadProgressBar
        progress={progress}
        progressVariant="success"
        title={t("disbursing")}
      />
      <div className="ml-3" style={{color: "red"}}>
        {errors}
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4">
        <Row className="mb-3">
          <Col>
            {t(
              "warningDisburseBonus",
              selectAllInSearch ? {count: count} : {count: customerList.length}
            )}
          </Col>
        </Row>
        <Row>
          <InlineFormField register={register} lmd={6} llg={6} {...amount} />
        </Row>
        <Row>
          <Col>
            <Button type="submit" variant="danger" className="ml-3 p-3 pull-right">
              {t("bonusDisburseNote")}
            </Button>
            <Button
              variant="light"
              onClick={() => setModalShow(false)}
              className="ml-3 p-3 pull-right"
            >
              {t("cancel")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
