export function removeBrackets(phoneNo) {
  // return null if props is undefined
  if (phoneNo == null) {
    return "";
  }

  // return same value if there are no brackets
  if (phoneNo.indexOf("{") === -1) {
    return phoneNo;
  }

  // otherwise clean the phone number
  let cleanPhoneNo = phoneNo.slice(phoneNo.indexOf("{") + 1, phoneNo.indexOf("}"));
  return cleanPhoneNo;
}

export function addBrackets(phoneNo) {
  // return null if props is undefined
  if (phoneNo == null) {
    return "{}";
  }

  // return same value if there are brackets
  if (phoneNo.indexOf("{") !== -1) {
    return phoneNo;
  }

  // otherwise format the phone number
  let fullPhoneNo = "{" + phoneNo + "}";
  return fullPhoneNo;
}
