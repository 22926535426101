import React, {useState, useContext, useEffect, useCallback} from "react";
import {Row, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {usePermissions, Unauthorized} from "../permissions";
import {UserContext} from "../login/userContext";
import * as meteringAPI from "../../services/meteringApi";
import * as billingApi from "../../services/billingApi";
import {getHttpMessage, showPopup, showErrors} from "../generics/alerts";
import {RegisterButton, CancelButton} from "../generics/buttons";
import {
  getFormProps,
  FormField,
  FormSelect,
  FormCheck,
  FormErrors,
} from "../generics/formsFields";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import {useHistory} from "react-router-dom";

import {GoogleMapPicker, getMapPickerProps} from "../generics/googleMapPicker";

export default function RegisterSite() {
  const {t} = useTranslation(["sites"]);
  const canView = usePermissions(meteringAPI.SITE_PATH, "POST");
  if (!canView) {
    return <Unauthorized />;
  }
  return (
    <div>
      <h1 className="mb-4 display-4 text-primary">
        <FontAwesomeIcon icon="map-marker-alt"></FontAwesomeIcon> {t("registerSite")}
      </h1>
      <div className="mt-2 shadow bg-white border rounded">
        <RegisterSiteForm />
      </div>
    </div>
  );
}

function RegisterSiteForm() {
  // set up contexts and variables for the form
  const user = useContext(UserContext);
  const {t} = useTranslation(["sites", "shared"]);
  const {register, handleSubmit} = useForm();
  // fetch and populate options for owner and tariff fields
  const [owners, setOwners] = useState([]);
  const [tariffs, setTariffs] = useState([]);
  const [isOwnersLoaded, setOwnersLoaded] = useState(false);
  const [isTariffsLoaded, setTariffsLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isCountriesLoaded, setCountriesLoaded] = useState(false);
  const history = useHistory();

  // for google map: https://www.npmjs.com/package/react-google-map-picker
  const defaultZoom = 4;
  const siteLocation = {
    lat: 10,
    lng: 10,
  };
  const [defaultLocation, setDefaultLocation] = useState(siteLocation);
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(defaultZoom);
  const [lat, setLat] = useState(location.lat);
  const [long, setLong] = useState(location.lng);
  const [isChanged, setIsChanged] = useState(false);

  const handleChangeLocation = (lat, lng) => {
    setLocation({lat: lat, lng: lng});
    setLat(lat);
    setLong(lng);
    setIsChanged(true);
  };

  const handleChangeZoom = (newZoom) => {
    setZoom(newZoom);
  };

  const handleResetLocation = () => {
    setDefaultLocation({...siteLocation});
    setZoom(defaultZoom);
    setLat(siteLocation.lat);
    setLong(siteLocation.lng);
    setIsChanged(true);
  };

  const fetchSiteOwners = useCallback(
    async function () {
      setOwnersLoaded(true);
      try {
        const resp = await meteringAPI.getSiteOwners(user.selected, {});
        const ownersJson = resp.data.map((owner) => {
          return {name: owner.Name, value: owner.OwnerID};
        });
        setOwners(ownersJson);
      } catch (err) {
        showPopup(t("ownersError") + ": " + err);
      }
    },
    [user.selected, setOwners, setOwnersLoaded, t]
  );

  const fetchTariffs = useCallback(
    async function () {
      setTariffsLoaded(true);
      let country_id = user.permissions[user.selected].countryID;
      try {
        const resp = await meteringAPI.getTariffs(user.selected, {country_id});
        const tariffsJson = resp.data.data.map((tariff) => {
          return {name: tariff.name, value: tariff.id};
        });
        setTariffs(tariffsJson);
      } catch (err) {
        showPopup(t("tariffsError") + ": " + err);
      }
    },
    [user, setTariffs, setTariffsLoaded, t]
  );

  const fetchCountries = useCallback(
    async function () {
      try {
        const resp = await billingApi.getCountries(user.selected, {});
        let country_list = [];
        resp.data.data.map((ci) => {
          return country_list.push({name: ci.name, value: ci.code});
        });
        setCountries(country_list);
        setCountriesLoaded(true);
      } catch (err) {
        showPopup(getHttpMessage(err), "error");
      }
    },
    [user.selected]
  );

  useEffect(() => {
    if (!isOwnersLoaded) fetchSiteOwners();
    if (!isTariffsLoaded) fetchTariffs();
    if (!isCountriesLoaded) fetchCountries();
    if (isChanged) {
      setDefaultLocation({lat: lat, lng: long});
      setIsChanged(false);
    }
  }, [
    isOwnersLoaded,
    isTariffsLoaded,
    isCountriesLoaded,
    fetchSiteOwners,
    fetchTariffs,
    fetchCountries,
    isChanged,
    lat,
    long,
  ]);

  if (!isOwnersLoaded || !isTariffsLoaded) {
    return <Skeleton height={40} count="4" className="m-3" />;
  }

  // prep props for form fields
  const code = getFormProps("code", t("code"), "text");
  const name = getFormProps("name", t("name"), "text");
  const region = getFormProps("region", t("region"), "text");
  const villages = getFormProps("villages", t("villages"), "text");
  const estates = getFormProps("estates", t("estates"), "text");
  const site_groups = getFormProps("site_groups", t("site_groups"), "text");
  const country = getFormProps(
    "country",
    t("country"),
    "select",
    "true",
    "",
    "",
    countries
  );
  const tariff = getFormProps(
    "tariff",
    t("defaultTariff"),
    "select",
    "true",
    "",
    "",
    tariffs
  );
  const owner = getFormProps("owner", t("owner"), "select", "true", "", "", owners);
  const date = getFormProps("date", t("dateCommissioned"), "date");
  const trained = getFormProps("trained", t("trained"), "switch");
  const picker = getMapPickerProps(
    defaultLocation,
    zoom,
    lat,
    long,
    handleChangeZoom,
    setLat,
    setLong,
    setIsChanged,
    handleChangeLocation,
    handleResetLocation
  );

  let country_id = user.permissions[user.selected].countryID;
  // function to call when submitting form
  const onSubmit = (data) => {
    const sites = [
      {
        code: data.code,
        name: data.name,
        region: data.region,
        villages: data.villages,
        estates: data.estates,
        site_groups: data.site_groups,
        sync_to_vendor: true,
        country: data.country,
        latitude: parseFloat(data.latitude),
        longitude: parseFloat(data.longitude),
        owner_id: data.owner,
        default_tariff_id: data.tariff,
        date_commissioned: data.date,
        is_trained: +data.trained,
        country_id,
      },
    ];
    registerSite(user.selected, sites);
  };

  async function registerSite(portfolio, sites) {
    setSaving(true);
    setErrors([]);
    try {
      await meteringAPI.registerSite(portfolio, sites);
      showPopup(t("shared:success"), "success");
      history.push("/sites");
    } catch (err) {
      showErrors(err, setErrors);
    }
    setSaving(false);
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="m-4">
      <Row>
        <FormErrors errors={errors}></FormErrors>
        <FormField register={register} {...name}></FormField>
        <FormField register={register} {...code}></FormField>
        <FormField register={register} {...region}></FormField>
        <FormSelect register={register} {...country}></FormSelect>
        <FormSelect register={register} {...tariff}></FormSelect>
        <FormSelect register={register} {...owner}></FormSelect>
        <FormField register={register} {...date}></FormField>
        <FormCheck register={register} {...trained}></FormCheck>
      </Row>
      <Row>
        <FormField register={register} {...villages}></FormField>
        <FormField register={register} {...estates}></FormField>
        <FormField register={register} {...site_groups}></FormField>
      </Row>
      <GoogleMapPicker register={register} {...picker} />
      <Row>
        <CancelButton label={t("cancel")} to="/sites"></CancelButton>
        <RegisterButton label={t("register")} disabled={saving}></RegisterButton>
      </Row>
    </Form>
  );
}
