import React, {useState, useEffect, useContext} from "react";
import {Form, Col, InputGroup} from "react-bootstrap";
import {usePermissions, Unauthorized} from "../permissions";
import {UserContext} from "../login/userContext";
import * as NotificationApi from "../../services/notificationApi";
import * as alerts from "../generics/alerts";
import {useLocation, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import {SmsLogsTable} from "./smsTable";
import {ListPagination} from "../generics/pagination";
import {ListHeader} from "../generics/headers";
import {formatFileNameDate} from "../../services/date";
import "./sms.scss";
import {Button} from "react-bootstrap";

const queryString = require("query-string");

export default function SmsLogs() {
  const canView = usePermissions(NotificationApi.NOTIFICATION_SMS_LIST_PATH);
  const location = useLocation(); //url query parameters
  const history = useHistory();
  let query = queryString.parse(location.search);
  const {t} = useTranslation(["sms"]);

  const user = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [smsLogs, setSmsLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  let country_id = user.permissions[user.selected].countryID;

  const updateQuery = (updates) => {
    query = Object.assign(query, updates);
    var params = queryString.stringify(query, {
      skipEmptyString: true,
    });
    history.push("/sms/list?" + params);
  };

  useEffect(() => {
    async function fetchSMSLogs() {
      const searchParams = queryString.parse(location.search);
      searchParams.page = page;
      searchParams.country_id = country_id;
      await NotificationApi.getSmsLogs(user.selected.toLowerCase(), limit, searchParams)
        .then((resp) => {
          setCount(resp.data.count);
          setSmsLogs(resp.data.Results);
          setLoading(false); // WIP
        })
        .catch((err) => {
          setSmsLogs([]);
          alerts.showPopup(alerts.getHttpMessage(err));
        });
    }
    fetchSMSLogs();
  }, [
    user.selected,
    country_id,
    limit,
    page,
    location.search,
    setSmsLogs,
    setLoading,
    setCount,
  ]);

  async function getAllSMSlogs() {
    const searchParams = queryString.parse(location.search);
    searchParams.country_id = country_id;
    let allData = [];
    setProgress(50);
    await NotificationApi.getSmsLogs(user.selected.toLowerCase(), count, searchParams)
      .then((resp) => {
        allData.push(...resp.data.Results);
        setProgress(100);
      })
      .catch((err) => {
        setProgress(0);
        alerts.showPopup(alerts.getHttpMessage(err));
      });
    setProgress(0);
    return allData;
  }

  const [provider, setProvider] = useState("");
  useEffect(() => {
    if (process.env.REACT_APP_IS_TEST === "3") {
      country_id = 4;
    } else {
      country_id = user.permissions[user.selected].countryID;
    }

    if (country_id === 4) {
      NotificationApi.getProviders(user.selected, {country_id})
        .then((response) => {
          setProvider(response.name);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [user.selected, country_id]);

  const canSwitchProvider = usePermissions(
    NotificationApi.NOTIFICATION_SWITCH_PROVIDER,
    "POST"
  );
  const handleProviderSwitch = () => {
    if (!canSwitchProvider) {
      alerts.showPopup(t("unauthorized"));
    } else {
      let country_code = "";
      if (process.env.REACT_APP_IS_TEST === "3") {
        country_code = "4";
      } else {
        country_code = user.permissions[user.selected].countryID.toString();
      }
      const requestData = {country_code};
      NotificationApi.switchProvider(user.selected, requestData)
        .then((response) => {
          let resp = response.data;
          if (resp.toLowerCase().includes("smpp")) {
            setProvider("Orange");
          } else {
            setProvider(response.data);
          }
          alerts.showPopup("Successfully changed SMS provider to " + provider, "success");
        })
        .catch((error) => {
          alerts.showPopup(
            "Failed to change provider: " + alerts.getHttpMessage(error.message)
          );
        });
    }
  };

  //check if user has access to link
  if (!canView) {
    return <Unauthorized />;
  }

  return (
    <div>
      <ListHeader title={t("smsList")} icon="envelope-open"></ListHeader>

      <div>
        {(country_id === 4 || process.env.REACT_APP_IS_TEST === "3") && (
          <div className="p-2 pull-right">
            <Button variant="primary" onClick={handleProviderSwitch}>
              {t("switchProvider")}
            </Button>
            {provider && (
              <p style={{margin: 0, right: "100%"}}>
                {t("currentProvider")}
                {provider.toLowerCase().includes("smpp") ? "Orange" : provider}
              </p>
            )}
          </div>
        )}
      </div>

      <SearchForm updateQuery={updateQuery} />

      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        data={smsLogs}
        filename={formatFileNameDate(new Date().toString()) + "_smsLogs.csv"}
        getAllData={getAllSMSlogs}
        progress={progress}
      />

      <SmsTable smsLogs={smsLogs} loading={loading} />

      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        data={smsLogs}
        filename={formatFileNameDate(new Date().toString()) + "_smsLogs.csv"}
        getAllData={getAllSMSlogs}
        progress={progress}
      />
    </div>
  );
}

export function SearchForm({updateQuery}) {
  const {t} = useTranslation(["sms"]);

  const smsTypeOptions = [
    {name: t("allTypes"), value: "all"},
    {name: t("outgoing"), value: "outgoing"},
    {name: t("incoming"), value: "incoming"},
  ];

  const smsStatusOptions = [
    {name: t("allStatus"), value: "all"},
    {name: t("new"), value: "1"},
    {name: t("forwared"), value: "2"},
    {name: t("sent"), value: "3"},
    {name: t("failed"), value: "4"},
    {name: t("received"), value: "5"},
    {name: t("resolved"), value: "6"},
    {name: t("delivered"), value: "7"},
  ];

  return (
    <Form className="mb-3">
      <Form.Row className="align-items-center">
        <Col sm={12} md={3}>
          <InputGroup className="mb-1">
            <InputGroup.Prepend>
              <InputGroup.Text>{t("from")}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              id="from-date"
              onChange={(e) => updateQuery({start_date: e.target.value})}
              type="date"
            />
          </InputGroup>
        </Col>

        <Col sm={12} md={3}>
          <InputGroup className="mb-1">
            <InputGroup.Prepend>
              <InputGroup.Text>{t("to")}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              id="end-date"
              onChange={(e) => updateQuery({end_date: e.target.value})}
              type="date"
            />
          </InputGroup>
        </Col>

        <Col sm={12} md={2}>
          <Form.Control
            className="mb-1"
            id="all-sms-type"
            onChange={(e) => updateQuery({sms_type: e.target.value})}
            as="select"
          >
            {smsTypeOptions.map((option) => {
              return <option value={option.value}>{option.name}</option>;
            })}
          </Form.Control>
        </Col>

        <Col sm={12} md={2}>
          <Form.Control
            className="mb-1"
            id="all-sms"
            onChange={(e) => updateQuery({status: e.target.value})}
            as="select"
          >
            {smsStatusOptions.map((option) => {
              return (
                <option key={option.name} value={option.value}>
                  {option.name}
                </option>
              );
            })}
          </Form.Control>
        </Col>

        <Col sm={12} md={2}>
          <Form.Control
            className="mb-1"
            type="number"
            name="phonenumber"
            onChange={(e) => updateQuery({phonenumber: e.target.value})}
            placeholder={t("phone")}
          ></Form.Control>
        </Col>
        <Col sm={12} md={12}>
          <Form.Control
            className="mb-1"
            type="text"
            name="search"
            onChange={(e) => updateQuery({search: e.target.value})}
            placeholder={t("searchbycontent")}
          ></Form.Control>
        </Col>
      </Form.Row>
    </Form>
  );
}

export function SmsTable({smsLogs, loading}) {
  if (loading) {
    return <Skeleton count={10} />;
  }
  return <SmsLogsTable smslogs={smsLogs} />;
}
