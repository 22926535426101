import moment from "moment-timezone";

/**
 *
 * @param {string} datetime  format date to friendly display on component
 */
export function formatDisplayDate(datetime) {
  let localTimezone = moment.tz.guess();
  const dt = moment(datetime);

  const formatedDate = dt.tz(localTimezone).format("MMM DD, YYYY h:mma z");

  return formatedDate;
}

/**
 *Format to a date without spaces for use with filenames
 * @param {string} datetime  format date to friendly display on component
 */
export function formatFileNameDate(datetime) {
  let localTimezone = moment.tz.guess();
  const dt = moment(datetime);

  const formatedDate = dt.tz(localTimezone).format("YYYYMMDDhmmz");

  return formatedDate;
}
/**
 *
 * @param {string} datetime  format date to friendly display on component
 */
export function formatDisplayDateDay(datetime) {
  let localTimezone = moment.tz.guess();
  const dt = moment(datetime);

  const formatedDate = dt.tz(localTimezone).format("MMM DD, YYYY");

  return formatedDate;
}

/**
 *Format date to format accepted by backend API
 * @param {string} datetime string date
 */
export function formatDateToApi(datetime) {
  let localTimezone = moment.tz.guess();
  let fmt = "YYYY-MM-DD H:mm:ss";
  const dt = moment(datetime);

  const formatedDate = dt.tz(localTimezone).format(fmt);

  //construct moment object based on the localtimezone
  let m = moment.tz(formatedDate, fmt, localTimezone);

  //convert the localtime to utc
  let formatedUtcDate = m.utc().format(fmt);

  return formatedUtcDate;
}

/**
 * Converts localtime to utc
 * @param {string} datetime
 */

export function convertLocalDatetimeToUtc(datetime) {
  let localTimezone = moment.tz.guess();
  let fmt = "YYYY-MM-DD H:mm:ss";
  let input = formatDateToApi(datetime);

  //construct moment object based on the localtimezone
  let m = moment.tz(input, fmt, localTimezone);

  //convert the time to utc and return it
  return m.utc().format(fmt);
}

/**
 *
 * @param {string} datetime  format date to form accepted by form
 */
export function formatFormDate(datetime) {
  if (!datetime) {
    return "";
  }
  let localTimezone = moment.tz.guess();
  const dt = moment(datetime);
  const formatedDate = dt.tz(localTimezone).format("YYYY-MM-DD");

  return formatedDate;
}

/**
 *
 * @param {string} datetime  format date to form accepted by form
 */
export function formatFormDateToRFC3339(datetime) {
  if (!datetime) {
    return "";
  }
  let localTimezone = moment.tz.guess();
  const dt = moment(datetime);
  const formatedDate = dt.tz(localTimezone).format("YYYY-MM-DDTHH:mm:ssZ");

  return formatedDate;
}

export function getTimeDifferenceDays(datetime) {
  const start = moment(datetime);
  const end = moment();
  const diffDays = end.diff(start, "days");
  return diffDays;
}
