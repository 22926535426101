import React, {useState} from "react";
import {useLocation} from "react-router";
import {Card, Nav} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {DashboardHeader} from "../generics/headers";
import SendSMS from "./sendSMSCard";
import ChangeTariff from "./changeTariff";
import RegisterBulkCustomer from "./registerBulk";
import BulkAssignMeters from "./bulkAssignMeters";
import DeactivateAccount from "./deactivateAccount";
import ChangeMeterState from "./changeMetersState";
import DisburseBonus from "./bonusPayment";
import BulkAssignTag from "./bulkAssignTag";
import BulkUpdateEnergyAccount from "./energyAccountUpdate";
import BulkActivateAccounts from "./bulkActivateAccounts";

export default function FileUpload() {
  const {t} = useTranslation(["fileupload", "shared"]);

  //Define tab hash for each tab in this file
  //this is displayed on the url for each tab
  const sendSMSHash = "#sendSMS";
  const tariffChangeHash = "#tariffChange";
  const bulkRegister = "#bulkRegister";
  const bulkAssignMeters = "#bulkAssign";
  const deactivate = "#deactivateAccounts";
  const changeStateHash = "#changeMetersState";
  const bonusPayment = "#bonusPayment";
  const bulkAssignTag = "#bulkAssignTag";
  const bulkUpdateEnergyAccountHash = "#bulkUpdateEnergyAccount";
  const bulkActivateAccounts = "#activateAccounts";

  //get location
  const location = useLocation();
  //extract hash from location / set default hash if empty
  let hash = location.hash;
  if (hash === null || hash.length === 0) {
    hash = sendSMSHash;
  }

  //create tap state with default as the hash
  const [showCard, setShowCard] = useState(hash);

  return (
    <div>
      <DashboardHeader title={t("pageTitle")} icon="file-import"></DashboardHeader>

      <Card>
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey={hash}>
            <Nav.Item>
              <Nav.Link
                href={sendSMSHash}
                onClick={() => {
                  setShowCard(sendSMSHash);
                }}
              >
                {t("sendSMS")}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                href={tariffChangeHash}
                onClick={() => {
                  setShowCard(tariffChangeHash);
                }}
              >
                {t("tariffChangeTitle")}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                href={changeStateHash}
                onClick={() => {
                  setShowCard(changeStateHash);
                }}
              >
                {t("stateChangeTitle")}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                href={bulkRegister}
                onClick={() => {
                  setShowCard(bulkRegister);
                }}
              >
                {t("registerCustomers")}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                href={bulkAssignMeters}
                onClick={() => {
                  setShowCard(bulkAssignMeters);
                }}
              >
                {t("assignMeters")}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                href={deactivate}
                onClick={() => {
                  setShowCard(deactivate);
                }}
              >
                {t("deactivate")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href={bonusPayment}
                onClick={() => {
                  setShowCard(bonusPayment);
                }}
              >
                {t("bonusTitle")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href={bulkAssignTag}
                onClick={() => {
                  setShowCard(bulkAssignTag);
                }}
              >
                {t("assignTagTitle")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href={bulkUpdateEnergyAccountHash}
                onClick={() => {
                  setShowCard(bulkUpdateEnergyAccountHash);
                }}
              >
                {t("updateAccTitle")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href={bulkActivateAccounts}
                onClick={() => {
                  setShowCard(bulkActivateAccounts);
                }}
              >
                {t("activateAccounts")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <CardBody showCard={showCard}></CardBody>
      </Card>
    </div>
  );

  function CardBody({showCard}) {
    if (showCard === sendSMSHash) {
      return <SendSMS />;
    } else if (showCard === tariffChangeHash) {
      return <ChangeTariff />;
    } else if (showCard === changeStateHash) {
      return <ChangeMeterState />;
    } else if (showCard === bulkRegister) {
      return <RegisterBulkCustomer />;
    } else if (showCard === bulkAssignMeters) {
      return <BulkAssignMeters />;
    } else if (showCard === deactivate) {
      return <DeactivateAccount />;
    }
    if (showCard === bonusPayment) {
      return <DisburseBonus />;
    }
    if (showCard === bulkAssignTag) {
      return <BulkAssignTag />;
    }
    if (showCard === bulkUpdateEnergyAccountHash) {
      return <BulkUpdateEnergyAccount />;
    }
    if (showCard === bulkActivateAccounts) {
      return <BulkActivateAccounts />;
    } else {
      return <div></div>;
    }
  }
}
