import React, {useState, useEffect, useContext} from "react";
import {usePermissions, Unauthorized} from "../permissions";
import {Col, Table, Form, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserContext} from "../login/userContext";
import * as billingAPI from "../../services/billingApi";
import {useForm} from "react-hook-form";
import RegisterProduct from "./register";
import {useHistory, useLocation} from "react-router-dom";
import {ListHeader} from "../generics/headers";
import {ListPagination} from "../generics/pagination";
import {showPopup, getHttpMessage} from "../generics/alerts";
import * as DateHelpers from "../../services/date";
const queryString = require("query-string");

export default function ProductAccountsList() {
  const {t} = useTranslation(["product", "shared"]);
  const canView = usePermissions(billingAPI.PRODUCT_ACCOUNTS_LIST_PATH);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [count, setCount] = useState(0);
  const user = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  let query = queryString.parse(location.search);
  query.country_id = user.permissions[user.selected].countryID;

  if (!canView) {
    return <Unauthorized />;
  }

  const updateQuery = (updates) => {
    query = Object.assign(query, updates);
    var params = queryString.stringify(query, {
      skipEmptyString: true,
    });
    history.push("/products/accounts?" + params);
  };

  return (
    <div>
      <ListHeader title={t("productAccounts")} icon="users" />
      <SearchForm query={query} updateQuery={updateQuery} />
      <AccountsTable searchData={query} page={page} limit={limit} setCount={setCount} />
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
      />
    </div>
  );
}

function AccountsTable({searchData, page, limit, setCount}) {
  const {t} = useTranslation(["product", "shared"]);
  const user = useContext(UserContext);
  const [productAccounts, setProductAccounts] = useState([]);

  useEffect(() => {
    async function fetchProuductAccounts() {
      try {
        const resp = await billingAPI.getProductAccounts(
          user.selected,
          page,
          limit,
          searchData
        );
        const respondData = resp.data;
        setCount(resp.data.count);
        setProductAccounts(respondData.data);
      } catch (err) {
        alert(err);
      }
    }
    fetchProuductAccounts();
  }, [user.selected, page, limit, searchData, setCount]);

  return (
    <Table response="sm" striped bordered hover responsive>
      <thead>
        <tr>
          <th>{t("accountNo")}</th>
          <th>{t("product")}</th>
          <th>{t("productCode")}</th>
          <th>{t("productPrice")}</th>
          <th>{t("deposited")}</th>
          <th>{t("balance")}</th>
          <th>{t("paymentPlan")}</th>
          <th>{t("loanStatus")}</th>
          <th>{t("dateOfDeposit")}</th>
        </tr>
      </thead>
      <tbody>
        {productAccounts.length
          ? productAccounts.map((pAcc) => (
              <AccountRow productAccount={pAcc} key={pAcc.AccountNumber} />
            ))
          : null}
      </tbody>
    </Table>
  );

  function AccountRow({productAccount}) {
    const link = `/customers/${productAccount.CustomerID}`;
    const history = useHistory();
    // calculate balance from Product price and deposit
    const balance = parseFloat(productAccount.Price) - parseFloat(productAccount.Paid);
    function onClick() {
      history.push(link);
    }

    return (
      <tr onClick={onClick}>
        <td>{productAccount.AccountNumber}</td>
        <td>{productAccount.ProductName}</td>
        <td>{productAccount.ProductCode}</td>
        <td>{productAccount.Price}</td>
        <td>{productAccount.Paid}</td>
        <td>{balance}</td>
        <td>{productAccount.PaymentPlanName}</td>
        <td>{productAccount.Status}</td>
        <td>{DateHelpers.formatDisplayDate(productAccount.DepositDueDate).toString()}</td>
      </tr>
    );
  }
}

//search data component
function SearchForm({updateQuery}) {
  const {t} = useTranslation(["product", "shared"]);
  const {register, handleSubmit} = useForm();
  const user = useContext(UserContext);
  const [productItems, setProductItems] = useState([]);

  useEffect(() => {
    async function fetchProductItems() {
      try {
        const resp = await billingAPI.getProductItems(user.selected, {});
        setProductItems(resp.data.data.products);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
    }
    fetchProductItems();
  }, [user.selected, setProductItems]);

  const onSubmit = (data) => {
    updateQuery(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mb-2">
      <Form.Row>
        <Col md={3} className="mb-2">
          <Form.Control
            type="text"
            ref={register}
            name="accountNumber"
            placeholder={t("accountNo")}
          />
        </Col>
        <Col md={3} className="mb-2">
          <Form.Control name="productCode" as="select" ref={register} custom>
            <option value="">{t("selectProduct")}</option>
            {productItems.length
              ? productItems.map((p) => {
                  return (
                    <option value={p.product_code} key={p.id}>
                      {p.product_code}
                    </option>
                  );
                })
              : null}
          </Form.Control>
        </Col>
        <Col md={2} className="mb-2">
          <Form.Control
            as="select"
            ref={register}
            name="status"
            placeholder={t("loanStatus")}
            custom
          >
            <option value="">{t("selectStatus")}</option>
            <option value="1">New Loan</option>
            <option value="2">Paid Loan</option>
            <option value="3">Unpaid Loan</option>
            <option value="4">Finished Loan</option>
            <option value="5">Paid Extra</option>
            <option value="6">New Cash</option>
            <option value="7">Overdue Loan</option>
            <option value="8">Paid cash</option>
            <option value="9">Lead</option>
          </Form.Control>
        </Col>
        <Col>
          <Button type="submit" ref={register} xs={1}>
            <FontAwesomeIcon icon="search"></FontAwesomeIcon>
          </Button>
        </Col>
        <Col xs={11} md={3}>
          <RegisterProduct />
        </Col>
      </Form.Row>
    </Form>
  );
}
