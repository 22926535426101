import React, {useState, useEffect, useCallback} from "react";
import {Table, Button, Col, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useParams, useHistory} from "react-router-dom";
import * as authAPI from "../../services/authApi";
import {usePermissions, Unauthorized} from "../permissions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import * as alerts from "../generics/alerts";
import Select from "react-select";

export default function RolePermissionsList() {
  const {id} = useParams();
  const history = useHistory();
  const canView = usePermissions(authAPI.ROLES_PATH);
  const [roleDetails, setRoleDetails] = useState({id: "", name: "", permissions: []});

  const fetchRole = useCallback(
    async function () {
      try {
        const resp = await authAPI.getRolePermissions(id);
        setRoleDetails(resp.data);
      } catch (e) {
        alerts.showPopup(alerts.getHttpMessage(e));
        history.push("/roles");
      }
    },
    [id, history]
  );

  useEffect(() => {
    fetchRole();
  }, [fetchRole]);

  if (!canView) {
    return <Unauthorized />;
  }

  return <RolePermissions roleDetails={roleDetails} fetchRole={fetchRole} />;
}

export function RolePermissions({roleDetails, fetchRole}) {
  const {t} = useTranslation(["roles"]);
  var name = roleDetails.name;
  var role_id = roleDetails.id;
  var userPermissions = roleDetails.permissions;
  return (
    <div>
      <h1 className="mb-4 display-4 text-primary">{name}</h1>
      <AssignPermission roleDetails={roleDetails} fetchRole={fetchRole} />
      <Table response="sm" striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t("verb")}</th>
            <th>{t("endpoint")}</th>
            <th>{t("delete")}</th>
          </tr>
        </thead>
        <tbody>
          {userPermissions.map((permission, i) => (
            <PermissionRow
              permission={permission}
              key={i}
              roleId={role_id}
              fetchRole={fetchRole}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
}
function PermissionRow({permission, roleId, fetchRole}) {
  return (
    <tr>
      <td>{permission.verb}</td>
      <td>{permission.endpoint}</td>
      <td>
        <DeleteRolePermission
          roleId={roleId}
          permissionId={permission.id}
          fetchRole={fetchRole}
        />
      </td>
    </tr>
  );
}

function DeleteRolePermission({roleId, permissionId, fetchRole}) {
  const {t} = useTranslation(["roles"]);
  const deletePermission = () => {
    let result = window.confirm(t("confirmDel"));
    if (result === true) {
      var data = {role_id: roleId, permission_id: permissionId};
      authAPI
        .deleteRolePermission(data)
        .then((response) => {
          fetchRole();
          alert(t("shared:success"));
        })
        .catch((err) => {
          alerts.showPopup(alerts.getHttpMessage(err));
        });
    }
  };

  return (
    <Button onClick={deletePermission}>
      <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
    </Button>
  );
}

export function AssignPermission({roleDetails, fetchRole}) {
  const {t} = useTranslation(["roles"]);
  var userPermissions = roleDetails.permissions;
  var role_id = roleDetails.id;
  const [disableSave, setDisableSave] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [permissions, setPermissions] = useState(userPermissions);

  async function fetchEndpoints() {
    try {
      const resp = await authAPI.getPermissions();
      setPermissions(resp.data);
    } catch (err) {
      alerts.showPopup(alerts.getHttpMessage(err));
    }
  }

  useEffect(() => {
    fetchEndpoints();
  }, []);

  var options = authAPI.getUnassignedPermissions(permissions, userPermissions);

  const assignPermission = (e) => {
    e.preventDefault();
    var permission_ids = authAPI.getPermissionIds(selectedOptions);
    let result;
    if (permission_ids.length === 1) {
      result = window.confirm(t("confirmAssign"));
    } else {
      result = window.confirm(t("confirmAssigns"));
    }
    if (result === true) {
      setDisableSave(true);
      var data = {role_id, permission_ids};
      authAPI
        .assignRolePermission(data)
        .then((resp) => {
          setSelectedOptions([]);
          fetchRole();
          alert(t("shared:success"));
        })
        .catch((err) => {
          alerts.showPopup(alerts.getHttpMessage(err));
        });
    }
  };

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    setDisableSave(false);
  };

  return (
    <div>
      <Form className="mb-3">
        <Form.Row>
          <Col>
            <Select
              placeholder={t("defaultValue")}
              isMulti
              value={selectedOptions}
              onChange={handleChange}
              closeMenuOnSelect={false}
              options={options}
            />
          </Col>
          <Col>
            <Button disabled={disableSave} variant="danger" onClick={assignPermission}>
              {t("assignPermissions")}
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </div>
  );
}
