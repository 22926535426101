import React, {useState, useContext} from "react";
import {UserContext} from "../login/userContext";
import {Button, Modal, Form, DropdownButton, Dropdown} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as meteringAPI from "../../services/meteringApi";
import {usePermissions} from "../permissions";
import {getFormProps, InlineFormField, FormErrors} from "../generics/formsFields";
import {showPopup, showErrors, getHttpMessage} from "../generics/alerts";
import SweetAlert from "react-bootstrap-sweetalert";

export default function MeterSwap({
  accountNo,
  customer,
  meter,
  setEnergyAccountLoaded,
  setAccountsAreLoaded,
}) {
  const canSwapMeter = usePermissions(meteringAPI.METER_SWAP_PATH, "POST");
  const canDeactivateMeter = usePermissions(meteringAPI.DEACTIVATE_METER_PATH, "POST");
  const userHasActions = canSwapMeter || canDeactivateMeter;
  const [swapModal, setSwapModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);

  const closeSwapModal = () => setSwapModal(false);
  const showSwapModel = () => setSwapModal(true);

  const closeDeactivateModal = () => setDeactivateModal(false);
  const showDeactivateModel = () => setDeactivateModal(true);
  const {t} = useTranslation(["customer"]);

  return (
    <>
      <DropdownButton
        variant="danger"
        id="dropdown-meter"
        size="md"
        drop="right"
        title={t("meterActions")}
        disabled={!userHasActions}
      >
        <Dropdown.Item onClick={showSwapModel} className={canSwapMeter ? "" : "d-none"}>
          <FontAwesomeIcon icon="sync-alt" /> {t("swapMeter")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={showDeactivateModel}
          className={canDeactivateMeter ? "" : "d-none"}
        >
          <FontAwesomeIcon icon="ban" /> {t("deactivateMeter")}
        </Dropdown.Item>
      </DropdownButton>

      <Modal show={swapModal} onHide={closeSwapModal} backdrop="static" centered>
        <Modal.Header closeButton className="text-secondary">
          <Modal.Title className="text-primary ml-2">{t("swapMeter")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mr-4">
          <MeterSwapForm
            setShow={setSwapModal}
            accountNo={accountNo}
            customer={customer}
            meter={meter}
            setAccountsAreLoaded={setAccountsAreLoaded}
            setEnergyAccountLoaded={setEnergyAccountLoaded}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={deactivateModal}
        onHide={closeDeactivateModal}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton className="text-secondary">
          <Modal.Title className="text-primary ml-2">{t("deactivateMeter")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mr-4">
          <DeactivateMeter
            setShow={setDeactivateModal}
            accountNo={accountNo}
            customer={customer}
            meter={meter}
            setAccountsAreLoaded={setAccountsAreLoaded}
            setEnergyAccountLoaded={setEnergyAccountLoaded}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export function MeterSwapForm({
  setShow,
  accountNo,
  customer,
  meter,
  setEnergyAccountLoaded,
  setAccountsAreLoaded
}) {
  // set up contexts and variables for the form
  const user = useContext(UserContext);
  const {t} = useTranslation(["customer", "shared"]);
  const {register, handleSubmit} = useForm();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const custName = getFormProps(
    "",
    t("customer"),
    "text",
    "true",
    customer.FirstName + " " + customer.LastName
  );
  const oldMeter = getFormProps("old_meter", t("currentMeter"), "text", "true", meter);
  const newMeter = getFormProps("new_meter", t("newMeter"), "text", "true");

  // function to call when submitting form
  const onSubmit = (data) => {
    data.account_number = accountNo;
    const swapData = {...data};
    swapMeters(user.selected, swapData);
  };

  async function swapMeters(portfolio, swapData) {
    try {
      setErrors([]);
      setLoading(true);
      await meteringAPI.meterSwap(portfolio, swapData);
      showPopup(t("swapsuccess"), "success");
      setShow(false);
      setAccountsAreLoaded(false);
      setEnergyAccountLoaded(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setLoading(false);
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormErrors errors={errors}></FormErrors>
        <InlineFormField {...custName} disabled className="my-4" />
        <InlineFormField {...oldMeter} disabled className="my-4" />
        <InlineFormField register={register} {...newMeter} className="my-4" />
        <Button
          md={4}
          lg={4}
          xs={6}
          type="submit"
          variant="danger"
          className="mt-2 mb-1 p-3 pull-right"
          disabled={loading}
        >
          <FontAwesomeIcon className=" mx-2" icon="sync-alt"></FontAwesomeIcon>
          {loading ? t("saving") : t("swapMeter")}
        </Button>
      </Form>
    </div>
  );
}

/**
 * Function to deactivate meter by account number
 */

export function DeactivateMeter({
  meter,
  accountNo,
  setEnergyAccountLoaded,
  setAccountsAreLoaded,
  setShow,
}) {
  const [loading, setLoading] = useState(false);
  const user = useContext(UserContext);
  const {t} = useTranslation(["customer", "shared"]);

  async function deactivateMeter() {
    setLoading(true);
    try {
      await meteringAPI.deactivateMeter(user.selected, accountNo);
      showPopup(t("shared:success"), "success");
    } catch (error) {
      showPopup(getHttpMessage(error));
    }
    setLoading(false);
    setShow(false);
    setEnergyAccountLoaded(false);
    setAccountsAreLoaded(false);
    return;
  }

  return (
    <SweetAlert
      warning={true}
      showCancel={true}
      confirmBtnText={loading ? t("saving") : t("deactivateMeter")}
      confirmBtnBsStyle={"danger"}
      title={t("deactivateMeter")}
      onConfirm={deactivateMeter}
      showConfirmButton={false}
      openAnim={false}
      onCancel={() => setShow(false)}
      disabled={loading}
    >
      {t("deactivateMeterMessage", {meter_number: meter})}
    </SweetAlert>
  );
}
