export const en = {
  registerPermission: "You don't have permission",
  // Customer List table headers
  listTitle: "Customers",
  firstName: "First Name",
  lastName: "Last Name",
  registerTitle: "Register Customer",
  phoneNumber: "Phone Number",
  idNumber: "ID Number",
  houseNumber: "House Number",
  meterNumber: "Meter Number",
  sites: "Sites",
  userUpload: "User Uploads",
  searchByTag: "Search by tag...",
  tags: "Tags",

  // Customer registrations fields
  gender: "Gender",
  male: "Male",
  female: "Female",
  village: "Village",
  address: "Address",
  siteCode: "Site Code",
  language: "Language",
  dateOfBirth: "Date of Birth",
  trained: "Trained",
  cancel: "Cancel",
  register: "Register",
  english: "English",
  swahili: "Swahili",
  siteError: "Site Error",
  langError: "Language Error",
  registerSuccess: "Customer successfully registered",
  addNew: "Add Customer",
  save: "Save",
  updateTariff: "Switch Tariff",
  latitude: "Latitude",
  longitude: "Longitude",

  // Customer Dashboard headers
  transactions: "Transactions",
  sms: "SMS",
  bundles: "Bundles",
  customerInfo: "Customer Info",
  energyAcc: "Energy Acc.",
  products: "Products",
  noEnergyAccounts: "No energy accounts",
  noProductAccounts: "No product accounts",
  saveConfirmation: "Are you sure you want to save your changes?",
  noMeter: "No meter currently assigned to this energy account.",
  meterErrors: "Meter Assign Errors: ",
  assignMeter: "Assign Meter",
  switchTariff: "Switch Tariff",
  successChangeTariff: "Successful tariff changed",
  searchCustmer: "Name, Phone Number, Account, Meter number",
  searchMeterSerial: "Meter Number",

  // Customer Dashboard form labels
  first: "First",
  last: "Last",
  phoneNo: "Phone #",
  idNo: "ID #",
  dob: "DOB",
  site: "Site",
  meter: "Meter",
  assigned: "Assigned",
  url: "URL",
  product: "Product",
  plan: "Plan",
  price: "Price",
  installment: "Installment",
  paid: "Paid",
  purchased: "Purchased",
  status: "Status",
  tariff: "Tariff",
  accountNo: "Account Number",

  // Actions
  swapMeter: "Swap Meter",
  moveCustomer: "Move Customer",
  deactivate: "Deactivate",
  activateEnergy: "Activate Energy Account",
  deactivating: "Deactivating...",
  wait: "Wait...",
  deactivateMessage: "You want to deactivate {{accountNo}}?",
  bulkDeactivateMessage: "You want to deactivate {{count}} customer(s)?",
  deactivateNote: "Deactivate customer(s)",
  deactivatePermission: "Not Authorized to Deactivate Customers",
  deactivateTitle: "Are you sure",
  ok: "OK",
  success: "Success",
  deactivated: "Deactivated ",
  failedDeactivation: "Deactivation Failed",

  //meter swap
  currentMeter: "Current Meter",
  newMeter: "New Meter",
  customer: "Customer",
  swapsuccess: "Meter Swap successful",
  unauthorized: "User is not authorized to Swap Meters",
  errorTemplate: "Request failed with the following errors:\n",
  newMeterUsed: "New meter is already used",
  accountNoMeter: "Account Number does not have any meter",
  saving: "Saving...",

  accountNumber: "Account #",
  meterSerial: "Meter Serial #",
  server: "Server",
  assign: "Assign",

  //move customer
  currentAccount: "Current Account",
  newAccount: "New Account",
  inactiveMeterNotFound: "New account is either occupied or doesn't exist",
  activeMeterNotFound: "Current account is not active",
  customerNotFound: "Customer not found",
  badBillingRequest: "Accounts not found on billing",
  movesuccess: "Move Customer successful",

  //product details
  updateProduct: "Update Details",
  updateProductTitle: "Update Product account",
  depositDueDate: "Deposit due date",
  paymentPlan: "Payment Plan",
  errPaymenPlan: "Failed to get product payment plan for product code {{product_code}}",
  selectPlan: "Kindly select Product Payment Plan..",
  update: "Update",
  updateSuccess: "Successfully updated",
  updating: "Updating ..",
  updateProductMessage:
    "Are you sure you want to update to this payment plan  {{planName}} and deposit due date to this {{depositDueDate}}?",

  //send sms
  sendSMS: "Send SMS",
  sending: "Sending SMS...",
  sendNote: "Send SMS.",
  sendSMSPermission: "You don't have permission to send SMS.",
  addSMS: "Write SMS message here...",
  all: "All",
  warningSendSMS:
    "You are about to send {{count}} message(s) from the customer list you have selected",

  // assign tariff
  failedAssign: "fail to assign tariff",
  assigning: "Assigning Tariff",
  assignTariffNote: "Assign Tariff",
  warningAssignTariff:
    "You are about to assign a tariff to {{count}} customer(s) you have selected",

  // bulk actions
  clearSelection: "Clear selection",
  selectAllResults: "Select all results that match this search",
  selectedCountCustomers: "Selected {{count}} customer(s)",

  //bonus disbursement
  bonusDisbursement: "Bonus Disbursement",
  warningDisburseBonus:
    "You are about to disburse a bonus payment to {{count}} customer(s) you have selected",
  addBonusAmount: "Add Bonus Amount:",
  disbursing: "Disbursing bonus",
  bonusDisburseNote: "Disburse Bonus",

  //manage tags
  manageTags: "Manage Tags",
  unassign: "unassign",
  meterActions: "Meter Actions ",
  deactivateMeter: "Deactivate Meter",
  deactivateMeterMessage: "You want to deactivate Meter {{meter_number}}?",
  activateButton: "Activate",

  //lat and long info
  addLatLongTitle: "Add/Edit Latitude and Longitude Coordinates",
  addLatLong: "Add/Edit Lat&Long",
  add: "Add",
  updateCustomerID: "Sync Customer ID",
  updatingID: "Syncing Customer ID...",
  successUpdateCustomerID: "Customer ID successfully updated from Vendor",
};

export const fr = {};
