import React, {useEffect, useState, useCallback} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Table, Button, Card} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Skeleton from "react-loading-skeleton";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {usePermissions} from "../permissions";

import EditTagModal from "./editTagModal";
import MergeTagModal from "./mergeTagModal";
import * as billingAPI from "../../services/billingApi";
const queryString = require("query-string");

export default function TagsTable({user, limit, page, setCount}) {
  const {t} = useTranslation(["tags", "shared"]);
  const location = useLocation();
  const [tags, setTags] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [showEditTagModal, setShowEditTagModal] = useState(false);
  const [showDeleteTagAlert, setShowDeleteTagAlert] = useState(false);
  const [showMergeTagModal, setShowMergeTagModal] = useState(false);

  const fetchTags = useCallback(
    async function () {
      try {
        const searchParams = queryString.parse(location.search);
        const resp = await billingAPI.getTags(user.selected, searchParams);
        setTags(resp.data.data);
        setCount(resp.data.count);
        setLoading(false);
      } catch (err) {
        showPopup(getHttpMessage(err), "error");
      }
    },
    [location.search, user.selected, setCount]
  );

  useEffect(() => {
    fetchTags();
  }, [reload, limit, page, fetchTags]);

  const deleteTag = async () => {
    try {
      await billingAPI.deleteTag(user.selected, payload.id);
      showPopup(t("shared:success"), "success");
      setShowDeleteTagAlert(false);
      setReload((prev) => !prev);
    } catch (err) {
      showPopup(getHttpMessage(err), "error");
    }
  };

  if (loading) {
    return (
      <Card className="text-center mb-3">
        <Skeleton height={30} count="8" />
      </Card>
    );
  }

  return (
    <>
      {showDeleteTagAlert ? (
        <SweetAlert
          warning={true}
          showCancel={true}
          confirmBtnText={t("shared:delete")}
          confirmBtnBsStyle={"danger"}
          title={t("deleteTitle")}
          onConfirm={deleteTag}
          showConfirmButton={false}
          openAnim={true}
          onCancel={() => setShowDeleteTagAlert(false)}
        >
          {t("deleteTagMessage", {tag: payload.name, count: payload.count})}
        </SweetAlert>
      ) : null}

      {showEditTagModal ? (
        <EditTagModal
          show={showEditTagModal}
          setShow={setShowEditTagModal}
          payload={payload}
          setPayload={setPayload}
          user={user}
          setReload={setReload}
        />
      ) : null}
      {showMergeTagModal ? (
        <MergeTagModal
          user={user}
          tagToMerge={payload}
          show={showMergeTagModal}
          setReload={setReload}
          setShow={setShowMergeTagModal}
        />
      ) : null}
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>{t("index")}</th>
            <th>{t("tag")}</th>
            <th>{t("assignedAccts")}</th>
            <th>{t("shared:delete")}</th>
            <th>{t("shared:edit")}</th>
            <th>{t("shared:merge")}</th>
          </tr>
        </thead>
        <tbody>
          {tags
            ? tags.map((tag, i) => (
                <TagRow
                  tag={tag}
                  index={i + 1}
                  key={tag.id}
                  setPayload={setPayload}
                  setShowEditTagModal={setShowEditTagModal}
                  setShowDeleteTagAlert={setShowDeleteTagAlert}
                  setShowMergeTagModal={setShowMergeTagModal}
                />
              ))
            : null}
        </tbody>
      </Table>
    </>
  );
}

function TagRow({
  index,
  tag,
  setPayload,
  setShowEditTagModal,
  setShowDeleteTagAlert,
  setShowMergeTagModal,
}) {
  const {id, name, number_assigned_accounts} = tag;
  const canEdit = usePermissions(billingAPI.TAGS_UPDATE_PATH, "PUT");
  const canMerge = usePermissions(billingAPI.TAGS_MERGE_PATH, "POST");
  const canDelete = usePermissions(billingAPI.TAGS_DELETE_PATH, "DELETE");

  const handleOnDeleteBtnClick = () => {
    setPayload({id, name, count: number_assigned_accounts});
    setShowDeleteTagAlert(true);
  };

  const handleOnEditBtnClick = () => {
    setPayload({id, name, count: number_assigned_accounts});
    setShowEditTagModal(true);
  };

  const handleOnMergeBtnClick = () => {
    setPayload({id, name, count: number_assigned_accounts});
    setShowMergeTagModal(true);
  };

  return (
    <>
      <tr>
        <td>{index}</td>
        <td>{name}</td>
        <td>{number_assigned_accounts}</td>
        <td>
          <Button
            variant="primary"
            disabled={!canDelete}
            onClick={handleOnDeleteBtnClick}
          >
            <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
          </Button>
        </td>
        <td>
          <Button variant="danger" disabled={!canEdit} onClick={handleOnEditBtnClick}>
            <FontAwesomeIcon icon="pencil-alt"></FontAwesomeIcon>
          </Button>
        </td>
        <td>
          <Button variant="primary" disabled={!canMerge} onClick={handleOnMergeBtnClick}>
            <FontAwesomeIcon icon="compress-alt"></FontAwesomeIcon>
          </Button>
        </td>
      </tr>
    </>
  );
}
