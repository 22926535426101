import React, {useState, useContext} from "react";
import {Card, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import {UserContext} from "../login/userContext";
import {usePermissions, Unauthorized} from "../permissions";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {FileUpload, Instructions, ErrorList} from "../generics/fileUploads";
import * as meteringAPI from "../../services/meteringApi";

export default function ChangeMeterState() {
  const {t} = useTranslation(["fileupload", "shared"]);
  const user = useContext(UserContext);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0); // %
  const [errors, setErrors] = useState([]);
  const [mode, setMode] = useState("");
  const canView = usePermissions(meteringAPI.CHANGE_METER_STATE, "POST");

  const stateOptions = ["ON", "OFF", "AUTO"];
  const states = stateOptions.map((state) => {
    return {name: state, value: state};
  });

  if (!canView) {
    return <Unauthorized />;
  }

  //sample file
  const filename = "sample_change_meter_state.csv";
  const headers = "account_number";
  const sampleData = [["account_number"], ["00400002"], ["232-1-40"], ["00620002"]];

  //instructions
  const pageTitle = t("meterStateChangeTitle"); //

  const reminders = [
    t("limitWarning"),
    t("emptyAccountWarning"),
    t("shared:waitAfterUpload"),
  ];

  async function changeMeterState(data, config) {
    let payload = {};
    let accounts = [];
    if (mode.length > 0) {
      payload.mode = mode;
    } else {
      setErrors([t("selectStateError")]);
      setProgress(0);
      return;
    }
    if (data.length > 0) {
      data.forEach((acc) => {
        accounts.push(acc.account_number);
      });
      payload.account_nos = accounts;
    } else {
      setErrors([t("emptyList")]);
      setProgress(0);
      return;
    }
    try {
      await meteringAPI.changeMeterState(user.selected, payload, config);
      showPopup(t("shared:successWithWait"), "success");
      resetStates();
    } catch (err) {
      setErrors(getHttpMessage(err));
      setProgress(0);
    }
  }

  const resetStates = () => {
    setProgress(0);
    setData([]);
    setLoaded(false);
    setErrors([]);
  };

  return (
    <div>
      <Card className="shadow">
        <Card.Body className="p-4">
          <Instructions
            title={pageTitle}
            isLoaded={isLoaded}
            headers={headers}
            filename={filename}
            sampleData={sampleData}
            reminders={reminders}
          />

          <Form.Group controlId="">
            <Form.Label>{t("selectState")} </Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                setMode(e.target.value);
              }}
              custom
            >
              <option id="select_meter_mode" value="">
                {t("select")}
              </option>
              {states && states.length > 0
                ? states.map((state) => {
                    return (
                      <option key={state.value} value={state.value} id={state.value}>
                        {state.name}
                      </option>
                    );
                  })
                : null}
            </Form.Control>
          </Form.Group>

          <FileUpload
            sampleData={sampleData}
            data={data}
            errors={errors}
            progress={progress}
            setData={setData}
            setErrors={setErrors}
            setProgress={setProgress}
            setLoaded={setLoaded}
            setColumns={setColumns}
            sendToAPI={changeMeterState}
            processButtonName={t("switchState")}
            handleCancel={resetStates}
          />

          <ErrorList errors={errors} handleReUpload={resetStates} />

          <DataTable
            pagination
            highlightOnHover
            columns={columns}
            data={data}
            noDataComponent=""
          />
        </Card.Body>
      </Card>
    </div>
  );
}
