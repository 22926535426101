import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as authApi from "../../services/authApi";
import SweetAlert from "react-bootstrap-sweetalert";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {Link} from "react-router-dom";

export default function Deleting({id, setReloadUpdate, reload}) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation(["aminiUpdates", "shared"]);

  async function deleteUpdate() {
    if (!loading) {
      setLoading(true);
    }
    try {
      await authApi.deleteUpdate(id);
      showPopup(t("shared:success"), "success");
    } catch (error) {
      showPopup(getHttpMessage(error));
    }
    setLoading(false);
    setShow(false);
    setReloadUpdate(!reload);
    return;
  }
  return (
    <>
      <span className="p-3 pull-right hover">
        <Link onClick={() => setShow(true)}>
          {" "}
          <FontAwesomeIcon icon="trash-alt" />
        </Link>
      </span>
      {show ? (
        <SweetAlert
          warning={true}
          showCancel={true}
          confirmBtnText={loading ? t("deleting") : t("delete")}
          confirmBtnBsStyle={"danger"}
          title={t("deleteTitle")}
          onConfirm={deleteUpdate}
          showConfirmButton={false}
          openAnim={false}
          onCancel={() => setShow(false)}
          disabled={loading}
        >
          {t("deleteMessage")}
        </SweetAlert>
      ) : null}
    </>
  );
}
