import React, {useState, useContext, useEffect} from "react";
import {Form, Button, Modal, ModalBody} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {usePermissions} from "../permissions";
import * as billingAPI from "../../services/billingApi";
import {ModalSubmitButton} from "../generics/buttons";
import {UserContext} from "../login/userContext";
import {showErrors} from "../generics/alerts";
import {GoogleMapPicker, getMapPickerProps} from "../generics/googleMapPicker";
import {FormErrors} from "../generics/formsFields";

export default function AddLatLongInfo({account, site, setAccountsAreLoaded}) {
  const canView = usePermissions(billingAPI.ENERGY_ACCOUNT_UPDATE_PATH, "PATCH");
  const [show, setShow] = useState(false);
  const {t} = useTranslation(["customer"]);
  return (
    <div>
      <Button
        variant="danger"
        disabled={!canView}
        onClick={() => setShow(true)}
        className="mb-2  pull-right"
      >
        {" "}
        {t("addLatLong")}
      </Button>
      <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
        <Modal.Header closeButton className="text-primary">
          <Modal.Title>{t("addLatLongTitle")}</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <AddLatLongForm
            account={account}
            setShow={setShow}
            site={site}
            setAccountsAreLoaded={setAccountsAreLoaded}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}

export function AddLatLongForm({account, setShow, site, setAccountsAreLoaded}) {
  const accountNo = account.account_number;
  const accountSiteCode = account.site_code;
  const user = useContext(UserContext);
  const {t} = useTranslation(["customer"]);
  const {register, handleSubmit} = useForm();
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  //google map picker variables and functions
  const defaultZoom = 4;
  const siteLocation = {
    lat: site.latitude,
    lng: site.longitude,
  };
  const [defaultLocation, setDefaultLocation] = useState(siteLocation);
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(defaultZoom);
  const [lat, setLat] = useState(location.lat);
  const [long, setLong] = useState(location.lng);
  const [isChanged, setIsChanged] = useState(false);

  const handleChangeLocation = (lat, lng) => {
    setLocation({lat: lat, lng: lng});
    setLat(lat);
    setLong(lng);
    setIsChanged(true);
  };

  const handleChangeZoom = (newZoom) => {
    setZoom(newZoom);
  };

  const handleResetLocation = () => {
    setDefaultLocation({...siteLocation});
    setZoom(defaultZoom);
    setLat(siteLocation.lat);
    setLong(siteLocation.lng);
    setIsChanged(true);
  };

  useEffect(() => {
    if (isChanged) {
      setDefaultLocation({lat: lat, lng: long});
      setIsChanged(false);
    }
  }, [accountSiteCode, isChanged, lat, long]);

  const onSubmit = async (data) => {
    data.account_no = accountNo;
    data.latitude = parseFloat(data.latitude);
    data.longitude = parseFloat(data.longitude);
    let payload = [data];
    try {
      await billingAPI.updateEnergyAccount(user.selected, payload);
      setErrors([]);
      setSaving(true);
      setShow(false);
      setAccountsAreLoaded(false);
      // showPopup(t("shared:success"), "success");
    } catch (err) {
      showErrors(err, setErrors);
    }
    setSaving(false);
  };
  const picker = getMapPickerProps(
    defaultLocation,
    zoom,
    lat,
    long,
    handleChangeZoom,
    setLat,
    setLong,
    setIsChanged,
    handleChangeLocation,
    handleResetLocation
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="m-3">
      <FormErrors errors={errors}></FormErrors>
      <GoogleMapPicker register={register} {...picker} />
      <ModalSubmitButton
        loading={saving}
        variant="danger"
        icon="plus"
        text={t("add")}
      ></ModalSubmitButton>
    </Form>
  );
}
