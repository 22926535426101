import React from "react";
import {Form, Row, Col} from "react-bootstrap";

export default function SearchForm({searchQuery, t}) {
  return (
    <Form>
      <Row>
        <Col xs={12} md={3}>
          <Form.Control
            id="search-form-customer"
            type="text"
            placeholder={t("searchFormPlaceholder")}
            onChange={(e) => searchQuery({name: e.target.value})}
            className="mb-3"
            name="name"
            cancel
          />
        </Col>
      </Row>
    </Form>
  );
}
