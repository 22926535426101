export const en = {
  accountNo: "Account No",
  bundleName: "Bundle Name",
  bundlePrice: "Bundle Price",
  pricePerKwh: "Price/kWh",
  unusedAmount: "Unused Amount",
  status: "Status",
  startDate: "Start Date",
  endDate: "End Date",
};

export const fr = {};

export const swa = {};
