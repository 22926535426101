import React, {useState, useEffect, useContext} from "react";
import {Table, Form, Col, Button} from "react-bootstrap";
import {usePermissions, Unauthorized} from "../permissions";
import {UserContext} from "../login/userContext";
import * as DateHelpers from "../../services/date";
import * as meteringAPI from "../../services/meteringApi";
import * as alerts from "../generics/alerts";
import {useLocation} from "react-router";
import {useHistory, Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {ListHeader} from "../generics/headers";
import {ListPagination} from "../generics/pagination";
import Skeleton from "react-loading-skeleton";
import {formatFileNameDate} from "../../services/date";

const queryString = require("query-string");

export default function SiteList(props) {
  const user = useContext(UserContext);
  const canView = usePermissions(meteringAPI.SITE_PATH);
  const location = useLocation();
  const history = useHistory();
  const {t} = useTranslation(["sites"]);
  const [sites, setSites] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  let query = queryString.parse(location.search);
  let country_id = user.permissions[user.selected].countryID;

  // updateQuery is a function to create params from the query
  const updateQuery = (updates) => {
    query = Object.assign(query, updates);
    var params = queryString.stringify(query, {
      skipEmptyString: true,
    });

    history.push("/sites/list?" + params);
  };

  useEffect(() => {
    async function fetchSites() {
      const searchParams = queryString.parse(location.search);
      searchParams.page = page;
      searchParams.limit = limit;
      searchParams.country_id = country_id;
      await meteringAPI
        .getSites(user.selected.toLowerCase(), searchParams)
        .then((resp) => {
          setCount(resp.data.count);
          setSites(resp.data.data);
          setLoading(true);
        })
        .catch((err) => {
          setSites({});
          alerts.showPopup(alerts.getHttpMessage(err));
        });
      setLoading(false);
    }
    fetchSites();
  }, [user.selected, country_id, location.search, page, limit]);

  async function getAllSites() {
    const searchParams = queryString.parse(location.search);
    searchParams.limit = count;
    searchParams.country_id = country_id;
    let allData = [];
    //setting up progress bar
    setProgress(50);
    await meteringAPI
      .getSites(user.selected.toLowerCase(), searchParams)
      .then((resp) => {
        allData.push(...resp.data.data);
        setProgress(100);
      })
      .catch((err) => {
        setProgress(0);
        alerts.showPopup(alerts.getHttpMessage(err));
      });
    setProgress(0);
    return allData;
  }

  //check if user has access to this page
  if (!canView) {
    return <Unauthorized />;
  }

  // returns the various segments of the page
  // header, search form, sites table, paginator
  return (
    <div>
      <ListHeader title={t("siteList")} icon="map-marker-alt" />
      <SearchForm updateQuery={updateQuery} query={query} />
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        data={sites}
        filename={formatFileNameDate(new Date().toString()) + "_sites.csv"}
        getAllData={getAllSites}
        progress={progress}
      />
      {loading ? (
        <Skeleton height={20} count="2" className="m-3" />
      ) : (
        <SitesTable sites={sites} />
      )}
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        data={sites}
        filename={formatFileNameDate(new Date().toString()) + "_sites.csv"}
        getAllData={getAllSites}
        progress={progress}
      />
    </div>
  );
}

function SearchForm({query, updateQuery}) {
  const {t} = useTranslation(["sites", "shared"]);

  // check if user can register sites
  const canReg = usePermissions(meteringAPI.SITE_PATH, "POST");
  var regButtonTitle = "";
  var disabled = false;
  if (!canReg) {
    regButtonTitle = t("shared:unauthorized");
    disabled = true;
  }
  const options = [
    {name: "Active", value: "active"},
    {name: "Inactive", value: "inactive"},
  ];

  return (
    <Form className="mb-3">
      <Form.Row>
        <Col xs={8} md={4}>
          <Form.Control
            className="mb-1"
            type="text"
            name="search"
            onChange={(e) => {
              updateQuery({search: e.target.value});
            }}
            placeholder={t("searchBar")}
          ></Form.Control>
        </Col>
        <Col xs={4} md={2}>
          <Form.Control
            placeholder={t("selectSiteStatus")}
            className="mb-1"
            name="status"
            as="select"
            onChange={(e) => {
              updateQuery({status: e.target.value});
            }}
          >
            <option value="">{t("selectSiteStatus")}</option>
            {options.map((option) => {
              return (
                <option value={option.value} key={option.value}>
                  {option.name}
                </option>
              );
            })}
          </Form.Control>
        </Col>
        <Col md={6}>
          <Link to="/sites-register">
            <Button
              variant="danger"
              className="mb-1 pull-right"
              title={regButtonTitle}
              disabled={disabled}
            >
              <FontAwesomeIcon icon="plus"></FontAwesomeIcon> {t("addNew")}
            </Button>
          </Link>
        </Col>
      </Form.Row>
    </Form>
  );
}

// component SitesTable that queries the API and displays results
function SitesTable({sites}) {
  const {t} = useTranslation(["sites", "shared"]);

  return (
    <Table striped bordered size="l" className="shadow" hover>
      <thead>
        <tr>
          <th>{t("code")}</th>
          <th>{t("name")}</th>
          <th>{t("region")}</th>
          <th>{t("country")}</th>
          <th>{t("owner")}</th>
          <th>{t("vendor")}</th>
          <th>{t("dateCommissioned")}</th>
          <th>{t("dateDecommissioned")}</th>
        </tr>
      </thead>
      <tbody>
        {sites.length ? (
          sites.map((st) => {
            return <SitesRow key={st.site_id} site={st} />;
          })
        ) : (
          <tr></tr>
        )}
      </tbody>
    </Table>
  );

  function SitesRow({site}) {
    const link = `/site/${site.site_id}`;
    const history = useHistory();

    function onClick() {
      history.push(link);
    }
    const textColor = (d) => {
      return d != null ? "text-light" : "";
    };
    return (
      <tr onClick={onClick} className={textColor(site.date_decommissioned)}>
        <td>{site.site_code}</td>
        <td>{site.site_name}</td>
        <td>{site.region}</td>
        <td>{site.country}</td>
        <td>{site.owner}</td>
        <td>{site.vendor_name}</td>
        <td>{DateHelpers.formatDisplayDateDay(site.date_commissioned).toString()}</td>
        <td>
          {site.date_decommissioned != null
            ? DateHelpers.formatDisplayDateDay(site.date_decommissioned).toString()
            : ""}
        </td>
      </tr>
    );
  }
}
