import React, {useState, useEffect, useContext, useCallback} from "react";
import {Link, useLocation, useHistory} from "react-router-dom";
import {Button, Row, Col, Form, Table, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {parse, stringify} from "query-string";
import Skeleton from "react-loading-skeleton";

import {usePermissions, Unauthorized} from "../permissions";
import {useTranslation} from "react-i18next";
import {UserContext} from "../login/userContext";

import {ListHeader} from "../generics/headers";
import {ListPagination} from "../generics/pagination";
import {showPopup, getHttpMessage} from "../generics/alerts";
import * as meteringAPI from "../../services/meteringApi";

export default function TariffList() {
  const {t} = useTranslation(["tariffs"]);
  const location = useLocation();
  const history = useHistory();
  const user = useContext(UserContext);
  const canView = usePermissions(meteringAPI.TARIFFS_PATH, "GET");
  const [tariffs, setTariffs] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(200);
  const [count, setCount] = useState(0);

  let query = parse(location.search);
  const updateQuery = (updates) => {
    query = Object.assign(query, updates);
    var search = stringify(query, {skipEmptyString: true});
    history.push("/tariffs?" + search);
  };

  useEffect(() => {
    async function fetchTariffs() {
      let query = parse(location.search);
      query.page = page;
      query.limit = limit;
      query.country_id = user.permissions[user.selected].countryID;
      try {
        const resp = await meteringAPI.getTariffs(user.selected, query);
        setLoading(true);
        setCount(resp.data.count);
        setTariffs(resp.data.data);
      } catch (err) {
        setTariffs([]);
        showPopup(getHttpMessage(err));
      }
      setLoading(false);
    }
    fetchTariffs();
  }, [user, location.search, limit, page]);

  if (!canView) {
    return <Unauthorized />;
  }

  return (
    <>
      <ListHeader icon="money-bill-alt" title={t("tariffs")} />
      <SearchBar setSearch={setSearch} updateQuery={updateQuery} />
      {loading ? (
        <Skeleton height={20} count="5" className="my-3" />
      ) : (
        <TariffsTable tariffs={tariffs} search={search} />
      )}
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
      />
    </>
  );
}

function SearchBar({setSearch, updateQuery}) {
  const canAdd = usePermissions(meteringAPI.TARIFFS_PATH, "POST");
  const user = useContext(UserContext);
  const {t} = useTranslation(["tariffs"]);
  const [vendors, setVendors] = useState([]);
  const [sites, setSites] = useState([]);

  const fetchVendors = useCallback(
    async function () {
      try {
        const resp = await meteringAPI.getVendors(user.selected);
        setVendors(resp.data);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
    },
    [user.selected]
  );

  const fetchSites = useCallback(
    async function () {
      let country_id = user.permissions[user.selected].countryID;
      try {
        const resp = await meteringAPI.getSites(user.selected, {country_id});
        setSites(resp.data.data);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
    },
    [user]
  );

  useEffect(() => {
    fetchSites();
    fetchVendors();
  }, [fetchVendors, fetchSites]);

  if (!sites || !vendors) {
    return <Skeleton height={40} count="1" className="m-3" />;
  }

  return (
    <Form>
      <Form.Row className="mb-2">
        <Col md={5} className="mb-2">
          <Form.Control
            className="mr-2"
            type="text"
            name="search"
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("searchByTariffName")}
          ></Form.Control>
        </Col>
        <Col md={2} className="mb-2">
          <Form.Control
            className="mr-2"
            as="select"
            onChange={(e) => {
              updateQuery({vendor_id: e.target.value});
            }}
            name="vendor_id"
          >
            <option value="">{t("selectVendor")}</option>
            {vendors.length
              ? vendors.map((v) => {
                  return (
                    <option value={v.VendorId} id={v.VendorId}>
                      {v.Name}
                    </option>
                  );
                })
              : null}
          </Form.Control>
        </Col>
        <Col md={3} className="mb-2">
          <Form.Control
            className="mr-2"
            as="select"
            onChange={(e) => {
              updateQuery({site_code: e.target.value});
            }}
            name="site_code"
          >
            <option value="">{t("selectSite")}</option>
            {sites.length
              ? sites.map((s) => {
                  return (
                    <option value={s.site_code} id={s.site_id}>
                      {s.site_name}
                    </option>
                  );
                })
              : null}
          </Form.Control>
        </Col>
        <Col md={2} className="mb-2">
          <Link to="tariffs-register">
            <Button variant="danger" className="mb-2 pull-right" disabled={!canAdd}>
              <FontAwesomeIcon icon="plus" /> {t("addNew")}
            </Button>
          </Link>
        </Col>
      </Form.Row>
    </Form>
  );
}

function TariffsTable({tariffs, fetchTariffs, search}) {
  const {t} = useTranslation(["tariffs"]);
  const [filteredTariffs, setFilteredTariffs] = useState(tariffs);

  useEffect(() => {
    setFilteredTariffs(
      tariffs.filter((tariff) => tariff.name.toLowerCase().includes(search.toLowerCase()))
    );
  }, [search, tariffs]);

  return (
    <>
      <Row>
        <Col>
          <Table striped bordered className="shadow" response="sm" hover>
            <thead>
              <tr>
                <th>{t("name")}</th>
                <th>{t("vendor")}</th>
                <th>{t("lowBalance")}</th>
                <th>{t("minimumTopUp")}</th>
                <th>{t("baseRate")}</th>
                <th>{t("planCharge")}</th>
                <th>{t("smsCode")}</th>
                <th>{t("delete")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredTariffs.map((tariff, i) => (
                <TariffsRow tariff={tariff} key={i} fetchTariffs={fetchTariffs} />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

function TariffsRow({tariff, fetchTariffs}) {
  const link = `/tariffs/${tariff.id}`;
  const history = useHistory();
  function onClick() {
    history.push(link);
  }
  return (
    <tr>
      <td onClick={onClick}>{tariff.name}</td>
      <td onClick={onClick}>{tariff.vendor_name}</td>
      <td onClick={onClick}>{tariff.low_bal_warning ? tariff.low_bal_warning : 0}</td>
      <td onClick={onClick}>{tariff.minimum_topup ? tariff.minimum_topup : 0}</td>
      <td onClick={onClick}>{tariff.base_rate ? tariff.base_rate : 0}</td>
      <td onClick={onClick}>{tariff.monthly_charge ? tariff.monthly_charge : 0}</td>
      <td onClick={onClick}>{tariff.sms_keyword}</td>
      <td>
        <DeleteTariff tariff={tariff} fetchTariffs={fetchTariffs} />
      </td>
    </tr>
  );
}

function DeleteTariff({tariff, fetchTariffs}) {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const canView = usePermissions(meteringAPI.TARIFFS_PATH, "DELETE");
  const handleDelete = () => {
    setShowDeleteConfirm(true);
  };

  return (
    <>
      <ConfirmDeleteRoleModal
        tariffDetails={tariff}
        showDeleteConfirm={showDeleteConfirm}
        setShowDeleteConfirm={setShowDeleteConfirm}
        fetchTariffs={fetchTariffs}
      />
      <Button onClick={handleDelete} disabled={!canView}>
        <FontAwesomeIcon icon="trash-alt" />
      </Button>
    </>
  );
}

export const ConfirmDeleteRoleModal = ({
  tariffDetails,
  setShowDeleteConfirm,
  showDeleteConfirm,
  fetchTariffs,
}) => {
  const {t} = useTranslation(["roles"]);
  const [disable, setDisabled] = useState(false);
  const {id, name} = tariffDetails;
  const user = useContext(UserContext);
  const text = `${t("confirmDelete")} ${name} ?`;

  const handleClose = () => {
    setShowDeleteConfirm(false);
  };

  const handleDelete = async () => {
    try {
      setDisabled(true);
      await meteringAPI.deleteTariff(user.selected, id);
      showPopup(t("shared:success"), "success");
      setShowDeleteConfirm(false);
      fetchTariffs();
    } catch (error) {
      const message = getHttpMessage(error);
      setShowDeleteConfirm(false);
      showPopup(message);
    }
    setDisabled(false);
  };

  return (
    <Modal centered show={showDeleteConfirm} onHide={handleClose} backdrop="static">
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer style={{borderTopWidth: 0}}>
        <Button variant="light" onClick={handleClose}>
          {t("shared:cancel")}
        </Button>
        <Button
          variant="primary"
          onClick={handleDelete}
          className="text-white"
          disabled={disable}
        >
          {t("shared:delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
