import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as billingApi from "../../services/billingApi";
import {usePermissions} from "../permissions";
import {faTags} from "@fortawesome/free-solid-svg-icons";
import AssignTag from "./../bulkActions/assignTag";

export default function ManageTags({accountNo, fetchAccounts}) {
  const canView = usePermissions(billingApi.ASSIGN_TAG_PATH, "POST");
  const [show, setShow] = useState(false); //dialog show/hide controller
  const handleShow = () => setShow(true);
  const {t} = useTranslation(["customer"]);

  let customer = [{AccountNumber: accountNo}];

  return (
    <>
      <Button
        className="mb-2 p-1 pull-left"
        variant="danger"
        test-id="manage-tags-button"
        disabled={!canView}
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faTags}></FontAwesomeIcon> {t("manageTags")}
      </Button>

      <AssignTag
        modalShow={show}
        setModalShow={setShow}
        customerList={customer}
        selectAllInSearch={false}
        count={1}
        fetchAccounts={fetchAccounts}
      />
    </>
  );
}
