export const en = {
  addNew: "Add New",
  registerNewTariff: "Register New Tariff",
  name: "Name",
  baseRate: "Base Rate",
  monthlyChargeDay: "Monthly Charge Day",
  monthlyCharge: "Monthly Charge",
  loadLimit: "Load Limit",
  lowBalanceWarning: "Low Balance Warning",
  vendorType: "Vendor",
  minimumTopUp: "Minimum Topup",
  showAdvance: "Show Advance",
  touRates: "TOU Rates",
  blockRates: "Block Rates",
  startTime: "Start Time",
  endTime: "End Time",
  multiplier: "Multiplier",
  energyUse: "Energy Range (kWh/month)",
  tariffs: "Tariffs",
  lowBalance: "Low Balance",
  vendor: "Vendor",
  dateCreated: "Date Created",
  delete: "Delete",
  searchByTariffName: "Search by Tariff Name",
  placeHolder: "Enter a number between 1-31",
  vendorRulesInstructions:
    "Enter valid JSON for custom tariff configurations in the metering vendor:",
  saveChanges: "Save Changes",
  sendNote: "Click to save rate",
  planCharge: "Plan Charge",
  vendorTariffId: "Vendor Tariff ID",
  selectVendor: "Select Vendor",
  selectSite: "Select Site",
  smsKeyword: "SMS Keyword",
  smsCode: "SMS Code",
  tariffDetails: "Tariff Explanation (50 characters)",

  vendorError: "Error fetching meter vendors",
  siteError: "Error fetching sites",
  tariffError: "Error fetching sites",
  smsSupport: "Add Tariff to SMS List",
  detailsRequired:
    "SMS Keyword and Tariff Explanation Fields are required to support SMS",
};
