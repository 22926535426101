import React, {useState, useEffect} from "react";
import {Accordion, Card, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserTag} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import * as alerts from "../generics/alerts";

import {AddRoleForm, ConfirmDeleteRoleModal} from "./roleForms";
import * as authApi from "../../services/authApi";
import {useHistory} from "react-router-dom";
import {usePermissions, Unauthorized} from "../permissions";
import {getAllRoles, getSingleRole, ROLES_PATH} from "../../services/authApi";

const RolesList = () => {
  const {t} = useTranslation(["roles"]);

  const canView = usePermissions(ROLES_PATH);
  const [roles, setRoles] = useState([]);
  const [isLoaded, setIsLoaded] = useState(null);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const resp = await getAllRoles();
        setRoles(resp.data);
      } catch (err) {
        const message = alerts.getHttpMessage(err);
        alerts.showPopup(message);
      }
    };
    fetchRoles();
    // eslint-disable-next-line
  }, [isLoaded]);

  if (!canView) {
    return <Unauthorized />;
  }

  return (
    <React.Fragment>
      <h1 className="mb-4 display-4 text-primary">
        <FontAwesomeIcon icon={faUserTag} /> {t("roles")}
      </h1>
      <AddRoleForm setIsLoaded={setIsLoaded} />
      <div className="shadow">
        <Accordion>
          {roles &&
            roles.map((role) => (
              <RoleCard role={role} key={role.id} setIsLoaded={setIsLoaded} />
            ))}
        </Accordion>
      </div>
    </React.Fragment>
  );
};

const RoleCard = ({role, setIsLoaded}) => {
  const {id, name} = role;
  const [roleDetails, setRoleDetails] = useState({id: "", name: "", users: []});

  const fetchRoleDetails = async (e) => {
    try {
      const resp = await getSingleRole(id);
      setRoleDetails(resp.data);
    } catch (err) {
      const message = alerts.getHttpMessage(err);
      alerts.showPopup(message);
    }
  };

  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={id} onClick={fetchRoleDetails}>
        {name}
        <FontAwesomeIcon icon="angle-down" className="pull-right"></FontAwesomeIcon>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={id}>
        <RoleDetails
          roleDetails={roleDetails}
          key={id}
          setIsLoaded={setIsLoaded}
          role={role}
        />
      </Accordion.Collapse>
    </Card>
  );
};

const RoleDetails = ({roleDetails, setIsLoaded, role}) => {
  const {t} = useTranslation(["roles"]);
  const canDeleteRole = usePermissions(authApi.ROLES_PATH, "DELETE");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDelete = () => {
    setShowDeleteConfirm(true);
  };

  const {users} = roleDetails;
  const history = useHistory();
  const link = `/roles/${role.id}`;
  function onClick() {
    history.push(link);
  }

  return (
    <Card.Body>
      <ConfirmDeleteRoleModal
        roleDetails={roleDetails}
        setIsLoaded={setIsLoaded}
        showDeleteConfirm={showDeleteConfirm}
        setShowDeleteConfirm={setShowDeleteConfirm}
      />
      {users.map((user) => {
        return (
          <p variant="primary" key={user.username}>
            <b>{user.username.toUpperCase()}:</b> {user.portfolios.join(", ")}
          </p>
        );
      })}

      <p className="pb-4">
        <Button
          variant="danger"
          className="mr-2 p-2 pull-right"
          onClick={handleDelete}
          disabled={!canDeleteRole}
          title={!canDeleteRole ? t("noDeletePermission") : null}
        >
          <FontAwesomeIcon icon="trash-alt" className="mr-2"></FontAwesomeIcon>
          {t("shared:delete")}
        </Button>
        <Button variant="primary" className="mr-2 p-2 pull-right" onClick={onClick}>
          {t("view")}
        </Button>
      </p>
    </Card.Body>
  );
};

export default RolesList;
