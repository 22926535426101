import React, {useState, useMemo, useRef} from "react";
import {Form, Col, Button, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {usePermissions, Unauthorized} from "../permissions";
import * as authApi from "../../services/authApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormErrors} from "../generics/formsFields";
import {showPopup, showErrors} from "../generics/alerts";
import {useHistory} from "react-router-dom";
import ReactQuill from "react-quill";
import {CancelButton} from "../generics/buttons";

export default function RegisterAminiUpdate() {
  const {t} = useTranslation(["aminiUpdates"]);
  const canCreate = usePermissions(authApi.AMINI_UPDATES_PATH, "POST");
  if (!canCreate) {
    return <Unauthorized />;
  }
  return (
    <div className="mx-5">
      <h1 className="mb-4 display-4 text-primary">
        <FontAwesomeIcon icon="flag"></FontAwesomeIcon> {t("createUpdate")}
      </h1>
      <div className="mt-2 shadow bg-white border rounded">
        <RegisterAminiUpdateForm />
      </div>
    </div>
  );
}

export function RegisterAminiUpdateForm({update}) {
  // set up contexts and variables for the form
  const {t} = useTranslation(["aminiUpdates"]);
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState("");
  const [heading, setHeading] = useState(update && update.heading ? update.heading : "");
  const quillRef = useRef(null);

  async function handleSubmit() {
    if (Object.keys(data).length < 1) {
      setErrors([t("dataRequired")]);
      return;
    }
    if (!heading) {
      setErrors([t("headingRequired")]);
      return;
    } else {
      if (heading.length > 50) {
        setErrors([t("heading50")]);
        return;
      }
    }

    setErrors([]);
    const quillOutput = JSON.stringify(data);
    setSaving(true);
    setErrors([]);
    const reqBody = {heading, data: quillOutput};
    try {
      if (update && update.id) {
        //update amini update
        await authApi.updateAminiUpdate(update.id, reqBody);
        showPopup(t("shared:success"), "success");
        history.push("/aminiupdates");
      } else {
        //register new
        await authApi.addAminiUpdates(reqBody);
        showPopup(t("shared:success"), "success");
        history.push("/aminiupdates");
      }
    } catch (err) {
      showErrors(err, setErrors);
    }
    setSaving(false);
  }

  function handleChange(content, delta, source, editor) {
    setData(editor.getContents());
  }

  //avoid react-quill reload https://stackoverflow.com/a/64146927
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{header: [1, 2, 3, 4, 5, 6]}, {font: []}],
          [{size: []}],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{list: "ordered"}, {list: "bullet"}],
          ["link", "image", "video"],
          ["clean"],
          ["code-block"],
        ],
        handlers: {
          image: () => {
            const range = quillRef.current.getEditorSelection();
            var value = prompt(t("imageURL"));
            if (value) {
              quillRef.current.getEditor().insertEmbed(range.index, "image", value);
            }
          },
        },
      },
    }),
    [t]
  );

  return (
    <div className="px-5 py-3" as={Col}>
      <FormErrors errors={errors}></FormErrors>
      <Form.Group>
        <Form.Control
          required={true}
          value={heading}
          onChange={(e) => setHeading(e.target.value)}
          type="text"
          name="heading"
          placeholder={t("udateSummary")}
          className="my-4"
        ></Form.Control>
      </Form.Group>
      <Form.Group className="my-3">
        <ReactQuill
          ref={quillRef}
          onChange={handleChange}
          placeholder={t("coolNewFeatures")}
          modules={modules}
          defaultValue={update && update.data ? JSON.parse(update.data) : ""}
        />
      </Form.Group>

      <Row>
        <CancelButton label={t("cancel")} to="/aminiupdates"></CancelButton>
        <Button
          onClick={handleSubmit}
          type="submit"
          className="mt-4 p-3 ml-4"
          disabled={saving}
          variant="danger"
        >
          <FontAwesomeIcon icon="plus"></FontAwesomeIcon>{" "}
          {saving ? t("shared:saving") : t("shared:submit")}
        </Button>
      </Row>
    </div>
  );
}
