import React from "react";
import MapPicker from "react-google-map-picker";
import {Row, Col, Form, FormControl, Button} from "react-bootstrap";

/**
 * Google map picker: https://www.npmjs.com/package/react-google-map-picker
 * @param {Object} defaultLocation an object of latitude{lat} and longitude {lng} data holds original default data
 * @param {Number} zoom google map zoom size
 * @param {Number} lat latitude digits
 * @param {Number} long longitude digits
 * @param {Function} handleChangeZoom a function to update the zoom size
 * @param {Function} setLat an useState hook function to update latitude digits
 * @param {Function} setLong an useState hook function to update longitude digits
 * @param {Function} setIsChanged an useState hook function to update the change of latitude or longitude
 * @param {Function} handleChangeLocation a function to update the change of location in the google map
 * @param {Function} handleResetLocation a function to reset location to it's default digits
 */

export function getMapPickerProps(
  defaultLocation = {lat: 10, lng: 10},
  zoom = 10,
  lat = 10,
  long = 10,
  handleChangeZoom,
  setLat,
  setLong,
  setIsChanged,
  handleChangeLocation,
  handleResetLocation
) {
  return {
    defaultLocation,
    zoom,
    lat,
    long,
    handleChangeZoom,
    setLat,
    setLong,
    setIsChanged,
    handleChangeLocation,
    handleResetLocation,
  };
}

export function GoogleMapPicker(props) {
  const handleChangeLatitude = (e) => {
    props.setLat(parseFloat(e.target.value));
    props.setIsChanged(true);
  };

  const handleChangeLongitude = (e) => {
    props.setLong(parseFloat(e.target.value));
    props.setIsChanged(true);
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <Form.Group controlId="latitude">
            <Form.Label>Latitude</Form.Label>
            <FormControl
              name="latitude"
              type="number"
              value={props.lat}
              step={0.0001}
              ref={props.register}
              onChange={(e) => {
                handleChangeLatitude(e);
              }}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="longitude">
            <Form.Label>Longitude</Form.Label>
            <FormControl
              name="longitude"
              type="number"
              value={props.long}
              step={0.0001}
              ref={props.register}
              onChange={(e) => {
                handleChangeLongitude(e);
              }}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Button
            onClick={props.handleResetLocation}
            variant="outline-danger"
            className="pull-right mt-3"
          >
            Reset Location
          </Button>
        </Col>
      </Row>

      <Row className="my-3">
        <Col>
          <MapPicker
            defaultLocation={props.defaultLocation}
            zoom={props.zoom}
            mapTypeId="hybrid"
            style={{height: "400px"}}
            onChangeLocation={props.handleChangeLocation}
            onChangeZoom={props.handleChangeZoom}
            apiKey="AIzaSyCb6TuuzmtFFNCPWtezVuM_9kXj5XhzamE"
          />
        </Col>
      </Row>
    </>
  );
}
