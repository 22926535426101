import React, {useState} from "react";
import {Row, Col, Button} from "react-bootstrap";
import {usePermissions} from "../permissions";
import {useTranslation} from "react-i18next";
import {ActionItemButton} from "../generics/buttons";

import SendSMS from "./sendSMS";
import AssignTariff from "./assignTariff";
import BonusDisbursement from "./bonusDisbursement";
import BulkDeactivateAccounts from "./bulkDeactivateAccounts";
import MeterState from "./meterState";
import AssignTag from "./assignTag";

import * as meteringAPI from "../../services/meteringApi";
import * as billingAPI from "../../services/billingApi";
import * as notificationAPI from "../../services/notificationApi";

export default function BulkActionMenu({
  customers,
  count,
  selectedCustomers,
  setSelectedCustomers,
  selectAllInSearch,
  setSelectAllInSearch,
  setReloadCustomersPage,
}) {
  const {t} = useTranslation(["bulkActions", "shared"]);
  selectedCustomers = [...new Set(selectedCustomers)]; //remove duplicates value in customersList array

  //modals
  const [smsModalShow, setSMSModalShow] = useState(false);
  const [assignTariffModalShow, setAssignTariffModalShow] = useState(false);
  const [bonusDisburseModalShow, setBonusDisburseModalShow] = useState(false);
  const [showBulkDeactivateAlert, setShowBulkDeactivateAlert] = useState(false);
  const [meterStateShow, setMeterStateModalShow] = useState(false);
  const [assignTagModalShow, setAssignTagModalShow] = useState(false);

  //authorize
  const canSendSMS = usePermissions(notificationAPI.NOTIFICATION_SMS_SEND, "POST");
  const canAssignTariff = usePermissions(meteringAPI.ASSIGN_TARIFF_PATH, "POST");
  const canDisburseBonus = usePermissions(billingAPI.BONUS_DISBURSEMENT_PATH, "POST");
  const canDeactivate = usePermissions(billingAPI.DEACTIVATE_ACCOUNT_PATH, "POST");
  const canChangeMeterState = usePermissions(meteringAPI.CHANGE_METER_STATE, "POST");
  const canAssignTag = usePermissions(billingAPI.ASSIGN_TAG_PATH, "POST");

  const onClickSelectSearch = () => {
    setSelectedCustomers(customers);
    setSelectAllInSearch(true);
  };

  const onClickClearSelection = () => {
    setSelectedCustomers([]);
    setSelectAllInSearch(false);
  };

  if (selectedCustomers.length > 0) {
    return (
      <Row>
        <Col>
          <ActionItemButton
            canView={canSendSMS}
            permissionError={t("registerPermission")}
            toolTipmessage={t("sendNote")}
            onClickAction={() => {
              setSMSModalShow(true);
            }}
            icon="sms"
          />

          <ActionItemButton
            canView={canAssignTariff}
            permissionError={t("registerPermission")}
            toolTipmessage={t("assignTariffNote")}
            onClickAction={() => {
              setAssignTariffModalShow(true);
            }}
            icon="money-bill-alt"
          />

          <ActionItemButton
            canView={canDeactivate}
            permissionError={t("deactivatePermission")}
            toolTipmessage={t("deactivateNote")}
            onClickAction={() => {
              setShowBulkDeactivateAlert(true);
            }}
            icon="user-minus"
          />

          <ActionItemButton
            canView={canDisburseBonus}
            permissionError={t("registerPermission")}
            toolTipmessage={t("bonusDisburseNote")}
            onClickAction={() => {
              setBonusDisburseModalShow(true);
            }}
            icon="dollar-sign"
          />

          <ActionItemButton
            canView={canChangeMeterState}
            permissionError={t("registerPermission")}
            toolTipmessage={t("meterStateNote")}
            onClickAction={() => {
              setMeterStateModalShow(true);
            }}
            icon={"toggle-on"}
          />
          <ActionItemButton
            canView={canAssignTag}
            permissionError={t("registerPermission")}
            toolTipmessage={t("assignTagNote")}
            onClickAction={() => {
              setAssignTagModalShow(true);
            }}
            icon={"user-tag"}
          />

          <Button disabled={true} variant="link" className="mb-2 mr-2">
            {t(
              "selectedCountCustomers",
              selectAllInSearch ? {count: count} : {count: selectedCustomers.length}
            )}
          </Button>

          <Button
            variant="link"
            className="mb-2 mr-2"
            onClick={onClickSelectSearch}
            disabled={selectAllInSearch}
          >
            {!selectAllInSearch ? t("selectAllResults") : ""}
          </Button>

          <Button
            variant="link"
            className="mb-2 mr-2"
            onClick={onClickClearSelection}
            disabled={!selectAllInSearch}
          >
            {selectAllInSearch ? t("clearSelection") : ""}
          </Button>

          <SendSMS
            customerList={selectedCustomers}
            modalShow={smsModalShow}
            setModalShow={setSMSModalShow}
            selectAllInSearch={selectAllInSearch}
            count={count}
          />

          <AssignTariff
            customerList={selectedCustomers}
            modalShow={assignTariffModalShow}
            setModalShow={setAssignTariffModalShow}
            selectAllInSearch={selectAllInSearch}
            count={count}
          />

          <BonusDisbursement
            customerList={selectedCustomers}
            modalShow={bonusDisburseModalShow}
            setModalShow={setBonusDisburseModalShow}
            selectAllInSearch={selectAllInSearch}
            count={count}
          />

          <BulkDeactivateAccounts
            customerList={selectedCustomers}
            selectAllInSearch={selectAllInSearch}
            count={count}
            show={showBulkDeactivateAlert}
            setShow={setShowBulkDeactivateAlert}
            setSelectedCustomers={setSelectedCustomers}
            setReloadCustomersPage={setReloadCustomersPage}
            setSelectAllInSearch={setSelectAllInSearch}
          />

          <MeterState
            customerList={selectedCustomers}
            modalShow={meterStateShow}
            setModalShow={setMeterStateModalShow}
            selectAllInSearch={selectAllInSearch}
            count={count}
          />
          <AssignTag
            modalShow={assignTagModalShow}
            setModalShow={setAssignTagModalShow}
            customerList={selectedCustomers}
            selectAllInSearch={selectAllInSearch}
            count={count}
            setReloadCustomersPage={setReloadCustomersPage}
          />
        </Col>
      </Row>
    );
  } else {
    return <></>;
  }
}
