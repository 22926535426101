import * as DateHelpers from "./date";
import {axiosInstance} from "./axios";

export const NOTIFICATION_TEMPLATES_LIST_PATH = "/notification/api/template";
export const NOTIFICATION_SMS_LIST_PATH = "/notification/api/sms/get";
export const NOTIFICATION_TEMPLATES_TYPE_PATH = "/notification/api/templatetype";
export const NOTIFICATION_SMS_SEND = "/notification/api/sms/send";
export const NOTIFICATION_SMS_PROVIDERS = "/notification/api/sms/providers";
export const NOTIFICATION_SWITCH_PROVIDER = "/notification/api/sms/switchprovider";

export function getSmsTemplates(portfolio, query) {
  let params = {};

  if (query.country_id) {
    params.country_id = query.country_id;
  }

  return axiosInstance(portfolio).get(NOTIFICATION_TEMPLATES_LIST_PATH, {
    params: params,
  });
}

export function getSmsLogs(portfolio, countPerPage, query) {
  let params = {limit: countPerPage};
  params.offset = query.page ? (parseInt(query.page) - 1) * countPerPage : 0;

  if (query.country_id) {
    params.country_id = query.country_id;
  }

  if (query.start_date) {
    params.from = DateHelpers.formatDateToApi(query.start_date);
  }
  if (query.end_date) {
    params.to = DateHelpers.formatDateToApi(query.end_date);
  }
  if (query.sms_type) {
    if (query.sms_type !== "all") {
      params.type = query.sms_type;
    }
  }
  if (query.phonenumber) {
    params.phonenumber = query.phonenumber;
  }
  if (query.status) {
    if (query.status !== "all") {
      params.status = parseInt(query.status);
    }
  }
  if (query.search) {
    params.search = query.search;
  }
  if (query.customer_id) {
    params.customerid = query.customer_id;
  }
  if (query.country_id) {
    if (query.country_id !== "all") {
      params.country_id = parseInt(query.country_id);
    }
  }
  return axiosInstance(portfolio)
    .get(NOTIFICATION_SMS_LIST_PATH, {
      params: params,
    })
    .then((resp) => {
      return resp;
    });
}

export function postSmsTemplate(portfolio, data) {
  return axiosInstance(portfolio).post(NOTIFICATION_TEMPLATES_LIST_PATH, data);
}

export function getTemplateTypes(portfolio) {
  return axiosInstance(portfolio).get(NOTIFICATION_TEMPLATES_TYPE_PATH);
}

export function editSmsTemplate(portfolio, data) {
  return axiosInstance(portfolio).patch(NOTIFICATION_TEMPLATES_LIST_PATH, data);
}

export function sendSms(portfolio, sms, config) {
  return axiosInstance(portfolio).post(NOTIFICATION_SMS_SEND, sms, config);
}

export function getProviders(portfolio, query) {
  let params = {};

  if (query.country_id) {
    params.country_instance = query.country_id;
  }

  return new Promise((resolve, reject) => {
    axiosInstance(portfolio)
      .get(NOTIFICATION_SMS_PROVIDERS, {
        params: params,
      })
      .then((response) => {
        const providers = response.data.data;
        const defaultProvider = providers.find((provider) => {
          return provider.is_default;
        });
        resolve(defaultProvider);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function switchProvider(portfolio, data) {
  return axiosInstance(portfolio)
    .post(NOTIFICATION_SWITCH_PROVIDER, data)
    .then((resp) => {
      return resp;
    });
}
