import React, {useState, useContext, useCallback} from "react";
import {useLocation} from "react-router-dom";
import {Form, Button, Modal, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {UserContext} from "../login/userContext";
import {showPopup} from "../generics/alerts";
import {UploadProgressBar, handleDataUpload} from "../generics/fileUploads";
import {faMoneyBillAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from "react-hook-form";
import * as meteringAPI from "../../services/meteringApi";
import {usePermissions} from "../permissions";
import * as alerts from "../generics/alerts";
import {CardFieldSelect} from "../generics/formsFields";

export default function AssignTariff({
  customerList,
  modalShow,
  setModalShow,
  selectAllInSearch,
  count,
}) {
  const {t} = useTranslation(["bulkActions", "shared"]);
  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-success ml-2">
            <FontAwesomeIcon icon={faMoneyBillAlt}></FontAwesomeIcon>
            {"   " + t("assignTariffNote")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssignTariffForm
            customerList={customerList}
            setModalShow={setModalShow}
            selectAllInSearch={selectAllInSearch}
            count={count}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export function AssignTariffForm({customerList, setModalShow, selectAllInSearch, count}) {
  const {register, handleSubmit} = useForm();
  const {t} = useTranslation(["bulkActions", "shared"]);
  const [errors, setErrors] = useState([]);
  const [progress, setProgress] = useState(0); // %
  const user = useContext(UserContext);
  const location = useLocation();
  const [tariffs, setTariffs] = useState([]);
  const [areTariffsLoaded, setTariffsLoaded] = useState(false);
  const canUpdate = usePermissions(meteringAPI.ASSIGN_TARIFF_PATH, "POST");

  const onSubmit = (data) => {
    let account_nos = [];
    let searchParams = "";
    if (selectAllInSearch) {
      searchParams = location.search.replace("?", "").replace("search_value", "search");
      if (searchParams.length === 0) {
        setErrors([t("warningUsingSearchParams")]);
        return;
      }
    } else {
      customerList.forEach((c) => {
        account_nos.push(c.AccountNumber);
      });
    }

    const payload = {
      tariff_id: data.tariff_id,
      account_nos: account_nos,
      search_params: searchParams,
    };
    handleDataUpload(setProgress, assignTariff, payload);
  };

  const fetchTariffs = useCallback(
    async function () {
      setTariffsLoaded(true);
      try {
        const resp = await meteringAPI.getTariffs(user.selected, {});
        const tariffsJson = resp.data.data.map((tariff) => {
          return {name: tariff.name, value: tariff.id};
        });
        setTariffs(tariffsJson);
      } catch (err) {
        alerts.showPopup(t("tariffsError") + ": " + err);
      }
    },
    [user.selected, setTariffs, t]
  );

  if (!areTariffsLoaded) fetchTariffs();

  async function assignTariff(data, config) {
    try {
      await meteringAPI.assignTariff(user.selected, data, config);
      showPopup(t("shared:success"), "success");
      setErrors([]);
      setModalShow(false);
    } catch (err) {
      setErrors(alerts.getHttpMessage(err));
      setProgress(0);
    }
  }

  return (
    <>
      <UploadProgressBar
        progress={progress}
        progressVariant="success"
        title={t("assigning")}
      />
      <div className="ml-3" style={{color: "red"}}>
        {errors}
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4">
        <Row className="mb-3">
          <Col>
            {t(
              "warningAssignTariff",
              selectAllInSearch ? {count: count} : {count: customerList.length}
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <CardFieldSelect
              register={register}
              name="tariff_id"
              label={t("tariff")}
              defaultValue={""}
              defaultName={""}
              options={tariffs}
              readOnly={!canUpdate}
            ></CardFieldSelect>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit" variant="danger" className="ml-3 p-3 pull-right">
              {t("assignTariffNote")}
            </Button>
            <Button
              variant="light"
              onClick={() => setModalShow(false)}
              className="ml-3 p-3 pull-right"
            >
              {t("cancel")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
