export const en = {
    siteName:"Site Name",
    bonusName:"Bonus Type",
    fromDate:"Start Date",
    thruDate:"End Date",
    bonusStatus:"Bonus Status",
    ACTIVE:"Active",
    SCHEDULED:"Scheduled",
    DEACTIVATED:"Deactivated",
    FINISHED:"Finished"

  };
  
  export const fr = {};
  