import React, {useCallback, useContext, useEffect, useState} from "react";
import {useParams, useHistory} from "react-router-dom";
import {Row, Col, Tabs, Tab, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import {UserContext} from "./../login/userContext";
import {usePermissions, Unauthorized} from "../permissions";
import * as billingAPI from "../../services/billingApi";
import {TransactionsTable} from "../transactions/transactionLogs";
import {InfoTab, EnergyAccountTabs, ProductAccountTabs} from "./dashboardCards";
import {SMSTable, BundlesTable} from "./dashboardTables";
import * as alerts from "../generics/alerts";

export default function CustomerDashboard() {
  const canView = usePermissions(billingAPI.CUSTOMER_LIST_PATH);
  const {id} = useParams();
  const user = useContext(UserContext);
  const [customer, setCustomer] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [accountsAreLoaded, setAccountsAreLoaded] = useState(false);
  const [customerIsLoaded, setCustomerIsLoaded] = useState(false);
  const history = useHistory();

  const fetchCustomer = useCallback(
    async function () {
      try {
        const resp = await billingAPI.getCustomers(user.selected, {id: id});
        let customer = resp.data.data[0];
        customer.Id = customer.ID;
        customer.PhoneNos = customer.PhoneNumber;
        customer.ELectricalTrainingStatus = customer.ElectricalTrainingStatus;
        setCustomer(customer);
        setCustomerIsLoaded(true);
      } catch (e) {
        console.error(e);
        history.push("/customers");
      }
    },
    [user.selected, id, history]
  );

  const fetchAccounts = useCallback(
    async function () {
      try {
        const resp = await billingAPI.getAccountsByCustomer(user.selected, id);
        setAccounts(resp.data.data);
        setAccountsAreLoaded(true);
      } catch (e) {
        console.error(e);
      }
    },
    [user.selected, id]
  );

  useEffect(() => {
    if (!customerIsLoaded) fetchCustomer();
    if (!accountsAreLoaded) fetchAccounts();
  }, [
    user.selected,
    id,
    fetchCustomer,
    fetchAccounts,
    accountsAreLoaded,
    customerIsLoaded,
  ]);

  if (!canView) {
    return <Unauthorized />;
  }

  return (
    <div style={{width: 1220}}>
      <PageHeader customer={customer} icon="user"></PageHeader>
      <CustomerCardsSection
        customer={customer}
        accounts={accounts}
        ready={accountsAreLoaded}
        //fetchAccounts={fetchAccounts}
        setAccountsAreLoaded={setAccountsAreLoaded}
      ></CustomerCardsSection>
      <CustomerTablesSection
        customer={customer}
        accounts={accounts}
        ready={accountsAreLoaded}
      ></CustomerTablesSection>
    </div>
  );
}

// Primary Page Sections

function PageHeader({customer, icon}) {
  if (!customer.Id) {
    return (
      <h1 className="mb-5 text-primary">
        <FontAwesomeIcon icon={icon} className="mr-3"></FontAwesomeIcon>
        <Skeleton width={400} />
      </h1>
    );
  }

  const fullName = customer.FirstName + " " + customer.LastName;
  return (
    <h1 className="mb-5 text-primary">
      <FontAwesomeIcon icon={icon} className="mr-3"></FontAwesomeIcon>
      {fullName}
    </h1>
  );
}

function CustomerCardsSection({
  customer,
  accounts,
  ready,
  fetchAccounts,
  setAccountsAreLoaded,
}) {
  if (!customer.Id || !ready) {
    return (
      <Row className="mb-5">
        <Col lg={4}>
          <Card className="text-center">
            <Skeleton height={40} width={250} count="4" className="m-3" />
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="text-center">
            <Skeleton height={40} width={250} count="4" className="m-3" />
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="text-center">
            <Skeleton height={40} width={250} count="4" className="m-3" />
          </Card>
        </Col>
      </Row>
    );
  }
  const assignedAccounts = accounts.filter((a) => a.date_to === null);
  const energyAccounts = assignedAccounts.filter((a) => a.account_type === "ENERGY");
  const productAccounts = assignedAccounts.filter((a) => a.account_type === "PRODUCT");
  const primaryEnergyAccountNo =
    energyAccounts.length > 0 ? energyAccounts[0].account_number : "";
  let inactiveAcc = "";
  let siteCode = "";
  for (let acc of accounts) {
    if (acc.site_code) {
      siteCode = acc.site_code;
    }
    if (acc.account_status === "INACTIVE") {
      inactiveAcc = acc.account_number;
      break;
    }
  }

  return (
    <Row className="mb-2">
      <Col lg={4}>
        <InfoTab
          customer={customer}
          accounts={energyAccounts}
          setAccountsAreLoaded={setAccountsAreLoaded}
        ></InfoTab>
      </Col>
      <Col md={4} lg={4}>
        <EnergyAccountTabs
          accounts={energyAccounts}
          customer={customer}
          fetchAccounts={fetchAccounts}
          setAccountsAreLoaded={setAccountsAreLoaded}
          inactiveAccount={inactiveAcc}
          siteCode={siteCode}
        ></EnergyAccountTabs>
      </Col>
      <Col md={4} lg={4}>
        <ProductAccountTabs
          energyAccountNo={primaryEnergyAccountNo}
          accounts={productAccounts}
          fetchAccounts={fetchAccounts}
          setAccountsAreLoaded={setAccountsAreLoaded}
        ></ProductAccountTabs>
      </Col>
    </Row>
  );
}

function CustomerTablesSection({customer, accounts, ready}) {
  const {t} = useTranslation(["customer", "shared"]);

  const [totalCount] = useState(20); //total payments in database
  const user = useContext(UserContext);
  const [transactions, setTransactions] = useState([]);
  const [transactionsLoaded, setTransactionsLoaded] = useState(false); //total payments in database

  const fetchTransactions = useCallback(
    async function () {
      //load only when customer has loaded
      if (customer.Id) {
        const query = {customer_id: customer.Id};
        await billingAPI
          .getTransactions(user.selected.toLowerCase(), totalCount, query)
          .then((resp) => {
            setTransactions(resp.data.data);
          })
          .catch((err) => {
            setTransactions({});
            alerts.showPopup(alerts.getHttpMessage(err));
          });
        setTransactionsLoaded(true);
      }
    },
    [user.selected, customer.Id, totalCount, setTransactions]
  );

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  if (!customer.Id || !ready || !transactionsLoaded) {
    return (
      <Card className="text-center">
        <Skeleton height={30} count="8" />
      </Card>
    );
  }

  if (accounts.length === 0) {
    return (
      <Tabs defaultActiveKey="transactions" id="table-tabs">
        <Tab eventKey="transactions" title={t("transactions")}>
          <TransactionsTable
            transactions={transactions}
            fetchTransactions={fetchTransactions}
          />
        </Tab>
        <Tab eventKey="sms" title={t("sms")}>
          <SMSTable customer={customer}></SMSTable>
        </Tab>
      </Tabs>
    );
  }

  return (
    <Tabs defaultActiveKey="transactions" id="table-tabs">
      <Tab eventKey="transactions" title={t("transactions")}>
        <TransactionsTable
          transactions={transactions}
          fetchTransactions={fetchTransactions}
        />
      </Tab>
      <Tab eventKey="sms" title={t("sms")}>
        <SMSTable customer={customer}></SMSTable>
      </Tab>
      <Tab eventKey="bundles" title={t("bundles")}>
        <BundlesTable account={accounts[0]}></BundlesTable>
      </Tab>
    </Tabs>
  );
}
