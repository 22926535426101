import React, {useState, useEffect, useContext} from "react";
import {UserContext} from "../login/userContext";
import {Button, Modal, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTv} from "@fortawesome/free-solid-svg-icons";
import * as billingAPI from "../../services/billingApi";
import {usePermissions} from "../permissions";
import {getFormProps, FormField, FormSelect, FormErrors} from "../generics/formsFields";
import {RegisterButton, ModalCancelButton} from "../generics/buttons";
import {showPopup, getHttpMessage, showErrors} from "../generics/alerts";

export default function RegisterProduct({
  setAccountsAreLoaded,
  className,
  energyAccountNo,
}) {
  const canView = usePermissions(billingAPI.PRODUCT_ACCOUNTS_LIST_PATH, "POST");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {t} = useTranslation(["product", "shared"]);

  return (
    <div>
      <Button
        disabled={!canView}
        title={!canView ? t("registerPermission") : null}
        variant="danger"
        onClick={handleShow}
        className={className ? className : "text-white float-right"}
      >
        <FontAwesomeIcon icon="plus" /> {t("registerButtonTitle")}
      </Button>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="mb-4 text-primary">
              <FontAwesomeIcon icon={faTv} /> {t("registerTitle")}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RegisterForm
            setShow={setShow}
            setAccountsAreLoaded={setAccountsAreLoaded}
            energyAccountNo={energyAccountNo}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export function RegisterForm({energyAccountNo, setShow, setAccountsAreLoaded}) {
  // set up contexts and variables for the form
  const user = useContext(UserContext);
  const {t} = useTranslation(["product", "shared"]);
  const {register, handleSubmit} = useForm();
  const [paymentPlan, setPaymentPlan] = useState([]);
  const [productLists, setProductLists] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      fetchProducts();
    }
  });

  async function fetchProducts() {
    let ci = user.permissions[user.selected].countryID;
    try {
      const resp = await billingAPI.getProductItemList(user.selected, 10, {
        country_id: ci,
      });
      let pJson = [];
      resp.data.data.products.forEach((pp) => {
        pJson.push({name: pp.product_name, value: pp.id});
      });

      setProductLists(pJson);
      if (pJson[0]) {
        setSelectedID(pJson[0].value);
      }
    } catch (err) {
      showPopup(getHttpMessage(err));
    }
    setLoaded(true);
  }
  useEffect(() => {
    async function fetchPaymentPlans() {
      if (!selectedID) return;

      try {
        const resp = await billingAPI.getProductPaymentPlans(user.selected, {
          product_id: selectedID,
        });
        let ppJson = [];
        resp.data.data.payment_plans.forEach((pp) => {
          ppJson.push({name: pp.name, value: pp.id});
        });
        setPaymentPlan(ppJson);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
    }
    fetchPaymentPlans();
  }, [user.selected, selectedID]);

  const energy_account = getFormProps(
    "energy_account",
    t("energyAccount"),
    "text",
    "true",
    energyAccountNo ? energyAccountNo : ""
  );
  const deposit_due_date = getFormProps("deposit_due_date", t("dateOfDeposit"), "date");
  const initial_deposit = getFormProps("initial_deposit", t("initialDeposit"), "number");
  const payment_reference = getFormProps(
    "payment_reference",
    t("paymentRef"),
    "text",
    "false"
  );
  const product_id = getFormProps(
    "product_id",
    t("product"),
    "select",
    "true",
    "",
    t("product"),
    productLists
  );
  const payment_plan_id = getFormProps(
    "payment_plan_id",
    t("paymentPlan"),
    "select",
    "true",
    "",
    t("paymentPlan"),
    paymentPlan
  );
  // function to call when submitting form
  const onSubmit = (data) => {
    data.initial_deposit = +data.initial_deposit;
    if (data.initial_deposit < 0) {
      showPopup(t("negativeInitialDeposit"), "error");
      return;
    }
    registerPaymentProduct(user.selected, [data]);
  };

  async function registerPaymentProduct(portfolio, productData) {
    setErrors([]);
    setSaving(true);
    try {
      await billingAPI.registerProductAccount(portfolio, productData);
      showPopup(t("shared:success"), "success");
      //cancel the modal
      setShow(false);
      setAccountsAreLoaded(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setSaving(false);
  }
  const handleChange = (event) => {
    setSelectedID(event.target.value);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4">
        <Row>
          <FormErrors errors={errors}></FormErrors>
          <FormField register={register} {...energy_account} />
          <FormSelect register={register} {...product_id} onChange={handleChange} />
          <FormSelect register={register} {...payment_plan_id} />
          <FormField register={register} {...initial_deposit} />
          <FormField register={register} {...payment_reference} />
          <FormField register={register} {...deposit_due_date} />
          <ModalCancelButton label={t("cancel")} setShow={setShow} />
          <RegisterButton label={t("register")} disabled={saving} />
        </Row>
      </Form>
    </div>
  );
}
