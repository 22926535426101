import React, {useState, useContext} from "react";
import {UserContext} from "../login/userContext";
import {Button, Modal, Form, Row, Col} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as billingAPI from "../../services/billingApi";
import {getFormProps, FormField, FormErrors} from "../generics/formsFields";
import {RegisterButton} from "../generics/buttons";
import {showPopup, showErrors} from "../generics/alerts";

export function CountryModal({show, setShow, handleClose, title, country}) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className="mb-4 text-primary">
            <FontAwesomeIcon icon="earth-africa" /> {title}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CountryForm setShow={setShow} country={country} />
      </Modal.Body>
    </Modal>
  );
}

function CountryForm({setShow, country = {}}) {
  const user = useContext(UserContext);
  const {t} = useTranslation(["countries", "shared"]);
  const {register, handleSubmit} = useForm();
  const [loading, setLoading] = useState();
  const [errors, setErrors] = useState([]);
  const id = getFormProps("id", t("id"), "text", "true", country.id ? country.id : "");
  const code = getFormProps(
    "code",
    t("code"),
    "text",
    "true",
    country.code ? country.code : ""
  );
  const name = getFormProps(
    "name",
    t("name"),
    "text",
    "true",
    country.name ? country.name : ""
  );
  const country_code = getFormProps(
    "country_code",
    t("countryCode"),
    "text",
    "true",
    country.country_code ? country.country_code : ""
  );
  const currency = getFormProps(
    "currency",
    t("currency"),
    "text",
    "false",
    country.currency ? country.currency : ""
  );
  const status = getFormProps(
    "status",
    t("status"),
    "text",
    "false",
    country.status ? country.status : ""
  );

  const onSubmit = (data) => {
    if (Object.keys(country).length === 0) {
      const countries = [{...data}];
      registerCountry(user.selected, countries);
    } else {
      updateCountry(user.selected, data);
    }
  };

  async function registerCountry(portfolio, countries) {
    try {
      setLoading(true);
      await billingAPI.registerCountry(portfolio, countries);
      showPopup(t("shared:success"), "success");
      setShow(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setLoading(false);
  }

  async function updateCountry(portfolio, country) {
    try {
      setLoading(true);
      await billingAPI.updateCountry(portfolio, country);
      showPopup(t("shared:success"), "success");
      setShow(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setLoading(false);
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <FormErrors errors={errors}></FormErrors>
          <FormField md={6} lg={6} register={register} {...id} />
          <FormField md={6} lg={6} register={register} {...code} />
          <FormField md={12} lg={12} register={register} {...name} />
          <FormField md={6} lg={6} register={register} {...country_code} />
          <FormField md={6} lg={6} register={register} {...currency} />
          <FormField md={6} lg={6} register={register} {...status} />
          <Col xs={6} />
          <Col xs={6}>
            <Button
              variant="light"
              onClick={() => setShow(false)}
              className="mt-4 p-3 pull-right"
            >
              {t("shared:cancel")}
            </Button>
          </Col>
          <RegisterButton
            disabled={loading}
            xs={6}
            md={6}
            lg={6}
            label={loading ? t("shared:loading") : t("shared:save")}
          ></RegisterButton>
        </Row>
      </Form>
    </div>
  );
}
