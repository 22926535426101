import React, {useState, useEffect, useContext} from "react";
import * as meteringAPI from "../../services/meteringApi";
import {usePermissions, Unauthorized} from "../permissions";
import {Col, Table, Form, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserContext} from "../login/userContext";
import {useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import Register from "./register";
import {ListHeader} from "../generics/headers";
const queryString = require("query-string");

export default function ServerList() {
  const {t} = useTranslation(["servers", "shared"]);
  const canView = usePermissions(meteringAPI.SERVER_LIST_PATH);
  const location = useLocation();
  const history = useHistory();
  const user = useContext(UserContext);

  let query = queryString.parse(location.search);
  query.country_id = user.permissions[user.selected].countryID;

  if (!canView) {
    return <Unauthorized />;
  }

  const updateQuery = (updates) => {
    query = Object.assign(query, updates);
    var params = queryString.stringify(query, {
      skipEmptyString: true,
    });
    history.push("/servers?" + params);
  };

  return (
    <div>
      <ListHeader title={t("title")} icon="wifi" />
      <SearchForm query={query} updateQuery={updateQuery} />
      <ServersTable query={query} />
    </div>
  );
}

function ServersTable({query}) {
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useContext(UserContext);
  const {t} = useTranslation(["servers", "shared"]);

  useEffect(() => {
    async function getServers() {
      try {
        const resp = await meteringAPI.getServers(user.selected, query);
        setServers(resp.data);
        setLoading(false);
      } catch (error) {
        alert(error);
      }
    }
    getServers();
  }, [user.selected, query]);

  if (loading) {
    return <h1>{t("load")}</h1>;
  }

  return (
    <Table striped bordered hover size="l" className="shadow">
      <thead>
        <tr>
          <th>{t("serverName")}</th>
          <th>{t("vendorName")}</th>
          <th>{t("siteName")}</th>
          <th>{t("endpoint")}</th>
          <th>{t("token")}</th>
        </tr>
      </thead>

      <tbody>
        {servers.length ? (
          servers.map((server) => {
            return <ServersRow key={server.server_id} server={server} />;
          })
        ) : (
          <tr>No data...</tr>
        )}
      </tbody>
    </Table>
  );

  function ServersRow({server}) {
    const link = `/server/${server.server_id}`;
    const history = useHistory();
    function onClick() {
      history.push(link);
    }

    return (
      <tr onClick={onClick}>
        <td>{server.server_name}</td>
        <td>{server.vendor_name}</td>
        <td>{server.site_name}</td>
        <td>
          <a href={meteringAPI.cleanEndpoint(server.server_endpoint)}>
            {server.server_endpoint}
          </a>
        </td>
        <td>{server.server_apitoken}</td>
      </tr>
    );
  }
}

export function SearchForm({query, updateQuery}) {
  const {t} = useTranslation(["servers", "shared"]);
  const {register, handleSubmit} = useForm();

  const onSubmit = (data) => {
    updateQuery({name: data.name});
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mb-3">
      <Form.Row>
        <Col xs={6}>
          <Form.Control
            type="text"
            ref={register}
            name="name"
            defaultValue={query.name}
            placeholder={t("searchBar")}
          />
        </Col>
        <Col xs={2}>
          <Button type="submit">
            <FontAwesomeIcon icon="search"></FontAwesomeIcon>
          </Button>
        </Col>
        <Col xs={4}>
          <Register />
        </Col>
      </Form.Row>
    </Form>
  );
}
