import React, {useState, useContext} from "react";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import {UserContext} from "../login/userContext";
import {usePermissions, Unauthorized} from "../permissions";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {FileUpload, Instructions, ErrorList} from "../generics/fileUploads";
import * as billingAPI from "../../services/billingApi";

export default function BulkUpdateEnergyAccount() {
  const {t} = useTranslation(["fileupload", "shared"]);
  const user = useContext(UserContext);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0); // %
  const [errors, setErrors] = useState([]);
  const canView = usePermissions(billingAPI.ENERGY_ACCOUNT_UPDATE_PATH, "PATCH");

  if (!canView) {
    return <Unauthorized />;
  }

  //sample file
  const filename = "sample_energy_account_update.csv";
  const headers =
    "account_no,latitude, longitude,[site_code],[building_no],[description],[turret_no],[breaker_no],[customer_type],[primary_productive_load]";
  const sampleData = [
    ["account_no", "latitude", "longitude"],
    ["00400002", "-4.14313", "36.01563"],
    ["00360001", "10.9078", "19.10438"],
  ];

  //instructions
  const pageTitle = t("updateEnergyAccountTitle");
  const reminders = [t("existAccount"), t("activeAccount")];

  async function updateEnergyAccount(data, config) {
    let payload = [];
    if (data.length > 0) {
      data.forEach((eAcc) => {
        eAcc.latitude = parseFloat(eAcc.latitude);
        eAcc.longitude = parseFloat(eAcc.longitude);
        payload.push(eAcc);
      });
    } else {
      setErrors([t("emptyList")]);
      setProgress(0);
      return;
    }
    try {
      await billingAPI.updateEnergyAccount(user.selected, payload, config);
      showPopup(t("shared:success"), "success");
      resetStates();
    } catch (err) {
      let er = getHttpMessage(err);
      setErrors(Array.isArray(er) ? er : [er]);
      setProgress(0);
    }
  }

  const resetStates = () => {
    setProgress(0);
    setData([]);
    setLoaded(false);
    setErrors([]);
  };

  return (
    <div>
      <Card className="shadow">
        <Card.Body className="p-4">
          <Instructions
            title={pageTitle}
            isLoaded={isLoaded}
            headers={headers}
            filename={filename}
            sampleData={sampleData}
            reminders={reminders}
          />

          <FileUpload
            sampleData={sampleData}
            data={data}
            errors={errors}
            progress={progress}
            setData={setData}
            setErrors={setErrors}
            setProgress={setProgress}
            setLoaded={setLoaded}
            setColumns={setColumns}
            sendToAPI={updateEnergyAccount}
            processButtonName={t("update")}
            handleCancel={resetStates}
          />

          <ErrorList errors={errors} handleReUpload={resetStates} />

          <DataTable
            pagination
            highlightOnHover
            columns={columns}
            data={data}
            noDataComponent=""
          />
        </Card.Body>
      </Card>
    </div>
  );
}
