import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {ListHeader} from "../generics/headers";
import {UserContext} from "../login/userContext";
import {useHistory, useLocation} from "react-router-dom";
import {usePermissions, Unauthorized} from "../permissions";
import {ListPagination} from "../generics/pagination";
import TagsTable from "./tagsTable";
import SearchForm from "./searchForm";
import * as billingAPI from "../../services/billingApi";

const queryString = require("query-string");

export default function TagList() {
  const {t} = useTranslation(["tags", "shared"]);
  const location = useLocation();
  const history = useHistory();
  const user = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const canView = usePermissions(billingAPI.TAGS_LIST_PATH, "GET");

  let query = queryString.parse(location.search);
  const searchQuery = (updates) => {
    query = Object.assign(query, updates);
    var params = queryString.stringify(query, {
      skipEmptyString: true,
    });
    history.push("/tags?" + params);
  };

  if (!canView) {
    return Unauthorized;
  }

  return (
    <>
      <ListHeader title={t("pageTitle")} icon="tags"></ListHeader>
      <SearchForm searchQuery={searchQuery} t={t} />
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
      />
      <TagsTable
        user={user}
        page={page}
        limit={limit}
        setCount={setCount}
        searchQuery={searchQuery}
      />
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
      />
    </>
  );
}
