import React, {useEffect, useState, useContext} from "react";
import {Modal, Form, Row, Col, Button} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {useTranslation} from "react-i18next";
import {faUserTag} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useLocation} from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";

import {UploadProgressBar, handleDataUpload} from "../generics/fileUploads";
import * as billingApi from "../../services/billingApi";
import {UserContext} from "../login/userContext";
import {showPopup} from "../generics/alerts";
import * as alerts from "../generics/alerts";

export default function AssignTag({
  modalShow,
  setModalShow,
  customerList,
  selectAllInSearch,
  count,
  setReloadCustomersPage,
  fetchAccounts,
}) {
  const {t} = useTranslation(["bulkActions", "shared"]);
  const user = useContext(UserContext);
  const location = useLocation();
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState([]);
  const [action, setAction] = useState("assign");
  const [selectedTag, setSelectedTag] = useState([]);
  function manageTag(action, payload) {
    async function assignOrUnassignTag(payload, config) {
      try {
        if (action === "assign") {
          await billingApi.assignTag(user.selected, payload, config);
        } else {
          await billingApi.unassignTag(user.selected, payload, config);
        }
        if (fetchAccounts) {
          fetchAccounts();
        }
        showPopup(t("shared:success"), "success");
        setModalShow(false);
        setProgress(0);
        setSelectedTag([]);
        setReloadCustomersPage((prevState) => !prevState);
      } catch (err) {
        setErrors(alerts.getHttpMessage(err));
        setProgress(0);
      }
    }
    handleDataUpload(setProgress, assignOrUnassignTag, payload);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let account_nos = [];
    let searchParams = "";
    let name = selectedTag[0].name;
    let id = selectedTag[0].id;

    if (selectAllInSearch) {
      searchParams = location.search.replace("?", "").replace("search_value", "search");
      if (searchParams.length === 0) {
        setErrors([t("warningUsingSearchParams")]);
        return;
      }
    } else {
      customerList.forEach((c) => {
        account_nos.push(c.AccountNumber);
      });
    }

    const payload = {
      search_params: searchParams,
      account_nos: account_nos,
    };

    if (action === "assign") {
      payload.tag = name;
    } else {
      payload.tag_id = id;
    }
    manageTag(action, payload);
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-success ml-2">
            <FontAwesomeIcon icon={faUserTag}></FontAwesomeIcon>
            {"   " + t("manageTags")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ml-3" style={{color: "red"}}>
            {errors}
          </div>
          <Row>
            <Col>
              <UploadProgressBar
                progress={progress}
                progressVariant="success"
                title={t("assignTagTitle")}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              {t(
                "warningAssignTag",
                selectAllInSearch
                  ? {action, count: count}
                  : {action, count: customerList.length}
              )}
            </Col>
          </Row>
          <ManageTagForm
            setModalShow={setModalShow}
            setSelectedTag={setSelectedTag}
            selectedTag={selectedTag}
            action={action}
            setAction={setAction}
            user={user}
            handleSubmit={handleSubmit}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

function ManageTagForm({
  action,
  setAction,
  setSelectedTag,
  selectedTag,
  setModalShow,
  user,
  handleSubmit,
  setErrors,
}) {
  const {t} = useTranslation(["bulkActions", "shared"]);
  const ref = React.createRef();
  const [tags, setTags] = useState([]);
  const [allowNew, setAllowNew] = useState(true);
  const [params, setParams] = useState({});

  const handleOnRadioInputChange = (value) => {
    setAction(value);
    setAllowNew((prev) => !prev);
    setParams({});
    setSelectedTag([]);
    ref.current.clear();
  };

  useEffect(() => {
    async function fetchTags() {
      try {
        const resp = await billingApi.getTags(user.selected, params);
        if (resp.data.data != null) {
          if (resp.data.data != null) {
            setTags(resp.data.data);
          }
        }
      } catch (err) {
        setErrors(alerts.getHttpMessage(err));
      }
    }
    fetchTags();
  }, [params, setErrors, user.selected]);

  const radios = [
    {label: t("assignCheckbox"), value: "assign"},
    {label: t("unassignCheckbox"), value: "unassign"},
  ];

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={3}>
            <Form.Group>
              {radios.map(({label, value}) => (
                <Form.Check
                  checked={action === value}
                  id={`action-${label}`}
                  key={value || "default"}
                  label={label}
                  onChange={() => handleOnRadioInputChange(value)}
                  type="radio"
                  value={value}
                />
              ))}
            </Form.Group>
          </Col>
          <Col md={8}>
            <Typeahead
              clearButton
              onInputChange={(e) => setParams({name: e})}
              onChange={(tag) => setSelectedTag(tag)}
              allowNew={allowNew}
              id="tag-input"
              labelKey="name"
              options={tags}
              placeholder={t("typeTag")}
              size={"small"}
              newSelectionPrefix={t("newSelectionPrefix")}
              ref={ref}
            />
          </Col>
        </Row>
        <Row>
          <Col md={11}>
            <Button
              type="submit"
              variant="danger"
              className="p-2 pull-right"
              disabled={selectedTag.length > 0 ? false : true}
            >
              {"Save"}
            </Button>
            <Button
              variant="light"
              onClick={() => setModalShow(false)}
              className="mr-2 p-2 pull-right"
            >
              {t("cancel")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
