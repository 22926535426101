import React, {useState, useEffect} from "react";
import {Row, Col, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {usePermissions, Unauthorized} from "../permissions";
import * as authApi from "../../services/authApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showPopup, getHttpMessage} from "../generics/alerts";
import ReactQuill from "react-quill";
import Skeleton from "react-loading-skeleton";
import {formatDisplayDateDay} from "../../services/date";
import {Link} from "react-router-dom";
import Deleting from "./deleteUpdate";

export default function Dashboard() {
  const {t} = useTranslation(["aminiUpdates"]);
  const canView = usePermissions(authApi.AMINI_UPDATES_PATH, "GET");
  const canAdd = usePermissions(authApi.AMINI_UPDATES_PATH, "POST");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    async function getAminiUpdates() {
      setLoading(true);
      const params = new URLSearchParams();
      params.set("offset", 0);
      params.set("limit", 5);
      try {
        const resp = await authApi.getAminiUpdates();
        setUpdates(resp.data.data);
      } catch (exp) {
        showPopup(getHttpMessage(exp));
      }
      setLoading(false);
    }
    getAminiUpdates();
  }, [reload]);

  if (!canView) {
    return <Unauthorized />;
  }

  if (loading) {
    return <Skeleton height={40} count="4" className="m-3" />;
  }

  return (
    <div className="mx-3">
      <Row className="mb-4 ">
        <Col xs={8} md={8}>
          <h1 className="text-primary">
            <FontAwesomeIcon icon="flag"></FontAwesomeIcon> {t("aminiUpdates")}
          </h1>
        </Col>
        {canAdd ? (
          <Col md={4} xs={4}>
            <Link to="/aminiupdates/register">
              <Button variant="danger" className="p-3 pull-right" title={t("addUpdate")}>
                <FontAwesomeIcon icon="plus"></FontAwesomeIcon> {t("addUpdate")}
              </Button>
            </Link>
          </Col>
        ) : (
          <br />
        )}
      </Row>
      {updates.map((update) => {
        return (
          <DisplayAminiUpdate update={update} setReload={setReload} reload={reload} />
        );
      })}
    </div>
  );
}

export function DisplayAminiUpdate({update, setReload, reload}) {
  const canEdit = usePermissions(authApi.AMINI_UPDATES_PATH, "POST");
  const canDelete = usePermissions(authApi.AMINI_UPDATES_PATH, "DELETE");
  let updateDetails = "";
  if (update.data) {
    updateDetails = JSON.parse(update.data);
  }
  return (
    <div className=" my-4 px-4 py-3  bg-white ">
      {canDelete ? (
        <Deleting id={update.id} setReloadUpdate={setReload} reload={reload} />
      ) : (
        <br />
      )}
      {canEdit ? (
        <Link to={"/aminiupdates/" + update.id}>
          <span className="p-3 pull-right hover">
            <FontAwesomeIcon icon="edit"></FontAwesomeIcon>
          </span>
        </Link>
      ) : (
        <br />
      )}

      <p className="text-primary py-3">{formatDisplayDateDay(update.date_created_utc)}</p>
      <h5>{update.heading}</h5>
      <ReactQuill
        className=" py-1"
        value={updateDetails}
        readOnly={true}
        theme={"bubble"}
      />
    </div>
  );
}
