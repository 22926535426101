import React, {useState, useContext} from "react";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {UserContext} from "../login/userContext";

import * as billingApi from "../../services/billingApi";

export default function BulkDeactivateAccounts({
  count,
  show,
  setShow,
  customerList,
  selectAllInSearch,
  setSelectedCustomers,
  setReloadCustomersPage,
  setSelectAllInSearch,
}) {
  const user = useContext(UserContext);
  const location = useLocation();
  const {t} = useTranslation(["bulkActions", "shared"]);
  const [loading, setLoading] = useState(false);
  const payload = {
    search_params: "",
    account_nos: [],
  };

  if (selectAllInSearch) {
    payload.search_params = location.search
      .replace("?", "")
      .replace("search_value", "search");
  } else {
    customerList.forEach((customer) => {
      payload.account_nos.push(customer.AccountNumber);
    });
  }

  async function deactivateAccounts() {
    if (!loading) {
      setLoading(true);
    }
    if (payload.search_params.length === 0 && payload.account_nos.length === 0) {
      showPopup([t("warningUsingSearchParams")], "error");
    } else {
      try {
        await billingApi.deactivateAccounts(user.selected, payload);
        showPopup(t("shared:success"), "success");
      } catch (error) {
        showPopup(getHttpMessage(error));
      }
    }
    setLoading(false);
    setShow(false);
    setSelectedCustomers([]);
    setReloadCustomersPage((prevState) => !prevState);
    setSelectAllInSearch(false);
    return;
  }

  return (
    <>
      {show ? (
        <SweetAlert
          warning={true}
          showCancel={true}
          confirmBtnText={loading ? t("saving") : t("deactivate")}
          confirmBtnBsStyle={"danger"}
          title={t("deactivateTitle")}
          onConfirm={deactivateAccounts}
          showConfirmButton={false}
          openAnim={false}
          onCancel={() => setShow(false)}
        >
          {t(
            "bulkDeactivateMessage",
            selectAllInSearch ? {count: count} : {count: payload.account_nos.length}
          )}
        </SweetAlert>
      ) : null}
    </>
  );
}
