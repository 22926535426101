import React from "react";
import {Link} from "react-router-dom";
import {Col, Button, Tooltip, OverlayTrigger} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

// RegisterButton is a custom form field for submission
export function RegisterButton(props) {
  return (
    <Col
      md={props.md ? props.md : 3}
      lg={props.lg ? props.lg : 2}
      xs={props.xs ? props.xs : 4}
    >
      <Button
        type="submit"
        disabled={props.disabled}
        variant="danger"
        className="mt-4 mb-1 p-3"
      >
        <FontAwesomeIcon icon="plus"></FontAwesomeIcon> {props.label}
      </Button>
    </Col>
  );
}

// SaveButton is a custom form field for submission
export function SaveButton(props) {
  return (
    <Col
      md={props.md ? props.md : 6}
      lg={props.lg ? props.lg : 4}
      xs={props.xs ? props.xs : 12}
    >
      <Button
        type="submit"
        variant="primary"
        size={props.size ? props.size : ""}
        className="mt-4 mb-1 pull-right"
        disabled={props.disabled}
      >
        <FontAwesomeIcon icon="save"></FontAwesomeIcon> {props.label}
      </Button>
    </Col>
  );
}
// SyncButton is a custom form field for submission
export function SyncButton(props) {
  return (
    <Button type="submit" variant="primary" className="buttonSave">
      <FontAwesomeIcon icon="sync" size="2x"></FontAwesomeIcon>
    </Button>
  );
}
// CancelButton is a custom form field for exiting the form
export function CancelButton(props) {
  return (
    <Col
      md={props.md ? props.md : 2}
      lg={props.lg ? props.lg : 1}
      xs={props.xs ? props.xs : 4}
    >
      <Link to={props.to}>
        <Button variant="light" className="mt-4 p-3">
          {props.label}
        </Button>
      </Link>
    </Col>
  );
}

export function ModalCancelButton(props) {
  return (
    <Col
      md={props.md ? props.md : 2}
      lg={props.lg ? props.lg : 1}
      xs={props.xs ? props.xs : 4}
    >
      <Link to={props.to}>
        <Button variant="light" onClick={() => props.setShow(false)} className="mt-4 p-3">
          {props.label}
        </Button>
      </Link>
    </Col>
  );
}

// DownloadButton is a custom form field for exiting the form
export function DownloadButton(props) {
  return (
    <Col
      md={props.md ? props.md : 2}
      lg={props.lg ? props.lg : 1}
      xs={props.xs ? props.xs : 4}
    >
      <Link to={props.to}>
        <Button variant="light" icon="download" className="mt-4 p-">
          {props.label}
        </Button>
      </Link>
    </Col>
  );
}

export function ModalSubmitButton({loading, variant, icon, text}) {
  const {t} = useTranslation(["shared"]);
  return (
    <Button
      type="submit"
      variant={variant}
      className="p-3 my-3 pull-right"
      disabled={loading}
    >
      <FontAwesomeIcon icon={icon} /> {loading ? t("loading") + "..." : text}
    </Button>
  );
}

export function FormSearchButton({register, handleSubmit}) {
  return (
    <Button
      name="search"
      type="submit"
      ref={register}
      onSubmit={handleSubmit}
      xs={12}
      md={1}
    >
      <FontAwesomeIcon icon="search"></FontAwesomeIcon>
    </Button>
  );
}

function renderTooltip(canView, permissionError, message) {
  return <Tooltip>{!canView ? permissionError : message}</Tooltip>;
}

export function ActionItemButton(props) {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{show: 250, hide: 400}}
      overlay={renderTooltip(props.canView, props.permissionError, props.toolTipmessage)}
    >
      <Button
        disabled={!props.canView}
        variant="outline-success"
        onClick={props.onClickAction}
        className="mb-2 mr-2"
      >
        <FontAwesomeIcon icon={props.icon} />
      </Button>
    </OverlayTrigger>
  );
}
