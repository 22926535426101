import React, {useState, useContext} from "react";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import {UserContext} from "../login/userContext";
import {usePermissions, Unauthorized} from "../permissions";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {FileUpload, Instructions, ErrorList} from "../generics/fileUploads";
import * as meteringAPI from "../../services/meteringApi";

export default function BulkAssignMeters() {
  const canView = usePermissions(meteringAPI.ASSIGN_METERS_PATH, "POST");
  const {t} = useTranslation(["fileupload"]);
  const user = useContext(UserContext);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0); // %
  const pageTitle = t("assignMeters");

  if (!canView) {
    return <Unauthorized />;
  }

  const filename = "sample_meter_assignments.csv";
  const headers = "account_number, serial_number, [vendor_server_id]";
  const sampleData = [
    ["account_number", "serial_number"],
    ["232-1-1234", "12345"],
    ["00621234", "SM5R-03-12345678"],
  ];
  const reminders = [
    t("existingAccountsOnly"),
    t("validSerialNumbers"),
    t("vendorServerIDOptional"),
    t("shared:waitAfterUpload"),
  ];

  async function assignMeters(data, config) {
    try {
      await meteringAPI.assignMeters(user.selected, data, config);
      showPopup(t("shared:successWithWait"), "success");
      resetStates();
    } catch (err) {
      let errorBuffer = err.response.data;
      if (Array.isArray(errorBuffer)) {
        setErrors(errorBuffer);
      } else if (errorBuffer) {
        setErrors([errorBuffer]);
      } else {
        setErrors([getHttpMessage(err)]);
      }
      setProgress(0);
    }
  }

  const resetStates = () => {
    setProgress(0);
    setData([]);
    setLoaded(false);
    setErrors([]);
  };

  return (
    <div>
      <Card className="shadow">
        <Card.Body className="p-4">
          <Instructions
            title={pageTitle}
            isLoaded={isLoaded}
            headers={headers}
            filename={filename}
            sampleData={sampleData}
            reminders={reminders}
          />
          <FileUpload
            sampleData={sampleData}
            data={data}
            errors={errors}
            progress={progress}
            setData={setData}
            setErrors={setErrors}
            setProgress={setProgress}
            setLoaded={setLoaded}
            setColumns={setColumns}
            sendToAPI={assignMeters}
            handleCancel={resetStates}
          />
          <ErrorList errors={errors} handleReUpload={resetStates} />
          <DataTable
            bordered
            pagination
            highlightOnHover
            columns={columns}
            data={data}
            noDataComponent=""
          />
        </Card.Body>
      </Card>
    </div>
  );
}
