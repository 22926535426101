export const en = {
  createUpdate: "Create Amini Update",
  headingRequired: "Heading is required",
  dataRequired: "Data is required",
  udateSummary: "Summary of update here",
  coolNewFeatures: "What cool new features were added?",
  heading50: "Heading should not be more than 50 characters",
  aminiUpdates: "Amini Updates",
  addUpdate: "Add Update",
  imageURL: "Enter Image URL",
  updateAminUpdate: "Update Amini Updates",
  delete: "Delete",
  deleting: "deleting",
  deleteTitle: "Delete notification",
  deleteMessage: "Are you sure, you want to delete this update?",
};

export const fr = {};
export const sw = {};
