import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Row, Col, Form, Button} from "react-bootstrap";
import {UserContext} from "./../login/userContext";
import {usePermissions, Unauthorized} from "../permissions";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  getFormProps,
  FormField,
  FormSelect,
  FormErrors,
  LoadingErrors,
} from "../generics/formsFields";
import {DashboardHeader} from "../generics/headers";
import {showPopup, showErrors, getHttpMessage} from "../generics/alerts";
import * as meteringAPI from "../../services/meteringApi";
import Skeleton from "react-loading-skeleton";

export default function ServerDashboard() {
  const canView = usePermissions(meteringAPI.SERVER_LIST_PATH);
  const [server, setServer] = useState(null);
  const [site, setSite] = useState(null);
  const [serverLoaded, setServerLoaded] = useState(false);
  const [siteLoaded, setSiteLoaded] = useState(false);
  const user = useContext(UserContext);
  const {id} = useParams();

  useEffect(() => {
    async function getServerInfo(id) {
      try {
        const resp = await meteringAPI.getServers(user.selected, id);
        setServer(resp.data[0]);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
      setServerLoaded(true);
    }
    getServerInfo({id: id});
  }, [user.selected, id]);

  useEffect(() => {
    const getSiteInfoByName = async function () {
      let country_id = user.permissions[user.selected].countryID;
      try {
        const resp = await meteringAPI.getSites(user.selected, {
          name: server.site_name,
          country_id,
        });
        setSite(resp.data.data[0]);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
      setSiteLoaded(true);
    };
    //load sites only after server has loaded
    if (serverLoaded && server && !siteLoaded) {
      getSiteInfoByName();
    }
  }, [server, serverLoaded, siteLoaded, user]);

  if (!canView) {
    return <Unauthorized />;
  }

  if (!serverLoaded || !siteLoaded) {
    return <Skeleton height={40} count="4" className="m-3" />;
  }
  //if both site  and server failed to load, don't return the form
  if (!server || !site) {
    return <LoadingErrors />;
  }
  return (
    <div>
      <DashboardHeader title={server.server_name} icon="wifi"></DashboardHeader>
      <ServerForm server={server} site={site} />
    </div>
  );
}

function ServerForm({server, site}) {
  const user = useContext(UserContext);
  const {register, handleSubmit} = useForm();
  const canView = usePermissions(meteringAPI.SERVER_LIST_PATH, "PUT");
  const {t} = useTranslation(["servers", "shared"]);
  const [sites, setSites] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [sitesLoaded, setSitesLoaded] = useState(false);
  const [vendorLoaded, setVendorLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  // function to call when submitting form
  const onSubmit = (data) => {
    updateServer(user.selected, data);
  };

  //Each loading to have it's own effect to reduce number of loads caused by state change
  useEffect(() => {
    const fetchSites = async function fetchSites() {
      let country_id = user.permissions[user.selected].countryID;
      try {
        const resp = await meteringAPI.getSites(user.selected, {country_id});
        let siteJson = [];
        resp.data.data.forEach((site) => {
          siteJson.push({name: site.site_name, value: site.site_id});
        });
        setSites(siteJson);
      } catch (err) {
        showPopup(t("siteError") + ": " + err);
      }
      setSitesLoaded(true);
    };
    if (!sitesLoaded) {
      fetchSites();
    }
  }, [sitesLoaded, t, user]);

  useEffect(() => {
    const fetchVendors = async function fetchVendors() {
      try {
        const resp = await meteringAPI.getVendors(user.selected, {});
        let vendorJson = [];
        resp.data.forEach((vendor) => {
          vendorJson.push({name: vendor.Name, value: vendor.VendorId});
        });
        setVendors(vendorJson);
      } catch (err) {
        showPopup(t("vendorError") + ": " + err);
      }
      setVendorLoaded(true);
    };
    if (!vendorLoaded) {
      fetchVendors();
    }
  }, [t, user.selected, vendorLoaded]);

  async function updateServer(portfolio, serverData) {
    try {
      setErrors([]);
      setSaving(true);
      await meteringAPI.updateServer(portfolio, server.server_id, serverData);
      showPopup(t("shared:success"), "success");
    } catch (err) {
      showErrors(err, setErrors);
    }
    setSaving(false);
  }
  //check if role can update the server info
  const canUpdate = canView ? "" : "true";

  //return skeleton if nothing has loaded
  if (!sitesLoaded || !vendorLoaded) {
    return <Skeleton height={40} count="4" className="m-3" />;
  }
  //setting form fields
  const token = getFormProps(
    "token",
    t("token"),
    "text",
    "true",
    server.server_apitoken,
    "",
    [],
    canUpdate
  );

  const apiKey = getFormProps(
    "api_key",
    t("apiKey"),
    "text",
    "true",
    server.server_api_key,
    "",
    [],
    canUpdate
  );

  const vendorServer = getFormProps(
    "vendor_server_id",
    t("vendorSeverId"),
    "text",
    "false",
    server.vendor_server_id
  );
  const vendorSiteId = getFormProps(
    "vendor_site_id",
    t("vendorSiteId"),
    "text",
    "false",
    server.vendor_site_id,
    "",
    "",
    "false",
    "Add service area id for Nova server"
  );
  const apiEndpoint = getFormProps(
    "endpoint",
    t("endpoint"),
    "text",
    "false",
    server.server_endpoint
  );
  const siteIDs = getFormProps(
    "site_id",
    t("siteName"),
    "select",
    "true",
    site.site_id,
    t("siteName"),
    sites
  );
  const vendorIDs = getFormProps(
    "vendor_id",
    t("vendor"),
    "select",
    "true",
    site.vendor_id,
    t("vendor"),
    vendors
  );
  const portfolioID = getFormProps(
    "portfolio_id",
    t("portfolio_id"),
    "text",
    "false",
    server.portfolio_id,
    "",
    [],
    "false"
  );

  return (
    <div className="mt-2 shadow bg-white border rounded">
      <Form onSubmit={handleSubmit(onSubmit)} id="server_update" className="m-4">
        <Row>
          <FormErrors errors={errors}></FormErrors>
          <FormSelect register={register} {...siteIDs} />
          <FormSelect register={register} {...vendorIDs} />
          <FormField register={register} {...apiEndpoint} />
          <FormField register={register} {...token} />
          <FormField register={register} {...apiKey} />
          <FormField register={register} {...vendorServer} />
          <FormField register={register} {...vendorSiteId} />
          <FormField register={register} {...portfolioID} />
          <ServerUpdateButton saving={saving} />
        </Row>
      </Form>
    </div>
  );
}

function ServerUpdateButton({saving}) {
  const {t} = useTranslation("servers", "shared");
  const canView = usePermissions(meteringAPI.SERVER_LIST_PATH, "PUT");

  return (
    <Col xs={12} md={6} lg={12}>
      <Button
        disabled={!canView || saving}
        title={!canView ? t("updatePermission") : null}
        type="submit"
        variant="primary"
        className="mt-4 mb-2 p-3 pull-right"
        form="server_update"
      >
        <FontAwesomeIcon icon="save"></FontAwesomeIcon> {t("update")}
      </Button>
    </Col>
  );
}
