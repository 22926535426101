import React, {useState, useEffect} from "react";
import {Table, Form, Col} from "react-bootstrap";
import {usePermissions, Unauthorized} from "../permissions";
import * as authApi from "../../services/authApi";
import * as alerts from "../generics/alerts";
import {useLocation} from "react-router";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ListHeader} from "../generics/headers";
import {ListPagination} from "../generics/pagination";
import Skeleton from "react-loading-skeleton";
import {formatFileNameDate} from "../../services/date";
import RegisterPortfolio from "./register";
import UpdatePortfolio from "./update";

const queryString = require("query-string");

export default function Portfolios() {
  const canView = usePermissions(authApi.PORTFOLIO_PATH);
  const location = useLocation();
  const history = useHistory();
  const {t} = useTranslation(["portfolios"]);
  const [portfolios, setPortfolios] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [refresh, setRefresh] = useState(false);

  let query = queryString.parse(location.search);

  // updateQuery is a function to create params from the query
  const updateQuery = (updates) => {
    query = Object.assign(query, updates);
    var params = queryString.stringify(query, {
      skipEmptyString: true,
    });

    history.push("/portfolios/list?" + params);
  };

  useEffect(() => {
    async function fetchPortfolios() {
      const searchParams = queryString.parse(location.search);
      searchParams.page = page;
      searchParams.limit = limit;
      try {
        const resp = await authApi.getPortfolios(searchParams);
        setCount(resp.data.length);
        setPortfolios(resp.data);
        setLoading(true);
      } catch (err) {
        setPortfolios({});
        alerts.showPopup(alerts.getHttpMessage(err));
      }
      setLoading(false);
    }
    fetchPortfolios();
  }, [location.search, page, limit, refresh]);

  async function getAllPortfolios() {
    const searchParams = queryString.parse(location.search);
    searchParams.limit = count;
    let allData = [];
    //setting up progress bar
    setProgress(50);
    await authApi
      .getPortfolios(searchParams)
      .then((resp) => {
        allData.push(...resp.data);
        setProgress(100);
      })
      .catch((err) => {
        setProgress(0);
        alerts.showPopup(alerts.getHttpMessage(err));
      });
    setProgress(0);
    return allData;
  }

  //check if user has access to this page
  if (!canView) {
    return <Unauthorized />;
  }
  return (
    <div>
      <ListHeader title={t("portfolios")} icon="earth-africa" />
      <SearchForm updateQuery={updateQuery} refresh={refresh} setRefresh={setRefresh} />
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        data={portfolios}
        filename={formatFileNameDate(new Date().toString()) + "_portfolios.csv"}
        getAllData={getAllPortfolios}
        progress={progress}
      />
      {loading ? (
        <Skeleton height={20} count="2" className="m-3" />
      ) : (
        <PortfoliosTable
          portfolios={portfolios}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        data={portfolios}
        filename={formatFileNameDate(new Date().toString()) + "_sites.csv"}
        getAllData={getAllPortfolios}
        progress={progress}
      />
    </div>
  );
}

function SearchForm({updateQuery, refresh, setRefresh}) {
  const {t} = useTranslation(["portfolios", "shared"]);
  return (
    <Form className="mb-3">
      <Form.Row>
        <Col xs={8} md={6}>
          <Form.Control
            className="mb-1"
            type="text"
            name="search"
            onChange={(e) => {
              updateQuery({search: e.target.value});
            }}
            placeholder={t("searchBar")}
          ></Form.Control>
        </Col>

        <Col md={6}>
          <RegisterPortfolio refresh={refresh} setRefresh={setRefresh} />
        </Col>
      </Form.Row>
    </Form>
  );
}

function PortfoliosTable({portfolios, refresh, setRefresh}) {
  const {t} = useTranslation(["portfolios", "shared"]);

  return (
    <Table striped bordered size="l" className="shadow" hover>
      <thead>
        <tr>
          <th>{t("name")}</th>
          <th>{t("shortcode")}</th>
          <th>{t("countryid")}</th>
        </tr>
      </thead>
      <tbody>
        {portfolios.length ? (
          portfolios.map((pf) => {
            return (
              <PorfolioRow
                key={pf.id}
                portfolio={pf}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            );
          })
        ) : (
          <tr></tr>
        )}
      </tbody>
    </Table>
  );

  function PorfolioRow({portfolio, refresh, setRefresh}) {
    const canUpdate = usePermissions(authApi.PORTFOLIO_PATH, "PATCH");
    const [show, setShow] = useState(false);
    const onClick = () => {
      canUpdate ? setShow(true) : setShow(false);
    };

    return (
      <>
        <tr onClick={onClick}>
          <td>{portfolio.name}</td>
          <td>{portfolio.short_code}</td>
          <td>{portfolio.country_id}</td>
        </tr>
        <UpdatePortfolio
          portfolio={portfolio}
          show={show}
          setShow={setShow}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </>
    );
  }
}
