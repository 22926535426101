import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";

export function ListHeader(props) {
  return (
    <h1 className="mb-4 text-primary display-4">
      <FontAwesomeIcon icon={props.icon} className="mr-3"></FontAwesomeIcon>
      {props.title}
    </h1>
  );
}

export function DashboardHeader({title, icon}) {
  return (
    <h1 className="mb-5 text-primary">
      <FontAwesomeIcon icon={icon} className="mr-3"></FontAwesomeIcon>
      {title ? title : <Skeleton width={400} />}
    </h1>
  );
}
