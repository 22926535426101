import React from "react";
import {useTranslation} from "react-i18next";
import {
  InputGroup,
  FormControl,
  Button,
  Form,
  Row,
  Col,
  ProgressBar,
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CSVLink} from "react-csv";
import XLSX from "xlsx";
import {validateData, checkDuplicates} from "../../services/fileUpload";
import {CSVDownloads} from "./csvDownloads";

export function Instructions({
  title,
  isLoaded,
  sampleData,
  headers,
  filename,
  reminders,
}) {
  return !isLoaded ? (
    <div data-testid="download-sample" className="mb-4">
      <UploadInstruction title={title} />
      <DownloadSample sampleData={sampleData} headers={headers} filename={filename} />
      <Reminders reminders={reminders} />
    </div>
  ) : (
    ""
  );
}

function Reminders({reminders}) {
  return (
    <ul className="ml-3 mt-3 text-secondary">
      {reminders.map((reminder, i) => {
        return <li key={i}>{reminder}</li>;
      })}
    </ul>
  );
}

function UploadInstruction({title}) {
  const {t} = useTranslation(["fileupload"]);
  return (
    <>
      <h4 className="text-primary mb-3">
        {title && title.length > 0 ? title : t("fileUpload")}
      </h4>
      <p className="text-secondary">{t("pleaseUpload")}</p>
    </>
  );
}

export function DownloadSample({headers, sampleData, filename}) {
  const {t} = useTranslation(["fileupload"]);
  return (
    <InputGroup className="mb-2">
      <FormControl value={headers} style={{color: "black"}} readOnly />
      <InputGroup.Append>
        <CSVLink data={sampleData} filename={filename}>
          <Button variant="outline-primary">
            <FontAwesomeIcon icon="download"></FontAwesomeIcon> {t("downloadSample")}
          </Button>
        </CSVLink>
      </InputGroup.Append>
    </InputGroup>
  );
}

export function FileUpload({
  sampleData,
  data,
  errors,
  progress,
  setData,
  setErrors,
  setProgress,
  setLoaded,
  setColumns,
  sendToAPI,
  processButtonName,
  handleCancel,
}) {
  const {t} = useTranslation(["fileupload"]);
  // https://www.npmjs.com/package/xlsx#parsing-workbooks
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    // handle successful read
    reader.onload = (event) => {
      // parse data
      const dataBS = event.target.result;
      const workbook = XLSX.read(dataBS, {type: "binary", raw: true});
      // get first worksheet: https://www.npmjs.com/package/xlsx#working-with-the-workbook
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      // convert into a json array
      const dataJSON = XLSX.utils.sheet_to_json(worksheet);

      //get object with many keys which will be used for validation
      let maxObjLength = 0;
      let singleFileData = {};
      dataJSON.forEach((obj) => {
        if (Object.keys(obj).length > maxObjLength) {
          maxObjLength = Object.keys(obj).length;
          singleFileData = obj;
        }
      });

      // data validation
      const missingHeaders = validateData([singleFileData], sampleData);
      if (missingHeaders) {
        setErrors([t("missingHeaders") + missingHeaders]);
        setLoaded(true);
        setData([{}]);
        return;
      }
      if (singleFileData["meter"] && singleFileData["house"] && singleFileData["phone"]) {
        const duplicates = checkDuplicates(dataJSON);
        if (duplicates.length > 0) {
          setErrors([t("duplicates") + duplicates.join(", ")]);
          setLoaded(true);
          setData([{}]);
          return;
        }
      }

      // prep data for DataTable component: https://www.npmjs.com/package/react-data-table-component
      const columns = Object.keys(singleFileData).map((col) => ({
        name: col,
        selector: col,
      }));
      setLoaded(true);
      setData(dataJSON);
      setColumns(columns);
    };

    // handle failed read
    reader.onerror = (event) => {
      setErrors(["Failed to load file"]);
      console.errpr(event);
    };
    // read file
    reader.readAsBinaryString(file);
  };
  return data.length < 1 ? (
    <div>
      <Form.File id="file_upload" custom>
        <Form.File.Input accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
        <Form.File.Label data-browse={t("selectFile")}>{t("sampleFile")}</Form.File.Label>
      </Form.File>
    </div>
  ) : !progress && errors.length < 1 ? (
    <div>
      <Row>
        <Col>
          <h4 className="text-primary">{t("dataPreview")}</h4>
        </Col>
        <Col>
          <Button
            variant="danger"
            className="mb-1 pull-right"
            data-testid="load"
            onClick={() => {
              handleDataUpload(setProgress, sendToAPI, data);
            }}
          >
            <FontAwesomeIcon icon="database" />{" "}
            {processButtonName && processButtonName.length > 0
              ? processButtonName
              : t("loadRow", {count: data.length})}
          </Button>

          <Button
            variant="light"
            className="mb-1 mr-3 pull-right"
            data-testid="cancelButton"
            onClick={handleCancel}
          >
            <FontAwesomeIcon icon="times-circle" /> Cancel
          </Button>
        </Col>
      </Row>
    </div>
  ) : (
    <UploadProgressBar progress={progress} progressVariant="success" />
  );
}

export function UploadProgressBar({progress, progressVariant, title = ""}) {
  const {t} = useTranslation(["fileupload"]);
  return progress > 0 ? (
    <div>
      <h4 className="text-success">{title.length > 0 ? title : t("uploading")} </h4>
      <ProgressBar
        animated="true"
        variant={progressVariant}
        now={progress}
        label={`${progress}%`}
      />
    </div>
  ) : (
    ""
  );
}

function handleErrors(inputData) {
  let data = [];
  const filename = "register_customers_errors.csv";

  // get data and errors and merge them
  for (let i = 0; i < inputData.length; i++) {
    var values = inputData[i].data;
    var errors = inputData[i].error;
    data[i] = [values.first_name, values.last_name, values.phone, errors];
  }

  // get headers
  let headers = ["first_name", "last_name", "phone", "errors"];

  // download data
  CSVDownloads(filename, data, headers);
}

export function ErrorList({errors, handleReUpload, exportErrors}) {
  const {t} = useTranslation(["fileupload"]);
  return errors.length > 0 ? (
    <div className="ml-3" style={{color: "red"}}>
      <Row>
        <Col>
          <h4 style={{color: "red"}}>{t("errors")}</h4>
        </Col>
        <Col>
          <Button className="pull-right" variant="danger" onClick={handleReUpload}>
            <FontAwesomeIcon icon="sync"></FontAwesomeIcon> {t("reUpload")}
          </Button>
          {exportErrors && (
            <Button
              className="pull-right mr-4"
              variant="outline-primary"
              onClick={() => {
                handleErrors(errors);
              }}
            >
              <FontAwesomeIcon icon="download"></FontAwesomeIcon> {t("downloadErrors")}
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            {exportErrors
              ? errors.map((error, i) => {
                  return (
                    <li key={i}>
                      {error.error ? error.data.AccountNo + ": " + error.error : error}
                    </li>
                  );
                })
              : errors.map((error, i) => {
                  return (
                    <li key={i}>
                      {error.error
                        ? error.data.account_number + ": " + error.error
                        : error}
                    </li>
                  );
                })}
          </ul>
        </Col>
      </Row>
    </div>
  ) : (
    ""
  );
}

// Progress Bar w/ Axios: https://gist.github.com/virolea/e1af9359fe071f24de3da3500ff0f429
export const handleDataUpload = (setProgress, sendToAPI, data) => {
  setProgress(10); // 10%
  // setup progress updates
  const updateProgress = (progressEvent) => {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setProgress(percentCompleted);
  };
  // setup axios progress change events to trigger progress state updates
  var config = {
    onUploadProgress: (progressEvent) => updateProgress(progressEvent),
  };
  // send data to backend API
  sendToAPI(data, config);
};

export function ErrorListing({errors}) {
  const {t} = useTranslation(["fileupload"]);
  return errors.length > 0 ? (
    <div className="ml-3" style={{color: "red"}}>
      <Row>
        <Col>
          <h4 style={{color: "red"}}>{t("errors")}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            {errors.map((error, i) => {
              return (
                <li key={i}>
                  {error.error ? error.data.account_number + ": " + error.error : error}
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </div>
  ) : (
    ""
  );
}
