export const en = {
  site: "Site",
  siteList: "Sites",
  registerSite: "Register Site",
  code: "Code",
  name: "Name",
  server: "Server",
  region: "Region",
  villages: "Village",
  estates: "Estate",
  site_groups: "Site Group",
  sync_to_vendor: "Sync to vendor",
  country: "Country",
  vendor: "Vendor",
  tariff: "Tariff",
  defaultTariff: "Default Tariff",
  owner: "Owner",
  selectOption: "Select option...",
  energyPrice: "Energy Price",
  lowBalWarn: "Low Balance Warning",
  minTop: "Minimum Top Up",
  dateCommissioned: "Commissioned",
  dateDecommissioned: "Decommissioned",
  trained: "Trained",
  register: "Register",
  addNew: "Add New",
  searchBar: "Site Name or Code",
  registrationSuccess: "Site successfully registered!",
  ownersError: "Error fetching site owners",
  cancel: "Cancel",
  saveChanges: "Save Changes",
  copyTariff: "Copy Tariff",
  copyTariffSite: "Copy Tariff to Sites",
  copySucces: "Tariff copy successful",
  noSiteSelected: "No Site Selected",
  invalidVendor: "Only Steamaco site tariffs can be copied",
  siteCodeNotFound: "Site Code was not found in our system",
  loading: "Loading sites...",
  selectSiteStatus: "Status..",
  resetLocation: "Reset Location",
  longitude: "Longitude",
  latitude: "Latitude",
  zoom: "Zoom",
};

export const fr = {};

export const swa = {};
