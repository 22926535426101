import React, {useState} from "react";
import {Modal, Form, Row, Col, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as billingAPI from "../../services/billingApi";
import {showPopup, getHttpMessage} from "../generics/alerts";

export default function EditTagModal({
  show,
  setShow,
  payload,
  setPayload,
  user,
  setReload,
}) {
  const {t} = useTranslation(["tags", "shared"]);

  return (
    <Modal show={show} onHide={() => setShow(false)} size="md">
      <Modal.Header closeButton>
        <Modal.Title className="text-primary ml-2">
          <FontAwesomeIcon icon="pencil-alt"></FontAwesomeIcon>
          {"   " + t("editTag")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditForm
          payload={payload}
          setPayload={setPayload}
          t={t}
          setShow={setShow}
          user={user}
          setReload={setReload}
        />
      </Modal.Body>
    </Modal>
  );
}

const EditForm = ({payload, setPayload, t, setShow, user, setReload}) => {
  const [input, setInput] = useState(payload.name);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setPayload((prev) => (prev.name = input));
    editTag(user.selected, payload);
  };

  const editTag = async (portfolio, payload) => {
    try {
      await billingAPI.editTag(portfolio, payload);
      showPopup(t("shared:success"), "success");
      setShow(false);
      setReload((prev) => !prev);
    } catch (err) {
      showPopup(getHttpMessage(err), "error");
      setShow(false);
    }
  };

  return (
    <Form className="ml-3 mt-3" onSubmit={handleOnSubmit}>
      <Form.Group as={Row} controlId="editTagForm">
        <Form.Label column sm={1} md={1} className="ml-5 p-2">
          {t("tag")}
        </Form.Label>
        <Col sm={7} md={7}>
          <Form.Control
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="p-0">
        <Col sm={8} md={9}>
          <Button
            type="submit"
            disabled={input ? false : true}
            variant="primary"
            className="mt-2 ml-2 pull-right"
          >
            {t("shared:save")}
          </Button>
          <Button
            variant="light"
            className="mt-2  pull-right"
            onClick={() => setShow(false)}
          >
            {t("shared:cancel")}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};
