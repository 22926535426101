export const en = {
  country: "Country",
  countries: "Countries",
  addNew: "Add New",
  addNewCountry: "Add New Country",
  updateCountry: "Update Country",
  id: "ID",
  code: "Code",
  name: "Name",
  countryCode: "Country Code",
  phone: "Phone",
  currency: "Currency",
  status: "Status",
  dateCreatedUTC: "Date Created",
};

export const fr = {};
