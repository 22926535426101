import React, {useState, useContext} from "react";
import {useTranslation} from "react-i18next";
import {GoogleLogin} from "@react-oauth/google";
import {GoogleOAuthProvider} from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import {loginWithGoogle, mockLogin} from "../../services/authApi";
import {UserContext} from "./userContext";
import {Redirect, useLocation} from "react-router-dom";
import {Row, Button, Card} from "react-bootstrap";
import Logo from "./logo.png";
import "./login.css";
const queryString = require("query-string");

export default function Login({setIsUserLoaded, setToken}) {
  const [errorMessage, setErrorMessage] = useState("");
  const {t} = useTranslation(["login", "shared"]);

  const user = useContext(UserContext);

  //get search params from url and parse
  const location = useLocation();
  let query = queryString.parse(location.search);
  let google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  if (user != null) {
    //redirect user to the intended path (passed in as query search param `redirect`) or go to customers page
    return <Redirect to={query.redirect ? query.redirect : "/customers"}></Redirect>;
  }

  const handleGoogleSuccess = async (googleResp) => {
    try {
      await loginWithGoogle(googleResp.credential);
      setIsUserLoaded(false);
      setToken(googleResp.credential);
    } catch (e) {
      var decoded = jwt_decode(googleResp.credential);
      const email = decoded.email;
      setErrorMessage(t("unregisteredError", {username: email}));
    }
  };

  const handleGoogleFailure = (err) => {
    console.error(err);
    setErrorMessage(t("googleAuthFailure"));
  };

  return (
    <Row>
      <div className="mx-auto">
        <Card className="px-5 py-3 shadow">
          <Card.Img src={Logo} className="my-4" />

          <GoogleOAuthProvider clientId={google_client_id}>
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleFailure}
              width={400}
              text="continue_with"
              useOneTap
            />
          </GoogleOAuthProvider>

          <MockLoginButton
            setIsUserLoaded={setIsUserLoaded}
            setErrorMessage={setErrorMessage}
          />
          <div className="text-danger text-center">{errorMessage}</div>
        </Card>
      </div>
    </Row>
  );
}

function MockLoginButton({setIsUserLoaded, setErrorMessage}) {
  const {t} = useTranslation(["login", "shared"]);

  if (process.env.REACT_APP_IS_TEST === "0" || process.env.REACT_APP_IS_TEST === "2") {
    return <div></div>;
  }

  const onClick = async () => {
    try {
      await mockLogin();
      setIsUserLoaded(false);
    } catch (e) {
      console.error(e);
      setErrorMessage(t("mockLoginError"));
    }
  };

  return (
    <Button
      id="mock-login-button"
      onClick={onClick}
      variant="outline-warning"
      className="btn-block my-3 btn-mock"
    >
      {t("mockLogin")}
    </Button>
  );
}
