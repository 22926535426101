import React, {useState, useEffect, useContext} from "react";
import {Table, Form, Col} from "react-bootstrap";
import {usePermissions, Unauthorized} from "../permissions";
import {UserContext} from "../login/userContext";
import * as DateHelpers from "../../services/date";
import * as billingAPI from "../../services/billingApi";
import * as alerts from "../generics/alerts";
import {useLocation} from "react-router";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ListHeader} from "../generics/headers";
import {ListPagination} from "../generics/pagination";
import Skeleton from "react-loading-skeleton";
import {formatFileNameDate} from "../../services/date";
import RegisterCountry from "./register";
import UpdateCountry from "./update";

const queryString = require("query-string");

export default function CountryList(props) {
  const user = useContext(UserContext);
  const canView = usePermissions(billingAPI.COUNTRIES_PATH);
  const location = useLocation();
  const history = useHistory();
  const {t} = useTranslation(["countries", "shared"]);
  const [countries, setCountries] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  let query = queryString.parse(location.search);

  // updateQuery is a function to create params from the query
  const updateQuery = (updates) => {
    query = Object.assign(query, updates);
    var params = queryString.stringify(query, {
      skipEmptyString: true,
    });
    history.push("/countries?" + params);
  };

  useEffect(() => {
    async function fetchCountries() {
      const searchParams = queryString.parse(location.search);
      searchParams.page = page;
      searchParams.limit = limit;
      await billingAPI
        .getCountries(user.selected.toLowerCase(), searchParams)
        .then((resp) => {
          setCount(resp.data.count);
          setCountries(resp.data.data);
          setLoading(true);
        })
        .catch((err) => {
          setCountries({});
          alerts.showPopup(alerts.getHttpMessage(err));
        });
      setLoading(false);
    }
    fetchCountries();
  }, [user.selected, location.search, page, limit]);

  async function getAllCountries() {
    const searchParams = queryString.parse(location.search);
    searchParams.limit = count;
    let allData = [];
    //setting up progress bar
    setProgress(50);
    await billingAPI
      .getCountries(user.selected.toLowerCase(), searchParams)
      .then((resp) => {
        allData.push(...resp.data.data);
        setProgress(100);
      })
      .catch((err) => {
        setProgress(0);
        alerts.showPopup(alerts.getHttpMessage(err));
      });
    setProgress(0);
    return allData;
  }

  //check if user has access to this page
  if (!canView) {
    return <Unauthorized />;
  }

  // returns the various segments of the page
  // header, search form, countries table, paginator
  return (
    <div>
      <ListHeader title={t("countries")} icon="earth-africa" />
      <SearchForm updateQuery={updateQuery} />
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        data={countries}
        filename={formatFileNameDate(new Date().toString()) + "_countries.csv"}
        getAllData={getAllCountries}
        progress={progress}
      />
      {loading ? (
        <Skeleton height={20} count="2" className="m-3" />
      ) : (
        <CountriesTable countries={countries} />
      )}
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        data={countries}
        filename={formatFileNameDate(new Date().toString()) + "_countries.csv"}
        getAllData={getAllCountries}
        progress={progress}
      />
    </div>
  );
}

function SearchForm({updateQuery}) {
  const {t} = useTranslation(["countries", "shared"]);

  return (
    <Form className="mb-3">
      <Form.Row>
        <Col xs={8} md={4}>
          <Form.Control
            className="mb-1"
            type="text"
            name="name"
            onChange={(e) => {
              updateQuery({name: e.target.value});
            }}
            placeholder={t("name")}
          ></Form.Control>
        </Col>
        <Col xs={4} md={2}>
          <Form.Control
            className="mb-1"
            type="text"
            name="country_code"
            onChange={(e) => {
              updateQuery({country_code: e.target.value});
            }}
            placeholder={t("phone")}
          ></Form.Control>
        </Col>
        <Col xs={4} md={2}>
          <Form.Control
            className="mb-1"
            type="text"
            name="currency"
            onChange={(e) => {
              updateQuery({currency: e.target.value});
            }}
            placeholder={t("currency")}
          ></Form.Control>
        </Col>
        <Col xs={8} md={4}>
          <RegisterCountry />
        </Col>
      </Form.Row>
    </Form>
  );
}

function CountriesTable({countries}) {
  const {t} = useTranslation(["countries", "shared"]);

  return (
    <Table striped bordered size="l" className="shadow" hover>
      <thead>
        <tr>
          <th>{t("id")}</th>
          <th>{t("code")}</th>
          <th>{t("name")}</th>
          <th>{t("phone")}</th>
          <th>{t("currency")}</th>
          <th>{t("dateCreatedUTC")}</th>
        </tr>
      </thead>
      <tbody>
        {countries ? (
          countries.map((country) => {
            return <CountriesRow key={country.id} country={country} />;
          })
        ) : (
          <tr></tr>
        )}
      </tbody>
    </Table>
  );
}

function CountriesRow({country}) {
  const canUpdate = usePermissions(billingAPI.COUNTRIES_PATH, "PUT");
  const [show, setShow] = useState(false);
  const onClick = () => {
    canUpdate ? setShow(true) : setShow(false);
  };
  return (
    <>
      <tr onClick={onClick}>
        <td>{country.id}</td>
        <td>{country.code}</td>
        <td>{country.name}</td>
        <td>{country.country_code}</td>
        <td>{country.currency}</td>
        <td>{DateHelpers.formatDisplayDateDay(country.date_created_utc).toString()}</td>
      </tr>
      <UpdateCountry country={country} show={show} setShow={setShow} />
    </>
  );
}
