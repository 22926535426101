import React, {useState, useContext, useEffect} from "react";
import {Form, Button, Modal, ModalBody} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {usePermissions} from "../permissions";
import * as meteringAPI from "../../services/meteringApi";
import {
  getFormProps,
  InlineFormField,
  InlineFormSelect,
  FormErrors,
} from "../generics/formsFields";
import {ModalSubmitButton} from "../generics/buttons";
import {UserContext} from "../login/userContext";
import {showPopup, getHttpMessage, showErrors} from "../generics/alerts";

export default function AssignMeter({account, setAccountsAreLoaded}) {
  const canView = usePermissions(meteringAPI.ASSIGN_METERS_PATH, "POST");
  const [show, setShow] = useState(false);
  const {t} = useTranslation(["customer"]);
  return (
    <div>
      <Button
        variant="danger"
        disabled={!canView}
        onClick={() => setShow(true)}
        className="m-1"
      >
        <FontAwesomeIcon icon="plus" /> {t("assignMeter")}
      </Button>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton className="text-primary">
          <Modal.Title>{t("assignMeter")}</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <AssignMeterForm
            account={account}
            setShow={setShow}
            setAccountsAreLoaded={setAccountsAreLoaded}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}

export function AssignMeterForm({account, setShow, setAccountsAreLoaded}) {
  const accountNo = account.account_number;
  const accountSiteCode = account.site_code;
  const user = useContext(UserContext);
  const {t} = useTranslation(["customer"]);
  const {register, handleSubmit} = useForm();
  const [saving, setSaving] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [errors, setErrors] = useState([]);
  const [servers, setServers] = useState([]);

  useEffect(() => {
    async function fetchServers(siteCode) {
      try {
        let serverMap = [];
        const resp = await meteringAPI.getServers(user.selected, {code: siteCode});
        resp.data.forEach((server) => {
          const displayVal = `${server.vendor_name}: ${server.vendor_server_id}`;
          serverMap.push({name: displayVal, value: server.vendor_server_id});
        });
        setServers(serverMap);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
      setLoaded(true);
    }
    if (!loaded) {
      fetchServers(accountSiteCode);
    }
  }, [user.selected, loaded, accountSiteCode]);

  //https://stackoverflow.com/questions/951021/what-is-the-javascript-version-of-sleep
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const onSubmit = async (meterAssignment) => {
    try {
      setErrors([]);
      setSaving(true);
      await meteringAPI.assignMeters(user.selected, [meterAssignment]);

      // wait 3 seconds until asynchronous database updates are complete
      await sleep(3000);
      showPopup(t("assignSuccess"), "success");
      setShow(false);
      setAccountsAreLoaded(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setSaving(false);
  };

  const meterSerial = getFormProps("serial_number", t("meterSerial"), "text", "true");
  const accountNumber = getFormProps(
    "account_number",
    t("accountNumber"),
    "text",
    "true",
    accountNo,
    "",
    [],
    "true"
  );
  const site = getFormProps(
    "site",
    t("site"),
    "select",
    "true",
    accountSiteCode,
    "",
    [],
    "true"
  );
  const server = getFormProps(
    "vendor_server_id",
    t("server"),
    "select",
    "false",
    "",
    "",
    servers
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="m-3">
      <FormErrors errors={errors}></FormErrors>
      <InlineFormField register={register} {...accountNumber}></InlineFormField>
      <InlineFormField register={register} {...meterSerial}></InlineFormField>
      <InlineFormField {...site}></InlineFormField>
      <InlineFormSelect register={register} {...server}></InlineFormSelect>
      <ModalSubmitButton
        loading={saving}
        variant="danger"
        icon="plus"
        text={t("assignMeter")}
      ></ModalSubmitButton>
    </Form>
  );
}
