import React, {useContext, useEffect, useState} from "react";
import {useParams, useHistory} from "react-router-dom";
import {UserContext} from "./../login/userContext";
import {usePermissions, Unauthorized} from "../permissions";
import {LoadingErrors} from "../generics/formsFields";
import {DashboardHeader} from "../generics/headers";
import {SiteForm} from "./form.js";
import Skeleton from "react-loading-skeleton";
import * as meteringAPI from "../../services/meteringApi";

export default function SiteDashboard() {
  const canView = usePermissions(meteringAPI.SITE_PATH);
  const {id} = useParams();
  const user = useContext(UserContext);
  const [site, setSite] = useState(null);
  const [siteLoading, setSiteLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function fetchSites() {
      try {
        const resp = await meteringAPI.getSites(user.selected, id);
        setSite(resp.data.data[0]);
      } catch (e) {
        console.error(e);
      }
      setSiteLoading(true);
    }
    fetchSites();
  }, [user.selected, id, history]);

  if (!canView) {
    return <Unauthorized />;
  }
  //show skeleton if site is still loading
  if (!siteLoading) {
    return <Skeleton height={40} count="4" className="m-3" />;
  }

  // if site still has no data, loading failed
  if (!site) {
    return <LoadingErrors />;
  }

  return (
    <div>
      <DashboardHeader title={site.site_name} icon="map-marker-alt"></DashboardHeader>
      <SiteForm site={site} setSiteLoading={setSiteLoading} />
    </div>
  );
}
