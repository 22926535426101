import React, {useState, useContext, useEffect} from "react";
import {Button, Modal, Form, Col} from "react-bootstrap";
import {UserContext} from "../login/userContext";
import {scheduleBonus} from "../../services/meteringApi";
import {usePermissions} from "../permissions";
import {getSites, getBonusDetails, SCHEDULE_BONUS_PATH} from "../../services/meteringApi";
import {formatDateToApi} from "../../services/date";
import {showPopup, getHttpMessage} from "../generics/alerts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const ScheduleBonusModal = ({setTotalCount}) => {
  const canView = usePermissions(SCHEDULE_BONUS_PATH, "POST");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <Button
        disabled={!canView}
        title={!canView ? "You don't have permissions" : null}
        variant="danger"
        onClick={handleShow}
        className="text-white float-right"
      >
        Schedule Bonus
      </Button>
      <Modal show={show} onHide={handleClose} animation={true} className="fade">
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">Schedule Bonus</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ScheduleBonusForm setTotalCount={setTotalCount} setShow={setShow} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const ScheduleBonusForm = ({setShow, setTotalCount}) => {
  let todayMidnight = new Date();
  todayMidnight.setHours(0, 0, 0);

  const initialPayloadState = {
    bonus_id: "",
    site_id: "",
    from_date: todayMidnight,
    thru_date: todayMidnight,
  };

  const [payload, setPayload] = useState(initialPayloadState);
  const [sites, setSites] = useState([]);
  const [bonuses, setBonuses] = useState([]);
  const minDate = new Date();

  const user = useContext(UserContext);

  const assignBonus = async (portfolio, payload) => {
    try {
      await scheduleBonus(portfolio, payload);
      setTotalCount((count) => count + 1);
    } catch (error) {
      alert(
        "Failed to schedule the bonus, make sure start time is 3 hours from now and end time is greater than start time "
      );
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    //Format from and thru_date to api accepted format
    const newPayload = {
      ...payload,
      from_date: formatDateToApi(payload.from_date),
      thru_date: formatDateToApi(payload.thru_date),
    };
    assignBonus(user.selected.toLowerCase(), newPayload);
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const getBonusList = async () => {
      try {
        const resp = await getBonusDetails(user.selected.toLowerCase(), {});
        setBonuses(resp.data);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
    };

    const getSitesList = async () => {
      let ci = user.permissions[user.selected].countryID;
      try {
        const sites = (
          await getSites(user.selected.toLowerCase(), {status: "active", country_id: ci})
        ).data;
        setSites(sites.data);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
    };
    getSitesList();
    getBonusList();
  }, [user]);

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Site:</Form.Label>
          <Form.Control
            required
            type="select"
            name="siteId"
            as="select"
            className="mb-3 form-control form-control-sm"
            onChange={(e) => setPayload({...payload, site_id: e.target.value})}
          >
            <option value=""></option>
            {sites.length > 0
              ? sites.map(({site_id, site_name}) => (
                  <option key={site_id} value={site_id}>
                    {site_name}
                  </option>
                ))
              : null}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Bonus:</Form.Label>
          <Form.Control
            required
            type="select"
            name="bonusId"
            as="select"
            className="mb-3 form-control form-control-sm"
            onChange={(e) => setPayload({...payload, bonus_id: e.target.value})}
          >
            <option value=""></option>
            {bonuses.map(({bonus_id, bonus_name}) => (
              <option key={bonus_id} value={bonus_id}>
                {bonus_name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            {/* <Row> */}
            <Form.Label>Start Date: </Form.Label>
            {/* </Row>
            <Row> */}
            <DatePicker
              as={Form.Control}
              selected={payload.from_date}
              onChange={(date) => setPayload({...payload, from_date: date})}
              showTimeSelect
              dateFormat="MMM d, yyyy h:mm aa"
              minDate={minDate}
              required
              timeIntervals={60}
              className="form-control form-control-sm  p-2"
            />
            {/* </Row> */}
          </Form.Group>
          <Form.Group as={Col}>
            {/* <Row> */}
            <Form.Label>End Date: </Form.Label>
            {/* </Row> */}
            {/* <Row> */}
            <DatePicker
              as={Form.Control}
              selected={payload.thru_date}
              onChange={(date) => setPayload({...payload, thru_date: date})}
              showTimeSelect
              dateFormat="MMM d, yyyy h:mm aa"
              minDate={minDate}
              required
              timeIntervals={60}
              className="form-control form-control-sm p-2"
            />
            {/* </Row> */}
          </Form.Group>
        </Form.Row>
        <Button type="submit" className="ml-3 float-right">
          Apply Bonus
        </Button>
        <Button
          className="text-white float-right"
          variant="warning"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Form>
    </div>
  );
};
