import * as DateHelpers from "./date";
import {axiosInstance} from "./axios";

export const TRANSACTIONS_LIST_PATH = "/billing/api/payments";
export const UNMATCHED_TRANSACTIONS_LIST_PATH = "/billing/api/payments/unmatched";
export const PRODUCT_ACCOUNTS_LIST_PATH = "/billing/api/accounts/product";
export const CUSTOMER_ACCOUNTS = "/billing/api/accounts/getbycustomer";
export const REDIRECT_TRANS = "/billing/api/payments/redirect";
export const PRODUCT_PAYMENT_PLAN_PATH = "/billing/api/products/paymentplan";
export const PRODUCT_PAYMENT_PLAN_UPDATE_PATH =
  "/billing/api/products/paymentplan/update";
export const PRODUCT_ITEMS_LIST_PATH = "/billing/api/products/product";
export const DEACTIVATE_ACCOUNT_PATH = "/billing/api/accounts/deactivateAccounts";
export const ACTIVATE_ACCOUNT_PATH = "/billing/api/accounts/activateAccounts";
export const BONUS_DISBURSEMENT_PATH = "/billing/api/createbonuspayment";
export const REPROCESS_TRANS = "/billing/api/reprocesstransaction";
export const TAGS_LIST_PATH = "/billing/api/tags";
export const TAGS_DELETE_PATH = "/billing/api/tags/delete";
export const TAGS_MERGE_PATH = "/billing/api/tags/merge";
export const ASSIGN_TAG_PATH = "/billing/api/tags/assign";
export const UNASSIGN_TAG_PATH = "/billing/api/tags/unassign";
export const TAGS_UPDATE_PATH = "/billing/api/tags/:id";
export const COLLECTION_ACCOUNTS_LIST_PATH = "/billing/api/accounts/collections";
export const COLLECTION_ACCOUNTS_REGISTER_PATH = "/billing/api/collections";
export const COLLECTION_ACCOUNTS_PROVIDER_LIST_PATH =
  "/billing/api/accounts/accountproviders";
export const LIST_CUSTOMER_ACCOUNTS = "/billing/api/accounts";
export const CUSTOMER_UPDATE_PATH = "/billing/api/updateCustomer";
export const ENERGY_ACCOUNT_UPDATE_PATH = "/billing/api/accounts/energy/bulk";
export const GATEWAYS_PATH = "/billing/api/payments/gateways";
export const COUNTRIES_PATH = "/billing/api/countryinstances";
export const CUSTOMER_LIST_PATH = "/billing/api/customers";
export const CUSTOMER_REGISTER_PATH = "/billing/api/massCustomers";
export const LANGUAGE_PATH = "/billing/api/languages";

export function getLanguages(portfolio) {
  return axiosInstance(portfolio).get(LANGUAGE_PATH);
}

export function getCustomers(portfolio, query, page, limit) {
  const p = page > 0 ? page - 1 : 0;
  let params = new URLSearchParams();

  if (query.tag) {
    if (Array.isArray(query.tag)) {
      // eslint-disable-next-line
      query.tag.map((tag) => {
        params.append("tag", tag);
        return tag;
      });
    } else {
      params.append("tag", query.tag);
    }
  }
  if (limit) {
    params.append("limit", limit);
    params.append("offset", p * limit);
    params.append("include_meter", true);
  }
  if (query.active) {
    params.append("active", query.active);
  }

  if (query.search_value) {
    params.append("search", query.search_value);
  }
  if (query.site_code) {
    params.append("site_code", query.site_code);
  }
  if (query.meter_serial) {
    params.append("meter_serial", query.meter_serial);
  }
  if (query.country_id) {
    params.append("country_id", query.country_id);
  }
  if (query.id) {
    params.append("id", query.id);
  }

  return axiosInstance(portfolio).get(CUSTOMER_LIST_PATH, {params});
}

// registerCustomer registers new customer on the CRM
export function registerCustomer(portfolio, customers, config) {
  let data = cleanCustomerRegPayload(customers);
  return axiosInstance(portfolio).post(CUSTOMER_REGISTER_PATH, data, config);
}

function cleanCustomerRegPayload(data) {
  let customersData = [];
  data.forEach((d) => {
    if (typeof d.latitude === "undefined" && typeof d.longitude === "undefined") {
      d.latitude = d.lat && d.lat !== "" ? parseFloat(d.lat) : null;
      d.longitude = d.long && d.long !== "" ? parseFloat(d.long) : null;
      delete d.lat;
      delete d.long;
    } else {
      d.latitude = parseFloat(d.latitude);
      d.longitude = parseFloat(d.longitude);
    }
    customersData.push(d);
  });
  return customersData;
}

export function getAccountsByCustomer(portfolio, id) {
  return axiosInstance(portfolio).get(CUSTOMER_ACCOUNTS, {
    params: {id},
  });
}

export function getProductAccounts(portfolio, page, limit, searchData) {
  //this to handle offset to start with zero from pagination
  const offset = page > 0 ? page - 1 : 0;
  page = offset;
  const limOff = {offset: page, limit: limit};

  const search = Object.keys(searchData).length !== 0 ? cleanObject(searchData) : {};

  const paramsData = {...limOff, ...search};
  if (searchData.country_id) {
    paramsData.country_id = searchData.country_id;
  }

  return axiosInstance(portfolio).get(PRODUCT_ACCOUNTS_LIST_PATH, {
    params: paramsData,
  });
}

export function getTransactions(portfolio, countPerPage, query) {
  let params = {limit: countPerPage};

  params.offset = query.page ? (parseInt(query.page) - 1) * countPerPage : 0;

  if (query.country_id) {
    params.country_id = query.country_id;
  }

  if (query.start_date) {
    const date = new Date(query.start_date);
    date.setHours(0, 0, 0); //set the start date to midnight
    params.start_date = DateHelpers.formatDateToApi(date);
  }
  if (query.end_date) {
    const date = new Date(query.end_date);
    date.setHours(23, 59, 59); //set the end time to 23:59:59;
    params.end_date = DateHelpers.formatDateToApi(date);
  }
  if (query.status) {
    params.status = query.status;
  }
  if (query.account_type) {
    params.account_type = query.account_type;
  }
  if (query.search) {
    params.search = query.search;
  }
  if (query.customer_id) {
    params.customerid = query.customer_id;
  }
  if (query.mno) {
    params.mno = query.mno;
  }
  return axiosInstance(portfolio)
    .get(TRANSACTIONS_LIST_PATH, {
      params: params,
    })
    .then((resp) => {
      return resp;
    });
}

export function getProductItemList(portfolio, limit, query) {
  const params = {limit: limit};
  params.offset = query.page ? (parseInt(query.page) - 1) * limit : 0;
  if (query.country_id) {
    params.country_id = query.country_id;
  }
  if (query.product_name) {
    params.product_name = query.product_name;
  }
  if (query.product_code) {
    params.product_code = query.product_code;
  }
  return axiosInstance(portfolio)
    .get(PRODUCT_ITEMS_LIST_PATH, {
      params: params,
    })
    .then((resp) => {
      return resp;
    });
}

export function redirectTransaction(portfolio, data) {
  let url = `${REDIRECT_TRANS}?payment_id=${data.payment_id}&new_account_no=${data.newAccount}`;
  return axiosInstance(portfolio).post(url, {});
}

export function registerProductAccount(portfolio, product) {
  return axiosInstance(portfolio).post(PRODUCT_ACCOUNTS_LIST_PATH, product);
}

export function activateEnergyAccount(portfolio, data, config) {
  return axiosInstance(portfolio).post(ACTIVATE_ACCOUNT_PATH, data, config);
}

export function registerProductItems(portfolio, items) {
  return axiosInstance(portfolio).post(PRODUCT_ITEMS_LIST_PATH, items);
}

export function updateProductItems(portfolio, data) {
  return axiosInstance(portfolio).patch(PRODUCT_ITEMS_LIST_PATH, data);
}

export function getProductPaymentPlans(portfolio, query) {
  return axiosInstance(portfolio).get(PRODUCT_PAYMENT_PLAN_PATH, {
    params: query,
  });
}

export function getProductItems(portfolio, query) {
  let params = {};

  if (query.country_id) {
    params.country_id = query.country_id;
  }

  if (query.product_name) {
    params.product_name = query.product_name;
  }
  if (query.product_code) {
    params.product_code = query.product_code;
  }
  if (query.product_id) {
    params.product_id = query.product_id;
  }
  if (query.offset) {
    params.offset = query.offset;
  }
  if (query.limit) {
    params.limit = query.limit;
  }

  return axiosInstance(portfolio).get(PRODUCT_ITEMS_LIST_PATH, {
    params: params,
  });
}

export function updateProductAccountItems(portfolio, data) {
  return axiosInstance(portfolio).patch(PRODUCT_ACCOUNTS_LIST_PATH, data);
}

export function deactivateAccounts(portfolio, payload, config) {
  return axiosInstance(portfolio).post(DEACTIVATE_ACCOUNT_PATH, payload, config);
}

export function registerPaymentPlans(portfolio, data) {
  return axiosInstance(portfolio).post(PRODUCT_PAYMENT_PLAN_PATH, data);
}

export function updatePaymentPlan(portfolio, data) {
  return axiosInstance(portfolio).patch(PRODUCT_PAYMENT_PLAN_UPDATE_PATH, data);
}

export function disburseBonusPayment(portfolio, data, config) {
  return axiosInstance(portfolio).post(BONUS_DISBURSEMENT_PATH, data, config);
}

export function reprocessTransaction(portfolio, paymentID) {
  let url = `${REPROCESS_TRANS}?payment_id=${paymentID}`;
  return axiosInstance(portfolio).patch(url, {});
}

export function deleteTag(portfolio, id) {
  return axiosInstance(portfolio).delete(TAGS_DELETE_PATH, {
    params: {id: id},
  });
}

export function editTag(portfolio, payload) {
  const path = `${TAGS_UPDATE_PATH.replace(":id", `${payload.id}`)}`;
  return axiosInstance(portfolio).put(path, {
    tag: payload.name,
  });
}

export function mergeTag(portfolio, payload) {
  return axiosInstance(portfolio).post(TAGS_MERGE_PATH, payload);
}

export function assignTag(portfolio, payload, config) {
  return axiosInstance(portfolio).post(ASSIGN_TAG_PATH, payload, config);
}

export function unassignTag(portfolio, payload, config) {
  return axiosInstance(portfolio).post(UNASSIGN_TAG_PATH, payload, config);
}

export function getTags(portfolio, query) {
  if (query.page) {
    query.offset = query.page > 0 ? (query.page - 1) * query.limit : 0;
    delete query.page;
  }
  return axiosInstance(portfolio).get(TAGS_LIST_PATH, {
    params: query,
  });
}

export function getCollectionAccounts(portfolio, query) {
  query.offset = query.page > 0 ? (query.page - 1) * query.limit : 0;
  delete query.page;
  return axiosInstance(portfolio).get(COLLECTION_ACCOUNTS_LIST_PATH, {
    params: query,
  });
}

export function registerCollectionAccounts(portfolio, data) {
  return axiosInstance(portfolio).post(COLLECTION_ACCOUNTS_REGISTER_PATH, data);
}

export function getCollectionAccountProviders(portfolio, query) {
  return axiosInstance(portfolio).get(COLLECTION_ACCOUNTS_PROVIDER_LIST_PATH, {
    params: query,
  });
}

export function getCustomerAccounts(portfolio, query) {
  return axiosInstance(portfolio).get(LIST_CUSTOMER_ACCOUNTS, {
    params: query,
  });
}
export function getGateways(portfolio, query) {
  return axiosInstance(portfolio).get(GATEWAYS_PATH, {
    params: query,
  });
}

export function updateCustomer(portfolio, customer) {
  return axiosInstance(portfolio).patch(CUSTOMER_UPDATE_PATH, customer);
}

export function updateEnergyAccount(portfolio, data, config) {
  return axiosInstance(portfolio).patch(ENERGY_ACCOUNT_UPDATE_PATH, data, config);
}

export function getCountries(portfolio, query) {
  let params = {};
  if (query.id) {
    params.id = query.id;
  }
  if (query.name) {
    params.name = query.name;
  }
  if (query.currency) {
    params.currency = query.currency;
  }
  if (query.country_code) {
    params.country_code = query.country_code;
  }
  if (query.code) {
    params.code = query.code;
  }
  if (query.offset) {
    params.offset = query.offset;
  }
  if (query.limit) {
    params.limit = query.limit;
  }
  return axiosInstance(portfolio).get(COUNTRIES_PATH, {
    params: params,
  });
}

export function updateCountry(portfolio, country) {
  return axiosInstance(portfolio).put(COUNTRIES_PATH, country);
}

export function registerCountry(portfolio, country) {
  return axiosInstance(portfolio).post(COUNTRIES_PATH, country);
}

function cleanObject(obj) {
  for (var key in obj) {
    if (obj[key] === "") delete obj[key];
  }
  return obj;
}
