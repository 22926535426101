import React, {useState, useEffect} from "react";
import {usePermissions, Unauthorized} from "../permissions";
import * as authAPI from "../../services/authApi";
import {Table, Form, Col, Button, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export default function Permissions() {
  const {t} = useTranslation(["permissions"]);
  const canView = usePermissions(authAPI.PERMISSION_PATH);
  const [permissions, setPermissions] = useState([]);

  async function fetchEndpoints() {
    try {
      const resp = await authAPI.getPermissions();
      setPermissions(resp.data.sort((a, b) => a.endpoint.localeCompare(b.endpoint)));
    } catch (err) {
      alert(err);
      console.error(err);
    }
  }

  useEffect(() => {
    fetchEndpoints();
  }, []);

  if (!canView) {
    return <Unauthorized />;
  }
  return (
    <React.Fragment>
      <Row className="mb-3">
        <h3 className="text-primary d-inline mr-3 col">{t("listTitle")}</h3>
      </Row>
      <CreatePermissionForm fetchEndpoints={fetchEndpoints} />
      <PermissionsTable permissions={permissions} fetchEndpoints={fetchEndpoints} />
    </React.Fragment>
  );
}

export function CreatePermissionForm({fetchEndpoints}) {
  const {t} = useTranslation(["permissions"]);
  const [verb, setVerb] = useState("GET");
  const [endpoint, setEndpoint] = useState("");
  const canCreate = usePermissions(authAPI.PERMISSION_PATH, "POST");

  if (!canCreate) {
    return <React.Fragment />;
  }

  const verbs = ["GET", "POST", "PATCH", "PUT", "DELETE"];

  const savePermission = (e) => {
    e.preventDefault();
    var data = {verb, endpoint};
    authAPI
      .createPermission(data)
      .then((resp) => {
        fetchEndpoints();
        alert(t("shared:success"));
      })
      .catch((e) => {
        alert(t("shared:internalError"));
      });
    setEndpoint("");
    setVerb("GET");
  };
  return (
    <Form className="mb-3">
      <Form.Row>
        <Col sm={3} lg={2}>
          <Form.Control
            as="select"
            name="verb"
            value={verb}
            onChange={(e) => setVerb(e.target.value)}
          >
            {verbs.map((v, i) => {
              return (
                <option key={i} value={v}>
                  {v}
                </option>
              );
            })}
          </Form.Control>
        </Col>
        <Col sm={6} lg={4}>
          <Form.Control
            type="text"
            name="endpoint"
            value={endpoint}
            onChange={(e) => setEndpoint(e.target.value)}
            placeholder={t("endpoint")}
          />
        </Col>
        <Col>
          <Button variant="primary" style={{marginLeft: "auto"}} onClick={savePermission}>
            Add
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}

export function PermissionsTable({permissions, fetchEndpoints}) {
  const {t} = useTranslation(["permissions"]);
  const [search, setSearch] = useState("");
  const [filteredPermissions, setFilteredPermissions] = useState(permissions);
  const canDelete = usePermissions(authAPI.PERMISSION_PATH, "DELETE");

  useEffect(() => {
    setFilteredPermissions(
      permissions.filter(
        (permission) =>
          permission.verb.toLowerCase().includes(search.toLowerCase()) ||
          permission.endpoint.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, permissions]);

  return (
    <>
      <Row>
        <Col lg={7}>
          <Form className="mb-3">
            <Form.Control
              className="mr-2"
              type="text"
              name="Search"
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t("Search")}
            ></Form.Control>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>{t("verb")}</th>
                <th>{t("endpoint")}</th>
                <th>{t("del")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredPermissions.map((permission) => (
                <PermissionsRow
                  permission={permission}
                  key={permission.id}
                  fetchEndpoints={fetchEndpoints}
                />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
  function PermissionsRow({permission, fetchEndpoints}) {
    return (
      <tr>
        <td>{permission.verb}</td>
        <td>{permission.endpoint}</td>
        <td>
          <DeletePermission
            id={permission.id}
            canDelete={canDelete}
            fetchEndpoints={fetchEndpoints}
          />
        </td>
      </tr>
    );
  }
}

function DeletePermission({id, canDelete, fetchEndpoints}) {
  const {t} = useTranslation(["permissions", "shared"]);
  const deletePermission = () => {
    let result = window.confirm(t("confirmDel"));
    if (result === true) {
      authAPI
        .deletePermission({id})
        .then((response) => {
          fetchEndpoints();
          alert(t("shared:success"));
        })
        .catch((e) => {
          console.error(e);
          alert(t("shared:internalError"));
        });
    }
  };

  return (
    <Button onClick={deletePermission} disabled={!canDelete}>
      <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
    </Button>
  );
}
