export const en = {
  // Product Accounts
  productAccounts: "Product Accounts",
  accountNo: "Customer Account Number",
  productListTitle: "Product Items List",
  siteCode: "Site Code",
  loanStatus: "Loan Status",
  product: "Product Name",
  productPrice: "Product Price",
  deposited: "Total Deposits",
  productCode: "Product Code",
  productDescription: "Product Description",
  AddButton: "Add New",
  firstPage: "First",
  lastPage: "Last",
  registerTitle: "Product Registration",
  registerButtonTitle: "Register",
  paymentPlan: "Payment Plan",
  energyAccount: "Energy Account",
  paymentRef: "Payment Reference",
  dateOfDeposit: "Date of Deposit",
  initialDeposit: "Initial Deposit",
  negativeInitialDeposit: "Initial Deposit cannot be less than zero.",
  selectStatus: "Select status...",
  selectSite: "Select site...",
  registerItemTitle: "Product Registration",
  updateProductTitle: "Update Product Item",

  // Product Payment Plans
  productPaymentPlans: "Product Payment Plans",
  selectProduct: "Select product...",
  planName: "Plan Name",
  productName: "Product Name",
  price: "Price",
  depositAmount: "Deposit Amount",
  deposit: "Deposit",
  monthlyPayment: "Monthly Payment",
  energyTax: "Energy Tax",
  active: "Active",
  addNew: "Add New",
  paymentPlanRegistration: "Payment Plan Registration",
  register: "Register",
  save: "Save",
  registrationFailed: "Registration failed",
  updateFailed: "Update failed",
  cancel: "Cancel",
  balance: "Remaining Balance",
};

export const fr = {};

export const swa = {
  listTitle: "Habari ya Akaunti ya Bidhaa",
  accountNo: "Nambari ya akaunti ya mteja",
  siteCode: "Nambari ya kijiji",
  status: "Hali ya mkopo",
  product: "Jina la kifaa",
  productPrice: "Gharama ya kifaa",
  deposited: "Kiasi Kilicholipwa",
  productCode: "nambari ya kifaa",
};
