import React, {useContext, useEffect, useState} from "react";
import {Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {UserContext} from "../login/userContext";
import * as meteringAPI from "../../services/meteringApi";
import * as notificationAPI from "../../services/notificationApi";
import * as date from "../../services/date";
import {SmsLogsTable} from "../sms/smsTable";

export function SMSTable({customer}) {
  const user = useContext(UserContext);
  const [sms, setSMS] = useState([]);

  useEffect(() => {
    async function fetchSMS(query) {
      await notificationAPI.getSmsLogs(user.selected, 50, query).then((resp) => {
        setSMS(resp.data.Results);
      });
    }
    fetchSMS({customer_id: customer.Id});
  }, [user.selected, customer]);

  return <SmsLogsTable smslogs={sms} />;
}

export function BundlesTable({account}) {
  const user = useContext(UserContext);
  const [bundles, setBundles] = useState([]);
  const {t} = useTranslation(["bundles"]);

  useEffect(() => {
    async function BundleQuery(portfolio, account_number) {
      const resp = await meteringAPI.getBundleSubscriptions(portfolio, account_number);
      setBundles(resp.data);
    }
    BundleQuery(user.selected, account.account_number);
  }, [user.selected, account]);

  return (
    <Table striped bordered size="sm">
      <thead>
        <tr>
          <th>{t("accountNo")}</th>
          <th>{t("bundleName")}</th>
          <th>{t("bundlePrice")}</th>
          <th>{t("pricePerKwh")}</th>
          <th>{t("unusedAmount")}</th>
          <th>{t("status")}</th>
          <th>{t("startDate")}</th>
          <th>{t("endDate")}</th>
        </tr>
      </thead>
      <tbody>
        {bundles ? (
          bundles.map((bundle, i) => {
            return <BundleRow key={i} bundle={bundle} />;
          })
        ) : (
          <tr></tr>
        )}
      </tbody>
    </Table>
  );
}

function BundleRow({bundle}) {
  return (
    <tr>
      <td>{bundle.account_number}</td>
      <td>{bundle.bundle_name}</td>
      <td>{bundle.bundle_price}</td>
      <td>{bundle.price_per_kwh}</td>
      <td>{bundle.unused_amount}</td>
      <td>{bundle.status}</td>
      <td>{date.formatDisplayDate(bundle.start_date_utc).toString()}</td>
      <td>{date.formatDisplayDate(bundle.end_date_utc).toString()}</td>
    </tr>
  );
}
