import React, {useState, useContext} from "react";
import {Card, Col, Row, InputGroup, FormControl} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import {UserContext} from "../login/userContext";
import {usePermissions, Unauthorized} from "../permissions";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {FileUpload, Instructions, ErrorList} from "../generics/fileUploads";
import * as notificationAPI from "../../services/notificationApi";

export default function SendSMS() {
  const {t} = useTranslation(["fileupload", "shared"]);
  const user = useContext(UserContext);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0); // %
  const [errors, setErrors] = useState([]);
  const canView = usePermissions(notificationAPI.NOTIFICATION_SMS_SEND, "POST");
  const [sms, setSMS] = useState("");

  if (!canView) {
    return <Unauthorized />;
  }

  //sample file
  const filename = "sample_send_sms.csv";
  const headers = "phone_number";
  const sampleData = [["phone_number"], ["255757356045"], ["232706600000"]];

  //instructions
  const pageTitle = t("pageSubTitle");
  const reminders = [t("smsBody"), t("phoneNumberStructure")];

  async function sendSMS(data, config) {
    let payload = {};
    let recipients = [];
    if (sms.length > 0) {
      payload.message = sms;
    } else {
      setErrors([t("smsBody")]);
      setProgress(0);
      return;
    }

    if (data.length > 0) {
      data.forEach((p) => {
        recipients.push(p.phone_number);
      });
      payload.recipients = recipients;
    } else {
    }

    try {
      await notificationAPI.sendSms(user.selected, payload, config);
      showPopup(t("shared:success"), "success");
      resetStates();
    } catch (err) {
      setErrors([getHttpMessage(err)]);
      setProgress(0);
    }
  }

  const resetStates = () => {
    setProgress(0);
    setData([]);
    setLoaded(false);
    setErrors([]);
  };

  return (
    <div>
      <Card className="shadow">
        <Card.Body className="p-4">
          <Instructions
            title={pageTitle}
            isLoaded={isLoaded}
            headers={headers}
            filename={filename}
            sampleData={sampleData}
            reminders={reminders}
          />

          <Row>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>{t("sms") + ":"} </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  as="textarea"
                  name="sms"
                  placeholder={t("addSMS")}
                  required
                  onChange={(e) => {
                    setSMS(e.target.value);
                  }}
                />
              </InputGroup>{" "}
            </Col>
          </Row>

          <FileUpload
            sampleData={sampleData}
            data={data}
            errors={errors}
            progress={progress}
            setData={setData}
            setErrors={setErrors}
            setProgress={setProgress}
            setLoaded={setLoaded}
            setColumns={setColumns}
            sendToAPI={sendSMS}
            processButtonName={t("sendSMS")}
            handleCancel={resetStates}
          />

          <ErrorList errors={errors} handleReUpload={resetStates} />

          <DataTable
            pagination
            highlightOnHover
            columns={columns}
            data={data}
            noDataComponent=""
          />
        </Card.Body>
      </Card>
    </div>
  );
}
