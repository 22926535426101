import React from "react";
import {useTranslation} from "react-i18next";
import {CountryModal} from "./modal";

export default function UpdateProduct({country, show, setShow}) {
  const handleClose = () => setShow(false);
  const {t} = useTranslation(["countries", "shared"]);
  return (
    <CountryModal
      show={show}
      setShow={setShow}
      handleClose={handleClose}
      title={t("updateCountry")}
      country={country}
    />
  );
}
