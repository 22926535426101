import React from "react";
import {Container} from "react-bootstrap";
export default function CSAT() {
  return (
    <Container fluid>
      <iframe
        title="csat"
        width="100%"
        height="825"
        src="https://datastudio.google.com/embed/reporting/a7af6adf-76a4-483b-8b18-4a9d74b8d7ab/page/SPMoB"
        frameborder="0"
      ></iframe>
    </Container>
  );
}
