import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {usePermissions, Unauthorized} from "../permissions";
import * as authApi from "../../services/authApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {useParams} from "react-router-dom";
import {RegisterAminiUpdateForm} from "./register";
import Skeleton from "react-loading-skeleton";

export default function UpdateAminiUpdate() {
  const [update, setUpdate] = useState({});
  const {t} = useTranslation(["aminiUpdates"]);
  const canUpdate = usePermissions(authApi.AMINI_UPDATES_PATH, "POST");
  const {id} = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getAminiUpdates() {
      if (id && canUpdate) {
        setLoading(true);
        //fetch update details
        let params = new URLSearchParams();
        params.set("id", id);
        try {
          let resp = await authApi.getAminiUpdates(params);
          if (resp.data.data.length > 0) {
            //get update data
            setUpdate(resp.data.data[0]);
          }
        } catch (exp) {
          showPopup(getHttpMessage(exp));
        }
        setLoading(false);
      }
    }
    getAminiUpdates();
  }, [canUpdate, id]);
  if (!canUpdate) {
    return <Unauthorized />;
  }
  if (loading) {
    return <Skeleton height={40} count="4" className="m-3" />;
  }
  return (
    <div className="mx-5">
      <h1 className="mb-4 display-4 text-primary">
        <FontAwesomeIcon icon="flag"></FontAwesomeIcon> {t("updateAminUpdate")}
      </h1>
      <div className="mt-2 shadow bg-white border rounded">
        <RegisterAminiUpdateForm update={update} />
      </div>
    </div>
  );
}
