import {axiosInstance} from "./axios";

const path = "/metering/api/sitebonusinfomation";

const getSitesBonuses = (portfolio, query, countPerPage) => {
  let params = {limit: countPerPage};

  params.offset = query.page ? (parseInt(query.page) - 1) * params.limit : 0;

  if (query.country_id) {
    params.country_id = query.country_id;
  }

  if (query.siteid) {
    params.siteid = query.siteid;
  }

  if (query.status) {
    params.status = query.status;
  }

  const apiRes = axiosInstance(portfolio).get(path, {
    params: params,
  });
  return apiRes;
};

export default getSitesBonuses;
