export const en = {
  search: "Search",
  failed: "Request was unsuccessfull",
  unauthorized:
    "Sorry, you are not authorized to access this resource. Please contact your administrator to give you access rights to this resource",
  badRequest: "Bad Request",
  requestFailedwithErrors: "Your request failed with the following errors:",
  internalError:
    "Oops, we experienced a problem with our servers, please try again later",
  unknownError:
    +"Sorry, we are unable to complete your request due to an unknown error, please try again soon",
  success: "Request successful!",
  cancel: "Cancel",
  refresh: "Refresh",
  ok: "OK",
  edit: "Edit",
  delete: "Delete",
  save: "Save",
  add: "Add",
  archive: "Archive",
  loading: "Loading",
  itemsPerPage: "Items per page",
  errors: "Errors",
  register: "Register",
  description: "Description",
  saving: "Saving...",
  submit: "Submit",
  merge: "Merge",
  loadingFailed:
    "Loading Failed, check your internet connection and try reloading this page",
  phoneErr:
    "Request Failed. Kindly make sure the phone number is not assigned to another customer on the meter vendor (Steamaco or Sparkmeter)",
  successWithWait:
    "Success: Kindly wait for atleast 10 minutes for these transactions to complete on the meter vendor and reflect on Amini",
  waitAfterUpload:
    "NB: Kindly wait for atleast 10 minutes after uploading for these transactions to complete on the meter vendor",
  country: "Country",
  selectCountry: "Select Country...",
};

export const fr = {};

export const swa = {};
