import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import * as shared from "./sharedTranslations";
import * as login from "./components/login/translations";
import * as customer from "./components/customer/translations";
import * as transactions from "./components/transactions/translations";
import * as nav from "./components/nav/translations";
import * as bonus from "./components/bonus/translations";
import * as product from "./components/product/translations";
import * as sites from "./components/sites/translations";
import * as servers from "./components/servers/translations";
import * as users from "./components/users/translations";
import * as roles from "./components/roles/translations";
import * as sms from "./components/sms/translations";
import * as permissions from "./components/authpermissions/translations";
import * as bundles from "./components/bundles/translations";
import * as tariffs from "./components/tariffs/translations";
import * as genericError from "./components/generics/errorTranslations";
import * as fileupload from "./components/uploadFile/translations";
import * as bulkActions from "./components/bulkActions/translations";
import * as tags from "./components/tags/translations";
import * as collection from "./components/collection/transalations";
import * as aminiUpdates from "./components/aminiUpdates/translations";
import * as countries from "./components/countries/translations";
import * as portfolios from "./components/portfolios/translations";

// pulled from https://react.i18next.com/latest/using-with-hooks#configure-i-18-next

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        shared: shared.en,
        customer: customer.en,
        login: login.en,
        nav: nav.en,
        transactions: transactions.en,
        bonus: bonus.en,
        product: product.en,
        sms: sms.en,
        sites: sites.en,
        servers: servers.en,
        users: users.en,
        roles: roles.en,
        permissions: permissions.en,
        bundles: bundles.en,
        tariffs: tariffs.en,
        genericError: genericError.en,
        fileupload: fileupload.en,
        bulkActions: bulkActions.en,
        tags: tags.en,
        collection: collection.en,
        aminiUpdates: aminiUpdates.en,
        countries: countries.en,
        portfolios: portfolios.en,
      },
      fr: {
        translation: {
          shared: shared.fr,
          customer: customer.fr,
          login: login.fr,
          nav: nav.fr,
          transactions: transactions.fr,
          bonus: bonus.fr,
          product: product.fr,
          sms: sms.fr,
          sites: sites.fr,
          servers: servers.fr,
          users: users.fr,
          permissions: permissions.fr,
          bundles: bundles.fr,
          bulkActions: bulkActions.fr,
          tags: tags.fr,
          collection: collection.fr,
          aminiUpdates: aminiUpdates.fr,
          countries: countries.fr,
          portfolios: portfolios.fr,
        },
      },
      swa: {
        translation: {
          product: product.swa,
          sites: sites.swa,
          servers: servers.swa,
          users: users.swa,
          bundles: bundles.swa,
          collection: collection.swa,
          aminiUpdates: aminiUpdates.sw,
          portfolios: portfolios.sw,
        },
      },
    },
    fallbackLng: "en",
    defaultNS: "shared",
    debug: process.env.NODE_ENV === "development",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
