import React, {useState, useContext, useEffect} from "react";
import {Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {usePermissions, Unauthorized} from "../permissions";
import {UserContext} from "../login/userContext";
import * as meteringAPI from "../../services/meteringApi";
import * as billingAPI from "../../services/billingApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  getFormProps,
  FormField,
  FormSelect,
  FormCheck,
  FormErrors,
} from "../generics/formsFields";
import {RegisterButton, CancelButton} from "../generics/buttons";
import {showPopup, showErrors} from "../generics/alerts";
import {useHistory} from "react-router-dom";

export default function RegisterCustomer() {
  const {t} = useTranslation(["customer"]);
  const canView = usePermissions(billingAPI.CUSTOMER_REGISTER_PATH, "POST");
  if (!canView) {
    return <Unauthorized />;
  }
  return (
    <div>
      <h1 className="mb-4 display-4 text-primary">
        <FontAwesomeIcon icon="users"></FontAwesomeIcon> {t("registerTitle")}
      </h1>
      <div className="mt-2 shadow bg-white border rounded">
        <RegisterCustomerForm />
      </div>
    </div>
  );
}

export function RegisterCustomerForm() {
  // set up contexts and variables for the form
  const user = useContext(UserContext);
  const {t} = useTranslation(["customer", "shared"]);
  const {register, handleSubmit} = useForm();
  const history = useHistory();
  // fetch and populate options for owner field
  const [sites, setSites] = useState([]);
  const [disable, setDisable] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [errors, setErrors] = useState([]);
  let countryID = user.permissions[user.selected].countryID;

  useEffect(() => {
    if (!isLoaded) {
      fetchSites();
      fetchLanguages();
    }
  });
  async function fetchSites() {
    try {
      const resp = await meteringAPI.getSites(user.selected, {
        status: "active",
        country_id: countryID,
      });
      let siteJson = [];
      resp.data.data.forEach((site) => {
        siteJson.push({name: site.site_name, value: site.site_code});
      });
      setSites(siteJson);
    } catch (err) {
      showPopup(t("siteError") + ": " + err);
    }
    setLoaded(true);
  }

  async function fetchLanguages() {
    try {
      const resp = await billingAPI.getLanguages(user.selected);
      let lanJson = [];
      resp.data.data.forEach((lang) => {
        lang.Language = lang.language;
        lang.Lang_Id = lang.id;
        lanJson.push({name: lang.Language, value: lang.Lang_Id});
      });
      setLanguages(lanJson);
    } catch (err) {
      showPopup(t("langError") + ": " + err, "error");
      let langJson = [{name: "English", value: 1}];
      setLanguages(langJson);
    }
    setLoaded(true);
  }
  // prep props for form fields
  const firstName = getFormProps("first_name", t("firstName"), "text");
  const lastName = getFormProps("last_name", t("lastName"), "text");
  const idNumber = getFormProps("identification", t("idNumber"), "text");
  const phoneNumber = getFormProps("phone", t("phoneNumber"), "phone");
  const houseNumber = getFormProps("house", t("houseNumber"), "number");
  const meterNumber = getFormProps("meter", t("meterNumber"), "text", "false");
  const gender = getFormProps("gender", t("gender"), "select", "true", "", "", [
    {name: t("male"), value: "M"},
    {name: t("female"), value: "F"},
  ]);
  const address = getFormProps("address", t("address"), "text");
  const siteCode = getFormProps(
    "site_code",
    t("siteCode"),
    "select",
    "true",
    "",
    t("siteCode"),
    sites
  );
  const language = getFormProps(
    "language",
    t("language"),
    "select",
    "true",
    "",
    "",
    languages
  );
  const dateOfBirth = getFormProps("dob", t("dateOfBirth"), "date");
  const trained = getFormProps("trained", t("trained"), "switch");
  const long = getFormProps(
    "longitude",
    t("longitude"),
    "number",
    "false",
    "",
    "",
    [],
    "false",
    "-180 to 180 degrees",
    "0.0001",
    "-180",
    "180"
  );
  const lat = getFormProps(
    "latitude",
    t("latitude"),
    "number",
    "false",
    "",
    "",
    [],
    "false",
    "-90 to 90 degrees",
    "0.0001",
    "-90",
    "90"
  );
  const tags = getFormProps("tags", t("tags"), "string", "false");

  // function to call when submitting form
  const onSubmit = (data) => {
    const customers = [{...data}];
    registerCustomer(user.selected, customers);
  };

  async function registerCustomer(portfolio, customers) {
    setDisable(true);
    setErrors([]);
    try {
      await billingAPI.registerCustomer(portfolio, customers);
      showPopup(t("shared:success"), "success");
      history.push("/customers");
    } catch (err) {
      showErrors(err, setErrors);
    }
    setDisable(false);
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4">
        <Row>
          <FormErrors errors={errors}></FormErrors>
          <FormField register={register} {...firstName}></FormField>
          <FormField register={register} {...lastName}></FormField>
          <FormField register={register} {...idNumber}></FormField>
          <FormField register={register} {...phoneNumber}></FormField>
          <FormField register={register} {...houseNumber}></FormField>
          <FormField register={register} {...meterNumber}></FormField>
          <FormSelect register={register} {...gender}></FormSelect>
          <FormField register={register} {...address}></FormField>
          <FormSelect register={register} {...siteCode}></FormSelect>
          <FormSelect register={register} {...language}></FormSelect>
          <FormField register={register} {...dateOfBirth}></FormField>
          <FormField register={register} {...lat}></FormField>
          <FormField register={register} {...long}></FormField>
          <FormField register={register} {...tags}></FormField>
          <FormCheck register={register} {...trained}></FormCheck>
          <CancelButton label={t("cancel")} to="/customers"></CancelButton>
          <RegisterButton disabled={disable} label={t("register")}></RegisterButton>
        </Row>
      </Form>
    </div>
  );
}
