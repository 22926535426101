import React, {useState, useEffect, useContext, useCallback} from "react";
import {useLocation, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Form, Table, Col, Button, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {parse, stringify} from "query-string";
import {usePermissions, Unauthorized} from "../permissions";
import {UserContext} from "../login/userContext";
import {ListHeader} from "../generics/headers";
import {FormSearchButton, ModalSubmitButton} from "../generics/buttons";
import {ListPagination} from "../generics/pagination";
import {showPopup, getHttpMessage, showErrors} from "../generics/alerts";
import {
  FormErrors,
  getFormProps,
  InlineFormField,
  InlineFormSelect,
} from "../generics/formsFields";
import * as billingAPI from "../../services/billingApi";
import Skeleton from "react-loading-skeleton";

export default function ProductPaymentPlanList() {
  const {t} = useTranslation("product");
  const canView = usePermissions(billingAPI.PRODUCT_PAYMENT_PLAN_PATH);
  const [limit, setLimit] = useState(50);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const history = useHistory();

  let query = parse(location.search);
  const updateQuery = (updates) => {
    query = Object.assign(query, updates);
    var search = stringify(query, {skipEmptyString: true});
    history.push("/products/payment-plans?" + search);
  };

  if (!canView) {
    return <Unauthorized />;
  }

  return (
    <div>
      <ListHeader title={t("productPaymentPlans")} icon="credit-card" />
      <ProductPaymentPlanSearchBar updateQuery={updateQuery} setLoaded={setLoaded} />
      <ProductPaymentPlanTable
        query={query}
        setCount={setCount}
        loaded={loaded}
        setLoaded={setLoaded}
      />
      <ListPagination
        page={page}
        count={count}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
      />
    </div>
  );
}

function ProductPaymentPlanSearchBar({updateQuery, setLoaded}) {
  const {t} = useTranslation(["product"]);
  const user = useContext(UserContext);
  const {register, handleSubmit} = useForm();
  const [products, setProducts] = useState([]);
  const [show, setShow] = useState(false);
  const canView = usePermissions(billingAPI.PRODUCT_PAYMENT_PLAN_PATH, "POST");

  useEffect(() => {
    async function fetchProductItems() {
      let ci = user.permissions[user.selected].countryID;
      try {
        let productMap = [];
        const resp = await billingAPI.getProductItems(user.selected, {country_id: ci});
        resp.data.data.products.forEach((product) => {
          productMap.push({
            name: product.product_code,
            value: product.id,
            key: product.id,
          });
        });
        setProducts(productMap);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
    }
    fetchProductItems();
  }, [user, setProducts]);

  const onSubmit = (data) => {
    updateQuery(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row className="mb-2">
        <Col md={4} className="mb-2">
          <Form.Control
            name="plan_name"
            placeholder="Plan Name"
            type="text"
            ref={register}
          ></Form.Control>
        </Col>
        <Col md={4} className="mb-2">
          <Form.Control name="product_id" as="select" ref={register} custom>
            <option value="">{t("selectProduct")}</option>
            {products.length
              ? products.map((p) => {
                  return <option {...p}>{p.name}</option>;
                })
              : null}
          </Form.Control>
        </Col>
        <Col xs={1}>
          <FormSearchButton register={register} handleSubmit={handleSubmit} />
        </Col>
        <Col xs={11} md={3}>
          <Button
            className="mb-1 pull-right"
            variant="danger"
            disabled={!canView}
            onClick={() => setShow(true)}
          >
            <FontAwesomeIcon icon="plus"></FontAwesomeIcon> {t("addNew")}
          </Button>
          <PaymentPlanModal show={show} setShow={setShow} setLoaded={setLoaded} />
        </Col>
      </Form.Row>
    </Form>
  );
}

function ProductPaymentPlanTable({query, setCount, loaded, setLoaded}) {
  const user = useContext(UserContext);
  const {t} = useTranslation(["product"]);
  const [paymentPlans, setPaymentPlans] = useState([]);
  const canClick = usePermissions(billingAPI.PRODUCT_PAYMENT_PLAN_UPDATE_PATH, "PATCH");
  query.country_id = user.permissions[user.selected].countryID;

  useEffect(() => {
    async function fetchPaymentPlans(query) {
      try {
        const resp = await billingAPI.getProductPaymentPlans(user.selected, query);
        setLoaded(true);
        setPaymentPlans(resp.data.data.payment_plans);
        setCount(resp.data.data.payment_plans.length);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
    }
    if (!loaded) fetchPaymentPlans(query);
  }, [query, user.selected, setCount, setPaymentPlans, loaded, setLoaded]);
  if (!paymentPlans || !loaded) {
    return <Skeleton height={40} count="4" className="m-3" />;
  }

  return (
    <Table striped bordered hover={canClick} className="shadow">
      <thead>
        <tr>
          <th>{t("planName")}</th>
          <th>{t("productName")}</th>
          <th>{t("productCode")}</th>
          <th>{t("price")}</th>
          <th>{t("depositAmount")}</th>
          <th>{t("monthlyPayment")}</th>
          <th>{t("energyTax")}</th>
          <th>{t("active")}</th>
        </tr>
      </thead>
      <tbody>
        {paymentPlans.length
          ? paymentPlans.map((p) => (
              <PaymentPlanRow data={p} key={p.id} setLoaded={setLoaded} />
            ))
          : null}
      </tbody>
    </Table>
  );
}

function PaymentPlanRow({data, setLoaded}) {
  const [show, setShow] = useState(false);
  return (
    <>
      <tr onClick={() => setShow(true)}>
        <td>{data.name}</td>
        <td>{data.product_name}</td>
        <td>{data.product_code}</td>
        <td>{data.price}</td>
        <td>{data.required_deposit}</td>
        <td>{data.monthly_instalment}</td>
        <td>{data.energy_tax}</td>
        <td>{data.active.toString()}</td>
      </tr>
      <PaymentPlanModal show={show} setShow={setShow} data={data} setLoaded={setLoaded} />
    </>
  );
}

function PaymentPlanModal({show, setShow, data, setLoaded}) {
  const {t} = useTranslation("product");
  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton className="text-secondary">
        <Modal.Title className="text-primary">
          <FontAwesomeIcon icon="credit-card" />{" "}
          {data ? data.name : t("paymentPlanRegistration")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-3 mt-3">
        <PaymentPlanForm setShow={setShow} data={data} setLoaded={setLoaded} />
      </Modal.Body>
    </Modal>
  );
}

export function PaymentPlanForm({setShow, data, setLoaded}) {
  const {t} = useTranslation("product");
  const user = useContext(UserContext);
  const {register, handleSubmit} = useForm();
  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);
  const [products, setProducts] = useState([]);
  const getProductID = useCallback(() => {
    let product = products.find((p) => p.name === data.product_code);
    return product ? product.value : "";
  }, [products, data]);

  useEffect(() => {
    async function fetchProductItems() {
      let ci = user.permissions[user.selected].countryID;
      setErrors([]);
      try {
        let productMap = [];
        const resp = await billingAPI.getProductItems(user.selected, {country_id: ci});
        resp.data.data.products.forEach((product) => {
          productMap.push({
            name: product.product_code,
            value: product.id,
            key: product.id,
          });
        });
        setProducts(productMap);
      } catch (err) {
        showErrors(err, setErrors);
      }
    }
    fetchProductItems();
  }, [user, setProducts]);

  const planName = getFormProps(
    "name",
    t("planName"),
    "text",
    "true",
    data ? data.name : null
  );

  const product = getFormProps(
    "product_id",
    t("product"),
    "select",
    "true",
    data && products ? getProductID(data.product_code) : null,
    t("selectProduct"),
    products
  );

  const price = getFormProps(
    "price",
    t("price"),
    "number",
    "true",
    data ? data.price : null
  );
  const deposit = getFormProps(
    "required_deposit",
    t("deposit"),
    "number",
    "true",
    data ? data.required_deposit : null
  );
  const instalment = getFormProps(
    "monthly_instalment",
    t("monthlyPayment"),
    "number",
    "true",
    data ? data.monthly_instalment : null
  );
  const tax = getFormProps(
    "energy_tax",
    t("energyTax"),
    "number",
    "true",
    data ? data.energy_tax : null,
    "",
    [],
    "false",
    "",
    "0.01",
    "0",
    "1"
  );
  const active = getFormProps(
    "active",
    t("active"),
    "select",
    "true",
    data ? data.active : null,
    "",
    [
      {value: true, name: "True"},
      {value: false, name: "False"},
    ]
  );

  const onSubmit = (formData) => {
    const payload = {
      id: data ? data.id : "",
      name: formData.name,
      product_id: formData.product_id,
      price: +formData.price,
      required_deposit: +formData.required_deposit,
      monthly_instalment: +formData.monthly_instalment,
      energy_tax: +formData.energy_tax,
      active: formData.active === "true",
    };
    data ? updatePaymentPlan(payload) : registerPaymentPlan(payload);
  };

  async function updatePaymentPlan(payload) {
    try {
      setSaving(true);
      setErrors([]);
      await billingAPI.updatePaymentPlan(user.selected, payload);
      showPopup(t("shared:success"), "success");
      setLoaded(false);
      setShow(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setSaving(false);
  }

  async function registerPaymentPlan(payload) {
    try {
      setSaving(true);
      setErrors([]);
      await billingAPI.registerPaymentPlans(user.selected, [payload]);
      showPopup(t("shared:success"), "success");
      setLoaded(false);
      setShow(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setSaving(false);
  }
  if (!products.length) {
    return <Skeleton height={40} width={400} count="6" className="m-3" />;
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormErrors errors={errors} />
      <InlineFormField {...planName} register={register} />
      <InlineFormSelect {...product} register={register} />
      <InlineFormField {...price} register={register} />
      <InlineFormField {...deposit} register={register} />
      <InlineFormField {...instalment} register={register} />
      <InlineFormField {...tax} register={register} />
      <InlineFormSelect {...active} register={register} />
      <ModalSubmitButton
        text={data ? t("save") : t("register")}
        icon={data ? "save" : "plus"}
        saving={saving}
        variant="danger"
      />
    </Form>
  );
}
