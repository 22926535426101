import React, {useState, useEffect, useContext, useCallback} from "react";
import {
  Table,
  Form,
  Col,
  Row,
  InputGroup,
  Button,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
  Modal,
  Card,
} from "react-bootstrap";
import {usePermissions, Unauthorized} from "../permissions";
import {UserContext} from "../login/userContext";
import {ListPagination} from "../generics/pagination";
import * as DateHelpers from "../../services/date";
import * as billingAPI from "../../services/billingApi";
import * as meteringAPI from "../../services/meteringApi";
import * as notificationApi from "../../services/notificationApi";
import * as alerts from "../generics/alerts";
import {useHistory, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {RedirectTransactionModal} from "./redirectTransactionModal";
import {CreateTransactionModal} from "./createTransactionModal";
import Skeleton from "react-loading-skeleton";

import {ListHeader} from "../generics/headers";
import {formatFileNameDate} from "../../services/date";
import {copyToClipboard} from "../../services/clipboard";

const queryString = require("query-string");

export default function TransactionLogs() {
  const canView = usePermissions(billingAPI.TRANSACTIONS_LIST_PATH);
  const location = useLocation(); //url query parameters
  const history = useHistory();
  let query = queryString.parse(location.search);
  const {t} = useTranslation(["transactions"]);

  const user = useContext(UserContext);
  const [transactions, setTransactions] = useState([]);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [gateways, setGateways] = useState([]);

  const updateQuery = (updates) => {
    query = Object.assign(query, updates);
    var params = queryString.stringify(query, {
      skipEmptyString: true,
    });

    history.push("/transactions?" + params);
  };

  let country_id = user.permissions[user.selected].countryID;

  const fetchTransactions = useCallback(
    async function () {
      setLoading(true);
      const searchParams = queryString.parse(location.search);
      searchParams.page = page;
      searchParams.country_id = country_id;
      await billingAPI
        .getTransactions(user.selected.toLowerCase(), limit, searchParams)
        .then((resp) => {
          setCount(resp.data.count);
          setTransactions(resp.data.data);
        })
        .catch((err) => {
          setTransactions([]);
          alerts.showPopup(alerts.getHttpMessage(err));
        });
      setLoading(false);
    },
    [user.selected, country_id, limit, page, setCount, setTransactions, location.search]
  );

  async function fetchAllTransactions() {
    const searchParams = queryString.parse(location.search);
    searchParams.limit = count;
    searchParams.country_id = country_id;
    let allData = [];
    //setting up progress bar
    setProgress(50);

    await billingAPI
      .getTransactions(user.selected.toLowerCase(), count, searchParams)
      .then((resp) => {
        allData.push(...resp.data.data);
        setProgress(100);
      })
      .catch((err) => {
        alerts.showPopup(alerts.getHttpMessage(err));
      });

    setProgress(0);
    return allData;
  }

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  useEffect(() => {
    async function fetchGateways() {
      try {
        const data = await billingAPI.getGateways(user.selected, {country_id});
        setGateways(data.data);
      } catch (ex) {
        alerts.showPopup(alerts.getHttpMessage(ex));
      }
    }
    fetchGateways();
  }, [user.selected, country_id]);

  //check if user has access to link
  if (!canView) {
    return <Unauthorized />;
  }

  return (
    <>
      <ListHeader title={t("customerTrans")} icon="credit-card" />
      <SearchForm
        updateQuery={updateQuery}
        query={query}
        gateways={gateways}
        fetchTransactions={fetchTransactions}
      />
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        data={transactions}
        progress={progress}
        filename={formatFileNameDate(new Date().toString()) + "_transactions.csv"}
        getAllData={fetchAllTransactions}
      />
      {loading ? (
        <Skeleton height={20} count="2" className="m-3" />
      ) : (
        <TransactionsTable
          transactions={transactions}
          fetchTransactions={fetchTransactions}
        />
      )}
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        data={transactions}
        progress={progress}
        filename={formatFileNameDate(new Date().toString()) + "_transactions.csv"}
        getAllData={fetchAllTransactions}
      />
    </>
  );
}

export function SearchForm({query, updateQuery, gateways, fetchTransactions}) {
  //internal state for the form
  const [startDate, setStartDate] = useState(query.start_date || "");
  const [endDate, setEndDate] = useState(query.end_date || "");
  const [status, setStatus] = useState(query.status || "");
  const [accountType, setAccountType] = useState(query.account_type || "");
  const [search, setSearch] = useState(query.search || "");
  const [mno, setMno] = useState(query.mno || "");
  const {t} = useTranslation(["transactions"]);

  const statusOptions = {
    new: t("new"),
    received: t("received"),
    sent_to_vendor: t("sentToVendor"),
    processed: t("processed"),
    queued: t("queued"),
    processing: t("processing"),
    processing_failed: t("processingFailed"),
    reversed: t("reversed"),
  };
  const accountTypeOptions = {
    ENERGY: t("energy"),
    PRODUCT: t("product"),
    OTHER: t("other"),
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateQuery({
      start_date: startDate,
      end_date: endDate,
      account_type: accountType,
      status,
      search,
      mno,
    });
  };

  return (
    <Form onSubmit={handleSubmit} className="mb-3">
      <Form.Row className="align-items-left">
        <Col md={12} lg={12}>
          <CreateTransactionModal refreshGrid={fetchTransactions} />
        </Col>
        <Col md={6} lg={3}>
          <InputGroup className="mb-1">
            <InputGroup.Prepend>
              <InputGroup.Text>{t("from")}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              id="from-date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              type="date"
            />
          </InputGroup>
        </Col>

        <Col md={6} lg={3}>
          <InputGroup className="mb-1">
            <InputGroup.Prepend>
              <InputGroup.Text>{t("to")}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              id="to-date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              type="date"
            />
          </InputGroup>
        </Col>

        <Col md={6} lg={3}>
          <Form.Control
            className="mb-1"
            id="all-transactions"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            as="select"
          >
            <option value="">{t("allTransactions")}</option>
            {Object.entries(statusOptions).map(([key, value]) => {
              return (
                <option key={key} value={key}>
                  {value}
                </option>
              );
            })}
          </Form.Control>
        </Col>

        <Col md={6} lg={3}>
          <Form.Control
            className="mb-1"
            id="account-type"
            onChange={(e) => setAccountType(e.target.value)}
            value={accountType}
            as="select"
          >
            <option value="">{t("allAccounts")}</option>

            {Object.entries(accountTypeOptions).map(([key, value]) => {
              return (
                <option key={key} value={key}>
                  {value}
                </option>
              );
            })}
          </Form.Control>
        </Col>

        <Col md={6} lg={3}>
          <Form.Control
            className="mb-1"
            id="gateways"
            onChange={(e) => setMno(e.target.value)}
            value={mno}
            as="select"
          >
            <option value="">{t("allMNO")}</option>

            {gateways &&
              gateways.length > 0 &&
              gateways.map((gateway) => {
                return (
                  <option key={gateway.id} value={gateway.name}>
                    {gateway.name}
                  </option>
                );
              })}
          </Form.Control>
        </Col>

        <Col xs={12} md={3}>
          <Form.Control
            className="mb-1"
            type="text"
            name="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("accountRef")}
          ></Form.Control>
        </Col>

        <Col>
          <Button
            data-testid="submit_button"
            variant="primary"
            type="submit"
            className="mb-1"
          >
            <FontAwesomeIcon icon="search"></FontAwesomeIcon>
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}

function TransactionActions({transaction, refreshGrid}) {
  const {t} = useTranslation(["transactions", "shared"]);
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const canReprocess =
    usePermissions(billingAPI.REPROCESS_TRANS, "PATCH") &&
    transaction.status !== "processed";
  const canReverse =
    usePermissions(meteringAPI.REVERSE_TRANS, "POST") &&
    transaction.status === "processed";
  const canRedirect =
    usePermissions(billingAPI.REDIRECT_TRANS, "POST") &&
    transaction.status === "processed";

  //check if user has any action
  const userHasActions = canReprocess || canReverse || canRedirect;

  async function reverse() {
    if (window.confirm(t("reverseConfirm"))) {
      setIsLoading(true);
      await alerts.handleHttp(() =>
        meteringAPI.reverseTransaction(user.selected, transaction)
      );
      refreshGrid();
      setIsLoading(false);
    }
  }

  async function reprocess() {
    if (window.confirm(t("reprocessConfirm"))) {
      setIsLoading(true);
      await alerts.handleHttp(() =>
        billingAPI.reprocessTransaction(user.selected, transaction.payment_id)
      );
      refreshGrid();
      setIsLoading(false);
    }
  }

  async function resendToken() {
    let sms = {
      recipients: [transaction.phone_number],
      search_params: "",
      message: transaction.token,
      gateway_id: "",
      is_token: true,
    };
    if (window.confirm(t("confirmResendToken"))) {
      setIsLoading(true);
      await alerts.handleHttp(() => notificationApi.sendSms(user.selected, sms));
      setIsLoading(false);
    }
  }

  return (
    <div title={userHasActions ? "" : t("noActionPermission")}>
      <DropdownButton
        variant="primary"
        id="dropdown-basic"
        size="sm"
        drop="right"
        title={isLoading ? t("saving") : t("actions")}
        disabled={!userHasActions || isLoading}
      >
        <Dropdown.Item
          as="button"
          onClick={() => setModalShow(true)}
          className={canRedirect ? "" : "d-none"}
        >
          <FontAwesomeIcon icon="share" /> {t("redirect")}
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          onClick={reprocess}
          className={canReprocess ? "" : "d-none"}
        >
          <FontAwesomeIcon icon="sync-alt" /> {t("reprocess")}
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          onClick={reverse}
          className={canReverse ? "" : "d-none"}
        >
          <FontAwesomeIcon icon="history" /> {t("reverse")}
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          onClick={resendToken}
          className={transaction.token ? "" : "d-none"}
        >
          <FontAwesomeIcon icon="fa-comment-dollar" /> {t("resendToken")}
        </Dropdown.Item>
      </DropdownButton>

      <RedirectTransactionModal
        show={modalShow}
        setShow={setModalShow}
        transaction={transaction}
        refreshGrid={refreshGrid}
      />
    </div>
  );
}

export function TransactionsTable({transactions, fetchTransactions}) {
  const {t} = useTranslation(["transactions"]);

  return (
    <Table striped bordered hover size="l">
      <thead>
        <tr>
          <th>{t("datetime")}</th>
          <th>{t("accountNo")}</th>
          <th>{t("amount")}</th>
          <th>{t("referenceNo")}</th>
          <th>{t("status")}</th>
          <th>{t("accountType")}</th>
          <th>{t("action")}</th>
        </tr>
      </thead>
      <tbody>
        {transactions.length ? (
          transactions.map((trans) => {
            return (
              <TransactionsRow
                key={trans.payment_id}
                transaction={trans}
                fetchTransactions={fetchTransactions}
              />
            );
          })
        ) : (
          <tr></tr>
        )}
      </tbody>
    </Table>
  );

  function TransactionsRow({transaction, fetchTransactions}) {
    const refreshGrid = () => fetchTransactions();
    const [show, setShow] = useState(false);
    const onClick = () => setShow(true);

    return (
      <tr>
        <td onClick={onClick}>
          {DateHelpers.formatDisplayDate(transaction.date_created_utc).toString()}
        </td>
        <td onClick={onClick}>{transaction.account_no}</td>
        <td onClick={onClick}>{transaction.amount}</td>
        <td onClick={onClick}>{transaction.reference}</td>
        <td onClick={onClick}>{transaction.status}</td>
        <td onClick={onClick}>{transaction.account_type}</td>
        <td>
          <TransactionActions refreshGrid={refreshGrid} transaction={transaction} />
        </td>
        <DetailsModal transaction={transaction} show={show} setShow={setShow} />
      </tr>
    );
  }
}

function DetailsModal({transaction, show, setShow}) {
  const handleClose = () => setShow(false);
  const {t} = useTranslation(["transactions", "shared"]);

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <h4 className="text-primary">
          {t("transaction", {ref: transaction.reference})}{" "}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <TransactionDetails transaction={transaction} />
      </Modal.Body>
    </Modal>
  );
}

function TransactionDetails({transaction}) {
  const {t} = useTranslation(["transactions", "shared"]);
  const [copied, setCopied] = useState(false);
  const date =
    transaction.date_paid === "0001-01-01T00:00:00Z"
      ? transaction.date_created_utc
      : transaction.date_paid;
  const tooltip = (
    <Tooltip id="tooltip">{copied ? t("copySuccess") : t("copyToken")}</Tooltip>
  );

  const copyToken = () => {
    copyToClipboard(transaction.token);
    setCopied(true);
  };

  return (
    <Card border="white">
      {/* show notes if transaction is not processed and note are available */}
      {transaction.status !== "processed" && transaction.notes ? (
        <div>
          <Card.Title>{t("notes")}</Card.Title>
          <Card.Text className="text-danger">{transaction.notes}</Card.Text>
        </div>
      ) : (
        <span />
      )}
      {transaction.token && (
        <div className="mb-3">
          <Card.Title>{t("token")}</Card.Title>
          <Row>
            <Col md={6}>
              <Card.Text>{transaction.token}</Card.Text>
            </Col>
            <Col md={1}>
              <OverlayTrigger placement="top" overlay={tooltip}>
                <FontAwesomeIcon onClick={copyToken} icon="copy" />
              </OverlayTrigger>
            </Col>
          </Row>
        </div>
      )}
      <Card.Title>{t("mno")}</Card.Title>
      <Card.Text>{transaction.mno}</Card.Text>
      <Card.Title>{t("organization")}</Card.Title>
      <Card.Text>{transaction.organization}</Card.Text>
      <Card.Title>{t("datePaid")}</Card.Title>
      <Card.Text>{DateHelpers.formatDisplayDate(date).toString()}</Card.Text>
      <Card.Title>{t("accountNo")}</Card.Title>
      <Card.Text>{transaction.account_no}</Card.Text>
      <Card.Title>{t("accountType")}</Card.Title>
      <Card.Text>{transaction.account_type}</Card.Text>
      <Card.Title>{t("phoneNumber")}</Card.Title>
      <Card.Text>{transaction.phone_number}</Card.Text>
      <Card.Title>{t("amount")}</Card.Title>
      <Card.Text>{transaction.amount}</Card.Text>
      <Card.Title>{t("paymentID")}</Card.Title>
      <Card.Text>{transaction.payment_id}</Card.Text>
      <Card.Title>{t("status")}</Card.Title>
      <Card.Text>{transaction.status}</Card.Text>
      <Card.Title>{t("notes")}</Card.Title>
      <Card.Text>{transaction.notes}</Card.Text>
    </Card>
  );
}
