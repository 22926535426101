import React, {useEffect, useState, useContext} from "react";
import getSitesBonuses from "../../services/bonusApi";
import {UserContext} from "../login/userContext";
import {formatDisplayDate} from "../../services/date";
import {getSites} from "../../services/meteringApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Table, Form, Button, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import Pagination from "react-js-pagination";
import {ScheduleBonusModal} from "./scheduleBonus";
const queryString = require("query-string");

export default function BonusList() {
  const [totalCount, setTotalCount] = useState(0); //Total payments in the database
  const user = useContext(UserContext);
  const location = useLocation(); //url query parameters
  const history = useHistory();
  const COUNT_PER_PAGE = 50;

  let query = queryString.parse(location.search);
  query.country_id = user.permissions[user.selected].countryID;
  let activePage = parseInt(query.page);

  const updateQuery = (updates) => {
    query = Object.assign(query, updates);
    var params = queryString.stringify(query, {
      skipEmptyString: true,
    });

    history.push("/bonuses?" + params);
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col xs={6}>
          <h3 className="text-primary d-inline">Bonuses</h3>
        </Col>

        <Col xs={6}>
          <ScheduleBonusModal setTotalCount={setTotalCount} />
        </Col>
      </Row>
      <SearchForm updateQuery={updateQuery} query={query} user={user} />
      <Pagination
        activePage={activePage}
        itemsCountPerPage={COUNT_PER_PAGE}
        totalItemsCount={totalCount}
        pageRangeDisplayed={5}
        onChange={(pageNumber) => updateQuery({page: pageNumber})}
        itemClass="page-item"
        linkClass="page-link"
      />
      <Bonuses
        query={query}
        countPerPage={COUNT_PER_PAGE}
        selected={user.selected.toLowerCase()}
        setTotalCount={setTotalCount}
        totalCount={totalCount}
      />
    </React.Fragment>
  );
}

const Bonuses = ({query, countPerPage, selected, totalCount, setTotalCount}) => {
  const [bonuses, setBonuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation(["bonus"]);

  useEffect(() => {
    async function getBonuses() {
      try {
        const resp = await getSitesBonuses(selected, query, countPerPage);
        setTotalCount(resp.data.count);
        setBonuses(resp.data.data);
        setLoading(false);
      } catch (error) {
        throw error;
      }
    }
    getBonuses();
  }, [selected, query, totalCount, countPerPage, setTotalCount]);

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return (
    <Table response="sm" hover>
      <thead>
        <tr>
          <th>{t("siteName")}</th>
          <th>{t("bonusName")}</th>
          <th>{t("fromDate")}</th>
          <th>{t("thruDate")}</th>
          <th>{t("bonusStatus")}</th>
        </tr>
      </thead>
      <tbody>
        {bonuses ? (
          bonuses.map((b) => <BonusRow bonus={b} key={b.site_bonus_id} />)
        ) : (
          <tr>
            <td>
              <p>No records found</p>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
const BonusRow = ({bonus}) => {
  return (
    <tr>
      <td>{bonus.site_name}</td>
      <td>{bonus.bonus_name}</td>
      <td>{formatDisplayDate(bonus.from_date)}</td>
      <td>{formatDisplayDate(bonus.thru_date)}</td>
      <td>{bonus.status}</td>
    </tr>
  );
};

const SearchForm = ({user, updateQuery, query}) => {
  const [siteid, setSiteid] = useState(query.siteid);
  const [status, setStatus] = useState(query.status);
  const [sites, setSites] = useState([]);
  const {t} = useTranslation(["bonus"]);

  const bonusStatus = [
    {id: 1, status: "SCHEDULED"},
    {id: 2, status: "ACTIVE"},
    {id: 3, status: "FINISHED"},
    {id: 4, status: "DEACTIVATED"},
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    updateQuery({siteid, status});
  };

  useEffect(() => {
    const getSitesList = async () => {
      let ci = user.permissions[user.selected].countryID;
      try {
        const sites = (await getSites(user.selected, {status: "active", country_id: ci}))
          .data.data;
        setSites(sites);
      } catch (error) {
        throw error;
      }
    };
    getSitesList();
  }, [query, user]);

  return (
    <Form onSubmit={onSubmit}>
      <Row className="mb-sm-3">
        <Col xs={12} sm={3} className="mb-3 sm-mb-0">
          <Form.Control
            as="select"
            name="siteid"
            value={siteid}
            onChange={(e) => setSiteid(e.target.value)}
          >
            <option value="">All sites</option>
            {sites.length > 0
              ? sites.map(({site_id, site_name}) => (
                  <option key={site_id} value={site_id}>
                    {site_name}
                  </option>
                ))
              : null}
          </Form.Control>
        </Col>
        <Col xs={12} sm={4} xl={2} className="mb-3 sm-mb-0">
          <Form.Control
            as="select"
            name="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">All statuses</option>
            {bonusStatus.map(({id, status}) => (
              <option key={id} value={status}>
                {t(status)}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col className="mb-3 sm-mb-0">
          <Button type="submit">
            <FontAwesomeIcon icon="search" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
