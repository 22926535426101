import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import * as DateHelpers from "../../services/date";
import {Table, Button, Modal, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./sms.scss";
import * as NotificationApi from "../../services/notificationApi";
import {getHttpMessage, showPopup} from "../generics/alerts";
import {UserContext} from "./../login/userContext";

export function SMSActionButtons(props) {
  const user = useContext(UserContext);
  const [sending, setSending] = useState(false);
  const {t} = useTranslation(["sms"]);
  const handleResend = (info) => {
    const sms = {
      recipients: [info.PhoneNumber],
      message: info.Message,
    };
    if (window.confirm(t("resendConfirm"))) {
      resendSMS(user.selected, sms);
    }
  };
  async function resendSMS(portfolio, sms) {
    try {
      setSending(true);
      await NotificationApi.sendSms(portfolio, sms);
      showPopup(t("sendSuccess"), "success");
    } catch (err) {
      showPopup(getHttpMessage(err));
    }
    setSending(false);
  }
  if (props.type.SmsType.toUpperCase() === "OUTGOING") {
    return (
      <Button
        size="sm"
        className="mr-1"
        disabled={sending}
        onClick={() => handleResend(props.type)}
      >
        <FontAwesomeIcon icon="sync-alt" /> {sending ? t("sending") : t("resend")}
      </Button>
    );
  }
  return "";
}

export function SmsLogsTable({smslogs}) {
  const {t} = useTranslation(["sms"]);
  return (
    <Table striped bordered hover size="l" className="shadow">
      <thead>
        <tr>
          <th>{t("date")}</th>
          <th>{t("phoneNumber")}</th>
          <th>{t("smsType")}</th>
          <th>{t("message")}</th>
          <th>{t("status")}</th>
          <th>{t("actions")}</th>
        </tr>
      </thead>
      {smslogs ? (
        <tbody>
          {smslogs.map((sms) => {
            return <SMSRow key={sms.SMS_Id} sms={sms} />;
          })}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td>{t("noRecordsFound")}</td>
          </tr>
        </tbody>
      )}
    </Table>
  );
}

export function SMSRow({sms}) {
  const [show, setShow] = useState(false);
  const onClick = () => setShow(true);

  return (
    <tr>
      <td onClick={onClick} className="smsDateColumn">
        {DateHelpers.formatDisplayDate(sms.Date).toString()}
      </td>
      <td onClick={onClick}>
        {sms.SmsType === "INCOMING" ? sms.Sender : sms.PhoneNumber}
      </td>
      <td onClick={onClick}>{sms.SmsType}</td>
      <td onClick={onClick} className="smsMessageColumn">
        {sms.Message}
      </td>
      <td onClick={onClick}>{sms.Status}</td>
      <td onClick={onClick} className="smsResendButtonColumn">
        <SMSActionButtons type={sms} />
      </td>
      <DetailsModal sms={sms} show={show} setShow={setShow} />
    </tr>
  );
}

function DetailsModal({sms, show, setShow}) {
  const handleClose = () => setShow(false);
  const {t} = useTranslation(["sms", "shared"]);

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <h4 className="text-primary">{t("smsID", {ref: sms.SMS_Id})} </h4>
      </Modal.Header>
      <Modal.Body>
        <Card border="white">
          <Card.Title>{t("date")}</Card.Title>
          <Card.Text>{DateHelpers.formatDisplayDate(sms.Date).toString()}</Card.Text>
          <Card.Title>{t("smsType")}</Card.Title>
          <Card.Text>{sms.SmsType}</Card.Text>
          <Card.Title>{t("phoneNumber")}</Card.Title>
          <Card.Text>{sms.PhoneNumber}</Card.Text>
          <Card.Title>{t("message")}</Card.Title>
          <Card.Text>{sms.Message}</Card.Text>
          <Card.Title>{t("status")}</Card.Title>
          <Card.Text>{sms.Status}</Card.Text>
          <Card.Title>{t("notes")}</Card.Title>
          <Card.Text>{sms.Notes}</Card.Text>
          <Card.Title>{t("referenceID")}</Card.Title>
          <Card.Text>{sms.ReferenceId}</Card.Text>
        </Card>
      </Modal.Body>
    </Modal>
  );
}
