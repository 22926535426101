import React, {useContext} from "react";
import {UserContext} from "./login/userContext";
import {Row} from "react-bootstrap";

// permissions hook determines if user has access to required endpoint
export function usePermissions(path, verb) {
  verb = verb || "GET";
  const user = useContext(UserContext);
  const permissions = user.permissions[user.selected];

  if (!permissions) return false;

  return permissions
    .filter((p) => p.endpoint === path)
    .map((p) => p.verb)
    .includes(verb);
}

export function Unauthorized() {
  return (
    <Row>
      <h1 className=" text-primary">
        Sorry, you do not have permission to access this page
      </h1>
    </Row>
  );
}
