import React, {useEffect, useState, useContext} from "react";
import {Col, Table, Form} from "react-bootstrap";
import {usePermissions, Unauthorized} from "../permissions";
import * as billingAPI from "../../services/billingApi";
import {UserContext} from "./../login/userContext";
import {ListHeader} from "../generics/headers";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {ListPagination} from "../generics/pagination";
import Register from "./register";
import Skeleton from "react-loading-skeleton";
const queryString = require("query-string");

export default function CollectionList() {
  const canView = usePermissions(billingAPI.COLLECTION_ACCOUNTS_LIST_PATH);
  const {t} = useTranslation(["collection", "shared"]);
  const location = useLocation();
  const history = useHistory();
  const user = useContext(UserContext);
  const [collections, setCollections] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  let query = queryString.parse(location.search);
  const searchQuery = (updates) => {
    query = Object.assign(query, updates);
    var params = queryString.stringify(query, {
      skipEmptyString: true,
    });
    history.push("/agents?" + params);
  };

  useEffect(() => {
    async function getCollectionAccounts() {
      let ci = user.permissions[user.selected].countryID;
      const searchParams = queryString.parse(location.search);
      searchParams.page = page;
      searchParams.limit = limit;
      searchParams.country_id = ci;
      await billingAPI
        .getCollectionAccounts(user.selected.toLowerCase(), searchParams)
        .then((resp) => {
          setCount(resp.data.count);
          setCollections(resp.data.data);
          setLoading(false);
        })
        .catch((err) => {
          setCollections([]);
          showPopup(getHttpMessage(err));
        });
    }
    getCollectionAccounts();
  }, [user, limit, loading, page, location.search, setCollections, setLoading, setCount]);

  if (!canView) {
    return <Unauthorized />;
  }

  return (
    <div>
      <ListHeader title={t("listTitle")} icon="store-alt"></ListHeader>
      <SearchForm
        query={query}
        searchQuery={searchQuery}
        setLoading={setLoading}
        loading={loading}
      />
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
      />
      {loading ? (
        <Skeleton height={20} count="2" className="m-3" />
      ) : (
        <CollectionTable
          page={page}
          limit={limit}
          setCount={setCount}
          customers={collections}
        />
      )}{" "}
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
      />
    </div>
  );
}

function SearchForm({query, searchQuery, setLoading, loading}) {
  const {t} = useTranslation(["collection", "shared"]);
  const placeholder = t("searchCollection");
  return (
    <Form>
      <Form.Row className="mb-3">
        <Col xs={8} className="mb-1">
          <Form.Control
            id="search-form-collection"
            type="text"
            placeholder={placeholder}
            onChange={(e) => {
              searchQuery({search: e.target.value});
            }}
            name="search"
            defaultValue={query.search_value}
            className="mr-2"
          ></Form.Control>
        </Col>
        <Col xs={4}>
          <Register setLoading={setLoading} loading={loading} />
        </Col>
      </Form.Row>
    </Form>
  );
}

function CollectionTable({customers}) {
  const {t} = useTranslation(["collection", "shared"]);
  return (
    <Table striped bordered className="shadow" response="sm" hover>
      <thead>
        <tr>
          <th>{t("firstName")}</th>
          <th>{t("lastName")}</th>
          <th>{t("phoneNumber")}</th>
          <th>{t("accountNo")}</th>
          <th>{t("siteCode")}</th>
        </tr>
      </thead>
      <tbody>
        {customers.map((c) => (
          <CollectionRow customer={c} key={c.id} />
        ))}
      </tbody>
    </Table>
  );
}

function CollectionRow({customer}) {
  const link = `/customers/${customer.customer_id}`;
  const history = useHistory();
  function onClick() {
    history.push(link);
  }
  return (
    <tr onClick={onClick}>
      <td>{customer.first_name}</td>
      <td>{customer.last_name}</td>
      <td>{customer.phone_number}</td>
      <td>{customer.account_no}</td>
      <td>{customer.site_code}</td>
    </tr>
  );
}
