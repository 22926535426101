import React from "react";
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import MergeTagForm from "./mergeTagForm";

export default function MergeTagModal({user, show, setShow, tagToMerge, setReload}) {
  const {t} = useTranslation(["tags", "shared"]);

  return (
    <Modal show={show} onHide={() => setShow(false)} size="md">
      <Modal.Header closeButton>
        <Modal.Title className="text-primary ml-2">
          <FontAwesomeIcon icon="compress-alt"></FontAwesomeIcon>
          {"   " + t("shared:merge")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MergeTagForm
          tagToMerge={tagToMerge}
          user={user}
          setShow={setShow}
          setReload={setReload}
        />
      </Modal.Body>
    </Modal>
  );
}
