import React from "react";
import {useTranslation} from "react-i18next";
import {PortfolioModal} from "./modal";

export default function UpdatePortfolio({portfolio, show, setShow, refresh, setRefresh}) {
  const {t} = useTranslation(["portfolios", "shared"]);
  return (
    <PortfolioModal
      show={show}
      setShow={setShow}
      title={t("updatePortfolio")}
      portfolio={portfolio}
      refresh={refresh}
      setRefresh={setRefresh}
    />
  );
}
