import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as authApi from "../../services/authApi";
import {usePermissions} from "../permissions";
import {PortfolioModal} from "./modal";

export default function RegisterPortfolio(refresh, setRefresh) {
  const canView = usePermissions(authApi.PORTFOLIO_PATH, "POST");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const {t} = useTranslation(["portfolios", "shared"]);

  return (
    <div>
      <Button
        disabled={!canView}
        title={!canView ? t("registerPermission") : null}
        variant="danger"
        onClick={handleShow}
        className="text-white float-right"
      >
        <FontAwesomeIcon icon="plus" /> {t("addNew")}
      </Button>

      <PortfolioModal
        show={show}
        setShow={setShow}
        title={t("addNewPortfolio")}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </div>
  );
}
