import React, {useState, useEffect, useContext, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {usePermissions, Unauthorized} from "../permissions";
import {UserContext} from "../login/userContext";
import * as billingAPI from "../../services/billingApi";
import Pagination from "react-js-pagination";
import {Row, Table, Col, Form} from "react-bootstrap";
import {faCartPlus} from "@fortawesome/free-solid-svg-icons";
import {ListHeader} from "../generics/headers";
import RegisterItem from "./registerItem";
import UpdateProduct from "./productUpdateModal";

export default function ProductItem() {
  const {t} = useTranslation(["product"]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [productLists, setProductLists] = useState([]);
  const user = useContext(UserContext);
  let countryID = user.permissions[user.selected].countryID;

  const canView = usePermissions(billingAPI.PRODUCT_ITEMS_LIST_PATH);

  const fetchProductsList = useCallback(
    async function () {
      try {
        const resp = await billingAPI.getProductItemList(user.selected, limit, {
          country_id: countryID,
          page,
        });
        const respondData = resp.data.data.products;
        setProductLists(respondData);
        setCount(resp.data.data.count);
      } catch (e) {
        console.error(e);
      }
    },
    [user.selected, countryID, limit, page]
  );
  useEffect(() => {
    fetchProductsList();
  }, [fetchProductsList]);
  if (!canView) {
    return <Unauthorized />;
  }
  return (
    <div>
      <Row className="mb-3">
        <ListHeader title={t("productListTitle")} icon={faCartPlus} />
      </Row>
      <Row>
        <Col>
          <ProductSearchForm setSearch={setSearch} />
        </Col>
        <Col xs={12} md={3}>
          <RegisterItem fetchProductsList={fetchProductsList} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-dark">
          <ProductItemList
            limit={limit}
            page={page}
            setCount={setCount}
            search={search}
            productLists={productLists}
            fetchProductsList={fetchProductsList}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={3}>
          <Form.Control
            as="select"
            onChange={(e) => {
              setLimit(Number(e.target.value));
            }}
          >
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </Form.Control>
        </Col>
        <Col md={6}></Col>
        <Col xs={12} md={3}>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={count}
            pageRangeDisplayed={5}
            onChange={(pageNumber) => setPage(pageNumber)}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText={t("firstPage")}
            lastPageText={t("lastPage")}
          />
        </Col>
      </Row>
    </div>
  );
}

function ProductItemList({
  limit,
  page,
  setCount,
  search,
  productLists,
  fetchProductsList,
}) {
  const {t} = useTranslation(["product"]);
  const [filteredProductLists, setFilteredProductLists] = useState(productLists);

  useEffect(() => {
    setFilteredProductLists(
      productLists.filter(
        (productList) =>
          productList.product_name.toLowerCase().includes(search.toLowerCase()) ||
          productList.product_code.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, productLists]);

  return (
    <Table response="sm" striped bordered hover responsive>
      <thead>
        <tr>
          <th>{t("ProductName")}</th>
          <th>{t("productCode")}</th>
          <th>{t("productDescription")}</th>
        </tr>
      </thead>
      <tbody>
        {filteredProductLists.map((productList) => (
          <ProductListRow
            productList={productList}
            key={productList.id}
            fetchProductsList={fetchProductsList}
          />
        ))}
      </tbody>
    </Table>
  );
}

function ProductListRow({productList, fetchProductsList}) {
  const canUpdate = usePermissions(billingAPI.PRODUCT_ACCOUNTS_LIST_PATH, "PATCH");
  const [show, setShow] = useState(false);
  const clicked = () => {
    canUpdate ? setShow(true) : setShow(false);
  };
  return (
    <>
      <tr onClick={clicked}>
        <td>{productList.product_name}</td>
        <td>{productList.product_code}</td>
        <td>{productList.description}</td>
      </tr>
      <UpdateProduct
        product={productList}
        show={show}
        setShow={setShow}
        fetchProductsList={fetchProductsList}
      />
    </>
  );
}

function ProductSearchForm({setSearch}) {
  const {t} = useTranslation(["product"]);

  return (
    <Form className="mb-3">
      <Form.Row>
        <Col xs={12} md={3}>
          <Form.Control
            className="mr-2"
            type="text"
            name="Search"
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("productName")}
          />
        </Col>
        <Col xs={12} md={3}>
          <Form.Control
            className="mr-2"
            type="text"
            name="Search"
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("productCode")}
          />
        </Col>
      </Form.Row>
    </Form>
  );
}
