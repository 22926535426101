import React, {useState, useContext, useEffect} from "react";
import {Card, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import {UserContext} from "../login/userContext";
import {usePermissions, Unauthorized} from "../permissions";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {FileUpload, Instructions, ErrorList} from "../generics/fileUploads";
import * as meteringAPI from "../../services/meteringApi";

export default function ChangeTariff() {
  const {t} = useTranslation(["fileupload", "shared"]);
  const user = useContext(UserContext);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0); // %
  const [errors, setErrors] = useState([]);
  const [tariffs, setTariffs] = useState([]);
  const [tariffID, setTariffID] = useState("");
  const canView = usePermissions(meteringAPI.ASSIGN_TARIFF_PATH, "POST");

  //get tariff options
  useEffect(() => {
    async function getTariffs() {
      try {
        const resp = await meteringAPI.getTariffs(user.selected, {});
        const tariffsJson = resp.data.data.map((tariff) => {
          return {name: tariff.name, value: tariff.id};
        });
        setTariffs(tariffsJson);
      } catch (err) {
        showPopup(t("tariffsError") + ": " + err);
      }
    }
    getTariffs();
  }, [t, user.selected]);

  if (!canView) {
    return <Unauthorized />;
  }

  //sample file
  const filename = "sample_tariff_assign.csv";
  const headers = "account_number";
  const sampleData = [["account_number"], ["00400002"], ["232-1-40"], ["00620002"]];

  //instructions
  const pageTitle = t("tariffChangeTitle");
  const reminders = [t("existAccount"), t("activeAccount")];

  async function changeTariff(data, config) {
    let payload = {};
    let accounts = [];
    if (tariffID.length > 0) {
      payload.tariff_id = tariffID;
    } else {
      setErrors([t("selectTariffError")]);
      setProgress(0);
      return;
    }
    if (data.length > 0) {
      data.forEach((acc) => {
        accounts.push(acc.account_number);
      });
      payload.account_nos = accounts;
    } else {
      setErrors([t("emptyList")]);
      setProgress(0);
      return;
    }
    try {
      await meteringAPI.assignTariff(user.selected, payload, config);
      showPopup(t("shared:successWithWait"), "success");
      resetStates();
    } catch (err) {
      setErrors(getHttpMessage(err));
      setProgress(0);
    }
  }

  const resetStates = () => {
    setProgress(0);
    setData([]);
    setLoaded(false);
    setErrors([]);
  };

  return (
    <div>
      <Card className="shadow">
        <Card.Body className="p-4">
          <Instructions
            title={pageTitle}
            isLoaded={isLoaded}
            headers={headers}
            filename={filename}
            sampleData={sampleData}
            reminders={reminders}
          />

          <Form.Group controlId="">
            <Form.Label>{t("selectTariff")} </Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                setTariffID(e.target.value);
              }}
              custom
            >
              <option id="select_tariff" value="">
                {t("select")}
              </option>
              {tariffs && tariffs.length > 0
                ? tariffs.map((tariff) => {
                    return (
                      <option key={tariff.value} value={tariff.value} id={tariff.value}>
                        {tariff.name}
                      </option>
                    );
                  })
                : null}
            </Form.Control>
          </Form.Group>

          <FileUpload
            sampleData={sampleData}
            data={data}
            errors={errors}
            progress={progress}
            setData={setData}
            setErrors={setErrors}
            setProgress={setProgress}
            setLoaded={setLoaded}
            setColumns={setColumns}
            sendToAPI={changeTariff}
            processButtonName={t("switchTariff")}
            handleCancel={resetStates}
          />

          <ErrorList errors={errors} handleReUpload={resetStates} />

          <DataTable
            pagination
            highlightOnHover
            columns={columns}
            data={data}
            noDataComponent=""
          />
        </Card.Body>
      </Card>
    </div>
  );
}
