import {library} from "@fortawesome/fontawesome-svg-core";
import {faFlag, faSearch} from "@fortawesome/free-solid-svg-icons";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons";
import {faHistory} from "@fortawesome/free-solid-svg-icons";
import {faDirections} from "@fortawesome/free-solid-svg-icons";
import {faWifi} from "@fortawesome/free-solid-svg-icons";
import {faFileImport} from "@fortawesome/free-solid-svg-icons";
import {faDatabase} from "@fortawesome/free-solid-svg-icons";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons";
import {faShare} from "@fortawesome/free-solid-svg-icons";
import {faTv} from "@fortawesome/free-solid-svg-icons";
import {faSms} from "@fortawesome/free-solid-svg-icons";
import {faCommentAlt} from "@fortawesome/free-solid-svg-icons";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {faMoneyBillAlt} from "@fortawesome/free-solid-svg-icons";
import {faCashRegister} from "@fortawesome/free-solid-svg-icons";
import {faToggleOn} from "@fortawesome/free-solid-svg-icons";
import {faCartPlus} from "@fortawesome/free-solid-svg-icons";
import {
  faUpload,
  faUserMinus,
  faTimesCircle,
  faDollarSign,
  faEnvelopeOpen,
  faTags,
  faPencilAlt,
  faCompressAlt,
  faUserTag,
  faStoreAlt,
  faBan,
  faFileExport,
  faEarthAfrica,
  faCommentDollar,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faSearch,
  faMapMarkerAlt,
  faSyncAlt,
  faPlus,
  faAngleDown,
  faUsers,
  faUser,
  faEdit,
  faTrashAlt,
  faSave,
  faSync,
  faHome,
  faMinusCircle,
  faHistory,
  faDirections,
  faWifi,
  faFileImport,
  faDatabase,
  faDownload,
  faCreditCard,
  faShare,
  faTv,
  faSms,
  faCommentAlt,
  faCopy,
  faMoneyBillAlt,
  faCashRegister,
  faUpload,
  faUserMinus,
  faTimesCircle,
  faDollarSign,
  faUserMinus,
  faToggleOn,
  faEnvelopeOpen,
  faTags,
  faPencilAlt,
  faCompressAlt,
  faUserTag,
  faStoreAlt,
  faBan,
  faFileExport,
  faFlag,
  faCartPlus,
  faEarthAfrica,
  faCommentDollar
);
