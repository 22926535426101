import React, {useState, useContext} from "react";
import {Card} from "react-bootstrap";
import {Form, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import {UserContext} from "../login/userContext";
import {usePermissions, Unauthorized} from "../permissions";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {FileUpload, Instructions, ErrorList} from "../generics/fileUploads";
import * as billingAPI from "../../services/billingApi";

export default function DisburseBonus() {
  const {t} = useTranslation(["fileupload", "shared"]);
  const user = useContext(UserContext);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [bonusAmount, SetBonusAmount] = useState(0);
  const [errors, setErrors] = useState([]);
  const canView = usePermissions(billingAPI.BONUS_DISBURSEMENT_PATH, "POST");

  if (!canView) {
    return <Unauthorized />;
  }

  //sample file
  const filename = "sample_bonus_payment.csv";
  const headers = "account_number";
  const sampleData = [["account_number"], ["00400001"], ["232-2-1"]];

  //instructions
  const pageTitle = t("bonusCardTitle");
  const reminders = [t("existAccount"), t("activeAccount"), t("shared:waitAfterUpload")];

  async function disburseBonusPayment(data, config) {
    let payload = {};
    let accounts = [];
    if (bonusAmount > 0) {
      payload.amount = parseFloat(bonusAmount);
    } else {
      setErrors([t("setBonusError")]);
      setProgress(0);
      return;
    }
    if (data.length > 0) {
      data.forEach((acc) => {
        accounts.push(acc.account_number);
      });
      payload.account_nos = accounts;
    } else {
      setErrors([t("emptyList")]);
      setProgress(0);
      return;
    }

    try {
      await billingAPI.disburseBonusPayment(user.selected, payload, config);
      showPopup(t("shared:successWithWait"), "success");
      resetStates();
    } catch (err) {
      setErrors(getHttpMessage(err));
      setProgress(0);
    }
  }

  const resetStates = () => {
    setProgress(0);
    setData([]);
    setLoaded(false);
    setErrors([]);
  };

  return (
    <div>
      <Card className="shadow">
        <Card.Body className="p-4">
          <Instructions
            title={pageTitle}
            isLoaded={isLoaded}
            headers={headers}
            filename={filename}
            sampleData={sampleData}
            reminders={reminders}
          />
          <Col>
            <Form.Group as={Row}>
              <Form.Label style={{textAlign: "right"}}>{t("bonusAmount")}</Form.Label>
              <Col lg={3}>
                <Form.Control
                  onChange={(e) => {
                    SetBonusAmount(e.target.value);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
          <FileUpload
            sampleData={sampleData}
            data={data}
            errors={errors}
            progress={progress}
            setData={setData}
            setErrors={setErrors}
            setProgress={setProgress}
            setLoaded={setLoaded}
            setColumns={setColumns}
            sendToAPI={disburseBonusPayment}
            processButtonName={t("bonusButton")}
            handleCancel={resetStates}
          />

          <ErrorList errors={errors} handleReUpload={resetStates} />

          <DataTable
            pagination
            highlightOnHover
            columns={columns}
            data={data}
            noDataComponent=""
          />
        </Card.Body>
      </Card>
    </div>
  );
}
