import React, {useState, useContext} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as meteringAPI from "../../services/meteringApi";
import {usePermissions} from "../permissions";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {UserContext} from "../login/userContext";
import {showPopup, getHttpMessage} from "../generics/alerts";

export default function UpdateCustomerID({accountNo}) {
  const canView = usePermissions(meteringAPI.UPDATE_CUSTOMER_ID, "POST");
  const {t} = useTranslation(["customer"]);
  const [saving, setSaving] = useState(false);
  const user = useContext(UserContext);

  async function updateCustomerID() {
    setSaving(true);
    let data = {account_numbers: [accountNo]};

    try {
      await meteringAPI.updateCustomerID(user.selected, data);
      showPopup(t("successUpdateCustomerID"), "success");
    } catch (ex) {
      showPopup(getHttpMessage(ex));
    }
    setSaving(false);
  }
  return (
    <>
      <Button
        className="mb-2 mx-1 p-1 pull-right"
        variant="danger"
        test-id="manage-tags-button"
        disabled={!canView || saving}
        onClick={updateCustomerID}
      >
        <FontAwesomeIcon icon={faPencilAlt} className="mx-1"></FontAwesomeIcon>
        {saving ? t("updatingID") : t("updateCustomerID")}
      </Button>
    </>
  );
}
