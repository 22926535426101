import React, {useState, useContext} from "react";
import {Form, Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {getFormProps, InlineFormField, FormErrors} from "../generics/formsFields";
import {useForm} from "react-hook-form";
import * as payGatewayApi from "../../services/paymentGatewayApi";
import {UserContext} from "../login/userContext";
import {usePermissions} from "../permissions";
import * as formatDate from "../../services/date";
import {showPopup, showErrors} from "../generics/alerts";
import {DashboardHeader} from "../generics/headers";

export function CreateTransactionModal({refreshGrid}) {
  const canCreate = usePermissions(payGatewayApi.CREATE_AMINI_TRANSACTION_PATH, "POST");
  const user = useContext(UserContext);
  const {t} = useTranslation(["transactions", "shared"]);
  const [loading, setLoading] = useState(false);
  const {register, handleSubmit} = useForm();
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState([]);
  const handleShow = () => setShow(true);
  const onHide = () => setShow(false);
  const onSubmit = (formData) => {
    setLoading(true);
    formData.amount = parseFloat(formData.amount);
    formData.payment_date = formatDate.formatFormDateToRFC3339(formData.payment_date);
    createTransaction(user.selected, formData);
  };
  async function createTransaction(portfolio, payData) {
    setErrors([]);
    setLoading(true);
    try {
      await payGatewayApi.createAMINIpayment(user.selected, payData);
      showPopup(t("shared:success"), "success");
      setShow(false);
      refreshGrid();
    } catch (err) {
      showErrors(err, setErrors);
    }
    setLoading(false);
  }
  const accNo = getFormProps("account_number", t("accountNo"), "text", "true");
  const ref = getFormProps("tx_ref", t("reference"), "text", "true");
  const amount = getFormProps("amount", t("amount"), "number", "true");
  const payDate = getFormProps("payment_date", t("payDate"), "date", "true");
  const phoneNumber = getFormProps("phone_number", t("phoneNumber"), "number", "true");
  return (
    <>
      <Button
        id="create-template"
        variant="danger"
        onClick={handleShow}
        style={{marginLeft: "auto"}}
        disabled={!canCreate}
        title={!canCreate ? t("shared:unauthorized") : null}
        className="pull-right mb-2"
      >
        {t("addPayment")}
      </Button>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <DashboardHeader title={t("createCashTrans")} icon="credit-card" />
        </Modal.Header>
        <Modal.Body className="show-grid">
          <FormErrors errors={errors}></FormErrors>
          <Form onSubmit={handleSubmit(onSubmit)} className="m-4" id="transaction_modal">
            <InlineFormField register={register} {...accNo} />
            <InlineFormField register={register} {...amount} />
            <InlineFormField register={register} {...ref} />
            <InlineFormField register={register} {...phoneNumber} />
            <InlineFormField register={register} {...payDate} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} variant="light">
            {t("shared:cancel")}
          </Button>
          <Button
            type="submit"
            variant="danger"
            form="transaction_modal"
            disabled={loading}
          >
            {loading ? t("saving") : t("create")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
