import React, {useContext} from "react";
import {Navbar, NavDropdown, Nav, NavItem, Dropdown} from "react-bootstrap";
import logo from "./logo-color.svg";
import {UserContext} from "../login/userContext";
import {logout} from "../../services/authApi";
import {Link, useHistory, useLocation} from "react-router-dom";

import {googleLogout} from "@react-oauth/google";
import {GoogleOAuthProvider} from "@react-oauth/google";

import {keys} from "lodash";
import "./nav.scss";
import {usePermissions} from "./../permissions";
import * as BillingAPI from "../../services/billingApi";
import * as authAPI from "../../services/authApi";
import * as meteringAPI from "../../services/meteringApi";
import {useTranslation} from "react-i18next";
import * as notificationAPI from "../../services/notificationApi";
import {showPopup} from "../generics/alerts";

export default function NavBar({setUser, setToken, token}) {
  const user = useContext(UserContext);
  const location = useLocation();
  const {t} = useTranslation(["nav"]);
  const bg = "primary";
  const variant = "dark";
  if (!user) {
    return (
      <Navbar bg={bg} variant={variant} expand="lg" fixed="top">
        <AminiBrand />
      </Navbar>
    );
  }
  if (isAuthPath(location.pathname)) {
    user.selected = "AUTH";
  }
  const menu = user.selected === "AUTH" ? <AuthMenu /> : <Menu />;

  return (
    <Navbar bg="primary" variant="dark" expand="lg" fixed="top">
      <AminiBrand />
      <PortfolioSwitcher setUser={setUser}></PortfolioSwitcher>
      <Navbar.Toggle aria-controls="amini-nav" />
      <Navbar.Collapse id="amini-nav" className={user ? "" : "d-none"}>
        {/* Navigation Menu */}
        {/* conditionally show these nav bars */}
        {menu}
        {/* Username + logout */}
        <Nav className="ml-auto">
          <Dropdown as={NavItem}>
            <Dropdown.Toggle as={Nav.Link}>{user ? user.username : ""}</Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item as={Link} to="/aminiupdates" className="py-2 border-bottom">
                {t("aminiUpdates")}
              </Dropdown.Item>
              <Dropdown.Item>
                <LogoutButton setUser={setUser} token={token} setToken={setToken} />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

function isAuthPath(path) {
  var authPaths = ["/permissions", "/roles", "/users"];
  //check if index exist (should have better ways)
  const result = authPaths.findIndex((authPath) => path === authPath);
  if (result > -1) {
    return true;
  }
  return false;
}

function AminiBrand({children}) {
  return (
    <Navbar.Brand as={Link} to="/customers">
      <img
        alt="amini.io"
        src={logo}
        width="30"
        height="30"
        className="d-inline-block align-top"
      />
      {" AMINI"}
      {children}
    </Navbar.Brand>
  );
}

function Menu() {
  return (
    <Nav className="mr-auto">
      <ProtectedDropdown
        title="Customers"
        id="customers-menu"
        permission={BillingAPI.CUSTOMER_LIST_PATH}
      >
        <NavDropdown.Item as={Link} to="/customers" permission>
          Customers
        </NavDropdown.Item>

        <NavDropdown.Item as={Link} to="/tags" permission>
          Tags
        </NavDropdown.Item>
      </ProtectedDropdown>

      <ProtectedDropdown title="Grids" id="grids-menu" permission={meteringAPI.SITE_PATH}>
        <NavDropdown.Item as={Link} to="/countries">
          Countries
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/sites">
          Sites
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/servers">
          Servers
        </NavDropdown.Item>
      </ProtectedDropdown>

      <ProtectedNav to="/transactions" permission={BillingAPI.TRANSACTIONS_LIST_PATH}>
        Transactions
      </ProtectedNav>

      <ProtectedDropdown
        title="SMS"
        id="logs-menu"
        permission={notificationAPI.NOTIFICATION_SMS_LIST_PATH}
      >
        <NavDropdown.Item as={Link} to="/sms">
          SMS Logs
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/smstemplates">
          SMS Templates
        </NavDropdown.Item>
      </ProtectedDropdown>
      <ProtectedDropdown
        title="Products"
        id="products-menu"
        permission={BillingAPI.PRODUCT_ITEMS_LIST_PATH}
      >
        <NavDropdown.Item as={Link} to="/products/items">
          Product Items
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/products/payment-plans">
          Product Payment Plans
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/products/accounts">
          Product Accounts
        </NavDropdown.Item>
      </ProtectedDropdown>
      <ProtectedDropdown
        title="Tariffs"
        id="settings-menu"
        permission={meteringAPI.TARIFFS_PATH}
      >
        <NavDropdown.Item as={Link} to="/tariffs">
          Tariffs
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/bonuses">
          Bonuses
        </NavDropdown.Item>
      </ProtectedDropdown>

      <ProtectedNav to="/agents" permission={BillingAPI.COLLECTION_ACCOUNTS_LIST_PATH}>
        Agents
      </ProtectedNav>

      <NavDropdown title="Reports" id="reports-menu" className="mx-2">
        <NavDropdown.Item as={Link} to="/reports/elewa">
          Elewa
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/reports/csat">
          CSAT
        </NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
}

function AuthMenu() {
  return (
    <Nav className="mr-auto">
      <ProtectedNav to="/users" permission={authAPI.USERS_PATH}>
        Users
      </ProtectedNav>
      <ProtectedNav to="/roles" permission={authAPI.ROLES_PATH}>
        Roles
      </ProtectedNav>
      <ProtectedNav to="/permissions" permission={authAPI.PERMISSION_PATH}>
        Permissions
      </ProtectedNav>
      <ProtectedNav to="/portfolios" permission={authAPI.PORTFOLIO_PATH}>
        Portfolios
      </ProtectedNav>
    </Nav>
  );
}

function ProtectedNav({to, permission, children}) {
  const isAuthorized = usePermissions(permission);
  if (!isAuthorized) return null;
  return (
    <Nav.Link as={Link} to={to} className={isAuthorized ? "mx-2" : "d-none"}>
      {children}
    </Nav.Link>
  );
}

function ProtectedDropdown({title, id, permission, children}) {
  const isAuthorized = usePermissions(permission);
  return (
    <NavDropdown title={title} id={id} className={isAuthorized ? "mx-2" : "d-none"}>
      {children}
    </NavDropdown>
  );
}

function PortfolioSwitcher({setUser}) {
  const canViewCustomers = usePermissions(BillingAPI.CUSTOMER_LIST_PATH);
  const user = useContext(UserContext);
  const history = useHistory();
  function switchPortfolio(p) {
    user.selected = p;
    setUser({...user});
    updatePath(p, history, canViewCustomers);
  }

  return (
    <Dropdown>
      <Dropdown.Toggle as="div" className="navbar-brand">
        {user ? user.selected : ""}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {keys(user.permissions).map((p) => {
          return (
            <Dropdown.Item key={p} onClick={() => switchPortfolio(p)}>
              {p}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function LogoutButton({setUser, token, setToken}) {
  let google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  async function logoutAmini() {
    await logout();
    setUser(null);
  }

  async function handleLogout() {
    try {
      await logout(token);
      setUser(null);
      setToken(null);
      googleLogout();
    } catch (e) {
      showPopup("Failed to logout", "warning");
    }
  }

  const user = useContext(UserContext);
  if (user.username === "admin@powergen-re.com") {
    return <div onClick={logoutAmini}>Logout</div>;
  }

  return (
    <GoogleOAuthProvider clientId={google_client_id}>
      <div onClick={handleLogout}>Logout</div>
    </GoogleOAuthProvider>
  );
}

// set pathname when portfolio is switched
function updatePath(portfolio, history, canViewCustomers) {
  if (portfolio !== "AUTH") {
    if (canViewCustomers) {
      history.push("/customers");
    } else {
      history.push("/reports/elewa");
    }
  } else {
    history.push("/users");
  }
}
