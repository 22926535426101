import React, {useEffect, useState, useContext} from "react";
import {Col, Table, Form, Button, Tooltip, OverlayTrigger, Badge} from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {usePermissions, Unauthorized} from "../permissions";
import * as meteringAPI from "../../services/meteringApi";
import {UserContext} from "./../login/userContext";
import {ListHeader} from "../generics/headers";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {ListPagination} from "../generics/pagination";
import {CheckBox, CheckBoxAll} from "../generics/checkList";
import Skeleton from "react-loading-skeleton";
import {Typeahead} from "react-bootstrap-typeahead";
import * as billingApi from "../../services/billingApi";
import BulkActionMenu from "../bulkActions/ActionMenu";
import {formatFileNameDate} from "../../services/date";
const queryString = require("query-string");

export default function CustomerList() {
  const canView = usePermissions(billingApi.CUSTOMER_LIST_PATH);
  const {t} = useTranslation(["customer", "shared"]);
  const location = useLocation();
  const history = useHistory();
  const user = useContext(UserContext);
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]); //defining checkbox and action menu useState
  const [selectAllInSearch, setSelectAllInSearch] = useState(false);
  const [reloadCustomersPage, setReloadCustomersPage] = useState(false); //Reloading the page
  const [progress, setProgress] = useState(0);
  const [active, setActive] = useState(true);

  let countryID = user.permissions[user.selected].countryID;
  let query = queryString.parse(location.search);

  const searchQuery = (updates) => {
    query = Object.assign(query, updates);
    var params = queryString.stringify(query, {
      skipEmptyString: true,
    });
    history.push("/customers?" + params);
  };

  useEffect(() => {
    async function fetchCustomers() {
      setLoading(true);
      const searchParams = queryString.parse(location.search);
      searchParams.country_id = countryID;
      try {
        const resp = await billingApi.getCustomers(
          user.selected,
          searchParams,
          page,
          limit
        );
        setCount(resp.data.count);
        setCustomers(resp.data.data);
      } catch (err) {
        showPopup(getHttpMessage(err), "error");
      }
      setLoading(false);
    }
    fetchCustomers();
  }, [
    user.selected,
    countryID,
    location.search,
    page,
    limit,
    setCount,
    reloadCustomersPage,
  ]);

  /**
   * Function to export customer data based on the search params
   * @returns data to be exported
   */
  async function getAllCustomers() {
    const searchParams = queryString.parse(location.search);
    searchParams.country_id = countryID;
    let singleDownloadCount = 300; //amount of data to download at once
    let iterations = Math.ceil(count / singleDownloadCount); // number of api calls to do
    let allData = [];
    setProgress(1);
    for (let i = 0; i < iterations; i++) {
      try {
        const resp = await billingApi.getCustomers(
          user.selected,
          searchParams,
          i + 1,
          singleDownloadCount
        );

        allData.push(...resp.data.data);

        //compute and set progress from iterations
        const p = Math.floor(((i + 1) / iterations) * 100);
        setProgress(p);
      } catch (err) {
        throw err;
      }
    }
    setProgress(0); //reset progress
    return allData;
  }

  if (!canView) {
    return <Unauthorized />;
  }

  return (
    <div>
      <ListHeader title={t("listTitle")} icon="users"></ListHeader>
      <SearchForm
        query={query}
        searchQuery={searchQuery}
        loading={loading}
        customers={customers}
        active={active}
        setActive={setActive}
      ></SearchForm>
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        data={customers}
        filename={formatFileNameDate(new Date().toString()) + "_customers"}
        getAllData={getAllCustomers}
        progress={progress}
      />
      <BulkActionMenu
        selectedCustomers={selectedCustomers}
        setSelectedCustomers={setSelectedCustomers}
        customers={customers}
        count={count}
        selectAllInSearch={selectAllInSearch}
        setSelectAllInSearch={setSelectAllInSearch}
        setReloadCustomersPage={setReloadCustomersPage}
      />
      {/* Show skeleton when table is still loading */}
      {loading ? (
        <Skeleton height={20} count="2" className="m-3" />
      ) : (
        <CustomerTable
          page={page}
          limit={limit}
          setCount={setCount}
          customers={customers}
          selectedCustomers={selectedCustomers}
          setSelectedCustomers={setSelectedCustomers}
          selectAllInSearch={selectAllInSearch}
        ></CustomerTable>
      )}{" "}
      <ListPagination
        count={count}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        data={customers}
        filename={formatFileNameDate(new Date().toString()) + "_customers"}
        getAllData={getAllCustomers}
        progress={progress}
      />
    </div>
  );
}

function SearchForm({query, searchQuery, active, setActive, loading}) {
  const {t} = useTranslation(["customer", "shared"]);
  const placeholder = t("searchCustmer");
  const user = useContext(UserContext);
  const [sites, setSites] = useState([]);
  const [tags, setTags] = useState([]);
  const [params, setParams] = useState({});

  const history = useHistory();
  let countryID = user.permissions[user.selected].countryID;

  if (query.active === undefined) {
    setActive(true);
  }

  if (query.active) {
    setActive(query.active === "true");
  }

  useEffect(() => {
    async function fetchSites() {
      try {
        const resp = await meteringAPI.getSites(user.selected, {
          status: "active",
          country_id: countryID,
        });
        let site_list = [];
        resp.data.data.map((site) => {
          return site_list.push({name: site.site_name, value: site.site_code});
        });
        setSites(site_list);
      } catch (err) {
        showPopup(getHttpMessage(err), "error");
      }
    }
    fetchSites();
  }, [user.selected, countryID]);

  useEffect(() => {
    async function fetchTags() {
      try {
        const resp = await billingApi.getTags(user.selected, params);
        if (resp.data.data != null) {
          setTags(resp.data.data);
        }
      } catch (err) {
        showPopup(getHttpMessage(err, "error"));
      }
    }
    fetchTags();
  }, [user.selected, params]);

  const onChangeTags = (tags) => {
    const filterTags = [];
    tags.map(({name}) => filterTags.push(name));
    searchQuery({tag: filterTags});
  };

  return (
    <Form>
      <Form.Row className="mb-3">
        <Col md={1}>
          <BootstrapSwitchButton
            checked={active}
            onlabel="Active"
            offlabel="Inactive"
            onstyle="success"
            width={90}
            onChange={(checked) => {
              setActive(checked);
              searchQuery({active: checked});
            }}
          />
        </Col>

        {sites && sites.length > 0 ? (
          <Col md={2} className="mb-1">
            <Form.Control
              name="site_code"
              defaultValue={query.site_code}
              as="select"
              onChange={(e) => {
                searchQuery({site_code: e.target.value});
              }}
            >
              <option value="">All sites...</option>
              {sites.map((site) => {
                return (
                  <option value={site.value} key={site.value}>
                    {site.name}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
        ) : (
          <br />
        )}
        <Col xs={12} md={5} className="mb-1">
          <Form.Control
            id="search-form-customer"
            type="text"
            placeholder={placeholder}
            name="search_value"
            defaultValue={query.search_value}
            onChange={(e) => {
              searchQuery({search_value: e.target.value});
            }}
            className="mr-2"
          ></Form.Control>
        </Col>
        <Col md={4}>
          <Button
            variant="primary"
            onClick={() => history.push("/file/upload")}
            className="mx-1 pull-right"
          >
            <FontAwesomeIcon icon="upload" /> {t("userUpload")}
          </Button>
          <Button
            variant="danger"
            onClick={() => history.push("/customers/register")}
            className="mx-1 pull-right"
          >
            <FontAwesomeIcon icon="plus" /> {t("addNew")}
          </Button>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col xs={12} md={8}>
          <Typeahead
            onInputChange={(e) => setParams({name: e})}
            onChange={(tags) => onChangeTags(tags)}
            id="public-methods-example"
            labelKey="name"
            multiple
            options={tags}
            placeholder={t("searchByTag")}
            className="mb-3"
          />
        </Col>
      </Form.Row>
    </Form>
  );
}

function CustomerTable({
  customers,
  selectedCustomers,
  setSelectedCustomers,
  selectAllInSearch,
}) {
  const {t} = useTranslation(["customer"]);

  return (
    <Table striped bordered className="shadow" response="sm" hover>
      <thead>
        <tr>
          <th style={{width: "40px"}}>
            <CheckBoxAll
              allData={customers}
              selectedData={selectedCustomers}
              setSelectedData={setSelectedCustomers}
              selectAllInSearch={selectAllInSearch}
            />
          </th>
          <th>{t("firstName")}</th>
          <th>{t("lastName")}</th>
          <th>{t("phoneNumber")}</th>
          <th>{t("accountNumber")}</th>
          <th>{t("meterNumber")}</th>
          <th style={{width: "25%"}}>{t("tags")}</th>
        </tr>
      </thead>
      <tbody>
        {customers.map((c) => (
          <CustomerRow
            customer={c}
            key={c.ID}
            selectedCustomers={selectedCustomers}
            setSelectedCustomers={setSelectedCustomers}
            selectAllInSearch={selectAllInSearch}
          />
        ))}
      </tbody>
    </Table>
  );
}

function CustomerRow({
  customer,
  selectedCustomers,
  setSelectedCustomers,
  selectAllInSearch,
}) {
  const phoneMatch = customer.PhoneNumber.match(/\d+/);
  const link = `/customers/${customer.ID}`;
  const history = useHistory();
  function onClick() {
    history.push(link);
  }

  /* Meter serial column
   Create a td column for meter serial display
  - Show serial if availabe
  - Show notes if serial not available and add a tooltip to display the notes details
   */
  function createMeterSerialColumn(meterSerial, notes) {
    let displayText = meterSerial;
    // show text if meter serial is empty and there are notes
    if (!meterSerial && notes) {
      displayText = notes;
      //shorten the notes not to cloud the column, show details with tooltip
      if (notes.length > 20) {
        displayText = notes.substr(0, 20) + "...";
      }
    }

    let meterColumn = <td onClick={onClick}>{displayText}</td>;

    //if there are notes with empty meter serial, show them as tooltips
    if (!meterSerial && notes) {
      meterColumn = (
        <OverlayTrigger
          placement="bottom"
          delay={{show: 250, hide: 400}}
          overlay={<Tooltip>{notes}</Tooltip>}
        >
          <td style={{color: "red"}} onClick={onClick}>
            {displayText}
          </td>
        </OverlayTrigger>
      );
    }
    return meterColumn;
  }
  let meterColumn = createMeterSerialColumn(customer.MeterSerial, customer.Notes);
  return (
    <tr>
      <td>
        <CheckBox
          itemObj={customer}
          selectedData={selectedCustomers}
          setSelectedData={setSelectedCustomers}
          selectAllInSearch={selectAllInSearch}
        />
      </td>
      <td onClick={onClick}>{customer.FirstName}</td>
      <td onClick={onClick}>{customer.LastName}</td>
      <td onClick={onClick}>{phoneMatch ? phoneMatch[0] : ""}</td>
      <td onClick={onClick}>{customer.AccountNumber}</td>
      {meterColumn}
      <td onClick={onClick}>
        {customer.Tags
          ? customer.Tags.map((tag) => (
              <Badge
                pill
                variant="light"
                className="mr-1 p-1"
                style={{fontWeight: "500"}}
              >
                {tag}
              </Badge>
            ))
          : null}
      </td>
    </tr>
  );
}
