import React, {useState, useContext} from "react";
import {UserContext} from "../login/userContext";
import {Button, Modal, Form, Row, Col} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus} from "@fortawesome/free-solid-svg-icons";
import * as billingAPI from "../../services/billingApi";
import {usePermissions} from "../permissions";
import {getFormProps, FormField, FormErrors} from "../generics/formsFields";
import {RegisterButton} from "../generics/buttons";
import {showPopup, showErrors} from "../generics/alerts";

export default function RegisterItem({fetchProductsList}) {
  const canView = usePermissions(billingAPI.PRODUCT_ACCOUNTS_LIST_PATH, "POST");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {t} = useTranslation(["product", "shared"]);

  return (
    <div>
      <Button
        disabled={!canView}
        title={!canView ? t("registerPermission") : null}
        variant="danger"
        onClick={handleShow}
        className="text-white float-right"
      >
        <FontAwesomeIcon icon="plus" /> {t("addNew")}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="mb-4 text-primary">
              <FontAwesomeIcon icon={faCartPlus} /> {t("registerItemTitle")}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductForm setShow={setShow} fetchProductsList={fetchProductsList} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export function ProductForm({setShow, fetchProductsList, product = {}}) {
  // set up contexts and variables for the form
  const user = useContext(UserContext);
  const {t} = useTranslation(["product", "shared"]);
  const {register, handleSubmit} = useForm();
  const [loading, setLoading] = useState();
  const [errors, setErrors] = useState([]);
  const product_name = getFormProps(
    "product_name",
    t("product"),
    "text",
    "true",
    product.product_name ? product.product_name : ""
  );
  const product_code = getFormProps(
    "product_code",
    t("productCode"),
    "text",
    "true",
    product.product_code ? product.product_code : ""
  );
  const description = getFormProps(
    "description",
    t("shared:description"),
    "textarea",
    "false",
    product.description ? product.description : ""
  );

  let country_id = user.permissions[user.selected].countryID;

  // function to call when submitting form
  const onSubmit = (data) => {
    data.country_id = country_id;
    if (Object.keys(product).length === 0) {
      const items = [{...data}];
      registerItem(user.selected, items);
    } else {
      data.id = product.id;
      updateProduct(user.selected, data);
    }
  };

  async function registerItem(portfolio, items) {
    try {
      setLoading(true);
      await billingAPI.registerProductItems(portfolio, items);
      showPopup(t("shared:success"), "success");
      fetchProductsList();
      setShow(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setLoading(false);
  }

  async function updateProduct(portfolio, product) {
    try {
      setLoading(true);
      await billingAPI.updateProductItems(portfolio, product);
      showPopup(t("shared:success"), "success");
      fetchProductsList();
      setShow(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setLoading(false);
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <FormErrors errors={errors}></FormErrors>
          <FormField lg={10} register={register} {...product_name} />
          <FormField lg={10} register={register} {...product_code} />
          <FormField lg={10} as="textarea" register={register} {...description} />
          <Col md={4} lg={6}>
            <Button
              variant="light"
              onClick={() => setShow(false)}
              className="mt-4 p-3 pull-right"
            >
              {t("shared:cancel")}
            </Button>
          </Col>
          <RegisterButton
            disabled={loading}
            md={4}
            lg={4}
            label={loading ? t("shared:loading") : t("save")}
          ></RegisterButton>
        </Row>
      </Form>
    </div>
  );
}
