import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as billingAPI from "../../services/billingApi";
import {usePermissions} from "../permissions";
import {CountryModal} from "./modal";

export default function RegisterCountry() {
  const canView = usePermissions(billingAPI.COUNTRIES_PATH, "POST");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {t} = useTranslation(["countries", "shared"]);

  return (
    <div>
      <Button
        disabled={!canView}
        title={!canView ? t("registerPermission") : null}
        variant="danger"
        onClick={handleShow}
        className="text-white float-right"
      >
        <FontAwesomeIcon icon="plus" /> {t("addNew")}
      </Button>

      <CountryModal
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        title={t("addNewCountry")}
      />
    </div>
  );
}
