export const en = {
  listPermission: "You don't have permission",
  searchCollection: "Search by account no, site code, name, phone number",
  listTitle: "Agent Accounts",
  firstName: "First Name",
  lastName: "Last Name",
  phoneNumber: "Phone Number",
  accountNo: "Account#",
  siteCode: "Site Code",
  registerPermission:
    "You don't have permisson to add the new collection/agent account! ",
  buttonTitle: "Register",
  registerTitle: "Register collection/agent accounts",
  registrationTitle: "Register Collection/agent account",
  accountNumber: "Account Number",
  providerID: "Provider ID",
  phoneNo: "Phone Number",
  selectCustomerName: "Select Customer Name",
  providerName: "Provider Name",
  cancel: "Cancel",
  register: "Register",
  selectCustomer: "Select Customer",
  selectSite: "Select Site",
  selectProvider: "Select Provider",
  vendorAgentID: "Vendor Agent ID",
};

export const fr = {};

export const swa = {};
