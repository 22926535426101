export const en = {
  title: "Servers",
  serverName: "Server Name",
  endpoint: "Server Endpoint",
  siteName: "Site",
  token: "API Token",
  vendorName: "Vendor",
  load: "Loading..",
  searchBar: "Server Name ..",
  vendorServerId: "Vendor Server ID",
  vendorSiteId: "Vendor Site ID",
  vendor: "Vendor",
  vendorSeverId: "Vendor Server id",
  register: "Add",
  update: "Save Changes",
  cancel: "Cancel",
  vendorError: "Vendor error",
  siteError: "Site error",
  registerTitle: "Server Registration",
  buttonTitle: "Register Server",
  registerPermission: "You don't have permisson to add the new server! ",
  updatePermission: "You don't have permisson to update server information! ",
  portfolio_id: "Portfolio ID",
  apiKey: "API Key",
};

export const fr = {};

export const swa = {};
