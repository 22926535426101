import {useParams, useHistory} from "react-router-dom";
import React, {useContext, useEffect, useState, useCallback} from "react";
import {UserContext} from "./../login/userContext";
import * as meteringAPI from "../../services/meteringApi";
import {useTranslation} from "react-i18next";
import {usePermissions, Unauthorized} from "../permissions";
import Skeleton from "react-loading-skeleton";
import {DashboardHeader} from "../generics/headers";
import {CreateTariffForm} from "./createTariff";
import {faMoneyBillAlt} from "@fortawesome/free-solid-svg-icons";
import {getHttpMessage, showPopup, showErrors} from "../generics/alerts";
import {LoadingErrors} from "../generics/formsFields";

export default function TariffDashboard() {
  const canView = usePermissions(meteringAPI.TARIFFS_PATH);
  const [vendors, setVendors] = useState([]);
  const [saving, setSaving] = useState(false);
  const [isVendorLoaded, setisVendorLoaded] = useState(false);
  const [isTariffLoaded, setIstariffLoaded] = useState(false);
  const [errors, setErrors] = useState([]);
  const {t} = useTranslation(["tariffs", "shared"]);
  const {id} = useParams();
  const user = useContext(UserContext);
  const [tariff, setTariff] = useState({
    block_rates: [],
    id: "",
    low_bal_warning: "",
    minimum_topup: "",
    monthly_charge: "",
    monthly_charge_day: "",
    tou_rates: [],
    vendor_id: "",
    vendor_tariff_id: "",
    vendor_rules: "",
  });
  const history = useHistory();
  let country_id = user.permissions[user.selected].countryID;

  async function sendToAPI(portfolio, tariff) {
    tariff.country_id = country_id;
    //if sms is supported, the keyword and details should be added
    if (tariff.supports_sms && (!tariff.details || !tariff.sms_keyword)) {
      setErrors([t("detailsRequired")]);
      return;
    }
    setErrors([]);
    setSaving(true);
    try {
      await meteringAPI.updateTariff(portfolio, tariff);
      showPopup(t("shared:success"), "success");
      history.push("/tariffs");
    } catch (err) {
      showErrors(err, setErrors);
    }
    setSaving(false);
  }

  const fetchTariffs = useCallback(
    async function () {
      try {
        const resp = await meteringAPI.getSingleTariff(user.selected, id);
        setTariff(resp.data);
      } catch (e) {
        showPopup(getHttpMessage(e));
      }
      setIstariffLoaded(true);
    },
    [user.selected, id, setIstariffLoaded]
  );
  const fetchVendors = useCallback(
    async function () {
      try {
        const resp = await meteringAPI.getVendors(user.selected, {});
        let vendorJson = resp.data.map((vendor) => {
          return {name: vendor.Name, value: vendor.VendorId};
        });
        setVendors(vendorJson);
      } catch (err) {
        showPopup(t("vendorError") + ": " + err);
      }
      setisVendorLoaded(true);
    },
    [t, user.selected, setisVendorLoaded]
  );

  useEffect(() => {
    if (!isVendorLoaded) fetchVendors();
    if (!isTariffLoaded) fetchTariffs();
  }, [isVendorLoaded, isTariffLoaded, fetchVendors, fetchTariffs]);

  if (!isVendorLoaded || !isTariffLoaded) {
    return <Skeleton height={40} count="4" className="m-3" />;
  }

  // If loading completed but no tariff or no vendors, there must be a problem loading
  if (!tariff.name || vendors.length === 0) {
    return <LoadingErrors />;
  }

  if (!canView) {
    return <Unauthorized />;
  }

  return (
    <div>
      <DashboardHeader title={tariff.name} icon={faMoneyBillAlt}></DashboardHeader>
      <div className="mt-2 shadow bg-white border rounded">
        <CreateTariffForm
          tariff={tariff}
          sendToAPI={sendToAPI}
          disable={saving}
          vendors={vendors}
          errors={errors}
        />
      </div>
    </div>
  );
}
