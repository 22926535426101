import {showPopup} from "./alerts";
import {ExportToCsv} from "export-to-csv";
import React, {useState, useEffect} from "react";
import {Button, Form, Modal, Dropdown, ProgressBar} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {CheckBox, CheckBoxAll} from "./checkList";

var ls = require("local-storage");

/**
 * Hook to download a csv file
 * @param {function} getAllData function to call and return all data that is to be exported
 * @param {Array} data list of data to be exported
 * @param {Array} headers optional array of headers to use for csv file
 * @param {string} filename the csv filename
 * @returns
 */

export function CSVDownloads(filename, data, headers, fieldsToExport = []) {
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    filename,
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  //use headers if supplied
  if (headers && headers.length > 0) {
    options.useKeysAsHeaders = false;
    options.headers = headers;
  }
  const csvExporter = new ExportToCsv(options);

  if (data.length > 0) {
    let tagHeaders = [];
    let cleanData = data;

    //if fieldsToExport is not supplied, or is supplied with tags,then export them
    let includeTags =
      !fieldsToExport || fieldsToExport.length === 0 || fieldsToExport.includes("Tags");

    //clean tags if available in data
    if (includeTags && "Tags" in data[0]) {
      let {newData, newHeaders} = formatTags(data);
      cleanData = newData;
      tagHeaders = newHeaders;
    }

    //clean data
    cleanData = selectAndCleanDataFields(cleanData, fieldsToExport, tagHeaders);
    csvExporter.generateCsv(cleanData);
  } else {
    showPopup("No data to export", "error");
  }
}

/**
 * take tags and create new columns with the tags
 * which can be exported separately
 * @param {Array} data arry of objects
 * @returns Object of newData and new tag headers generated.
 */
function formatTags(data) {
  const newData = [];
  const allTags = new Set();
  data.forEach((dataRow) => {
    //shallow copy
    let row = Object.assign({}, dataRow);
    const tags = row["Tags"];
    //remove tags key from row
    delete row["Tags"];

    if (tags && tags.length > 0) {
      for (let tag of tags) {
        let key = "tag_" + tag;
        row[key] = tag;
        allTags.add(key);
      }
    }
    newData.push(row);
  });
  return {newData, newHeaders: [...allTags]};
}

export function CSVDownloadAllData(filename, getAllData, headers, fieldsToExport = []) {
  try {
    //both  getAllData is required
    if (isFunction(getAllData)) {
      let get = async () => {
        const allData = await getAllData();
        CSVDownloads(filename, allData, headers, fieldsToExport);
      };
      get();
    } else {
      showPopup("Failed to export data", "error");
    }
  } catch (err) {
    showPopup("Failed to export data", "error");
  }
}

function isFunction(functionToCheck) {
  return typeof functionToCheck === "function";
}

/**
 * Function to clean data fields based on the selected fields
 * @param {JSON} data json array of customer data
 * @param {Array} fieldsToExport the custom fields to export
 * @param {Array} tagHeaders any new headers from tags
 * @returns JSON array of clean data
 */
function selectAndCleanDataFields(data, fieldsToExport, tagHeaders) {
  //return empty array if no data
  if (data.length === 0) {
    return data;
  }

  let fields;
  //if no fields are selected, export everything
  if (fieldsToExport && fieldsToExport.length > 0) {
    fields = [...fieldsToExport];
  } else {
    fields = Object.keys(data[0]);
  }

  //add tag headers
  if (tagHeaders && tagHeaders.length > 0) {
    fields.push(...tagHeaders);
  }
  let cleanData = []; //holds an array of the cleaned data

  //Get and clean each row of data
  for (let row of data) {
    const cleanRow = {}; //object holding clean row data
    //each row is an object of key, value pairs like {firstname: Mohammed, ...}
    //loop through properties of object
    for (let key in row) {
      //if the key is included in the fields to export, add the field
      if (fields.includes(key)) {
        // if data is null, put empty string
        if (!row[key] || row[key] === "null") {
          cleanRow[key] = "";
        } else {
          cleanRow[key] = row[key];
        }
      }
    }

    //add tag keys if they don't exist
    //this is to avoid undefined and null values
    if (tagHeaders && tagHeaders.length > 0) {
      tagHeaders.forEach((tag) => {
        if (!(tag in cleanRow)) {
          cleanRow[tag] = "";
        }
      });
    }
    //add customer data to list
    cleanData.push(cleanRow);
  }
  return cleanData;
}

export function SelectFieldsModal({data, filename, getAllData, headers}) {
  const [show, setShow] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [exportAll, setExportAll] = useState(false);
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  const [select] = useState(false);
  const {t} = useTranslation(["fileupload"]);

  useEffect(() => {
    const checkedField = ls.get("selected");
    if (checkedField) {
      setSelectedFields(JSON.parse(checkedField));
    }
  }, [data]);

  if (data == null || data.length === 0) {
    return null;
  }

  function handleExportAll(e) {
    if (e.target.checked === true) {
      setExportAll(true);
    } else {
      setExportAll(false);
    }
  }
  function handleExportCurrentPage(e) {
    if (e.target.checked === true) {
      setExportAll(false);
    } else {
      setExportAll(true);
    }
  }

  function exportData() {
    handleHide(); //hide export modal
    if (exportAll) {
      CSVDownloadAllData(filename, getAllData, headers, selectedFields);
    } else {
      CSVDownloads(filename, data, headers, selectedFields);
      // set selected fields
      ls.set("selected", JSON.stringify(selectedFields));
    }
  }

  //create checkboxes for fields
  let rows = [];
  let fields = Object.keys(data[0]);
  fields.forEach((field) => {
    let r = (
      <Form.Row>
        <CheckBox
          itemObj={field}
          selectedData={selectedFields}
          setSelectedData={setSelectedFields}
          selectAllInSearch={select}
        />
        <Form.Label for={field}>{field}</Form.Label>
      </Form.Row>
    );
    rows.push(r);
  });

  return (
    <>
      <Dropdown.Item onClick={handleShow}>{t("customDownload")}</Dropdown.Item>
      <Modal
        show={show}
        backdrop="static"
        onHide={handleHide}
        centered
        scrollable={true}
        size="md"
      >
        <Modal.Header closeButton className="text-secondary">
          <Modal.Title className="text-primary ml-2">{t("customDownload")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mr-4">
          <React.Fragment>
            <Form.Row className="my-2">
              <Form.Check
                type="checkbox"
                checked={!exportAll}
                key="current"
                name="current"
                id="current"
                onChange={handleExportCurrentPage}
              ></Form.Check>
              <Form.Label for="current" className="mr-4">
                {t("exportCurPage")}
              </Form.Label>
              <Form.Check
                type="checkbox"
                checked={exportAll}
                key="all"
                name="all"
                id="allData"
                onChange={handleExportAll}
              ></Form.Check>
              <Form.Label for="allData">{t("exportAllData")}</Form.Label>
            </Form.Row>

            <Form.Row className="my-2">
              <CheckBoxAll
                allData={fields}
                selectedData={selectedFields}
                setSelectedData={setSelectedFields}
                selectAllInSearch={select}
              />
              <Form.Label for="allFields">{t("selectAllFields")}</Form.Label>
            </Form.Row>

            <Form.Group>
              <h5 className="mt-3">Select Fields to Export</h5>
              {rows}
            </Form.Group>
            <Modal.Footer>
              <Button
                md={4}
                lg={4}
                xs={6}
                variant="danger"
                onClick={exportData}
                className="mt-2 mb-5 p-3  pull-right"
              >
                <FontAwesomeIcon className=" mx-2" icon="file-export"></FontAwesomeIcon>
                {t("downloadData")}
              </Button>
            </Modal.Footer>
          </React.Fragment>
        </Modal.Body>
      </Modal>
    </>
  );
}

/**
 * Hook to show progress bar
 * @param {number} progress holds progress of export in %
 * @returns
 */
export function UploadProgressBar({progress}) {
  const {t} = useTranslation(["fileupload"]);
  return (
    <Modal
      show={progress > 0}
      backdrop="static"
      onHide={() => {}}
      centered={true}
      scrollable={false}
      size="md"
    >
      <Modal.Header className="text-secondary " closeButton={false}>
        <Modal.Title className="text-primary">{t("downloading")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mr-4">
        <ProgressBar
          animated="true"
          variant="success"
          now={progress}
          label={`${progress}% `}
        />
      </Modal.Body>
    </Modal>
  );
}
