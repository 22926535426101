import React, {useState, useEffect, useContext} from "react";
import {UserContext} from "../login/userContext";
import {Button, Modal, Form, Row, Col} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWifi} from "@fortawesome/free-solid-svg-icons";
import * as meteringAPI from "../../services/meteringApi";
import {usePermissions} from "../permissions";
import {getFormProps, FormField, FormSelect, FormErrors} from "../generics/formsFields";
import {RegisterButton} from "../generics/buttons";
import {showPopup, showErrors} from "../generics/alerts";
import {useHistory} from "react-router-dom";

export default function Register() {
  const canView = usePermissions(meteringAPI.SERVER_LIST_PATH, "POST");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {t} = useTranslation(["servers", "shared"]);

  return (
    <div>
      <Button
        disabled={!canView}
        title={!canView ? t("registerPermission") : null}
        variant="danger"
        onClick={handleShow}
        className="text-white float-right"
      >
        <FontAwesomeIcon icon="plus" /> {t("buttonTitle")}
      </Button>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="mb-4 text-primary">
              <FontAwesomeIcon icon={faWifi} /> {t("registerTitle")}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RegisterForm setShow={setShow} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export function RegisterForm({setShow}) {
  // set up contexts and variables for the form
  const user = useContext(UserContext);
  const {t} = useTranslation(["servers", "shared"]);
  const {register, handleSubmit} = useForm();
  // fetch and populate options for owner field
  const [sites, setSites] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [errors, setErrors] = useState([]);
  const history = useHistory();
  const [saving, setSaving] = useState(false);

  // const [languages, setLanguages] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!isLoaded) {
      fetchSites();
      fetchVendors();
    }
  });

  async function fetchSites() {
    let country_id = user.permissions[user.selected].countryID;
    try {
      const resp = await meteringAPI.getSites(user.selected, {
        status: "active",
        country_id,
      });
      let siteJson = [];
      resp.data.data.forEach((site) => {
        const name = `${site.site_name}(${site.site_code})`;
        siteJson.push({name, value: site.site_id});
      });
      setSites(siteJson);
    } catch (err) {
      showPopup(t("siteError") + ": " + err);
    }
    setLoaded(true);
  }

  async function fetchVendors() {
    try {
      const resp = await meteringAPI.getVendors(user.selected, {});
      let vendorJson = [];
      resp.data.forEach((vendor) => {
        vendorJson.push({name: vendor.Name, value: vendor.VendorId});
      });
      setVendors(vendorJson);
    } catch (err) {
      showPopup(t("vendorError") + ": " + err);
    }
    setLoaded(true);
  }

  const vendorServer = getFormProps(
    "vendor_server_id",
    t("vendorSeverId"),
    "text",
    "false"
  );
  const vendorSiteId = getFormProps("vendor_site_id", t("vendorSiteId"), "text", "false");
  const token = getFormProps("api_token", t("token"), "text");
  const apiKey = getFormProps("api_key", t("apiKey"), "text", "false");
  const apiEndpoint = getFormProps("comms_endpoint", t("endpoint"), "text");
  const portfolioID = getFormProps("portfolio_id", t("portfolio_id"), "text", "false");
  const siteIDs = getFormProps(
    "site_id",
    t("siteName"),
    "select",
    "true",
    "",
    t("siteName"),
    sites
  );
  const vendorIDs = getFormProps(
    "vendor_id",
    t("vendor"),
    "select",
    "true",
    "",
    t("vendor"),
    vendors
  );

  // function to call when submitting form
  const onSubmit = (data) => {
    const server = [{...data}];
    registerServer(user.selected, server);
  };

  async function registerServer(portfolio, serverData) {
    setErrors([]);
    setSaving(true);
    try {
      await meteringAPI.registerServer(portfolio, serverData);
      showPopup(t("shared:success"), "success");
      setShow(false);
      history.push("/servers");
    } catch (err) {
      showErrors(err, setErrors);
    }
    setSaving(false);
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4">
        <Row>
          <FormErrors errors={errors}></FormErrors>
          <FormSelect register={register} {...siteIDs} />
          <FormSelect register={register} {...vendorIDs} />

          <FormField register={register} {...apiEndpoint} />
          <FormField register={register} {...token} />
          <FormField register={register} {...apiKey} />

          <FormField register={register} {...vendorServer} />
          <FormField register={register} {...vendorSiteId} />
          <FormField register={register} {...portfolioID} />

          <Col md={2} lg={1} xs={4}>
            <Button variant="light" onClick={() => setShow(false)} className="mt-4 p-3">
              {t("cancel")}
            </Button>
          </Col>
          <RegisterButton label={t("register")} disabled={saving}></RegisterButton>
        </Row>
      </Form>
    </div>
  );
}
