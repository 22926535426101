import React, {useContext, useEffect, useState, useCallback} from "react";
import {Row, Col, Form} from "react-bootstrap";
import {UserContext} from "./../login/userContext";
import * as meteringAPI from "../../services/meteringApi";
import * as billingApi from "../../services/billingApi";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {getFormProps, FormField, FormCheck, FormSelect} from "../generics/formsFields";
import {SaveButton} from "../generics/buttons";
import * as DateHelpers from "../../services/date";
import {getHttpMessage, showPopup} from "../generics/alerts";
import Skeleton from "react-loading-skeleton";
import {GoogleMapPicker, getMapPickerProps} from "../generics/googleMapPicker";
import CopyTariff from "./copyTariff";

export function SiteForm({site, setSiteLoading}) {
  const user = useContext(UserContext);
  const {t} = useTranslation(["sites", "shared"]);
  const {register, handleSubmit} = useForm();
  const [owners, setOwners] = useState([]);
  const [tariffs, setTariffs] = useState([]);
  const [isOwnersLoaded, setOwnersLoaded] = useState(false);
  const [isTariffsLoaded, setTariffsLoaded] = useState(false);
  const [isCountriesLoaded, setCountriesLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [countries, setCountries] = useState([]);

  // for google map: https://www.npmjs.com/package/react-google-map-picker
  const siteLocation = {
    lat: site.latitude ? site.latitude : 0,
    lng: site.longitude ? site.longitude : 0,
  };
  const defaultZoom = 10;
  const [defaultLocation, setDefaultLocation] = useState(siteLocation);
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(defaultZoom);
  const [lat, setLat] = useState(location.lat);
  const [long, setLong] = useState(location.lng);
  const [isChanged, setIsChanged] = useState(false);

  const handleChangeLocation = (lat, lng) => {
    setLocation({lat: lat, lng: lng});
    setLat(lat);
    setLong(lng);
    setIsChanged(true);
  };

  const handleChangeZoom = (newZoom) => {
    setZoom(newZoom);
  };

  const handleResetLocation = () => {
    setDefaultLocation({...siteLocation});
    setZoom(defaultZoom);
    setLat(siteLocation.lat);
    setLong(siteLocation.lng);
    setIsChanged(true);
  };

  const fetchSiteOwners = useCallback(
    async function () {
      try {
        const resp = await meteringAPI.getSiteOwners(user.selected, {});
        const ownersJson = resp.data.map((owner) => {
          return {name: owner.Name, value: owner.OwnerID};
        });
        setOwners(ownersJson);
        setOwnersLoaded(true);
      } catch (err) {
        showPopup(t("ownersError") + ": " + err);
      }
    },
    [user.selected, setOwners, setOwnersLoaded, t]
  );

  const fetchTariffs = useCallback(
    async function () {
      let country_id = user.permissions[user.selected].countryID;
      try {
        const resp = await meteringAPI.getTariffs(user.selected, {country_id});
        const tariffsJson = resp.data.data.map((tariff) => {
          return {name: tariff.name, value: tariff.id};
        });
        setTariffs(tariffsJson);
        setTariffsLoaded(true);
      } catch (err) {
        showPopup(t("tariffsError") + ": " + err);
      }
    },
    [user, setTariffs, setTariffsLoaded, t]
  );

  //Each load has a useEffect to reduce number of reloads due to state changes
  useEffect(() => {
    if (!isOwnersLoaded) fetchSiteOwners();
  }, [isOwnersLoaded, fetchSiteOwners]);

  useEffect(() => {
    if (!isTariffsLoaded) fetchTariffs();
    if (isChanged) {
      setDefaultLocation({lat: lat, lng: long});
      setIsChanged(false);
    }
  }, [isTariffsLoaded, fetchTariffs, lat, long, isChanged]);

  useEffect(() => {
    async function fetchCountries() {
      try {
        const resp = await billingApi.getCountries(user.selected, {});
        let country_list = [];
        resp.data.data.map((ci) => {
          return country_list.push({name: ci.name, value: ci.code});
        });
        setCountries(country_list);
        setCountriesLoaded(true);
      } catch (err) {
        showPopup(getHttpMessage(err), "error");
      }
    }
    if (!isCountriesLoaded) fetchCountries();
  }, [user.selected, isCountriesLoaded]);

  if (!site.site_name || !isOwnersLoaded || !isTariffsLoaded || !isCountriesLoaded) {
    return <Skeleton height={40} count="4" className="m-3" />;
  }

  // prep props for form fields
  const code = getFormProps("code", t("code"), "text", "true", site.site_code);
  const name = getFormProps("name", t("name"), "text", "true", site.site_name);
  const region = getFormProps("region", t("region"), "text", "true", site.region);
  const villages = getFormProps(
    "villages",
    t("villages"),
    "text",
    "false",
    site.villages
  );
  const estates = getFormProps("estates", t("estates"), "text", "false", site.estates);
  const site_groups = getFormProps(
    "site_groups",
    t("site_groups"),
    "text",
    "false",
    site.site_groups
  );
  const country = getFormProps(
    "country",
    t("country"),
    "select",
    "true",
    site.country,
    "",
    countries
  );
  const tariff = getFormProps(
    "tariff",
    t("defaultTariff"),
    "select",
    "true",
    site.default_tariff_id,
    "",
    tariffs
  );
  const owner = getFormProps(
    "owner",
    t("owner"),
    "select",
    "false",
    site.owner_id,
    "",
    owners
  );
  const date = getFormProps(
    "date",
    t("dateCommissioned"),
    "date",
    "true",
    DateHelpers.formatFormDate(site.date_commissioned)
  );
  const dateDecommissioned = getFormProps(
    "date_decommissioned",
    t("dateDecommissioned"),
    "date",
    "false",
    DateHelpers.formatFormDate(site.date_decommissioned)
  );
  const trained = getFormProps(
    "trained",
    t("trained"),
    "switch",
    "false",
    site.is_trained ? "true" : "false"
  );
  const sync_to_vendor = getFormProps(
    "sync_to_vendor",
    t("sync_to_vendor"),
    "switch",
    "true",
    site.sync_to_vendor ? "true" : "false"
  );
  const picker = getMapPickerProps(
    defaultLocation,
    zoom,
    lat,
    long,
    handleChangeZoom,
    setLat,
    setLong,
    setIsChanged,
    handleChangeLocation,
    handleResetLocation
  );

  let country_id = user.permissions[user.selected].countryID;

  // function to call when submitting the form
  const onSubmit = (data) => {
    const sitesForAPI = [
      {
        id: site.site_id,
        code: data.code,
        name: data.name,
        region: data.region,
        villages: data.villages,
        estates: data.estates,
        site_groups: data.site_groups,
        sync_to_vendor: data.sync_to_vendor,
        country: data.country,
        latitude: parseFloat(data.latitude),
        longitude: parseFloat(data.longitude),
        owner_id: data.owner,
        default_tariff_id: data.tariff,
        is_trained: +data.trained,
        organization: site.organization,
        date_commissioned: data.date,
        date_decommissioned: data.date_decommissioned,
        country_id,
      },
    ];
    sendToAPI(user.selected, sitesForAPI);
  };

  async function sendToAPI(portfolio, sites) {
    setSaving(true);
    try {
      await meteringAPI.updateSite(portfolio, sites);
      showPopup(t("shared:success"), "success");
      setSiteLoading(true);
    } catch (err) {
      showPopup(getHttpMessage(err));
    }
    setSaving(false);
  }

  return (
    <div className="mt-2 shadow bg-white border rounded">
      <Form id="site_dashboard" onSubmit={handleSubmit(onSubmit)} className="m-4">
        <Row>
          <FormField register={register} {...name}></FormField>
          <FormField register={register} {...code}></FormField>
          <FormField register={register} {...region}></FormField>
          <FormSelect register={register} {...country}></FormSelect>
          <FormSelect register={register} {...tariff}></FormSelect>
          <FormSelect register={register} {...owner}></FormSelect>
          <FormField register={register} {...date}></FormField>
          <FormField register={register} {...dateDecommissioned} />
          <Row style={{whiteSpace: "nowrap"}}>
            <FormCheck register={register} {...trained}></FormCheck>
            <FormCheck register={register} {...sync_to_vendor}></FormCheck>
          </Row>
        </Row>
        <Row>
          <FormField register={register} {...villages}></FormField>
          <FormField register={register} {...estates}></FormField>
          <FormField register={register} {...site_groups}></FormField>
        </Row>
        <GoogleMapPicker register={register} {...picker} />
        <Row>
          <Col>
            {meteringAPI.isSteamacoSite(site) && <CopyTariff siteCode={site.site_code} />}
            <SaveButton
              label={t("saveChanges")}
              disabled={saving}
              md={3}
              lg={2}
              xs={6}
            ></SaveButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
