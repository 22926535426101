import React, {useEffect, useState, useContext, useCallback} from "react";
import {Form, Table, Modal, Button} from "react-bootstrap";
import {usePermissions, Unauthorized} from "../permissions";
import {showPopup, getHttpMessage, showErrors} from "../generics/alerts";
import {UserContext} from "../login/userContext";
import {useTranslation} from "react-i18next";
import {FormErrors} from "../generics/formsFields";
import {ListHeader} from "../generics/headers";
import Skeleton from "react-loading-skeleton";
import * as notificationAPI from "../../services/notificationApi";
import * as billingAPI from "../../services/billingApi";

export default function TemplateList() {
  const canView = usePermissions(notificationAPI.NOTIFICATION_TEMPLATES_LIST_PATH);
  const {t} = useTranslation(["sms"]);
  const user = useContext(UserContext);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchSmsTemplates = useCallback(
    async function () {
      let country_id = user.permissions[user.selected].countryID;
      try {
        const resp = await notificationAPI.getSmsTemplates(user.selected, {country_id});
        setTemplates(resp.data);
        setLoading(false);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
    },
    [user]
  );

  if (!canView) {
    return <Unauthorized />;
  }

  return (
    <div>
      <ListHeader title={t("smsTemplates")} icon="envelope-open"></ListHeader>
      <CreateTemplate fetchSmsTemplates={fetchSmsTemplates} />
      <GetSmsTemplates
        fetchSmsTemplates={fetchSmsTemplates}
        templates={templates}
        loading={loading}
      />
    </div>
  );
}

export function GetSmsTemplates({templates, fetchSmsTemplates, loading}) {
  const {t} = useTranslation(["sms"]);
  useEffect(() => {
    fetchSmsTemplates();
  }, [fetchSmsTemplates]);

  if (loading) {
    return <Skeleton count={10} />;
  }

  return (
    <Table response="sm" striped bordered hover responsive>
      <thead>
        <tr>
          <th>{t("templateType")}</th>
          <th>{t("message")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {templates.map((template) => (
          <TemplateRow
            fetchSmsTemplates={fetchSmsTemplates}
            template={template}
            key={template.id}
          />
        ))}
      </tbody>
    </Table>
  );

  function TemplateRow({template, fetchSmsTemplates}) {
    return (
      <tr>
        <td>{template.template_type}</td>
        <td>{template.message}</td>
        <td>
          <EditTemplate fetchSmsTemplates={fetchSmsTemplates} template={template} />
        </td>
      </tr>
    );
  }
}

export function MockedModal({children, ...otherProps}) {
  return (
    <div mock="modal" {...otherProps}>
      {children}
    </div>
  );
}

export function CreateTemplate({fetchSmsTemplates}) {
  const {t} = useTranslation(["sms"]);
  const user = useContext(UserContext);
  const [selectMessage, setSelectMessage] = useState("");
  const [selectTemplate, setSelectTemplate] = useState("");
  const [selectLanguage, setSelectLanguage] = useState("");
  const [selectCountry, setSelectCountry] = useState("");
  const [countryInstance, setCountryInstance] = useState([]);
  const [templateType, setTemplateType] = useState([]);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const canCreate = usePermissions(notificationAPI.NOTIFICATION_TEMPLATES_LIST_PATH);
  var disableCreate = false;
  var Createbutton = "";

  if (!canCreate) {
    Createbutton = t("shared:unauthorized");
    disableCreate = true;
  }

  const saveTemplate = () => {
    setErrors([]);
    var data = {
      message: selectMessage,
      template_type: parseInt(selectTemplate),
      country_id: parseInt(selectCountry),
      language_id: parseInt(selectLanguage),
    };

    notificationAPI
      .postSmsTemplate(user.selected, data)
      .then((resp) => {
        fetchSmsTemplates();
        showPopup(t("shared:success"), "success");
        setShow(false);
        setSelectLanguage("");
        setSelectMessage("");
        setSelectCountry("");
        setSelectTemplate("");
      })
      .catch((e) => {
        showErrors(e, setErrors);
      });
  };

  useEffect(() => {
    async function fetchTemplatesTypes() {
      try {
        const resp = await notificationAPI.getTemplateTypes(user.selected);
        setTemplateType(resp.data);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
    }
    fetchTemplatesTypes();
  }, [user.selected]);

  useEffect(() => {
    async function fetchCountryInstance() {
      try {
        const resp = await billingAPI.getCountries(user.selected, {});
        let country_list = [];
        resp.data.data.map((ci) => {
          return country_list.push({name: ci.name, value: ci.id});
        });
        setCountryInstance(country_list);
      } catch (err) {
        showPopup(getHttpMessage(err), "error");
      }
    }
    fetchCountryInstance();
  }, [user.selected]);

  return (
    <>
      <Button
        id="create-template"
        variant="danger"
        onClick={handleShow}
        style={{marginLeft: "auto"}}
        title={Createbutton}
        disabled={disableCreate}
        className="pull-right mb-2"
      >
        {t("createTemplate")}
      </Button>

      <Modal
        dialogClassName="custom-modal-style"
        id="form"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("createTemplate")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Group>
                <FormErrors errors={errors}></FormErrors>
                <Form.Control
                  as="select"
                  required
                  value={selectTemplate}
                  onChange={(e) => setSelectTemplate(e.target.value)}
                >
                  <option>{t("templateType")}</option>
                  {templateType.map((template) => (
                    <option key={template.Id} value={template.Id}>
                      {" "}
                      {template.Name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  required
                  as="select"
                  value={selectCountry}
                  onChange={(e) => setSelectCountry(e.target.value)}
                >
                  <option>{t("countryInstance")}</option>
                  {countryInstance.map((ci) => (
                    <option key={ci.value} value={ci.value}>
                      {" "}
                      {ci.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  required
                  as="select"
                  name="Template Language"
                  value={selectLanguage}
                  onChange={(e) => setSelectLanguage(e.target.value)}
                >
                  <option>{t("languageType")}</option>
                  <option key={1} value={1}>
                    {t("language1")}
                  </option>
                  <option key={2} value={2}>
                    {t("language2")}
                  </option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("textArea")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  type="text"
                  id="message"
                  required
                  name="message"
                  placeholder={t("placeholder")}
                  value={selectMessage}
                  onChange={(e) => setSelectMessage(e.target.value)}
                />
              </Form.Group>
              <Button
                className="mr-3 text-white float-right"
                variant="warning"
                onClick={handleClose}
              >
                {t("cancel")}
              </Button>
              <Button className="mr-3  float-right" onClick={saveTemplate}>
                {t("createTemplate")}
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export function EditTemplate({template, fetchSmsTemplates}) {
  const user = useContext(UserContext);
  const {t} = useTranslation(["sms"]);
  const [selectMessage, setSelectMessage] = useState(template.message);
  const [selectTemplate, setSelectTemplate] = useState(template.template_type);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const canEdit = usePermissions(notificationAPI.NOTIFICATION_TEMPLATES_LIST_PATH);
  var disableEdit = false;
  var Editbutton = "";

  if (!canEdit) {
    Editbutton = t("shared:unauthorized");
    disableEdit = true;
  }

  const updateTemplates = (e) => {
    e.preventDefault();
    const data = {
      template_id: template.id,
      message: selectMessage,
    };
    notificationAPI
      .editSmsTemplate(user.selected, data)
      .then((result) => {
        showPopup(t("shared:success"), "success");
        fetchSmsTemplates();
      })
      .catch((e) => {
        showPopup(getHttpMessage(e));
      });
    setSelectMessage(data.message);
    setSelectTemplate(template.template_type);
    setShow(false);
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        style={{marginLeft: "auto"}}
        title={Editbutton}
        disabled={disableEdit}
        id="edit-template"
      >
        {t("edit")}
      </Button>

      <Modal
        dialogClassName="custom-modal-style"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("editTemplate")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Group>
                <Form.Control required as="select" value={selectTemplate} disabled>
                  <option>{selectTemplate}</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("textArea")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  type="text"
                  id="message"
                  required
                  name="message"
                  placeholder="Enter template message"
                  value={selectMessage}
                  feedback="error"
                  onChange={(e) => setSelectMessage(e.target.value)}
                />
              </Form.Group>
              <Button
                className="mr-3 text-white float-right"
                variant="warning"
                onClick={handleClose}
              >
                {t("cancel")}
              </Button>
              <Button className="mr-3 text-white float-right" onClick={updateTemplates}>
                {t("editTemplate")}
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
