import React from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus} from "@fortawesome/free-solid-svg-icons";
import {ProductForm} from "./registerItem";

export default function UpdateProduct({product, show, setShow, fetchProductsList}) {
  const handleClose = () => setShow(false);
  const {t} = useTranslation(["product", "shared"]);
  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className="mb-4 text-primary">
            <FontAwesomeIcon icon={faCartPlus} /> {t("updateProductTitle")}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProductForm
          setShow={setShow}
          product={product}
          fetchProductsList={fetchProductsList}
        />
      </Modal.Body>
    </Modal>
  );
}
