import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, Modal, Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as authApi from "../../services/authApi";
import {usePermissions} from "../permissions";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {showPopup, getHttpMessage} from "../generics/alerts";

export function CreateUser({fetchUsers}) {
  const {t} = useTranslation(["users"]);
  const canView = usePermissions(authApi.USERS_PATH, "POST");
  const [show, setShow] = useState(false);
  const [userDetail, setUserDetail] = useState({roles: []});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  var CreateUserButton = "";
  var disabled = false;
  if (!canView) {
    CreateUserButton = t("shared:unauthorized");
    disabled = true;
  }

  async function saveNewUser(u) {
    await authApi.createUser(u);
    fetchUsers();
  }

  function setUserName(e) {
    const username = e.target.value;
    setUserDetail({...userDetail, username: username});
  }

  return (
    <>
      <Button
        variant="danger"
        className="mt-4 pull-right"
        title={CreateUserButton}
        disabled={disabled}
        onClick={handleShow}
      >
        <FontAwesomeIcon icon="plus"></FontAwesomeIcon> {t("addUser")}
      </Button>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton style={{borderBottomWidth: 0}}>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserForm
            userDetail={userDetail}
            saveFunc={saveNewUser}
            setShow={setShow}
            setUserDetail={setUserDetail}
          >
            <Form.Group controlId="formBasicEmail">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                value={userDetail.username}
                onChange={setUserName}
              />
            </Form.Group>
          </UserForm>
        </Modal.Body>
      </Modal>
    </>
  );
}

export function EditUser({userDetail, setUserDetail, fetchUser}) {
  const {t} = useTranslation(["users"]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function updateUser(u) {
    await authApi.updateUser(u);
    fetchUser();
  }

  return (
    <>
      <Button variant="danger" className="ml-2 mr-2 pull-right" onClick={handleShow}>
        <FontAwesomeIcon icon="edit"></FontAwesomeIcon> {t("shared:edit")}
      </Button>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton style={{borderBottomWidth: 0}}>
          <Modal.Title>{userDetail.username}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserForm
            userDetail={userDetail}
            saveFunc={updateUser}
            setShow={setShow}
            setUserDetail={setUserDetail}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

function UserForm({userDetail, setUserDetail, saveFunc, setShow, children}) {
  const {t} = useTranslation(["users"]);
  const [roleAssignments, setRoleAssignments] = useState(userDetail.roles);
  const [disableSave, setDisableSave] = useState(true);
  const [portfolios, setPortfolios] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const roleResp = await authApi.getAllRoles();
        setRoles(roleResp.data);

        const portResp = await authApi.getPortfolios();
        setPortfolios(portResp.data);
        authApi.populateIds(userDetail.roles, roleResp.data, portResp.data);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
    }
    fetchData();
    return () => setUserDetail({roles: []}); // cleanup user details after CreateUser component is unmounted
  }, [userDetail.roles, setUserDetail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableSave(true);
    try {
      var roles = authApi.reformatRoleAssignments(roleAssignments);
      const userInfo = {
        username: userDetail.username,
        id: userDetail.id,
        roles: roles,
      };
      await saveFunc(userInfo);
      setShow(false);
      showPopup(t("shared:success"), "success");
    } catch (err) {
      console.error(err);
      showPopup(getHttpMessage(err));
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {children}
      <Form.Group>
        {roleAssignments.map((role, i) => (
          <UserRole
            role={role}
            key={i}
            roleAssignments={roleAssignments}
            setRoleAssignments={setRoleAssignments}
            setDisableSave={setDisableSave}
          />
        ))}
      </Form.Group>
      <NewRoleAssignment
        roles={roles}
        portfolios={portfolios}
        roleAssignments={roleAssignments}
        setRoleAssignments={setRoleAssignments}
        setDisableSave={setDisableSave}
      />
      <Button
        type="submit"
        variant="danger"
        className="ml-3 text-white float-right"
        disabled={disableSave}
      >
        Save
      </Button>
      <Button className="text-dark float-right" variant="light" onClick={handleClose}>
        Cancel
      </Button>
    </Form>
  );
}

function NewRoleAssignment({
  roles,
  portfolios,
  roleAssignments,
  setRoleAssignments,
  setDisableSave,
}) {
  const [roleId, setRoleId] = useState("");
  const [portfolioId, setPortfolioId] = useState("");

  function addRoleAssignment(e) {
    e.preventDefault();
    const username = roles.find((role) => role.id === roleId).name;
    const short_code = portfolios.find(
      (portfolio) => portfolio.id === portfolioId
    ).short_code;

    roleAssignments.push({
      name: username,
      portfolio: short_code,
      roleId: roleId,
      portfolioId: portfolioId,
    });
    setRoleAssignments([...roleAssignments]);
    setDisableSave(false);
  }
  return (
    <Form.Row>
      <Form.Group as={Col} controlId="formGridCity">
        <Form.Control
          type="select"
          name="roles"
          as="select"
          defaultValue="Role"
          onChange={(e) => setRoleId(e.target.value)}
        >
          <option disabled>Role</option>
          {roles.map((role) => (
            <option key={role.id} value={role.id}>
              {role.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group as={Col} controlId="formGridCity">
        <Form.Control
          type="select"
          name="portfolio"
          as="select"
          defaultValue="BU"
          onChange={(e) => setPortfolioId(e.target.value)}
        >
          <option disabled>BU</option>
          {portfolios.map((portfolio) => (
            <option key={portfolio.id} value={portfolio.id}>
              {portfolio.short_code}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group as={Col} controlId="formGridCity">
        <Button variant="primary" onClick={addRoleAssignment}>
          <FontAwesomeIcon icon={faPlus} variant="warning" size="lg"></FontAwesomeIcon>
        </Button>
      </Form.Group>
    </Form.Row>
  );
}

function UserRole({role, roleAssignments, setRoleAssignments, setDisableSave}) {
  var index = findIndex(role);
  function deleteRoleAssignment(e) {
    e.preventDefault();
    roleAssignments.splice(index, 1);
    setRoleAssignments([...roleAssignments]);
    setDisableSave(false);
  }
  function findIndex(role) {
    var index = roleAssignments.indexOf(role);

    return index;
  }
  return (
    <Form.Row>
      <Form.Group as={Col} controlId="formGridCity">
        <p>
          <b>
            {role.name.toUpperCase()} {role.portfolio}
          </b>
        </p>
      </Form.Group>
      <Form.Group as={Col} controlId="formGridCity">
        <p>
          <b> </b>
        </p>
      </Form.Group>
      <Form.Group as={Col} controlId="formGridCity">
        <Button variant="primary" onClick={deleteRoleAssignment}>
          <FontAwesomeIcon icon={faTimes} size="lg"></FontAwesomeIcon>
        </Button>
      </Form.Group>
    </Form.Row>
  );
}
