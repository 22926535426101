import React, {useState, useContext} from "react";
import {useLocation} from "react-router-dom";
import {Form, InputGroup, FormControl, Button, Modal, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {UserContext} from "../login/userContext";
import {showPopup, getHttpMessage} from "../generics/alerts";
import * as notificationAPI from "../../services/notificationApi";
import {UploadProgressBar, handleDataUpload} from "../generics/fileUploads";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from "react-hook-form";

export default function SendSMS({
  customerList,
  modalShow,
  setModalShow,
  selectAllInSearch,
  count,
}) {
  const {t} = useTranslation(["bulkActions", "shared"]);
  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-success ml-2">
            <FontAwesomeIcon icon="sms" />
            {"   " + t("sendSMS")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SendSMSForm
            customerList={customerList}
            setModalShow={setModalShow}
            selectAllInSearch={selectAllInSearch}
            count={count}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export function SendSMSForm({customerList, setModalShow, selectAllInSearch, count}) {
  const {register, handleSubmit} = useForm();
  const {t} = useTranslation(["bulkActions", "shared"]);
  const [errors, setErrors] = useState([]);
  const [progress, setProgress] = useState(0); // %
  const user = useContext(UserContext);
  const location = useLocation();

  const onSubmit = (data) => {
    let phoneNos = [];
    let searchParams = "";
    if (selectAllInSearch) {
      searchParams = location.search.replace("?", "").replace("search_value", "search");
      if (searchParams.length === 0) {
        setErrors([t("warningUsingSearchParams")]);
        return;
      }
    } else {
      customerList.forEach((c) => {
        phoneNos.push(c.PhoneNumber);
      });
    }
    const payload = {
      message: data.sms,
      recipients: phoneNos,
      search_params: searchParams,
    };
    handleDataUpload(setProgress, sendSms, payload);
  };

  async function sendSms(data, config) {
    try {
      await notificationAPI.sendSms(user.selected, data, config);
      showPopup(t("shared:success"), "success");
      setErrors([]);
      setModalShow(false);
    } catch (err) {
      setErrors(getHttpMessage(err));
      setProgress(0);
    }
  }

  return (
    <>
      <UploadProgressBar
        progress={progress}
        progressVariant="success"
        title={t("sending")}
      />
      <div className="ml-3" style={{color: "red"}}>
        {errors}
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4">
        <Row className="mb-3">
          <Col>
            {t(
              "warningSendSMS",
              selectAllInSearch ? {count: count} : {count: customerList.length}
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>{t("sms") + ":"} </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="textarea"
                name="sms"
                placeholder={t("addSMS")}
                ref={register}
                required
              />
            </InputGroup>{" "}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit" variant="danger" className="ml-3 p-3 pull-right">
              {t("sendSMS")}
            </Button>
            <Button
              variant="light"
              onClick={() => setModalShow(false)}
              className="ml-3 p-3 pull-right"
            >
              {t("cancel")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
