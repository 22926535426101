export const en = {
  //generic
  registerPermission: "You don't have permission",
  warningUsingSearchParams:
    "Could not process the action for all customers, you must add a search or choose the site or select customer(s).",
  cancel: "Cancel",
  saving: "Saving...",

  moveCustomer: "Move Customer",
  deactivate: "Deactivate",
  deactivating: "Deactivating...",
  wait: "Wait...",
  deactivateMessage: "You want to deactivate {{accountNo}}?",
  bulkDeactivateMessage: "You want to deactivate {{count}} customer(s)?",
  deactivateNote: "Deactivate customer(s)",
  deactivatePermission: "Not Authorized to Deactivate Customers",
  deactivateTitle: "Are you sure",
  ok: "OK",
  success: "Success",
  deactivated: "Deactivated ",
  failedDeactivation: "Deactivation Failed",

  load: "Load ",
  bulkRegister: "Bulk Registration",
  downloadSample: "Download Sample",
  siteInstruction: "All sites must already be registered in AMIni",
  languageInstruction: "Language must be ENGLISH or KISWAHILI",
  uploadErrorMissingHeaders: "Upload Error: Missing data",
  reUpload: "Re-upload",
  rows: "row(s)",
  pleaseUpload: "Upload a CSV or Excel file with the following headers:",
  selectFile: "Select File to Upload",
  sampleFile: "sample_file_to_upload.csv",
  fileUpload: "File Upload",
  dataPreview: "Data Preview",
  errors: "Errors",
  uploading: "Uploading...",
  loadRow: "Load {{count}} row(s)",

  //send sms
  sendSMS: "Send SMS",
  sending: "Sending SMS...",
  sendNote: "Send SMS.",
  sendSMSPermission: "You don't have permission to send SMS.",
  addSMS: "Write SMS message here...",
  all: "All",
  warningSendSMS:
    "You are about to send {{count}} message(s) from the customer list you have selected",

  // assign tariff
  tariff: "Tariff",
  updateTariff: "Switch Tariff",
  switchTariff: "Switch Tariff",
  successChangeTariff: "Successful tariff changed",
  failedAssign: "Fail to assign tariff",
  assigning: "Assigning Tariff",
  assignTariffNote: "Assign Tariff",
  tariffsError: "Failed to get tariffs from DB",
  warningAssignTariff:
    "You are about to assign a tariff to {{count}} customer(s) you have selected",

  // bulk actions
  clearSelection: "Clear selection",
  selectAllResults: "Select all results that match this search",
  selectedCountCustomers: "Selected {{count}} customer(s)",

  //bonus disbursement
  bonusDisbursement: "Bonus Disbursement",
  warningDisburseBonus:
    "You are about to disburse a bonus payment to {{count}} customer(s) you have selected",
  addBonusAmount: "Add Bonus Amount:",
  disbursing: "Disbursing bonus",
  bonusDisburseNote: "Disburse Bonus",

  //MeteStateNotes
  meterStateNote: "Turn on/off/auto meters",
  warningChangeMeterState:
    "You are about to change meter state to {{count}} customer(s) you have selected",
  selectState: "Select State",
  okButton: "Ok",
  changeMeterStatus: "Changing Meter State",
  meterStateHeader: "Change meter state on/off/auto ",

  //Tags Note
  assignTagNote: "Assign tag to customer(s)",
  manageTags: "Manage Tags",
  assignTagTitle: "Assigning tag...",
  newSelectionPrefix: "Add new tag: ",
  typeTag: "Type a tag...",
  assignCheckbox: "Assign",
  unassignCheckbox: "Unassign",
  warningAssignTag:
    "You are about to {{action}} a tag to {{count}} customer(s) you have selected",
};

export const fr = {};
