// 0 == production BASE_URL
// 2 == amini production BASE_URL
// 3 == test BASE_URL

// serviceBaseURL function returns service base URL.
export function serviceBaseURL(portfolio, path) {
  if (process.env.REACT_APP_IS_TEST === "2") {
    return `https://amini.powergen-re.net`; // one VM production base url
  }
  return `https://${portfolio}.powergen-re.net`; // base url depending on the portfolio code
}

//  BASE_URL returns Auth base URL
export const AUTH_BASE_URL =
  process.env.REACT_APP_IS_TEST === "0" || process.env.REACT_APP_IS_TEST === "2" // Both amini production and amini-porfolios shares the auth service
    ? "https://services.powergen-re.net"
    : "https://test.powergen-re.net";
