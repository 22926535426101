import React, {useState, useEffect, useContext} from "react";
import {UserContext} from "../login/userContext";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import * as meteringAPI from "../../services/meteringApi";
import {usePermissions} from "../permissions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getHttpMessage, showErrors, showPopup} from "../generics/alerts";
import {FormCheck, FormErrors} from "../generics/formsFields";

export default function CopyTariff({siteCode}) {
  const canView = usePermissions(meteringAPI.COPY_TARIFF_PATH, "POST");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {t} = useTranslation(["sites"]);

  return (
    <div>
      <Button
        className="mt-4 ml-3 mb-1 pull-right"
        md={3}
        lg={2}
        xs={6}
        test-id="copy-tariff"
        variant="danger"
        disabled={!canView}
        onClick={handleShow}
      >
        <FontAwesomeIcon icon="copy"></FontAwesomeIcon> {t("copyTariff")}
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton className="text-secondary">
          <Modal.Title className="text-primary ml-2">{t("copyTariffSite")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mr-4">
          <CopyTariffForm setShow={setShow} siteCode={siteCode}></CopyTariffForm>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export function CopyTariffForm({setShow, siteCode}) {
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const user = useContext(UserContext);
  const {t} = useTranslation(["sites"]);
  const [selectedSites, setSelectedSites] = useState([]);
  useEffect(() => {
    //fetch sites
    async function fetchSites() {
      setLoading(true);
      let country_id = user.permissions[user.selected].countryID;
      try {
        let resp = await meteringAPI.getSites(user.selected.toLowerCase(), {country_id});
        //get only steamaco sites
        let steamacoSites = resp.data.filter((site) => {
          return meteringAPI.isSteamacoSite(site);
        });
        setSites(steamacoSites);
      } catch (err) {
        setSites({});
        showPopup(getHttpMessage(err));
      } finally {
        setLoading(false);
      }
    }

    fetchSites();
  }, [siteCode, user]);

  /** Add or remove site from selected sites when checkbox is checked/unchecked*/
  function handleCheckBox(event) {
    let newSelectedSites = selectedSites;
    if (event.target.checked) {
      newSelectedSites.push(event.target.name);
      setSelectedSites(newSelectedSites);
    } else {
      //remove from array if present
      const index = newSelectedSites.indexOf(event.target.name);
      if (index > -1) {
        newSelectedSites.splice(index, 1);
      }
      setSelectedSites(newSelectedSites);
    }
  }

  async function handleSubmit(ev) {
    //if not sites selected, display error.
    if (selectedSites.length < 1) {
      setErrors([t("noSiteSelected")]);
      return;
    }

    //build api post data
    let data = {from_site_code: siteCode, to_site_code: selectedSites};
    try {
      setErrors([]);
      setSaving(true);
      await meteringAPI.copyTariff(user.selected, data);
      showPopup(t("copySuccess"), "success");
      setShow(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setSaving(false);
  }
  return (
    <div>
      {loading && <p>{t("loading")}</p>}
      <FormErrors errors={errors}></FormErrors>
      <div style={{maxHeight: "300px", overflowY: "auto"}}>
        {sites ? (
          sites.map((site) => {
            return (
              <FormCheck
                key={sites.site_id}
                type="checkbox"
                name={site.site_code}
                label={site.site_name}
                onChange={handleCheckBox}
              ></FormCheck>
            );
          })
        ) : (
          <br />
        )}
      </div>
      <Button
        onClick={handleSubmit}
        variant="danger"
        className="mt-3 mb-1 p-2 pull-right"
        disabled={loading || saving}
      >
        <FontAwesomeIcon className=" mx-1" icon="copy"></FontAwesomeIcon>
        {saving ? t("saving") : t("copyTariff")}
      </Button>
    </div>
  );
}
