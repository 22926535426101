import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Button, Col, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as alerts from "../generics/alerts";
import {usePermissions} from "../permissions";
import * as authApi from "../../services/authApi";

export function AddRoleForm({setIsLoaded}) {
  const {t} = useTranslation(["roles"]);
  const [role, setRole] = useState({
    name: "",
    permission: [],
  });

  const canAddRole = usePermissions(authApi.ROLES_PATH, "POST");

  const addRole = async (role) => {
    try {
      const resp = await authApi.addRole(role);
      setIsLoaded(resp.data);
      alerts.showPopup(t("shared:success"), "success");
    } catch (error) {
      const message = alerts.getHttpMessage(error);
      alerts.showPopup(message);
    }
  };

  const handleChange = (e) => setRole({...role, name: e.target.value});

  const handleSubmit = (e) => {
    e.preventDefault();
    addRole(role);
  };

  return (
    <Form onSubmit={handleSubmit} className="mb-4">
      <Form.Row>
        <Col xs={6} md={3}>
          <Form.Control
            required
            value={role.name}
            placeholder="New Role Name"
            onChange={handleChange}
            disabled={!canAddRole}
            title={!canAddRole ? t("noPermission") : null}
            className="p-1"
          />
        </Col>
        <Col>
          <Button
            disabled={!canAddRole}
            type="submit"
            variant="danger"
            className="text-white px-3 ml-3"
            title={!canAddRole ? t("noPermission") : null}
          >
            <FontAwesomeIcon icon="plus" className="mr-2"></FontAwesomeIcon>
            {t("shared:add")}
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}

export const ConfirmDeleteRoleModal = ({
  roleDetails,
  setIsLoaded,
  setShowDeleteConfirm,
  showDeleteConfirm,
}) => {
  const {t} = useTranslation(["roles"]);
  const {id, name} = roleDetails;
  const text = `${t("confirmDelete")} ${name} ${t("role")}?`;

  const handleClose = () => {
    setShowDeleteConfirm(false);
  };

  const handleDelete = async () => {
    try {
      const resp = await authApi.deleteRole({id});
      setIsLoaded(resp.data);
      alerts.showPopup(t("shared:success"), "success");
      setShowDeleteConfirm(false);
    } catch (error) {
      const message = alerts.getHttpMessage(error);
      setShowDeleteConfirm(false);
      alerts.showPopup(message);
    }
  };

  return (
    <Modal centered show={showDeleteConfirm} onHide={handleClose} backdrop="static">
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer style={{borderTopWidth: 0}}>
        <Button variant="light" onClick={handleClose}>
          {t("shared:cancel")}
        </Button>
        <Button variant="primary" onClick={handleDelete} className="text-white">
          {t("shared:delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
