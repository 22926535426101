import React, {useContext, useState, useEffect} from "react";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";
import {Container} from "react-bootstrap";
import {ToastContainer} from "react-toastify";
import ReactGA from "react-ga";
import "./App.scss";
import "./i18n";
import "./fontAwesome.js";

import Login from "./components/login/login";
import {UserContext} from "./components/login/userContext";
import * as authApi from "./services/authApi";
import NavBar from "./components/nav/navbar";
import CustomerList from "./components/customer/list";
import SiteList from "./components/sites/list";
import TransactionLogs from "./components/transactions/transactionLogs";
import ProductAccountsList from "./components/product/accountsList";
import ProductPaymentPlanList from "./components/product/paymentPlanList";
import UsersList from "./components/users/list";
import RolesList from "./components/roles/list";
import RolePermissionsList from "./components/roles/editRole";
import CustomerDashboard from "./components/customer/dashboard";
import SiteDashboard from "./components/sites/dashboard";
import BonusList from "./components/bonus/list";
import TemplateList from "./components/sms/templateList";
import RegisterSite from "./components/sites/register";
import SmsLogs from "./components/sms/list";
import RegisterCustomer from "./components/customer/register";
import ServerList from "./components/servers/list";
import ServerDashboard from "./components/servers/dashboard";
import Permissions from "./components/authpermissions/list";
import Portfolios from "./components/portfolios/list";
import ProductItem from "./components/product/itemList";
import TariffList from "./components/tariffs/list";
import CreateTariff from "./components/tariffs/createTariff";
import TariffDashboard from "./components/tariffs/dashboard";
import FileUpload from "./components/uploadFile/uploadPage";
import TagList from "./components/tags/list";
import CollectionList from "./components/collection/list";
import Elewa from "./components/reports/elewa";
import CSAT from "./components/reports/csat";
import RegisterAminiUpdates from "./components/aminiUpdates/register";
import UpdateAminiUpdates from "./components/aminiUpdates/update";
import AminiUpdatesDashboard from "./components/aminiUpdates/dashboard";
import CountriesList from "./components/countries/list";
//import {GoogleLogout} from "react-google-login";
import {AminiUpdates} from "./components/generics/alerts";

export default function App() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  let location = useLocation();
  // https://github.com/react-ga/react-ga/wiki/Using-history-for-GA-Tracking
  // https://github.com/react-ga/react-ga/issues/336
  const trackingId = "UA-205850358-1";
  ReactGA.initialize(trackingId, {testMode: process.env.NODE_ENV === "test"});

  useEffect(() => {
    function logPageViewToGoogleAnalytics() {
      const page = location.pathname;
      ReactGA.set({page: page});
      ReactGA.pageview(page);
    }

    async function fetchCurrentUser() {
      if (!isUserLoaded) {
        try {
          const user = await authApi.getCurrentUser();
          setUser(user);
          ReactGA.set({
            userId: user.username,
          });
        } catch (e) {
          setUser(null);
        }
        setIsUserLoaded(true);
      }
    }
    fetchCurrentUser();
    logPageViewToGoogleAnalytics();
  }, [location, isUserLoaded]);

  // https://stackoverflow.com/questions/42464888/how-do-i-change-the-background-color-of-the-body
  // https://www.w3schools.com/colors/color_tryit.asp?color=GhostWhite
  document.body.style.backgroundColor = "ghostwhite";

  if (!isUserLoaded) {
    return <NavBar setUser={setUser} token={token} setToken={setToken} />;
  }
  return (
    <UserContext.Provider value={user}>
      <NavBar setUser={setUser} token={token} setToken={setToken} />
      {/* GoogleLogout added here to enable all resources associated with Google logout to load when the page first renders. This prevents
        errors caused by clicking the logout button when these resources are still loading */}
      {/* <GoogleLogout
        className="d-none"
        clientId="954932680554-kndnf81lo5bkeeso80kvdjqko1v5h8ik.apps.googleusercontent.com"
      ></GoogleLogout> */}
      <Container className="app">
        <ToastContainer />
        <AminiUpdates />
        <Switch>
          <Route path="/login">
            <Login setIsUserLoaded={setIsUserLoaded} setToken={setToken} />
          </Route>
          <Route exact path="/">
            <Redirect to="/customers" />
          </Route>
          <PrivateRoute exact path="/customers/register">
            <RegisterCustomer />
          </PrivateRoute>
          <PrivateRoute exact path="/file/upload">
            <FileUpload />
          </PrivateRoute>
          <PrivateRoute exact path="/customers">
            <CustomerList />
          </PrivateRoute>
          <PrivateRoute exact path="/bonuses">
            <BonusList />
          </PrivateRoute>
          <PrivateRoute exact path="/tariffs">
            <TariffList />
          </PrivateRoute>
          <PrivateRoute path="/tariffs/:id">
            <TariffDashboard />
          </PrivateRoute>
          <PrivateRoute exact path="/tariffs-register">
            <CreateTariff />
          </PrivateRoute>
          <PrivateRoute path="/customers/:id">
            <CustomerDashboard />
          </PrivateRoute>
          <PrivateRoute path="/countries">
            <CountriesList />
          </PrivateRoute>
          <PrivateRoute path="/site/:id">
            <SiteDashboard />
          </PrivateRoute>
          <PrivateRoute path="/sites">
            <SiteList />
          </PrivateRoute>
          <PrivateRoute path="/servers">
            <ServerList />
          </PrivateRoute>
          <PrivateRoute path="/server/:id">
            <ServerDashboard />
          </PrivateRoute>
          <PrivateRoute path="/sites-register">
            <RegisterSite />
          </PrivateRoute>
          <PrivateRoute path="/products/accounts">
            <ProductAccountsList />
          </PrivateRoute>
          <PrivateRoute path="/products/payment-plans">
            <ProductPaymentPlanList />
          </PrivateRoute>
          <PrivateRoute path="/products/items">
            <ProductItem />
          </PrivateRoute>
          <PrivateRoute path="/sms">
            <SmsLogs />
          </PrivateRoute>
          <PrivateRoute path="/smstemplates">
            <TemplateList />
          </PrivateRoute>
          <PrivateRoute path="/transactions">
            <TransactionLogs />
          </PrivateRoute>
          <PrivateRoute path="/users">
            <UsersList />
          </PrivateRoute>
          <PrivateRoute exact path="/roles">
            <RolesList />
          </PrivateRoute>
          <PrivateRoute path="/roles/:id">
            <RolePermissionsList />
          </PrivateRoute>
          <PrivateRoute path="/permissions">
            <Permissions />
          </PrivateRoute>
          <PrivateRoute path="/portfolios">
            <Portfolios />
          </PrivateRoute>
          <PrivateRoute path="/tags">
            <TagList />
          </PrivateRoute>
          <PrivateRoute path="/agents">
            <CollectionList />
          </PrivateRoute>
          <PrivateRoute path="/reports/elewa">
            <Elewa />
          </PrivateRoute>
          <PrivateRoute path="/reports/csat">
            <CSAT />
          </PrivateRoute>
          <PrivateRoute path="/aminiupdates/register">
            <RegisterAminiUpdates />
          </PrivateRoute>
          <PrivateRoute exact path="/aminiupdates">
            <AminiUpdatesDashboard />
          </PrivateRoute>
          <PrivateRoute exact path="/aminiupdates/:id">
            <UpdateAminiUpdates />
          </PrivateRoute>
        </Switch>
      </Container>
    </UserContext.Provider>
  );
}

// A wrapper for <Route> that redirects to the login route if you're not yet authenticated.
// See https://reactrouter.com/web/example/auth-workflow
function PrivateRoute({children, ...rest}) {
  const user = useContext(UserContext);

  //get intended URL path in case we need to redirect to login page
  const currentLocation = useLocation();
  const redirectURL = "?redirect=" + currentLocation.pathname;

  return (
    <Route
      {...rest}
      render={({location}) =>
        user != null ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              search: redirectURL,
              state: {from: location},
            }}
          />
        )
      }
    />
  );
}
