import React, {useEffect, useState, useCallback} from "react";
import {Accordion, Card, Button, Alert, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showPopup} from "../generics/alerts";
import {useTranslation} from "react-i18next";
import {usePermissions, Unauthorized} from "../permissions";
import * as authApi from "../../services/authApi";
import {faClone} from "@fortawesome/free-solid-svg-icons";
import {EditUser, CreateUser} from "./createUser";

// UsersList is the default function for entire user list page
export default function UsersList() {
  const canView = usePermissions(authApi.USERS_PATH);
  const {t} = useTranslation(["users"]);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  const fetchUsers = useCallback(
    async function () {
      try {
        const response = await authApi.getUsers();
        setUsers(response.data);
      } catch (err) {
        setError(t("usersError") + " -- " + err);
      }
    },
    [t]
  );

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  //check if user has access to this page
  if (!canView) {
    return <Unauthorized />;
  }

  return (
    <div>
      <h1 className="mb-4 display-4 text-primary">
        <FontAwesomeIcon icon="users"></FontAwesomeIcon> {t("users")}
        <CreateUser fetchUsers={fetchUsers} />
      </h1>
      <div className="shadow">
        <UsersAccordion users={users} error={error} fetchUsers={fetchUsers} />
      </div>
    </div>
  );
}

// UsersAccordion displays all users in an expandable table
function UsersAccordion({users, error, fetchUsers}) {
  if (error) {
    return (
      <Alert className="p-4" variant="warning">
        {error}
      </Alert>
    );
  }

  return (
    <Accordion defaultActiveKey="0">
      {users.map((user, i) => {
        return <UserCard key={i} idx={i} user={user} fetchUsers={fetchUsers} />;
      })}
    </Accordion>
  );
}

// UserCard is a single, expandable row of the table
function UserCard({user, idx, fetchUsers}) {
  const [userDetail, setUserDetail] = useState({
    id: "",
    username: "",
    roles: [],
  });

  // fetch more detailed user info from api
  const fetchUser = async (e) => {
    const resp = await authApi.getSingleUser(user.id);
    setUserDetail(resp.data);
  };

  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={idx} onClick={fetchUser}>
        {user.username}
        <FontAwesomeIcon icon="angle-down" className="pull-right"></FontAwesomeIcon>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={idx}>
        <UserDetail
          userDetail={userDetail}
          fetchUser={fetchUser}
          fetchUsers={fetchUsers}
          setUserDetail={setUserDetail}
        />
      </Accordion.Collapse>
    </Card>
  );
}

// UserDetails is the contents of an expanded UserCard
function UserDetail({userDetail, fetchUser, setUserDetail, fetchUsers}) {
  // set up card details
  const roleMap = {};

  userDetail.roles.forEach((role) => {
    if (!roleMap[role.name]) {
      roleMap[role.name] = [];
    }

    roleMap[role.name].push(role.portfolio.toUpperCase());
  });

  return (
    <Card.Body>
      {Object.keys(roleMap).map((name, i) => {
        return (
          <p variant="primary" key={i}>
            <b>{name.toUpperCase()}:</b> {roleMap[name].join(", ")}
          </p>
        );
      })}
      <p className="pb-4">
        <DeleteUser userDetail={userDetail} fetchUsers={fetchUsers} />
        <RefreshTokenAPI userDetail={userDetail} />
        <EditUser
          userDetail={userDetail}
          fetchUser={fetchUser}
          setUserDetail={setUserDetail}
        />
      </p>
    </Card.Body>
  );
}

function RefreshTokenAPI({userDetail}) {
  const {t} = useTranslation(["users"]);
  const [showToken, setShowToken] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [token, setToken] = useState();
  const handleClose = () => {
    setShowToken(false);
    setShowModal(false);
  };
  const handleShow = () => setShowModal(true);
  const text = ` ${t("text1")} ${userDetail.username} ${t("text2")}`;

  const canRefresh = usePermissions(authApi.TOKEN_PATH, "POST");
  var buttonTitleRefresh = "";
  var disabledRefresh = false;
  if (!canRefresh) {
    buttonTitleRefresh = t("shared:unauthorized");
    disabledRefresh = true;
  }

  const copyToClipboard = () => {
    const elem = document.createElement("textarea");
    elem.value = token;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  };

  const savePermission = () => {
    var data = {
      id: userDetail.id,
    };
    authApi
      .createUserToken(data)
      .then((resp) => {
        setShowModal(false);
        setShowToken(true);
        setToken(resp.data.token);
      })
      .catch((e) => {
        alert(t("refreshFailed"));
        handleClose();
      });
  };
  return (
    <>
      <Button
        variant="primary"
        className="ml-2 mr-2 pull-right"
        title={buttonTitleRefresh}
        disabled={disabledRefresh}
        onClick={handleShow}
      >
        <FontAwesomeIcon icon="sync-alt"></FontAwesomeIcon> {t("refreshApiToken")}
      </Button>
      <Modal centered show={showToken} onHide={handleClose} backdrop="static" size="lg">
        <Modal.Header style={{borderBottomWidth: 0}}>
          <b>{t("token")}</b>
        </Modal.Header>
        <Modal.Body>
          <div className="border p-4 text-center align-center">
            <small>{token}</small>
            <Button
              className="pull-right"
              title={t("copyToClip")}
              onClick={copyToClipboard}
            >
              <FontAwesomeIcon icon={faClone}></FontAwesomeIcon>
            </Button>
          </div>
          <div>{t("tokentext")}</div>
        </Modal.Body>
        <Modal.Footer style={{borderTopWidth: 0}}>
          <Button variant="light" onClick={handleClose}>
            {t("shared:ok")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={showModal} onHide={handleClose} backdrop="static">
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer style={{borderTopWidth: 0}}>
          <Button variant="light" onClick={handleClose}>
            {t("shared:cancel")}
          </Button>
          <Button variant="primary" onClick={savePermission}>
            {t("shared:refresh")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function DeleteUser({userDetail, fetchUsers}) {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const canView = usePermissions(authApi.USERS_PATH, "DELETE");
  const {t} = useTranslation(["users"]);
  const handleDelete = () => {
    setShowDeleteConfirm(true);
  };

  return (
    <>
      <ConfirmDeleteRoleModal
        userDetail={userDetail}
        showDeleteConfirm={showDeleteConfirm}
        setShowDeleteConfirm={setShowDeleteConfirm}
        fetchUsers={fetchUsers}
      />
      <Button
        onClick={handleDelete}
        disabled={!canView}
        variant="light"
        className="ml-2 mr-2 pull-right"
      >
        <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon> {t("shared:delete")}
      </Button>
    </>
  );
}

function ConfirmDeleteRoleModal({
  userDetail,
  showDeleteConfirm,
  setShowDeleteConfirm,
  fetchUsers,
}) {
  const {t} = useTranslation(["users"]);
  const [disabled, setDisable] = useState(false);
  const {id, username} = userDetail;
  const text = `${t("confirmDelete")} ${username} ?`;
  const handleClose = () => {
    setShowDeleteConfirm(false);
  };
  var data = {
    id: id,
  };
  const handleDelete = async () => {
    try {
      setDisable(true);
      await authApi.deleteUser(data);
      showPopup(t("deleteSuccess"), "success");
      setShowDeleteConfirm(false);
      fetchUsers();
    } catch (error) {
      setShowDeleteConfirm(false);
      showPopup(t("deleteFailure") + ": " + error);
    }
    setDisable(false);
  };
  return (
    <Modal centered show={showDeleteConfirm} onHide={handleClose} backdrop="static">
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer style={{borderTopWidth: 0}}>
        <Button variant="light" onClick={handleClose}>
          {t("shared:cancel")}
        </Button>
        <Button
          variant="primary"
          onClick={handleDelete}
          className="text-white"
          disabled={disabled}
        >
          {t("shared:delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
