import React, {useState, useContext, useEffect} from "react";
import {UserContext} from "../login/userContext";
import {Button, Modal, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as meteringAPI from "../../services/meteringApi";
import {usePermissions} from "../permissions";
import {
  getFormProps,
  InlineFormField,
  InlineFormSelect,
  FormErrors,
} from "../generics/formsFields";
import {showPopup, showErrors} from "../generics/alerts";
import {faCashRegister} from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import * as alerts from "../generics/alerts";

export default function UpdateTariff({
  account_number,
  tariffId,
  vendorId,
  setAccountsAreLoaded,
  setEnergyAccountLoaded,
}) {
  const canView = usePermissions(meteringAPI.ASSIGN_TARIFF_PATH, "POST");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {t} = useTranslation(["customer"]);

  return (
    <>
      <Button
        className="p-1"
        test-id="swap-button"
        variant="primary"
        disabled={!canView}
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faCashRegister}></FontAwesomeIcon> {t("updateTariff")}
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton className="text-secondary">
          <Modal.Title className="text-primary ml-2">{t("switchTariff")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mr-4">
          <TariffSwapForm
            setShow={setShow}
            account_number={account_number}
            defaultValue={tariffId}
            vendorId={vendorId}
            setAccountsAreLoaded={setAccountsAreLoaded}
            setEnergyAccountLoaded={setEnergyAccountLoaded}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export function TariffSwapForm({
  setShow,
  account_number,
  defaultValue,
  vendorId,
  setAccountsAreLoaded,
  setEnergyAccountLoaded,
}) {
  // set up contexts and variables for the form
  const user = useContext(UserContext);
  const {t} = useTranslation(["customer", "shared"]);
  const {register, handleSubmit} = useForm();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [tariffs, setTariffs] = useState([]);
  const [areTariffsLoaded, setTariffsLoaded] = useState(false);
  let portfolioID = user.permissions[user.selected].countryID;

  const account = getFormProps(
    "account",
    t("accountNumber"),
    "text",
    "true",
    account_number,
    "",
    "",
    "true"
  );

  useEffect(() => {
    async function fetchTariffs() {
      setTariffsLoaded(true);
      try {
        const resp = await meteringAPI.getTariffs(user.selected, {
          vendor_id: vendorId,
          country_id: portfolioID,
        });
        const tariffsJson = resp.data.data.map((tariff) => {
          return {name: tariff.name, value: tariff.id};
        });
        setTariffs(tariffsJson);
      } catch (err) {
        alerts.showPopup(alerts.getHttpMessage(err));
      }
    }
    if (!areTariffsLoaded) fetchTariffs();
  }, [vendorId, portfolioID, areTariffsLoaded, user.selected]);

  const tariff = getFormProps(
    "tariffs",
    t("tariff"),
    "select",
    "true",
    defaultValue,
    "",
    tariffs
  );
  // function to call when submitting form
  const onSubmit = (data) => {
    const payload = {
      tariff_id: data.tariffs,
      account_nos: [account_number],
    };
    assignTariff(user.selected, payload);
  };

  async function assignTariff(portfolio, data) {
    try {
      setErrors([]);
      setLoading(true);
      await meteringAPI.assignTariff(portfolio, data);
      showPopup(t("successChangeTariff"), "success");
      setShow(false);
      setEnergyAccountLoaded(false);
      setAccountsAreLoaded(false);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setLoading(false);
  }
  if (!tariffs.length) {
    return <Skeleton height={40} width={420} count="2" className="m-3" />;
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormErrors errors={errors}></FormErrors>
        <InlineFormField register={register} {...account} className="my-4" />
        <InlineFormSelect register={register} {...tariff} className="my-4" />
        <Button
          md={4}
          lg={4}
          xs={6}
          type="submit"
          variant="danger"
          className="mt-2 mb-1 p-3 pull-right"
          disabled={loading}
        >
          <FontAwesomeIcon className=" mx-2" icon={faCashRegister}></FontAwesomeIcon>
          {loading ? t("saving") : t("updateTariff")}
        </Button>
      </Form>
    </div>
  );
}
