import {getTimeDifferenceDays} from "./date";
var ls = require("local-storage");

//save id to local storage so it can prevent updates from showing
export function cancelUpdate(id) {
  ls.set("id", id);
}
/**
 * Check if we should show update pop up message on amini
 * @param {Object} update
 * @returns
 */
export function canShowUpdate(update) {
  //get last updated id saved in local storage
  let savedID = ls("id");

  //if saved id is same as last update id, don't show update
  if (savedID && savedID === update.id) {
    return false;
  }

  //get days difference
  let timeDiffDays = getTimeDifferenceDays(update.date_created_utc);
  //if update occured within the past five days, show update
  if (timeDiffDays <= 5) {
    return true;
  }
  return false;
}
