export const en = {
  listTitle: "Permissions",
  endpoint: "Endpoint",
  search: "Search",
  verb: "Verb",
  del: "Delete",
  confirmDel: "Do you want to delete this permission?",
};

export const fr = {};
