import React, {useState, useContext} from "react";
import {Form, Button, Modal} from "react-bootstrap";
import * as alerts from "../generics/alerts";
import {useTranslation} from "react-i18next";
import {getFormProps, InlineFormField} from "../generics/formsFields";
import {useForm} from "react-hook-form";
import * as billingAPI from "../../services/billingApi";
import {UserContext} from "../login/userContext";

export function RedirectTransactionModal({show, setShow, transaction, refreshGrid}) {
  const user = useContext(UserContext);
  const {t} = useTranslation(["transactions", "shared"]);
  const [loading, setLoading] = useState(false);
  const {register, handleSubmit} = useForm();

  const onSubmit = async (formData) => {
    setLoading(true);
    formData.payment_id = transaction.payment_id;
    await alerts.handleHttp(() =>
      billingAPI.redirectTransaction(user.selected, formData)
    );
    setLoading(false);
    setShow(false);
    refreshGrid();
  };

  const onHide = () => {
    setShow(false);
  };

  const accNo = getFormProps(
    "account_number",
    t("accountNo"),
    "text",
    "true",
    transaction.account_no,
    "",
    [],
    "true"
  );

  const newAccNo = getFormProps("newAccount", t("NewAccountNo"), "text", "true");

  const amount = getFormProps(
    "amount",
    t("amount"),
    "text",
    "true",
    transaction.amount,
    "",
    [],
    "true"
  );

  const ref = getFormProps(
    "reference",
    t("referenceNo"),
    "text",
    "true",
    transaction.reference,
    "",
    [],
    "true"
  );

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="text-primary ml-2">{t("redirect")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Form onSubmit={handleSubmit(onSubmit)} className="m-4" id="transaction_modal">
          <InlineFormField register={register} {...accNo} disabled />
          <InlineFormField register={register} {...amount} disabled />
          <InlineFormField register={register} {...ref} disabled />
          <InlineFormField register={register} {...newAccNo} />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="light">
          {t("shared:cancel")}
        </Button>
        <Button
          type="submit"
          variant="danger"
          form="transaction_modal"
          disabled={loading}
        >
          {loading ? t("saving") : t("redirect")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
