import React, {useState, useContext} from "react";
import {UserContext} from "../login/userContext";
import {Button, Modal, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as billingAPI from "../../services/billingApi";
import {usePermissions} from "../permissions";
import {getFormProps, FormField, FormErrors} from "../generics/formsFields";
import {RegisterButton, ModalCancelButton} from "../generics/buttons";
import {DashboardHeader} from "../generics/headers";
import {showPopup, getHttpMessage, showErrors} from "../generics/alerts";
import * as phoneNumbers from "../../services/phoneNumbers";

export default function ActivateAccount({
  customer,
  siteCode,
  accountNo,
  setAccountsAreLoaded,
}) {
  const canActivate = usePermissions(billingAPI.ACTIVATE_ACCOUNT_PATH, "POST");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {t} = useTranslation(["customer", "shared"]);

  return (
    <div>
      <Button
        disabled={!canActivate}
        title={!canActivate ? t("activateEnergy") : null}
        variant="danger"
        onClick={handleShow}
      >
        <FontAwesomeIcon icon="plus" /> {t("activateEnergy")}
      </Button>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <DashboardHeader title={t("activateEnergy")} icon="user" />
        </Modal.Header>
        <Modal.Body>
          <ActivateForm
            setShow={setShow}
            customer={customer}
            siteCode={siteCode}
            accountNo={accountNo}
            setAccountsAreLoaded={setAccountsAreLoaded}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export function ActivateForm({
  customer,
  accountNo,
  siteCode,
  setShow,
  setAccountsAreLoaded,
}) {
  const user = useContext(UserContext);
  const {t} = useTranslation(["customer", "shared"]);
  const {register, handleSubmit} = useForm();
  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);
  const firstName = getFormProps(
    "first_name",
    t("firstName"),
    "text",
    "true",
    customer.FirstName
  );
  const lastName = getFormProps(
    "last_name",
    t("lastName"),
    "text",
    "true",
    customer.LastName
  );
  const phoneNumber = getFormProps(
    "phone_number",
    t("phoneNumber"),
    "text",
    "true",
    phoneNumbers.removeBrackets(customer.PhoneNos)
  );
  const accNo = getFormProps(
    "account_number",
    t("accountNo"),
    "text",
    "true",
    accountNo,
    "",
    [],
    "true"
  );
  const code = getFormProps(
    "site_code",
    t("accountNo"),
    "text",
    "true",
    siteCode,
    "",
    [],
    "true"
  );
  const meterNumber = getFormProps("meter_number", t("meterNumber"), "text", "true");

  // function to call when submitting form
  const onSubmit = (data) => {
    data.customer_id = customer.Id;
    activateEnergyAccount(user.selected, [data]);
  };

  async function activateEnergyAccount(portfolio, accountData) {
    setErrors([]);
    setSaving(true);
    try {
      await billingAPI.activateEnergyAccount(portfolio, accountData);
      showPopup(t("shared:success"), "success");
      setAccountsAreLoaded(false);
      setShow(false);
    } catch (err) {
      showErrors(getHttpMessage(err), setErrors);
    }
    setSaving(false);
  }
  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4">
        <Row>
          <FormErrors errors={errors}></FormErrors>
          <FormField register={register} {...firstName} />
          <FormField register={register} {...lastName} />
          <FormField register={register} {...phoneNumber} />
          <FormField register={register} {...accNo} />
          <FormField register={register} {...code} />
          <FormField register={register} {...meterNumber} />
          <ModalCancelButton label={t("cancel")} setShow={setShow} />
          <RegisterButton label={t("activateButton")} disabled={saving} />
        </Row>
      </Form>
    </div>
  );
}
