import React, {useContext, useEffect, useState, createRef, useRef} from "react";
import {Row, Col, Form, Button, Tabs, Tab, Card} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserContext} from "../login/userContext";
import * as billingAPI from "../../services/billingApi";
import * as meteringAPI from "../../services/meteringApi";
import * as date from "../../services/date";
import * as phoneNumbers from "../../services/phoneNumbers";
import * as alerts from "../generics/alerts";
import MeterActions from "./meterActions";
import UpdateTariff from "./swapTariff";
import MoveCustomerAccount from "./moveCustomerAccount";
import DeactivateAccount from "./deactivateAccount";
import AssignMeter from "./assignMeter";
import {usePermissions} from "../permissions";
import Skeleton from "react-loading-skeleton";
import ManageTags from "./manageTags";
import UpdateCustomerID from "./updateCustomerID";
import {Typeahead} from "react-bootstrap-typeahead";
import AddLatLongInfo from "./addLatLong";
import RegisterProduct from "../product/register";
import ActivateAccount from "./activateEnergyAccount";

export function InfoTab({customer, accounts, setAccountsAreLoaded}) {
  const {t} = useTranslation(["customer"]);
  return (
    <Tabs defaultActiveKey="info" id="info">
      <Tab eventKey="info" tabClassName="text-primary" title={t("customerInfo")} disabled>
        <InfoCard
          customer={customer}
          accounts={accounts}
          setAccountsAreLoaded={setAccountsAreLoaded}
        />
      </Tab>
    </Tabs>
  );
}

export function EnergyAccountTabs({
  accounts,
  customer,
  fetchAccounts,
  setAccountsAreLoaded,
  inactiveAccount,
  siteCode,
}) {
  const {t} = useTranslation(["customer"]);
  if (accounts.length === 0) {
    return (
      <Tabs id="energy">
        <Tab
          eventKey="header"
          tabClassName="text-primary"
          title={t("energyAcc")}
          disabled
        >
          <Card>
            <Card.Body className="m-2 text-muted text-center">
              <ActivateAccount
                customer={customer}
                accountNo={inactiveAccount}
                siteCode={siteCode}
                setAccountsAreLoaded={setAccountsAreLoaded}
              />
            </Card.Body>
          </Card>
        </Tab>
      </Tabs>
    );
  }

  return (
    <Tabs defaultActiveKey="0" id="energy">
      <Tab
        eventKey="header"
        tabClassName="text-primary"
        title={t("energyAcc")}
        disabled
      />

      {accounts.map((account, i) => {
        return (
          <Tab eventKey={i} title={account.account_number} key={i}>
            <EnergyAccountCard
              account={account}
              customer={customer}
              fetchAccounts={fetchAccounts}
              setAccountsAreLoaded={setAccountsAreLoaded}
            ></EnergyAccountCard>
          </Tab>
        );
      })}
    </Tabs>
  );
}

export function ProductAccountTabs({
  accounts,
  energyAccountNo,
  fetchAccounts,
  setAccountsAreLoaded,
}) {
  const {t} = useTranslation(["customer"]);
  if (accounts.length === 0) {
    return (
      <Tabs id="products">
        <Tab eventKey="header" tabClassName="text-primary" title={t("products")} disabled>
          <Card>
            <Card.Body className="m-2 text-muted text-center">
              {t("noProductAccounts")}
            </Card.Body>
            <Card.Footer className="text-center">
              <RegisterProduct
                setAccountsAreLoaded={setAccountsAreLoaded}
                className="float-center p-2"
                energyAccountNo={energyAccountNo}
              />
            </Card.Footer>
          </Card>
        </Tab>
      </Tabs>
    );
  }

  return (
    <Tabs defaultActiveKey="0" id="products">
      <Tab eventKey="header" tabClassName="text-primary" title={t("products")} disabled />

      {accounts.map((account, i) => {
        return (
          <Tab eventKey={i} title={account.account_number} key={i}>
            <ProductAccountCard
              account={account}
              fetchAccounts={fetchAccounts}
              setAccountsAreLoaded={setAccountsAreLoaded}
              energyAccountNo={energyAccountNo}
            ></ProductAccountCard>
          </Tab>
        );
      })}
    </Tabs>
  );
}

// Card components to contain customer info
function InfoCard({customer, accounts = [], setAccountsAreLoaded}) {
  const user = useContext(UserContext);
  const [languages, setLanguages] = useState([]);
  const {register, handleSubmit} = useForm();
  const {t} = useTranslation(["customer", "shared"]);
  const canUpdate = usePermissions(billingAPI.CUSTOMER_UPDATE_PATH, "PATCH");
  const [loading, setLoading] = useState(false);
  const [site, setSite] = useState({});
  const [siteLoaded, setSiteLoaded] = useState(false);
  const [langLoaded, setLangLoaded] = useState(false);

  let accountSiteCode = accounts.length > 0 ? accounts[0].site_code : "";

  const onSubmit = (data) => {
    const userConfirm = window.confirm(t("saveConfirmation"));
    if (userConfirm) {
      const updatedCustomer = {
        cust_id: customer.Id,
        first_name: data.first_name,
        last_name: data.last_name,
        phone_number: phoneNumbers.removeBrackets(data.phone_number),
        gender: data.gender,
        language_id: parseInt(data.language),
        identification_no: data.identification_no,
        dob: data.dob,
      };
      updateCustomerInfo(user.selected, updatedCustomer);
    }
  };

  useEffect(() => {
    async function fetchLanguages() {
      try {
        let lanJson = [];
        const resp = await billingAPI.getLanguages(user.selected);
        resp.data.data.forEach((lang) => {
          lang.Language = lang.language;
          lang.Lang_Id = lang.id;
          lanJson.push({name: lang.Language, value: lang.Lang_Id});
        });
        setLanguages(lanJson);
      } catch (err) {
        let langJson = [{name: "English", value: 1}];
        setLanguages(langJson);
      }
      setLangLoaded(true);
    }

    async function fetchSite(siteCode) {
      try {
        const resp = await meteringAPI.getSites(user.selected, {code: siteCode});
        if (accounts[0].latitude !== null && accounts[0].longitude !== null) {
          setSite({latitude: accounts[0].latitude, longitude: accounts[0].longitude});
        } else {
          setSite(resp.data.data[0]);
        }
      } catch (err) {
        setSite({latitude: 10, longitude: 10});
      }
      setSiteLoaded(true);
    }
    if (!siteLoaded) fetchSite(accountSiteCode);
    if (!langLoaded) fetchLanguages();
  }, [user.selected, t, siteLoaded, langLoaded, accountSiteCode, accounts]);

  if (!languages.length || !customer.Id) {
    return <Skeleton height={40} width={300} count="4" className="m-3" />;
  }

  async function updateCustomerInfo(portfolio, customer) {
    try {
      setLoading(true);
      await billingAPI.updateCustomer(portfolio, customer);
      alerts.showPopup(t("shared:success"), "success");
    } catch (err) {
      //500 errors are mostly due duplicate phone number on Steamaco
      if (err.response.code >= 500) {
        alerts.showPopup(t("shared:phoneErr"));
      } else {
        alerts.showPopup(alerts.getHttpMessage(err));
      }
    }
    setLoading(false);
  }
  const gender = [
    {name: t("male"), value: "M"},
    {name: t("female"), value: "F"},
  ];

  function getName(value, list) {
    let name = "";
    list.forEach((l) => {
      if (l.value === value) {
        name = l.name;
      }
    });
    return name;
  }
  const genderName = getName(customer.Gender, gender);
  const langName = getName(customer.Language, languages);

  return (
    <Card className="text-right mb-4">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Body>
          <CardFieldText
            register={register}
            name="first_name"
            label={t("first")}
            type="text"
            defaultValue={customer.FirstName}
            readOnly={!canUpdate}
          ></CardFieldText>
          <CardFieldText
            register={register}
            name="last_name"
            label={t("last")}
            type="text"
            defaultValue={customer.LastName}
            readOnly={!canUpdate}
          ></CardFieldText>
          <CardFieldText
            register={register}
            name="phone_number"
            label={t("phoneNo")}
            type="text"
            defaultValue={phoneNumbers.removeBrackets(customer.PhoneNos)}
            readOnly={!canUpdate}
          ></CardFieldText>
          <CardFieldText
            register={register}
            name="identification_no"
            label={t("idNo")}
            type="text"
            defaultValue={customer.IdentificationNo}
            readOnly={!canUpdate}
          ></CardFieldText>
          <CardFieldSelect
            register={register}
            name="language"
            label={t("language")}
            defaultValue={customer.Language}
            defaultName={langName}
            options={languages}
            readOnly={!canUpdate}
          ></CardFieldSelect>
          <CardFieldSelect
            register={register}
            name="gender"
            label={t("gender")}
            defaultValue={customer.Gender}
            defaultName={genderName}
            options={gender}
            readOnly={!canUpdate}
          ></CardFieldSelect>
          <CardFieldText
            register={register}
            name="dob"
            label={t("dob")}
            type="date"
            defaultValue={date.formatFormDate(customer.Dob)}
            readOnly={!canUpdate}
          ></CardFieldText>
          <LatitudeAndLongitudeCardField
            accounts={accounts}
            site={site}
            setAccountsAreLoaded={setAccountsAreLoaded}
          />
        </Card.Body>
        {/* TODO disable this if there are no changes in the form */}
        <Card.Footer>
          <CardUpdateButton canUpdate={canUpdate} loading={loading} />
          <AddLatLongInfo
            account={accounts[0]}
            site={site}
            setAccountsAreLoaded={setAccountsAreLoaded}
          />
        </Card.Footer>
      </Form>
    </Card>
  );
}

function LatitudeAndLongitudeCardField({accounts}) {
  const {t} = useTranslation(["customer", "shared"]);
  if (accounts.length === 0) {
    return "";
  }
  if (accounts[0].latitude === null && accounts[0].longitude === null) {
    return "";
  }
  return (
    <>
      <CardFieldText
        name="latitude"
        label={t("latitude")}
        type="text"
        defaultValue={accounts[0].latitude}
        readOnly={true}
      />
      <CardFieldText
        name="longitude"
        label={t("longitude")}
        type="text"
        defaultValue={accounts[0].longitude}
        readOnly={true}
      />
    </>
  );
}

function EnergyAccountCard({account, customer, fetchAccounts, setAccountsAreLoaded}) {
  const ref = createRef();
  const user = useContext(UserContext);
  const account_number = account.account_number;
  const [accountDetails, setAccountDetails] = useState(null);
  const [isEnergyAccountLoaded, setEnergyAccountLoaded] = useState(false);
  const {register} = useForm();
  const {t} = useTranslation(["customer"]);

  useEffect(() => {
    async function getEnergyAccountDetails(account_number) {
      setEnergyAccountLoaded(true);
      try {
        const resp = await meteringAPI.getEnergyAccountDetails(
          user.selected,
          account_number
        );
        if (resp.data.length > 0) {
          let meter = getActiveMeterDetails(resp.data);
          if (meter) {
            setAccountDetails(meter);
          }
        }
      } catch (err) {
        alerts.showPopup(alerts.getHttpMessage(err));
      }
    }
    //getActiveMeter get an active meter -- thru_date is null
    function getActiveMeterDetails(meters) {
      for (let meter of meters) {
        if (meter.thru_date == null) {
          return meter;
        }
      }
      return null;
    }

    if (!isEnergyAccountLoaded) getEnergyAccountDetails(account_number);
  }, [account_number, isEnergyAccountLoaded, user.selected]);

  if (!account.account_number || !customer.Id) {
    return <Skeleton height={40} width={300} count="4" className="m-3" />;
  }

  if (account.account_status === "INACTIVE" || accountDetails === null) {
    return (
      <Card>
        {" "}
        {/* Show notes if any availabe */}
        {account.notes ? (
          <Card.Body className="m-2 text-muted text-center">
            <p>{t("meterErrors")}</p>
            <span style={{color: "red"}}>{account.notes}</span>
          </Card.Body>
        ) : (
          <Card.Body className="m-2 text-muted text-center">{t("noMeter")}</Card.Body>
        )}
        <Card.Footer className="text-center">
          <AssignMeter account={account} setAccountsAreLoaded={setAccountsAreLoaded} />
        </Card.Footer>
      </Card>
    );
  }
  return (
    <Card className="text-right mb-4">
      <Form>
        <Card.Body>
          <CardFieldText
            name="site_name"
            label={t("site")}
            type="text"
            defaultValue={accountDetails.site_name}
            readOnly
          ></CardFieldText>
          <CardFieldText
            name="site_code"
            label={t("siteCode")}
            type="text"
            defaultValue={accountDetails.site_code}
            readOnly
          ></CardFieldText>
          <CardFieldText
            name="meter_serial_number"
            label={
              <MeterActions
                accountNo={account.account_number}
                customer={customer}
                meter={accountDetails.serial_number}
                setEnergyAccountLoaded={setEnergyAccountLoaded}
                setAccountsAreLoaded={setAccountsAreLoaded}
              ></MeterActions>
            }
            type="text"
            defaultValue={accountDetails.serial_number}
            readOnly
          ></CardFieldText>
          <CardFieldText
            name="assigned"
            label={t("assigned")}
            type="date"
            defaultValue={date.formatFormDate(accountDetails.from_date)}
            readOnly
          ></CardFieldText>
          <CardFieldText
            register={register}
            name="tariff_id"
            label={
              <UpdateTariff
                account_number={account.account_number}
                vendorId={accountDetails.vendor_id}
                tariffId={accountDetails.tariff_id}
                tariffName={accountDetails.tariff_name}
                setAccountsAreLoaded={setAccountsAreLoaded}
                setEnergyAccountLoaded={setEnergyAccountLoaded}
              ></UpdateTariff>
            }
            defaultValue={accountDetails.tariff_name}
            readOnly
          ></CardFieldText>
          <CardFieldURL
            name="url"
            label={t("url")}
            type="text"
            defaultValue={getUIEndpoint(accountDetails)}
            url={getUIEndpoint(accountDetails)}
            color="text-primary"
            readOnly
            plaintext
          ></CardFieldURL>
          <Row>
            <Col>
              <ManageTags
                accountNo={account.account_number}
                fetchAccounts={fetchAccounts}
              ></ManageTags>
              <UpdateCustomerID accountNo={account.account_number}></UpdateCustomerID>
            </Col>
          </Row>
          <Row>
            <Col className="mb-1 primary">
              <Typeahead
                id="public-methods-example"
                labelKey="name"
                multiple
                options={account.tags ? account.tags : []}
                disabled={true}
                placeholder="Choose a state..."
                ref={ref}
                selected={account.tags ? account.tags : []}
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer style={{height: 55}}>
          <MoveCustomerAccount
            accountNo={account.account_number}
            customer={customer}
            setAccountsAreLoaded={setAccountsAreLoaded}
          ></MoveCustomerAccount>
          <DeactivateAccount
            accountNo={account.account_number}
            setAccountsAreLoaded={setAccountsAreLoaded}
          ></DeactivateAccount>
        </Card.Footer>
      </Form>
    </Card>
  );
}

function ProductAccountCard({
  account,
  energyAccountNo,
  fetchAccounts,
  setAccountsAreLoaded,
}) {
  const user = useContext(UserContext);
  const ref = useRef();
  const account_number = account.account_number;
  const [productDetails, setProductDetails] = useState([]);
  const [err, setError] = useState(0);
  const {t} = useTranslation(["customer", "shared"]);
  const {register, handleSubmit} = useForm();
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const canUpdate = usePermissions(billingAPI.PRODUCT_ACCOUNTS_LIST_PATH, "PATCH");

  useEffect(() => {
    async function getProductDetails(portfolio, accountNumber) {
      try {
        const params = {accountNumber: accountNumber};
        let ci = user.permissions[user.selected].countryID;
        const resp = await billingAPI.getProductAccounts(portfolio, 0, 1, params);
        if (resp.data.count === 0) {
          setError(1);
        }
        setProductDetails(resp.data.data[0]);
        const rp = await billingAPI.getProductItemList(portfolio, 1, {
          product_code: resp.data.data[0].ProductCode,
          country_id: ci,
        });
        const r = await billingAPI.getProductPaymentPlans(portfolio, {
          product_id: rp.data.data.products[0].id,
        });
        const pList = [];
        r.data.data.payment_plans.forEach((p) => {
          pList.push({name: p.name, value: p.id});
        });
        setPaymentPlans(pList);
      } catch (e) {
        setError(1);
      }
    }
    getProductDetails(user.selected, account_number);
  }, [account, account_number, user]);

  if (err) {
    return (
      <Card>
        {" "}
        <Card.Body className="m-2 text-muted text-center">
          Product detail request failed
        </Card.Body>
      </Card>
    );
  }

  if (!account.account_number || !productDetails.ProductName || !paymentPlans.length) {
    return <Skeleton height={40} width={300} count="4" className="m-3" />;
  }

  const onSubmit = (data) => {
    const userConfirm = window.confirm(t("saveConfirmation"));
    if (userConfirm) {
      const payload = {
        id: productDetails.ProductAccountID,
        payment_plan_id: data.product_payment_plan,
        deposit_due_date: data.product_deposit_due_date,
      };
      updateProductDetails(user.selected, payload);
    }
  };

  async function updateProductDetails(portfolio, data) {
    try {
      setLoading(true);
      await billingAPI.updateProductAccountItems(portfolio, data);
      alerts.showPopup(t("updateSuccess"), "success");
    } catch (err) {
      alerts.showPopup(alerts.getHttpMessage(err), "error");
    }
    setLoading(false);
  }

  return (
    <Card className="text-right mb-4 ">
      <Form onSubmit={handleSubmit(onSubmit)} key={"savePrd"}>
        <Card.Body>
          <CardFieldText // TODO turn into a link to the product's page
            name="product_name"
            label={t("product")}
            type="text"
            defaultValue={productDetails.ProductName}
            readOnly
          ></CardFieldText>
          <CardFieldSelect
            register={register}
            name="product_payment_plan"
            label={t("plan")}
            defaultValue={productDetails.PaymentPlanID}
            defaultName={productDetails.PaymentPlanName}
            options={paymentPlans}
            readOnly={!canUpdate}
          ></CardFieldSelect>
          <CardFieldText
            name="product_price"
            label={t("price")}
            type="text"
            defaultValue={productDetails.Price}
            readOnly
          ></CardFieldText>
          <CardFieldText
            name="product_payment_amount"
            label={t("installment")}
            type="text"
            defaultValue={productDetails.MonthlyInstalment}
            readOnly
          ></CardFieldText>
          <CardFieldText
            name="product_amount_paid"
            label={t("paid")}
            type="text"
            defaultValue={productDetails.Paid}
            readOnly
          ></CardFieldText>
          <CardFieldText
            register={register}
            name="product_deposit_due_date"
            label={t("purchased")}
            type="date"
            defaultValue={date.formatFormDate(productDetails.DepositDueDate)}
            readOnly={!canUpdate}
          ></CardFieldText>
          <CardFieldText
            name="product_status"
            label={t("status")}
            type="text"
            defaultValue={productDetails.Status}
            readOnly
          ></CardFieldText>
          <Row>
            <Col>
              <ManageTags
                accountNo={account.account_number}
                fetchAccounts={fetchAccounts}
              ></ManageTags>
            </Col>
          </Row>
          <Row>
            <Col className="mb-1 primary">
              <Typeahead
                selected={account.tags ? account.tags : []}
                id="public-methods-example"
                labelKey="name"
                multiple
                options={account.tags ? account.tags : []}
                disabled={true}
                placeholder="Choose a state..."
                ref={ref}
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="text-center">
          <CardUpdateButtonProduct canUpdate={canUpdate} loading={loading} />
          <DeactivateAccount
            accountNo={account.account_number}
            setAccountsAreLoaded={setAccountsAreLoaded}
          ></DeactivateAccount>
          <RegisterProduct
            energyAccountNo={energyAccountNo}
            setAccountsAreLoaded={setAccountsAreLoaded}
            className="text-white p-1"
          />
        </Card.Footer>
      </Form>
    </Card>
  );
}

// Card fields to display individual data points inside cards

function CardFieldSelect(props) {
  return (
    <Form.Group as={Row} controlId={props.name}>
      <Form.Label column xs={4}>
        {props.label}
      </Form.Label>
      <Col xs={8}>
        <Form.Control
          ref={props.register}
          name={props.name}
          as="select"
          readOnly={props.readOnly}
          plaintext={props.plaintext}
          defaultValue={props.defaultValue}
        >
          {props.options.length
            ? props.options.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.name}
                </option>
              ))
            : null}
        </Form.Control>
      </Col>
    </Form.Group>
  );
}

function CardFieldURL(props) {
  return (
    <Form.Group as={Row} controlId={props.name}>
      <Form.Label column xs={5} className={props.className}>
        {props.label}
      </Form.Label>
      <Col xs={7}>
        <a href={props.url} rel="noopener noreferrer" target="_blank">
          <Form.Control
            className={props.color}
            ref={props.register}
            name={props.name}
            type={props.type}
            readOnly={props.readOnly}
            plaintext={props.plaintext}
            defaultValue={props.defaultValue}
          />
        </a>
      </Col>
    </Form.Group>
  );
}

function CardFieldText(props) {
  return (
    <Form.Group as={Row} controlId={props.name}>
      <Form.Label column xs={5} className={props.className}>
        {props.label}
      </Form.Label>
      <Col xs={7}>
        <Form.Control
          ref={props.register}
          name={props.name}
          type={props.type}
          readOnly={props.readOnly}
          plaintext={props.plaintext}
          defaultValue={props.defaultValue}
        />
      </Col>
    </Form.Group>
  );
}

function CardUpdateButton(props) {
  const {t} = useTranslation(["customer", "shared"]);
  if (props.canUpdate) {
    return (
      <Button type="submit" className="mb-2  pull-left" disabled={props.loading}>
        <FontAwesomeIcon icon="save" /> {props.loading ? t("updating") : t("save")}
      </Button>
    );
  } else {
    return null;
  }
}

function CardUpdateButtonProduct(props) {
  const {t} = useTranslation(["customer", "shared"]);
  if (props.canUpdate) {
    return (
      <Button type="submit" className="mb-2 p-1 pull-left" disabled={props.loading}>
        <FontAwesomeIcon icon="save" /> {props.loading ? t("updating") : t("save")}
      </Button>
    );
  } else {
    return null;
  }
}

function getUIEndpoint(accountDetails) {
  if (!accountDetails.endpoint) return "";
  const sparkRegex = /\/\/(.+).sparkmeter/;
  const sparkMatch = accountDetails.endpoint.match(sparkRegex);

  if (sparkMatch) {
    //nova site should have `/api/v1`
    if (accountDetails.endpoint.includes("/api/v1")) {
      return `https://sparkmeter.cloud/portfolio/${accountDetails.portfolio_id}/customers/${accountDetails.customer_id}`;
    }
    return `https://${sparkMatch[1]}.sparkmeter.cloud/meter/${accountDetails.serial_number}`;
  } else if (accountDetails.endpoint.includes("steama")) {
    return "https://ui.steama.co/customers/" + accountDetails.customer_id;
  } else {
    return accountDetails.endpoint;
  }
}
