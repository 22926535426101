import axios from "axios";
import {serviceBaseURL, AUTH_BASE_URL} from "./baseURL";

//This creates an axios instance for all microservices different from auth
export function axiosInstance(portfolio) {
  const instance = axios.create();
  instance.defaults.withCredentials = true;
  instance.defaults.baseURL = serviceBaseURL(portfolio);
  instance.defaults.headers["x-pg-portfolio"] = portfolio;
  return instance;
}

//This creates an axios instance for auth microservice
export function axiosAuthInstance() {
  const authIntance = axios.create();
  authIntance.defaults.withCredentials = true;
  authIntance.defaults.baseURL = AUTH_BASE_URL;
  authIntance.defaults.headers["x-pg-portfolio"] = "AUTH";
  return authIntance;
}
