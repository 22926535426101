import React, {useState, useEffect, useContext} from "react";
import {UserContext} from "../login/userContext";
import {Button, Modal, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as billingAPI from "../../services/billingApi";
import * as meteringAPI from "../../services/meteringApi";
import {usePermissions} from "../permissions";
import {
  getFormProps,
  FormField,
  FormSelect,
  FormTypeAhead,
  getTypeAhedProps,
  FormErrors,
} from "../generics/formsFields";
import {RegisterButton, ModalCancelButton} from "../generics/buttons";
import {showPopup, showErrors, getHttpMessage} from "../generics/alerts";
import {ListHeader} from "../generics/headers";

export default function Register({setLoading, loading}) {
  const canView = usePermissions(billingAPI.COLLECTION_ACCOUNTS_REGISTER_PATH, "POST");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {t} = useTranslation(["collection", "shared"]);

  return (
    <div>
      <Button
        disabled={!canView}
        title={!canView ? t("registerPermission") : null}
        variant="danger"
        onClick={handleShow}
        className="text-white float-right"
      >
        <FontAwesomeIcon icon="plus" /> {t("buttonTitle")}
      </Button>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <ListHeader title={t("registrationTitle")} icon="users" />
        </Modal.Header>
        <Modal.Body>
          <RegisterForm setShow={setShow} setLoading={setLoading} loading={loading} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export function RegisterForm({setShow, setLoading, loading}) {
  // set up contexts and variables for the form
  const user = useContext(UserContext);
  const {t} = useTranslation(["collection", "shared"]);
  const {register, handleSubmit} = useForm();
  // fetch and populate options for owner field
  const [customers, setCustomers] = useState([]);
  const [providers, setProviders] = useState([]);
  const [errors, setErrors] = useState([]);
  const [sites, setSites] = useState([]);
  const [saving, setSaving] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  let countryID = user.permissions[user.selected].countryID;

  useEffect(() => {
    async function fetchCustomers() {
      try {
        const resp = await billingAPI.getCustomerAccounts(user.selected, {
          account_type: "ENERGY",
          country_id: countryID,
        });
        let customerInfo = [];
        resp.data.data.forEach((cust) => {
          let customer_name_account = cust.customer_name + " - " + cust.account_number;
          customerInfo.push({name: customer_name_account, value: cust.customer_id});
        });
        setCustomers(customerInfo);
      } catch (err) {
        showErrors(getHttpMessage(err));
      }
      setLoaded(true);
    }

    async function fetchAccountProviders() {
      try {
        const resp = await billingAPI.getCollectionAccountProviders(user.selected, {});
        let prov = [];
        resp.data.data.account_providers.forEach((p) => {
          prov.push({name: p.name, value: p.id});
        });
        setProviders(prov);
      } catch (err) {
        showErrors(getHttpMessage(err));
      }
      setLoaded(true);
    }

    async function fetchSites() {
      try {
        const resp = await meteringAPI.getSites(user.selected, {
          status: "active",
          country_id: countryID,
        });
        let siteJson = [];
        resp.data.data.forEach((site) => {
          siteJson.push({name: site.site_name, value: site.site_code});
        });
        setSites(siteJson);
      } catch (err) {
        showPopup(getHttpMessage(err));
      }
      setLoaded(true);
    }

    if (!isLoaded) {
      fetchCustomers();
      fetchAccountProviders();
      fetchSites();
    }
  }, [user.selected, countryID, isLoaded]);

  const account_number = getFormProps(
    "account_number",
    t("accountNumber"),
    "text",
    "true"
  );
  const vendor_agent_id = getFormProps(
    "external_id",
    t("vendorAgentID"),
    "text",
    "false"
  );
  const provider_id = getFormProps(
    "provider_id",
    t("providerID"),
    "select",
    "false",
    "",
    t("selectProvider"),
    providers
  );
  const phone_number = getFormProps("phone_number", t("phoneNo"), "text", "true");
  const site_code = getFormProps(
    "site_code",
    t("siteCode"),
    "select",
    "true",
    "",
    t("selectSite"),
    sites
  );
  const customer = getTypeAhedProps(
    "typeahead-options",
    "customer",
    "Customer",
    selected,
    setSelected,
    customers
  );

  // function to call when submitting form
  const onSubmit = (data) => {
    data.customer_id = selected[0].value;
    registerCollection(user.selected, [{...data}]);
  };

  async function registerCollection(portfolio, data) {
    setErrors([]);
    setSaving(true);
    try {
      await billingAPI.registerCollectionAccounts(portfolio, data);
      showPopup(t("shared:success"), "success");
      setShow(false);
      setLoading(!loading);
    } catch (err) {
      showErrors(err, setErrors);
    }
    setSaving(false);
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4">
        <Row>
          <FormErrors errors={errors}></FormErrors>
          <FormTypeAhead {...customer} />
          <FormField register={register} {...account_number} />
          <FormSelect register={register} {...site_code} />
          <FormField register={register} {...vendor_agent_id} />
          <FormSelect register={register} {...provider_id} />
          <FormField register={register} {...phone_number} />
          <ModalCancelButton label={t("cancel")} setShow={setShow} />
          <RegisterButton label={t("register")} disabled={saving} />
        </Row>
      </Form>
    </div>
  );
}
