import React, {useState, useContext} from "react";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import {UserContext} from "../login/userContext";
import {usePermissions, Unauthorized} from "../permissions";
import {showPopup, getHttpMessage} from "../generics/alerts";
import {FileUpload, Instructions, ErrorList} from "../generics/fileUploads";
import * as billingAPI from "../../services/billingApi";

export default function RegisterBulkCustomer() {
  const {t} = useTranslation(["fileupload"]);
  const user = useContext(UserContext);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0); // %
  const [errors, setErrors] = useState([]);
  const canView = usePermissions(billingAPI.CUSTOMER_REGISTER_PATH, "POST");
  const pageTitle = t("registerCustomers");

  if (!canView) {
    return <Unauthorized />;
  }

  const filename = "sample_customers.csv";
  const headers =
    "first_name, last_name, gender, language, dob, identification, phone, site_code, house,  meter, [lat], [long], [address], [server_id], [tags]";
  const sampleData = [
    [
      "first_name",
      "last_name",
      "gender",
      "language",
      "dob",
      "identification",
      "phone",
      "site_code",
      "house",
      "meter",
    ],
    [
      "John",
      "Doe",
      "M",
      "ENGLISH",
      "1970-01-23",
      "123456789",
      "+255725648558",
      "0040",
      "120",
      "SM5R-05-00006789",
    ],
  ];

  const reminders = [
    t("siteInstruction"),
    t("languageInstruction"),
    t("shared:waitAfterUpload"),
  ];

  async function registerCustomer(customers, config) {
    try {
      await billingAPI.registerCustomer(user.selected, customers, config);
      showPopup(t("shared:successWithWait"), "success");
      resetStates();
    } catch (err) {
      let errorBuffer = err.response.data;
      if (Array.isArray(errorBuffer)) {
        setErrors(errorBuffer);
      } else if (errorBuffer) {
        setErrors([errorBuffer]);
      } else {
        setErrors([getHttpMessage(err)]);
      }
      setProgress(0);
    }
  }

  const resetStates = () => {
    setProgress(0);
    setData([]);
    setLoaded(false);
    setErrors([]);
  };

  return (
    <div>
      {/* <ListHeader title={t("uploadCustomers")} icon="file-import" /> */}
      <Card className="shadow">
        <Card.Body className="p-4">
          <Instructions
            title={pageTitle}
            isLoaded={isLoaded}
            headers={headers}
            filename={filename}
            sampleData={sampleData}
            reminders={reminders}
          />
          <FileUpload
            sampleData={sampleData}
            data={data}
            errors={errors}
            progress={progress}
            setData={setData}
            setErrors={setErrors}
            setProgress={setProgress}
            setLoaded={setLoaded}
            setColumns={setColumns}
            sendToAPI={registerCustomer}
            handleCancel={resetStates}
          />
          <ErrorList errors={errors} handleReUpload={resetStates} exportErrors={true} />
          <DataTable
            pagination
            highlightOnHover
            columns={columns}
            data={data}
            noDataComponent=""
          />
        </Card.Body>
      </Card>
    </div>
  );
}
